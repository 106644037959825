import React, { useEffect, useState } from "react";
import images from "../../services/images";
import StarRating from "../Starating";
import { useNavigate } from "react-router-dom";
import FormCarousel from "../FormCarousel";
import AdEscorts from "../AdEscorts";

export default function EscortAvailable({ escortimages, addData }) {
  const navigate = useNavigate();

  const combinedData = [];
  let adIndex = 0; // This index will track the position in the ads array

  // Insert each escort into the combinedData array
  escortimages?.forEach((escort, index) => {
    combinedData.push(escort); // Add the escort

    // Every three escorts, add an ad (if there are still ads to add)
    if ((index + 1) % 3 === 0 && adIndex < addData.length) {
      combinedData.push({ type: "ad", content: addData[adIndex] });
      adIndex++; // Move to the next ad
    }
  });

  // After all escorts have been added, add any remaining ads
  while (adIndex < addData?.length) {
    combinedData.push({ type: "ad", content: addData[adIndex] });
    adIndex++;
  }
  console.log("EscortDat", combinedData);

  return (
    <div className="w-full gap-3 flex flex-col ">
      {combinedData.map((item, index) =>
        item.type === "ad" ? (
          <AdEscorts key={`ad-${index}`} ad={item.content} />
        ) : (
          <div key={`escort-${index}`} className="w-full flex flex-col gap-6">
            <div
              className="bg-buttoncolor cursor-pointer active:opacity-50 h-40 items-center gap-4 flex w-full md:w-[750px]"
              onClick={() => {
                const _id = item._id;
                navigate(`/Modeldetails/${_id}`, { state: { item } });
              }}
            >
              <img src={item.profilePhoto} alt="" className="h-32 w-32" />
              <div className="flex flex-col gap-5">
                <div className="text-sm sm:text-2xl text-whitepink font-semibold">
                  {item.firstname} {item.lastname}
                </div>
                <div className="text-xxs sm:text-sm text-whitepink">
                  {item.listingDetails?.age} - {item.listingDetails?.name} -{" "}
                  {item.listingDetails?.location}
                </div>
                <div className="font-semibold text-xs sm:text-base rounded-2xl gap-1 sm:gap-3 h-5 sm:h-9 text-sm bg-whitepink flex items-center justify-center w-20 sm:w-32 text-textdarkerpurpel">
                  <img
                    src={images.verified1}
                    className="h-4 w-4 sm:h-6 sm:w-6"
                  />
                  Verified
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}
