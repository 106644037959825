import axios from "axios";
import { useAuth } from "../Authcontext/Authcontext";

// export const baseURL = "https://pleasury-59d76303ffbd.herokuapp.com/";
// export const baseURL = "http://192.168.100.112:3001/";       //Aleem
// export const baseURL = "http://192.168.100.30:3001/"; //ali
// export const baseURL = "http://192.168.100.24:3001/";    //Mustafa
export const baseURL = "https://pleasury-2-321d8ece8c3d.herokuapp.com/"; //updated backend

export const serverConnection = async () => {
  try {
    const { data } = await axios.get(baseURL);
    return data;
  } catch (error) {
    return error;
  }
};

export const userSignin = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      //   Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(`${baseURL}auth/userSignin`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const escortSignin = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.post(`${baseURL}auth/escortSignin`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const createInitialListing = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.post(`${baseURL}escort/updateListing`, body, {
      headers,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const escortSignup = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.post(`${baseURL}auth/escortSignup`, body, {
      headers,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const createAdvertisement = async (body, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/createAdvertisement`,
      body,
      { headers }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const getStreamers = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${baseURL}escort/getStreamers`, {
      headers,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const getAds = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${baseURL}escort/getAds`, {
      headers,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const getEscorts = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${baseURL}escort/getEscorts`, {
      headers,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const userSignup = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.post(`${baseURL}auth/userSignup`, body, {
      headers,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const forgotPass = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.post(`${baseURL}auth/forgotPassword`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const resetPass = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.post(`${baseURL}auth/resetPassword`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateListing = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.post(`${baseURL}escort/updateListing`, body, {
      headers,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const checkEmail = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.post(`${baseURL}auth/checkEmail`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const verifyEmail = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.post(`${baseURL}auth/verifyEmail`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const uploadProfile = async (formData) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const { data } = await axios.post(`${baseURL}auth/uploadProfile`, formData, {
    headers,
  });
  return data;
};

export const updateEscortProfile = async (body, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(`${baseURL}escort/updateProfile`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateUserProfile = async (body, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(`${baseURL}user/updateProfile`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getBookedSlot = async (body, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/getBookedSchedule`,
      body,
      { headers }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const handleVideosUpload = async (body, token) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(`${baseURL}auth/uploadVideo`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const handleUploadPictures = async (body, token) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/uploadMultiplesImages`,
      body,
      { headers }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getMyAds = async (_id, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${baseURL}escort/getMyAds/${_id}`, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateAd = async (body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.post(`${baseURL}escort/updateAd`, body);
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteAd = async (_id, upgrades_id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const { data } = await axios.post(`${baseURL}escort/deleteAd/${_id}`, {
      upgrades_id,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const applyVerify = async (body, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/updateVerificationPicture`,
      body,
      { headers }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getEscortData = async (_id, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(
      `${baseURL}escort/getEscortForVerify/${_id}`,
      { headers }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const selectVerified = async (_id, token, body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/acceptVerifiedInvitation/${_id}`,
      body,
      { headers }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteAccount = async (body, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(`${baseURL}auth/deleteAccount`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const toggleFavoriteAd = async (body, token) => {
  try {
    console.log(body, token);
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(`${baseURL}user/toggleFavorite`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllFavorites = async (token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${baseURL}user/getFavorites`, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getVerifiedStatus = async (token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${baseURL}escort/getVerifiedStatus`, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const toggleHideAds = async (body, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(`${baseURL}escort/toggleHideAds`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const changePasswordUser = async (body, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(`${baseURL}user/changePassword`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const changePasswordEscort = async (body, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(`${baseURL}escort/changePassword`, body, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const streamingToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJjNjhiYTc4Ny01MTJiLTQ3OGUtYmMwZS02N2ViNzk5NTllMDUiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcyMzY1NDUyMywiZXhwIjoxODgxNDQyNTIzfQ.o282xmS9x-CA190TDn-iAM9-gAANltlJ8bd6rUXCLwk";
export const createMeeting = async ({ token }) => {
  const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
    method: "POST",
    headers: {
      authorization: `${streamingToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  const { roomId } = await res.json();
  return roomId;
};

// export const streamingToken =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJlOGNiNGIyNC00YzM0LTRjYTMtOTQ2ZC01MDk5MWI2MjcwZGMiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcxNzQwMTUyMywiZXhwIjoxODc1MTg5NTIzfQ.ZmOuXPyO_G_tcrbriuPWvIvPHpL7-FJIKnbKzIRr4ik";
// // API call to create a meeting
// export const createMeeting = async ({ token }) => {
//   const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
//     method: "POST",
//     headers: {
//       authorization: `${streamingToken}`,
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({}),
//   });
//   //Destructuring the roomId from the response
//   const { roomId } = await res.json();
//   return roomId;
// };

export const updateStreamingId = async (body, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/updateStreamingId`,
      body,
      {
        headers,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const handleManageUpgrades = async (token, body, _id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/manageUpgrades/${_id}`,
      body,
      { headers }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const handlePaymentVerification = async (token, body) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/sendEmailForPayment`,
      body,
      { headers }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const handleUpdatePayment = async (token, body, _id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/setPaymentDetailsForVerify`,
      body,
      { headers }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllTransactions = async (token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${baseURL}escort/getTransactions`, {
      headers,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateTransactionStatus = async (id, status, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/updateTransactionStatus`,
      { id, status },
      { headers }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const setPaymentDetailsForVerify = async (body, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/setPaymentDetailsForVerify`,
      body,
      { headers }
    );
    return data;
  } catch (error) {
    console.error(
      "Error updating wallet:",
      error.response ? error.response.data : error.message
    );
    return error;
  }
};
export const setPaymentDetailsForVerifyDec = async (body, token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `${baseURL}escort/setPaymentDetailsForVerifyDec`,
      body,
      { headers }
    );
    return data;
  } catch (error) {
    console.error(
      "Error updating wallet:",
      error.response ? error.response.data : error.message
    );
    return error;
  }
};

// export const getUserDetails = async (token) => {
//   try {
//     const response = await axios.get('user/getescortDetails', {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     if (response.data.success) {
//       return response.data.userData;
//     } else {
//       console.error(response.data.message);
//       return null;
//     }
//   } catch (error) {
//     console.error('Error fetching user details:', error);
//     return null;
//   }
// };

export const getUserDetails = async (token) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${baseURL}user/getescortDetails`, {
      headers,
    });

    if (data.success) {
      return data.userData;
    } else {
      console.error(data.message);
      return null;
    }
  } catch (error) {
    console.error("Error fetching user details:", error);
    return null;
  }
};

export const escortSubscribeToEscort = async (targetEscortId, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(
      `${baseURL}escort/subscribeEscort`, // Adjust the endpoint as needed
      { targetEscortId },
      { headers }
    );
    return response?.data;
  } catch (error) {
    console.error("Error subscribing to escort:", error);
    throw error; // rethrow the error to be caught by the calling function
  }
};

export const unsubscribeFromEscort = async (escortId, token) => {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const { data } = await axios.post(
      `${baseURL}user/unsubscribeEscort`,
      { escortId },
      { headers }
    );
    return data;
  } catch (error) {
    console.error("Error unsubscribing from escort:", error);
    throw error;
  }
};

export const subscribeToEscort = async (escortId, token) => {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.post(
      `${baseURL}user/subscribeEscort`,
      { escortId },
      { headers }
    );
    return response?.data;
  } catch (error) {
    console.error("Error subscribing to escort:", error);
    throw error;
  }
};

export const getSmallUserDetails = async (token) => {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get(`${baseURL}user/getUserDetails`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};
