import React from "react";
import images from "../../services/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { selectUserData } from "../../store/userData";
import { useSelector } from "react-redux";

export default function Golive() {
  const navigate = useNavigate();
  const location = useLocation();
  const { meetingId } = location.state || {};
  console.log(meetingId, "=-==========???");
  // const authToken = useSelector(selectToken);

  const escort = useSelector(selectUserData);

  console.log(meetingId, "========??");

  return (
    <div className="bg-purplegray">
      <div className=" p-10 w-full flex justify-between">
        <div
          className="bg-white  px-3 py-3 flex items-center justify-center cursor-pointer active:opacity-50"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={images.arrowleft} alt="" className="w-4 h-3" />
        </div>
        <div className=" w-full pr-10 flex justify-center items-center text-white font-semibold lg:text-4xl md:text-2xl sm:text-xl text-xl ">
          Ready to Go Live
        </div>
      </div>
      {/* <div className="flex w-full justify-center text-white lg:text-xl md:text-md sm:text-sm text-sm font-semibold">
        SETTINGS
      </div> */}

      {/* <div className="flex w-full justify-center pt-5">
        <div className="bg-newcolor lg:h-24 lg:w-24 md:h-20 md:w-20 sm:w-16 sm:h-16 w-12 h-12 rounded-full flex items-center justify-center mr-10 cursor-pointer active:opacity-50">
          <img src={images.microphone} alt="" className="w-5" />
        </div>
        <div className="bg-newcolor lg:h-24 lg:w-24 md:h-20 md:w-20 sm:w-16 sm:h-16 w-12 h-12 rounded-full flex items-center justify-center mr-10 cursor-pointer active:opacity-50">
          <img src={images.soundlisten} alt="" className="w-5" />
        </div>
        <div className="bg-newcolor lg:h-24 lg:w-24 md:h-20 md:w-20 sm:w-16 sm:h-16 w-12 h-12 rounded-full flex items-center justify-center cursor-pointer active:opacity-50 ">
          <img src={images.videocamera} alt="" className="w-5" />
        </div>
      </div> */}
      {/* <div className="w-full pb-10 flex flex-col  text-whitepink pt-10 gap-2 items-center">
        <div className="lg:text-xl md:text-md sm:text-sm text-sm font-semibold ">
          Select Stream Categories
        </div>
        <div className="lg:text-sm md:text-sm sm:text-xs text-xxs">
          Add your stream to a category so viewers can find it more easily
        </div>
        <div className="text-sm lg:w-30p md:w-60p sm:w-60p w-80p  bg-buttoncolor flex items-center gap-4">
          <img src={images.search} alt="" className="w-4 h-4 ml-5" />
          <input
            type="text"
            className="focus:outline-none bg-buttoncolor text-white h-14 w-full placeholder-text-white"
            placeholder="Search Stream categories"
          />
        </div>
      </div> */}
      <div className="flex justify-center h-[50vh] items-center ">
        <div>
          <div className="flex justify-center ">
            <img
              src={escort?.profilePhoto}
              alt=""
              className="h-32 rounded-full w-32 "
            />
          </div>
          <div className="flex justify-center ">
            <div className="text-white pt-5 lg:text-xl md:text-lg sm:text-md text-base font-semibold cursor-pointer whitespace-nowrap">
              {escort?.firstname} {escort?.lastname}
            </div>
          </div>
          <div className="w-full flex  justify-center mt-8 ">
            <div
              className="bg-purple px-8 py-3 lg:text-base md:text-md text-sm  flex justify-center items-center text-white cursor-pointer active:opacity-50 "
              onClick={() => {
                navigate(`/LiveStream/856242`, { state: { meetingId } });
              }}
            >
              Start Live Stream
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
