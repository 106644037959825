import React, { useState } from "react";
import images from "../../services/images";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function EditListing() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [height, setHeight] = useState("");
  const [location, setLocation] = useState("");
  const [age, setAge] = useState("");
  const [error, setError] = useState();

  const [image, setImage] = useState("");
  const inputFile = useRef(null);

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;

      var parts = filename.split(".");
      const fileType = parts[parts.length - 1];

      setImage(files[0]);
    }
  };

  const navigate = useNavigate();

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const uploadimages = [
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
  ];

  const validateForm = () => {
    // const { name, email, location, phoneNumber, height, age } = formData;

    // Check if any required fields are empty
    if (!name || !email || !location || !phone || !height || !age) {
      setError("Please fill out all required fields.");

      return false;
    }

    // Validate phone format (simple example)
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      setError("Invalid phone number format. Use 10 digits.");
      return false;
    }
    // Validate email format (simple example)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email format.");
      return false;
    }

    // Validate height format (e.g., 5'9")
    const heightRegex = /^(\d{1,})'(\d{1,2})"$/;
    if (!heightRegex.test(height)) {
      setError("Invalid height format. Use a format like 5'9\".");
      return false;
    }

    // Validate age above 18

    if (age < 18) {
      setError("Age must be a number and 18 or above.");
      return false;
    }
    // Clear error if all validations pass
    setError("");
    navigate("/Escorts/viewlisting");
    return true;
  };

  const [uploadimg, setuploadedimg] = useState("null");

  return (
    <div className="bg-purplegray">
      <div className=" flex flex-col justify-center items-center">
        <div className="w-full  flex items-center pt-10 pb-5 pl-10">
          <Link to={"/Escorts/viewlisting"}>
            <div className="bg-white flex justify-center items-center lg:w-13 lg:h-12 w-8 md:h-7 sm:h-7 h-7 cursor-pointer active:oapcity-50">
              <img src={images.arrowleft} alt="" className="lg:w-5 md:w-4 sm:w-3 w-3" />
            </div>
          </Link>

         <div className="w-full  flex text-white ml-[2%]  font-semibold justify-evenly pr-10">
            <div className="text-center lg:mr-16 md:mr-16 sm:mr-16 mr-10">
            <div className=" lg:text-4xl md:text-3xl sm:text-xl text-lg ">Edit Listing</div>
            </div>
          </div>
        </div>
        <div className="flex w-95p flex-col  items-center">
          <div className="w-28p  text-center lg:text-base md:text-sm sm:text-sm text-xs text-white">
            To apply, please fill out this form below. your application with
            luxury escort will be kept strictly confidential.
          </div>
          <div className="text-red-500 pt-2 lg:text-base md:text-sm sm:text-xs text-xs  ">{error}</div>
        </div>
        <div className="flex-col flex  w-96p ">
          <div className=" flex  justify-center items-center pt-10 ">
            <div className="lg:w-[19.7%] md:w-50p w-50p">
              <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal ">
                  Name
                </div>
              </div>
              <input
                type="text"
                className="bg-transparent focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-xs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="lg:w-[19.7%] md:w-50p w-50p  ml-5">
              <div className=" h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal ">
                  Phone Number
                </div>
              </div>
              <input
                type="number"
                className="bg-transparent focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-xs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="flex w-full justify-center items-center pt-4">
            <div className="lg:w-[19.7%] md:w-50p w-50p ">
              <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal ">
                  Email
                </div>
              </div>
              <input
                type="email"
                className="bg-transparent focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-xs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="lg:w-[19.7%] md:w-50p w-50p ml-5">
              <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal ">
                  Height
                </div>
              </div>
              <input
                type="text"
                className="bg-transparent focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-xs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5"
                placeholder="Enter Height"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              />
            </div>
          </div>
          <div className="flex w-full justify-center items-center pt-4">
            <div className="lg:w-[19.7%] md:w-50p w-50p ">
              <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal ">
                  Location
                </div>
              </div>
              <input
                type="text"
                className="bg-transparent focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-xs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5"
                placeholder="Enter Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="lg:w-[19.7%] md:w-50p w-50p ml-5">
              <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal ">
                  Age
                </div>
              </div>
              <input
                type="number"
                className="bg-transparent focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-xs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="pt-10 w-full justify-center flex relative ">
          <div className="w-20 h-6 px-2 py-1 bg-purplegray justify-center absolute bottom-[75%] left-[32%] items-center inline-flex">
            <div className="text-center text-fuchsia-100 text-xs font-normal ">
              Description
            </div>
          </div>
          <div className="lg:w-39p w-full pt-2 rounded-xl border border-whitepink border-opacity-20 h-auto">
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="10"
              className="w-full block bg-transparent text-white opacity-60 h-40 lg:text-lg md:text-base sm:text-sm text-xs focus:outline-none pl-4"
              placeholder="Please tell us about yourself"
            ></textarea>
          </div>
        </div>
        <div
          className="bg-footercolor lg:w-39p w-full rounded-xl flex items-center justify-center h-24 mt-5 mb-5 border border-dashed border-2 cursor-pointer active:opacity-50 "
          onClick={onButtonClick}
        >
          <div className="">
            <img src={images.picture} alt="" className="w-10 flex " />
          </div>
          <input
            style={{ display: "none" }}
            // accept=".zip,.rar"
            ref={inputFile}
            onChange={handleFileUpload}
            type="file"
          />
          <div className="text-white">Upload Photos</div>
        </div>

        <div className="flex mb-10 gap-2 ">
          {uploadimages.map((item, index) => (
            <div key={index} className="">
              <img
                src={uploadimg ? item.image1 : images.Liveimgmodel1}
                alt=""
                className="w-16 cursor-pointer acitve:opacity-50"
                onClick={() => {
                  setuploadedimg(!uploadimages);
                }}
              />
            </div>
          ))}
        </div>
        <div
          className="lg:w-48 lg:text-base md:text-sm sm:text-xs text-xs lg:h-14 h-10 px-2 bg-fuchsia-600 mb-32 cursor-pointer justify-center items-center gap-2.5 inline-flex"
          onClick={validateForm}
        >
          <div className="text-center text-fuchsia-100 font-semibold">
            Apply Now
          </div>
        </div>
      </div>
    </div>
  );
}
