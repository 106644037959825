import React, { useEffect, useState } from "react";
import images from "../../services/images";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store/userData";
import { useNavigate } from "react-router-dom";

export default function Contact() {
  const [dropDown, setDropDown] = useState("");
  const [category, setCatergory] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [location, setLocation] = useState("");

  const toggleDropdown = (name) => {
    if (dropDown === name) {
      setDropDown("");
    } else {
      setDropDown(name);
    }
  };

  const [cateogryData, setCategoryData] = useState([
    "Advertising Inquiry",
    "Tech Support/Report a bug",
    "Problems with payment",
    "General Question/Suggestion",
    "Breach of Copyright (DMCA)",
    "Report Violation/Offense",
    "Verification/Directory",
    "Identity Verification",
  ]);

  const [provinceCandaData, setProvinceCanadaData] = useState([
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon",
  ]);

  const userData = useSelector(selectUserData);

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -100; // Adjust this value as needed to scroll to the desired position within the section
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }

  const navigate = useNavigate();

  useEffect(() => {
    handleScrollToTop();

    console.log(userData);
    setUserEmail(userData?.email);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="">
      <div className="flex text-whitepink flex justify-center  gap-10 pt-20 pb-20">
        <div className="w-full md:w-36p">
          <div className=" bg-footercolor py-3 flex flex-col ">
            <div className="text-2xl pl-3 pb-3" id="rules">
              Rules & Guidelines
            </div>
            <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2 border-b pb-10 border-whitepink border-opacity-40 pt-4 ">
              <div>
                We seek to create a safe and welcoming environment for users to
                post and respond to ads. We have provided the following
                guidelines to make sure our users may interact safely.
              </div>
              <div>
                In your interaction with others, always be respectful. Follow
                the golden rule: if you don't have anything nice to say, don't
                say anything at all.
              </div>
            </div>
            <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2 border-b pb-10 border-whitepink border-opacity-40 pt-4 ">
              <div className="text-lg font-bold">Reviews</div>
              <div>
                We are NOT a review board. Do not link to, or post, any reviews
                (with the exception of Lyla recommendations).
              </div>
            </div>
            <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2 border-b pb-10 border-whitepink border-opacity-40 pt-4 ">
              <div className="text-lg font-bold">Content Violations</div>
              <div>
                The content you post -- words, pictures, videos, images --
                should not violate the rights of others and should not contain
                or condone defamatory, fraudulent, deceptive, abusive,
                drug-related or illegal activities
              </div>
              <div>
                Given the amount of content that is posted, we are unable to
                monitor it all. We ask that you as a community assist us in
                making this site a pleasant environment. If you have a
                reasonable belief any posted content would violate our terms of
                service, gives misleading information, or serves the purpose of
                harassing -- please report it to us by clicking the "Report Ad"
                button located on every ad. If you suspect that content posted
                breaks the law by violating copyright law, please report that{" "}
                <span
                  className="font-bold cursor-pointer active:opacity-40  text-purple"
                  onClick={() => navigate("/report-copyright")}
                >
                  here
                </span>
                .
              </div>
            </div>
            <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2 border-b pb-10 border-whitepink border-opacity-40 pt-4 ">
              <div className="text-lg font-bold">Adult Ads</div>
              <div>
                If you have a reason to suspect that content distributed might
                be of an underage individual or is somehow connected to human
                trafficking -- or you have reason to know of suspected criminal
                activity -- please report it immediately to the appropriate LAW
                ENFORCEMENT authorities. Once contacted by the proper
                authorities, we will cooperate to the fullest extent possible.
                If you suspect or believe you have been the victim of a crime,
                please ensure you reach out to your local authority and seek
                further assistance. Only by reporting illegal activity to the
                appropriate authorities, can you assist us by ensuring we remain
                a safe environment and community.
              </div>
              <div>
                In our Adult ads, we do allow tasteful topless images to appear.
                However, any nudity 'below the waist' nudity is strictly
                forbidden along with any sexually explicit acts. We encourage
                all of our users to report any such cases to us by visiting the
                offending listing and pressing the report an ad button. This is
                the only way we can ensure swift action is taken to remove such
                content from the site. If an ad violates our rules on nudity,
                then all the images within the listing are also removed. Any
                form of nudity in any section, other than in our Personals
                section, is strictly forbidden and should be reported.
              </div>
            </div>
            <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2 border-b pb-10 border-whitepink border-opacity-40 pt-4 ">
              <div className="text-lg font-bold">Ad Rules</div>
              <div>
                <li>Always post ads in the correct category</li>
                <li>
                  {" "}
                  Users can post as many paid ads as they like. No one likes
                  spam though, please respect your fellow advertisers by not
                  over doing it.
                </li>
                <li>
                  You can only post 1 free ad per day. If you want to post more
                  ads, please purchase an upgrade, or when you try to post your
                  2nd ad, follow the ‘get back in action’ steps. *Only
                  applicable to the free sections of the site.
                </li>
                <li>
                  Creating multiple accounts to post free ads in unpaid
                  categories is not permitted. We only allow 1 account per user.
                </li>
                <li>
                  We encourage you to promote your website on our site to
                  increase your traffic. You may link to your website as allowed
                  by the terms of service. However we ask that you do NOT use
                  our site to promote other competing websites.
                </li>
                <li>Always use your common sense and better judgement.</li>
              </div>
            </div>
          </div>
          <div className=" bg-footercolor  flex flex-col " id="FAQ">
            <div className="text-2xl pl-3 pb-3 border-b border-opacity-40 border-whitepink">
              FAQ
            </div>
            <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2 border-b pb-10 border-whitepink border-opacity-40 pt-4 ">
              <div className="text-lg font-bold">Upgrades and Features</div>
              <div>
                <ol className="list-decimal list-inside ml-2">
                  <li className="font-bold">
                    Always post ads in the correct category
                  </li>
                  <div className="ml-4">
                    Don't panic! Cryptocurrency transactions can occasionally
                    encounter delays which are beyond our control. If you don’t
                    see the amount credited to your Giftcard immediately, you
                    should not worry. This is due to delayed processing times on
                    Blockchain. Smaller transactions generally take longer,
                    whilst larger transactions tend to take less time. To play
                    it safe, we recommend topping up your Giftcard regularly, so
                    that you always have available funds.
                  </div>

                  <li className="font-bold mt-2">
                    How will my payments on the site display on my bank
                    statements?
                  </li>
                  <div className="ml-4">
                    Purchases made on the site will appear as PleasuryServices
                    or as PleasuryList.com.
                  </div>
                  <li className="font-bold mt-2">What is a Bump?</li>
                  <div className="ml-4">
                    When you bump one of your ads, it is pushed to the first
                    position of the page in which it appears. You can only use
                    Bumps for the specific ad you purchased it for, so they
                    can't be transferred between your ads.
                  </div>
                  <li className="font-bold mt-2">How does Page 1 work?</li>
                  <div className="ml-4">
                    Page 1 allows you to have your ads automatically kept on the
                    first page of the list in the region/city they appear in.
                    For the duration of your Page 1 upgrade, it will
                    automatically be bumped each time it drops off the first
                    page, so that it returns to the very top of it. The process
                    will then repeat itself until the upgrade expires. If your
                    ad does not drop off the first page within 2h 20m, it will
                    still be automatically bumped to the top again.
                  </div>
                  <li className="font-bold mt-2">
                    What does the Auto Repost upgrade do?
                  </li>
                  <div className="ml-4">
                    Auto Repost allows you to create a schedule for your ads to
                    be bumped to the very top of the list in which it appears at
                    specific times of the day. Once you have purchased an Auto
                    Repost for one of your ads, it is possible to extend the
                    number of days it will run for, but it is NOT possible to
                    add or remove the number of times per day that the ad is
                    bumped. Please also note that any changes you make to its
                    schedule will only take effect the following day.
                  </div>
                  <li className="font-bold mt-2">What is a Featured Ad?</li>
                  <div className="ml-4">
                    It is a small ad snippet that appears after ten regular ads
                    on the page where you posted your ad. It is another way of
                    helping advertisers like yourself stand out from the crowd!
                    Please note that our Featured Ad upgrade does NOT also bump
                    the ad when purchased. You should purchase a separate
                    upgrade which allows you to bump your ads (such as Auto
                    Repost, Page 1, or regular Bumps)
                  </div>
                  <li className="font-bold mt-2">
                    How do I edit my Featured Ad?
                  </li>
                  <div className="ml-4">
                    Simply log into your account, and visit the Ad Center. Here
                    you will find a list of your ads, and you can then select
                    the ad that you purchased the Featured Ad upgrade for. Then
                    just click on ‘edit ad’ and you will see a tab that says
                    'Featured Ad'. Click on this, and you can edit your ad. When
                    you have finished doing this, please make sure that you save
                    your changes.
                  </div>
                  <li className="font-bold mt-2">
                    What happens to my upgrades if I delete my ad?
                  </li>
                  <div className="ml-4">
                    If you delete an ad, it is instantly removed, and all
                    upgrades associated with it are lost.
                  </div>
                  <li className="font-bold mt-2">
                    What happens to my upgrades if I delete my ad?
                  </li>
                  <div className="ml-4">
                    If you delete an ad, it is instantly removed, and all
                    upgrades associated with it are lost.
                  </div>
                  <li className="font-bold mt-2">
                    What does the ‘copy’ button do which I see next to each on
                    of my ads?
                  </li>
                  <div className="ml-4">
                    It will copy the entire ad that you clicked 'copy' on and
                    take you to the post ad page so you can use it to create a
                    new ad. You will then be able to continue through the
                    various steps and it will also be possible for you to edit
                    any of the pre-filled sections.
                  </div>
                  <li className="font-bold mt-2">
                    Are there any limits on how many ads I can post in the Free
                    Ad sections?
                  </li>
                  <div className="ml-4">
                    Yes. You can only post 1 free ad per day. If you want to
                    post more, please purchase one of the upgrades, or when you
                    try to post your 2nd ad, follow the ‘get back in action’
                    steps.
                  </div>

                  <li className="font-bold mt-2">
                    How can I access my paid features or ads?
                  </li>
                  <div className="ml-4">
                    If you paid for any upgrade or posted an ad and want to
                    manage or access it, just login to your account and go to
                    your Ad Center page.
                  </div>
                  <li className="font-bold mt-2">
                    What exactly is a GIFTCARD and how do I use it:
                  </li>
                  <div className="ml-4 mt-4">
                    Please note that our Giftcard can only be used on our site
                    when making payments. For example, it does not let you bump
                    your ad directly, but it does let you buy ads and upgrades
                    with the money that you put on it.
                  </div>
                  <div className="ml-4 mt-4">
                    You can use your Giftcard after you log in by following
                    these steps:
                  </div>
                  <div className="ml-4 mt-4">1. Log in.</div>
                  <div className="ml-4">
                    2. Go to your
                    <span
                      className="font-bold cursor-pointer active:opacity-40 pl-2 text-purple"
                      onClick={() => navigate("/myads")}
                    >
                      Ad Center page
                    </span>
                  </div>
                  <div className="ml-4">
                    3. Click on 'Buy and Manage your upgrades' next to one of
                    your ads
                  </div>
                  <div className="ml-4">
                    4. then you can buy any upgrade you like using your Giftcard
                    balance.
                  </div>
                </ol>
              </div>
            </div>
          </div>

          <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2 border-b pb-10 border-whitepink border-opacity-40 pt-5 ">
            <div className="text-lg font-bold ">Privacy & Security</div>
            <ol className="list-decimal list-inside ml-2">
              <li className="font-bold">Can you please delete my account?</li>
              <div className="ml-4">
                Just log into your account and go the Privacy & Security
                Settings page. Once you delete your account, it is permanently
                deleted, as well as any ads or upgrades you have purchased.
              </div>
              <li className="font-bold mt-2">
                How do I block someone on chat?
              </li>
              <div className="ml-4">
                There is a block feature built into the chat that you can use,
                this will make you appear offline to them, but they will still
                be able to message you.
              </div>
              <li className="font-bold mt-2">How do I disable chat?</li>
              <div className="ml-4">
                Through the chat bar, select the offline setting.
              </div>
            </ol>
          </div>

          <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2 border-b pb-10 border-whitepink border-opacity-40 pt-5 ">
            <div className="text-lg font-bold ">Copyright</div>
            <ol className="list-decimal list-inside ml-2">
              <li className="font-bold">
                Someone is using my images and work – Can you remove it?
              </li>
              <div className="ml-4">
                If you believe your work has been copied in a way that
                constitutes copyright infringement, then please go to our Terms
                and Conditions page and follow the procedures listed under the
                heading '5. NOTIFICATION OF CLAIMS OF INFRINGEMENT'.
              </div>
            </ol>
          </div>

          <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2 border-b pb-10 border-whitepink border-opacity-40 pt-5 ">
            <div className="text-lg font-bold ">Payments</div>
            <ol className="list-decimal list-inside ml-2">
              <li className="font-bold">
                My ad was removed, I want my money back!
              </li>
              <div className="ml-4">
                We only remove ads that are against our Rules and Terms and
                Conditions. In such cases, refunds are not issued.
              </div>
            </ol>
          </div>
          <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2 border-b pb-10 border-whitepink border-opacity-40 pt-5 ">
            <div className="text-lg font-bold ">General</div>
            <ol className="list-decimal list-inside ml-2">
              <li className="font-bold">Do you allow reviews?</li>
              <div className="ml-4">
                No. We are not a review board, please do not post any reviews,
                nor link to any reviews.
              </div>
              <li className="font-bold">
                Can I set my avatar/profile picture for the chat bar?
              </li>
              <div className="ml-4">
                At this time you can’t, but once our social network is up and
                running after our next update you will be able to do so.
              </div>
              <li className="font-bold">
                How do I chat with another user who is online?
              </li>
              <div className="ml-4">
                Find them on the chat bar. Advertisers can appear offline on
                chat, so even though their status on their ad shows them online,
                they may be unavailable.
              </div>
              <li className="font-bold">
                How do I change my username or email address?
              </li>
              <div className="ml-4">
                Sorry, we do not allow that. If you must change either, you will
                have to delete your account and make a new one.
              </div>
              <li className="font-bold">What are favourites?</li>
              <div className="ml-4">
                They help you keep track of your favourite ads.
              </div>
            </ol>
          </div>
          <div
            className="text-md sm:text-2xl pl-3 p-3 border-b border-opacity-40 border-whitepink"
            id="inTouch"
          >
            Still can’t find an answer? Get in touch
          </div>
          <div className=" w-full bg-footercolor p-3">
            {/* CONTACT US */}
            <div className="flex flex-col gap-4">
              <div className="text-2xl sm:text-4xl font-semibold ">
                Contact Us
              </div>
              <div className=" text-xs border-l-4 flex  border-purple  w-52 justify-center items-center h-10 gap-2     ">
                <div className="bg-purple rounded-full w-4  flex items-center justify-center h-5">
                  <img src={images.views} className="h-4 w-3 " />
                </div>
                <div className="flex flex-col">
                  <div className="font-bold">We are currently online</div>
                  <div>Average reply within 15 minutes</div>
                </div>
              </div>
              <div className="">
                Use this form to contact us. Please check the following
                resources before reporting violations:
              </div>
              <div className="text-purple">
                <li
                  className="cursor-pointer active:opacity-40"
                  onClick={() => navigate("/report-offense")}
                >
                  Report Offense
                </li>
                <li
                  className="cursor-pointer active:opacity-40"
                  onClick={() => navigate("/report-tos")}
                >
                  Report ToS Violations
                </li>
              </div>
              <div className="w-full flex sm:flex-row flex-col gap-4">
                <div className="flex flex-col w-full gap-2">
                  Full Name
                  <input className="bg-transparent  focus:outline-none border border-whitepink border-opacity-40 h-10 w-full pl-2" />
                </div>
                <div className="flex flex-col w-full gap-2">
                  Category
                  <div
                    className="bg-transparent relative cursor-pointer flex items-center  focus:outline-none border border-whitepink border-opacity-40 h-10 w-full"
                    onClick={() => toggleDropdown("category")}
                  >
                    <div className="w-full flex justify-between px-3 items-center">
                      <div className="">{category}</div>
                      <img
                        src={
                          dropDown === "category"
                            ? images.dropDownOpen
                            : images.dropDownClosed
                        }
                        className="h-3 w-5"
                      />
                    </div>
                    {dropDown === "category" && (
                      <div className="absolute top-10 bg-buttoncolor  w-full max-h-[200px] overflow-auto z-20">
                        {cateogryData.map((item, index) => (
                          <div
                            className="  hover:bg-purple cursor-pointer border-opacity-40 border-whitepink  border-b acitve:opaicty-50"
                            onClick={() => setCatergory(item)}
                          >
                            <div className="ml-4 pt-4 pb-2 ">{item}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full flex sm:flex-row flex-col gap-4">
                <div className="flex flex-col w-full gap-2">
                  Email
                  <input
                    className="bg-transparent  focus:outline-none border border-whitepink border-opacity-40 h-10 w-full pl-2"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </div>
                <div className="flex flex-col w-full gap-2">
                  Your Location
                  <div
                    className="bg-transparent relative cursor-pointer flex items-center  focus:outline-none border border-whitepink border-opacity-40 h-10 w-full"
                    onClick={() => toggleDropdown("location")}
                  >
                    <div className="w-full flex justify-between px-3 items-center">
                      <div className="">{location}</div>
                      <img
                        src={
                          dropDown === "location"
                            ? images.dropDownOpen
                            : images.dropDownClosed
                        }
                        className="h-3 w-5"
                      />
                    </div>
                    {dropDown === "location" && (
                      <div className="absolute top-10 bg-buttoncolor w-full max-h-[200px] overflow-auto z-20">
                        {provinceCandaData.map((item, index) => (
                          <div
                            className="  hover:bg-purple cursor-pointer acitve:opaicty-50 border-opacity-40 border-whitepink  border-b"
                            onClick={() => setLocation(item)}
                          >
                            <div className="ml-4 pt-4 pb-2 ">{item}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full gap-2 z-10">
                Ad Url (if Applicable)
                <input className="bg-transparent  focus:outline-none border border-whitepink border-opacity-40 h-10 w-full pl-2" />
              </div>
              <div className="flex flex-col w-full gap-2">
                Message
                <textarea className="bg-transparent  focus:outline-none border border-whitepink border-opacity-40 h-40 w-full pl-2" />
              </div>
              <div>
                <div className="flex flex-col w-full gap-2">Attachment</div>
                <form action="/action_page.php">
                  <input type="file" id="myFile" name="filename" />
                  {/* <input type="submit" /> */}
                </form>
              </div>
              <div className="bg-purple flex cursor-pointer active:opacity-40 item-center justify-center items-center w-32 py-2">
                Send Message
              </div>
              <div className="border-t border-whitepink border-opacity-40   pt-6">
                Need support with billing?{" "}
                <span
                  className="text-purple font-bold cursor-pointer active:opacity-40"
                  onClick={() => navigate("/contactus")}
                >
                  Contact Us
                </span>
              </div>
              <div className="flex items-center gap-2 text-textopacity ">
                <img
                  src={images.mail}
                  className="h-4 w-5 white-filter opacity-40"
                />
                <div className="cursor-pointer active:opacity-40">
                  support@pleasurylist.com
                </div>
              </div>
              <div className="flex items-center gap-2 text-textopacity ">
                <img src={images.phonecall} className="h-4 w-5  opacity-40" />
                <div className="cursor-pointer active:opacity-40">
                  +1 (231) 123 13123132
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block w-full md:w-60 relative bg-footercolor h-40p sticky top-24">
          <div
            className="py-5 hover:bg-purple cursor-pointer active:opacity-50 pl-5 text-xl border-b border-whitepink border-opacity-40"
            onClick={() => scrollToSection("rules")}
          >
            Rules
          </div>
          <div
            className="py-5 hover:bg-purple cursor-pointer active:opacity-50 pl-5 text-xl border-b border-whitepink border-opacity-40"
            onClick={() => scrollToSection("FAQ")}
          >
            FAQ
          </div>
          <div
            className="w-full bg-purple font-bold py-2 flex items-center justify-center cursor-pointer active:opacity-50"
            onClick={() => scrollToSection("inTouch")}
          >
            Get in Touch
          </div>
        </div>
      </div>
    </div>
  );
}
