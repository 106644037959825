import React, { useState } from "react";
import images from "../../services/images";
import { useModal } from "../../screens/Layout";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { Link } from "react-router-dom";

export default function Homebanner() {
  const { setIsModalOpen, setmodalcomponent } = useModal();
  const { isLoggedIn } = useAuth();

  const handleJoinNowClick = () => {
    setmodalcomponent("signup");
    setIsModalOpen(true);
    // Use navigate to push the route
  };
  return (
    <div>
      <div
        className="p-4 py-20 bg-cover bg-center mt-10"
        style={{
          backgroundImage: `url(${images.center1})`,
        }}
      >
        <div className="py-7 flex justify-center item-center">
          <div className="flex flex-col items-center  ">
            <div className="text-white text-2xl font-bold w-65p  md:w-100p  lg:w-70p lg:text-5xl md:text-4xl sm:text-4xl lg:w-full text-center ">
              FIND YOUR PERFECT COMPANION
            </div>
            <div className="pt-3 text-white lg:text-2xl md:text-lg sm:text-xl text-base  w-60p md:w-100p lg:w-full text-center ">
              Explore the largest community secret gift on the internet
            </div>
            {isLoggedIn ? (
              <>
                <div
                  className="bg-whitepink  text-center px-10 py-3 rounded-sm font-bold mt-10 cursor-pointer active:opacity-50 text-sm"
                  onClick={handleJoinNowClick}
                >
                  SIGN UP
                </div>
              </>
            ) : (
              <>
                <Link to={"/Escorts/All"}>
                  <div className="bg-whitepink  text-center px-10 py-3 rounded-sm font-bold mt-10 cursor-pointer active:opacity-50 text-sm">
                    View Escort
                  </div>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
