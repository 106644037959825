import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faLeaf } from "@fortawesome/free-solid-svg-icons";
import images from "../../services/images";
import CreateProfile from "../CreateProfile";
import { useRef, useState } from "react";

export default function Signin({ openclosemodal, subscribetomodal }) {
  const textToCopyRef = useRef(null);
  const modalRef = useRef();

  const closemodal = () => {
    subscribetomodal();
    openclosemodal();
  };

  // const [isAgreed, setIsAgreed] = useState(false);
  // const [isAgreed2, setIsAgreed2] = useState(true);
  // const [changepress, setchangepress] = useState(false);

  // const togglechangepress = (value) => {
  //   setchangepress(!changepress);
  //   setIsAgreed((prevIsAgreed) => !prevIsAgreed);
  // };

  const [togglePayment, setTogglePayment] = useState(true);
  const [selectedImage1, setselectedImage1] = useState(1);
  const [selectedImage2, setselectedImage2] = useState(null);
  const [selectedImage3, setselectedImage3] = useState(null);
  const [changepanel, setchangepanel] = useState(true);

  const togglechangepanel = () => {
    setchangepanel(!changepanel);
  };

  const handleCopyClick = () => {
    // Select the text inside the div
    const range = document.createRange();
    range.selectNode(textToCopyRef.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Deselect the text
    window.getSelection().removeAllRanges();
  };

  const paymentDataArray = [
    { bitTotal1: 100, bitAmount1: 0.0011, USD: "USD", BTC: "BTC" },
    { bit2Total: 200, bit2Amount: 0.0012, USD: "USD", BTC: "BTC" },
    { bit3Total: 300, bit3Amount: 0.0013, USD: "USD", BTC: "BTC" },
  ];

  const handletoggleImage1 = (index) => {
    setselectedImage1(index);
    setselectedImage2(null);
    setselectedImage3(null);
  };

  const handletoggleImage2 = (index) => {
    setselectedImage1(null);
    setselectedImage2(index);
    setselectedImage3(null);
  };

  const handletoggleImage3 = (index) => {
    setselectedImage1(null);
    setselectedImage2(null);
    setselectedImage3(index);
  };

  return (
    <div
      className="fixed  top-0 left-0 w-full  h-full flex items-center mt-10 justify-center  bg-gray-900 bg-opacity-70"
      onClick={openclosemodal}
    >
      <div className="h-full w-full  flex  justify-center items-center">
        <div
          className=" bg-no-repeat h-50vh bg-center bg-cover 1470:w-60p lg:w-80p md:w-full w-full"
          style={{
            backgroundImage: `url(${images.bluredimg4})`,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex justify-center items-center w-full   ">
            <div className="lg:w-full w-60p h-full flex justify-center flex-col items-start ml-2 lg:pl-20    ">
              <img
                src={images.arrowwhiteleft}
                alt=""
                className="pb-32 cursor-pointer active:opacity-50 w-7 sm:w-10 md:w-10 lg:w-auto"
                onClick={closemodal}
              />
              <div className="text-white font-bold lg:text-4xl md:text-2xl sm:text-xl text-xs pb-10">
                SUBSCRIPTION
              </div>
              <div className="text-purple font-bold lg:text-7xl md:text-5xl sm:text-3xl text-2xl w-full">
                $300
              </div>
              <div className="text-white text-nowrap pt-10 text-xs sm:text-sm  md:text-sm lg:text-xl">
                No hidden Changes
              </div>
              <div className="text-white mt-10 text-sm sm:text-base md:text-lg lg:text-xl bg-darkpurple cursor-pointer active:opacity-50 h-10 flex items-center justify-center border w-28 sm:w-28  md:w-40 lg:w-52">
                <div> Subscribe</div>
                <img src={images.tick} alt="" className="w-4 ml-4" />
              </div>
            </div>
            <div
              className=" bg-no-repeat w-full lg:mr-10 md:mr-10 sm:mr-0 mr-2 mb-10 mt-10"
              style={{
                backgroundImage: `url(${images.bluredimg2})`,
              }}
            >
              <div className="">
                <div className="p-3 lg:p-14 md:p-14 sm:p-14 flex flex-col w-full">
                  <div className="flex justify-between  items-center">
                    <div className="text-white font-semibold lg:text-3xl md:text-3xl sm:text-3xl text-lg ">
                      Payment Details
                    </div>
                  </div>
                  <div className="flex md:flex-row sm:flex-row flex-row items-center  justify-between mt-10">
                    <div className="flex items-center ">
                      <div
                        className="flex"
                        onClick={() => {
                          setTogglePayment(true);
                        }}
                      >
                        {togglePayment ? (
                          <div className="border-1  rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4  flex items-center justify-center bg-purple">
                            <div className="  ">
                              {" "}
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="1x"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                  color: "transparent",
                                  border: "2px solid #4c2f4d ",
                                  "@media(maxWidth: 444px)": {
                                    height: "7px",
                                    width: "7px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="border-2 rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4 flex items-center  justify-center">
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              size="1x"
                              style={{
                                borderRadius: "50%",
                                height: "10px",
                                width: "10px",
                                cursor: "pointer",
                                color: "transparent",
                                "@media(maxWidth: 444px)": {
                                  height: "7px",
                                  width: "7px",
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="text-white lg:text-xl md:text-xl sm:text-sm text-xs text-nowrap  pl-3 font-semibold">
                        Credit Card
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div
                        className="flex  "
                        onClick={() => {
                          setTogglePayment(false);
                        }}
                      >
                        {!togglePayment ? (
                          <div className=" border-1  rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4 flex items-center  justify-center bg-purple">
                            <div className="">
                              {" "}
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="1x"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                  color: "transparent",
                                  border: "2px solid #4c2f4d ",
                                  "@media(maxWidth: 444px)": {
                                    height: "7px",
                                    width: "7px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="border-2 rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4 flex items-center  justify-center">
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              size="1x"
                              style={{
                                borderRadius: "50%",
                                height: "10px",
                                width: "10px",
                                cursor: "pointer",
                                color: "transparent",
                                "@media(maxWidth: 444px)": {
                                  height: "5px",
                                  width: "5px",
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className=" ml-2 text-white lg:text-xl md:text-xl sm:text-sm text-xs text-nowrap">
                        Crypto
                      </div>
                    </div>
                  </div>
                  {togglePayment && (
                    <div className="">
                      <div className="mt-10 justify-center flex flex-col items-center space-y-10 text-purple font-semibold">
                        <div className="flex justify-start w-full lg:h-13 h-10">
                          <input
                            type="text"
                            placeholder="Card Number"
                            className="placeholder-purple placeholder-opacity-40 w-full h-full md:text-sm sm:text-sm  text-xxs focus:outline-none md:pl-5 sm:pl-2 pl-2"
                          />
                        </div>
                        <div className="flex justify-start w-full lg:h-13 h-10">
                          <input
                            type="text"
                            placeholder="Name on Card"
                            className="placeholder-purple placeholder-opacity-40 w-full h-full md:text-sm sm:text-sm  text-xxs focus:outline-none md:pl-5 sm:pl-2 pl-2"
                          />
                        </div>
                        <div className="w-full flex ">
                          <div className="flex justify-start w-full lg:h-13 h-10">
                            <input
                              type="text"
                              placeholder="Expiry Date"
                              className="placeholder-purple placeholder-opacity-40 w-90p h-full md:text-sm sm:text-sm  text-xxs focus:outline-none md:pl-5 sm:pl-2 pl-2 "
                            />
                          </div>
                          <div className="flex  justify-start w-full lg:h-13 h-10">
                            <input
                              type="text"
                              placeholder="CVC"
                              className="placeholder-purple placeholder-opacity-40 w-full h-full md:text-sm sm:text-sm  text-xxs  focus:outline-none md:pl-5 sm:pl-2 pl-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" flex justify-center items-center pt-5">
                        <img
                          src={images.AMEX20}
                          alt=""
                          className="w-12 h-7 cursor-pointer active:opacity-50"
                        />
                        <img
                          src={images.AMEX21}
                          alt=""
                          className="w-12 h-7 ml-2 cursor-pointer active:opacity-50"
                        />
                        <img
                          src={images.VISA21}
                          alt=""
                          className="w-12 h-7 ml-2 cursor-pointer active:opacity-50"
                        />
                      </div>
                      <div
                        className="bg-purple w-full mt-10 flex lg:h-20 h-10 items-center justify-center cursor-pointer text-nowrap  active:opacity-50"
                        onClick={closemodal}
                      >
                        <div className="text-white lg:text-2xl md:text-xl  sm:text-xl text-xxs font-semibold">
                          PAY NOW
                        </div>
                      </div>
                    </div>
                  )}
                  {!togglePayment && (
                    <div className="pt-10">
                      <div className="text-white font-thin opacity-80 lg:text-base md:text-base sm:text-sm text-xs">
                        Select a cryptocurrency
                      </div>
                      <div className="w-full h-10 flex items-center pt-10 text-purple text-base ">
                        <div className="bg-white lg:h-14 md:h-14 sm:h-10 h-10 flex items-center lg:pl-5 pl-2 ">
                          <img
                            src={images.search2}
                            alt=""
                            className=" cursor-pointer "
                          />
                        </div>
                        <input
                          type="text"
                          placeholder="Find a Currency"
                          className="placeholder-purple placeholder-opacity-40 lg:text-base md:text-sm sm:text-xs text-xs w-full lg:h-14 md:h-14 sm:h-10 h-10 lg:pl-5 pl-2 focus:outline-none  "
                        />
                        <div className="flex bg-white lg:h-14 md:h-14 sm:h-10 h-10 items-center w-full justify-end pr-4">
                          <img
                            src={images.bitcoin1}
                            alt=""
                            className={`lg:w-7 lg:h-7 md:w-7 md:h-7 sm:w-5 sm:h-5 w-5 h-5 cursor-pointer ${
                              selectedImage1 === 0 ? "active:opacity-50" : ""
                            }`}
                            onClick={() => {
                              handletoggleImage1(0);
                            }}
                          />

                          <img
                            src={images.bitcoin2}
                            alt=""
                            className={`lg:w-7 lg:h-7 md:w-7 md:h-7 sm:w-5 sm:h-5 w-5 h-5 cursor-pointer ${
                              selectedImage2 === 1 ? "active:opacity-50" : ""
                            }`}
                            onClick={() => {
                              handletoggleImage2(1);
                            }}
                          />
                          <img
                            src={images.bitcoin3}
                            alt=""
                            className={`lg:w-7 lg:h-7 md:w-7 md:h-7 sm:w-5 sm:h-5 w-5 h-5 cursor-pointer ${
                              selectedImage3 === 2 ? "active:opacity-50" : ""
                            }`}
                            onClick={() => {
                              handletoggleImage3(2);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        {selectedImage1 !== null && (
                          <div className="flex text-white justify-between pt-20">
                            <div className="opacity-80 lg:text-lg md:text-base sm:text-sm text-xs">
                              Total
                            </div>
                            <div className="font-semibold lg:text-lg md:text-base sm:text-sm text-xs">
                              {paymentDataArray[selectedImage1].bitTotal1}.
                              {paymentDataArray[selectedImage1].USD}
                            </div>
                          </div>
                        )}

                        {selectedImage2 !== null && (
                          <div className="flex text-white justify-between pt-20">
                            <div className="opacity-80 lg:text-lg md:text-base sm:text-sm text-xs">
                              Total
                            </div>
                            <div className="font-semibold lg:text-lg md:text-base sm:text-sm text-xs">
                              {paymentDataArray[selectedImage2].bit2Total}.
                              {paymentDataArray[selectedImage2].USD}
                            </div>
                          </div>
                        )}

                        {selectedImage3 !== null && (
                          <div className="flex text-white justify-between pt-20">
                            <div className="opacity-80 lg:text-lg md:text-base sm:text-sm text-xs">
                              Total
                            </div>
                            <div className="font-semibold lg:text-lg md:text-base sm:text-sm text-xs">
                              {paymentDataArray[selectedImage3].bit3Total}.
                              {paymentDataArray[selectedImage3].USD}
                            </div>
                          </div>
                        )}

                        <div className="flex text-white justify-between border-b pb-6  ">
                          <div className=" opacity-80 lg:text-lg md:text-base sm:text-sm text-xs">
                            Amount
                          </div>
                          {selectedImage1 !== null && (
                            <div className="flex lg:text-lg md:text-base sm:text-sm text-xs font-semibold ">
                              <div className="mr-1">
                                {paymentDataArray[selectedImage1].bitAmount1}
                              </div>
                              {paymentDataArray[selectedImage1].BTC}
                            </div>
                          )}
                          {selectedImage2 !== null && (
                            <div className="flex text-lg font-semibold ">
                              <div className="mr-1">
                                {paymentDataArray[selectedImage2].bit2Amount}
                              </div>
                              {paymentDataArray[selectedImage2].BTC}
                            </div>
                          )}
                          {selectedImage3 !== null && (
                            <div className=" flex text-lg font-semibold ">
                              <div className="mr-1">
                                {paymentDataArray[selectedImage3].bit3Amount}
                              </div>
                              {paymentDataArray[selectedImage3].BTC}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="pt-2 flex flex-col w-full  lg:items-start items-start">
                        <div className="text-white opacity-80 pb-2 flex justify-center w-full  lg:text-base md:text-sm sm:text-xs text-xs">
                          Please send to address:
                        </div>
                        <div className="flex lg:w-full md:w-full sm:w-full w-32  items-center">
                          <div className="border border-white border-dashed w-full  ">
                            <div className="bg-whitepink h-8 flex items-center justify-center">
                              <div
                                className="text-purple truncate lg:text-base md:text-sm sm:text-xs text-xs "
                                ref={textToCopyRef}
                              >
                                1sdafuhiufg56d85hsjhbveds125sdlkjhgvjfddsg
                              </div>
                            </div>
                          </div>
                          <div className="lg:ml-5 md:ml-5 sm:ml-5 ml-1 cursor-pointer active:opacity-50 lg:w-8 lg:max-w-8 md:w-8 md:max-w-8 sm:w-8 sm:max-w-8 w-6 max-w-6 ">
                            <img
                              src={images.copy}
                              alt=""
                              className="lg:w-8 lg:max-w-8 md:w-8 md:max-w-8 sm:w-8 sm:max-w-8 w-5 max-w-5"
                              onClick={handleCopyClick}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="bg-purple w-full mt-10 flex lg:h-20 h-10 items-center justify-center cursor-pointer text-nowrap  active:opacity-50"
                        onClick={closemodal}
                      >
                        <div className="text-white lg:text-2xl md:text-xl  sm:text-xl text-xxs font-semibold">
                          OPEN IN WALLET
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
