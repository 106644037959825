import React from "react";
import images from "../../services/images";
import { useModal } from "../../screens/Layout";
import { useAuth } from "../../services/Authcontext/Authcontext";

export default function Donemodal() {

    const { setIsModalOpen } = useModal();

    const closeModal = () =>{
      setisLoggedIn(false)
        setIsModalOpen(false)
    }
    const { isLoggedIn, login, setisLoggedIn } = useAuth();


  return (
    <div className="w-full text-white  h-full bg-footercolor">
      <div className="w-full flex items-center mt-7 gap-4 flex-col">
        <img src={images.tickmarkdone} className="sm:h-32 h-20" />
        <div className="sm:text-2xl text-sm text-wrap w-96 font-semibold text-center">
          Profile creation for both roles successfully finalized
        </div>
        <div className=" mt-10">
          <div className="bg-purple flex justify-center items-center h-12 w-60 sm:text-xl text-sm cursor-pointer active:opacity-50"
          onClick={closeModal}
          >Let’s get Started!</div>
        </div>
      </div>
    </div>
  );
}
