import React from "react";
import { useNavigate } from "react-router-dom";
import images from "../../services/images";
import FormCarousel from "../FormCarousel";
import moment from "moment";

export default function AdEscorts({ ad }) {
  const navigate = useNavigate();

  const parseDateTime = (date, time) => {
    if (!date || !time) {
      console.error("Invalid date or time provided:", date, time);
      return null;
    }
    const [month, day, year] = date?.split("/") || [];
    const [hour, minute, period] = (
      time.match(/(\d+):(\d+)\s(AM|PM)/) || []
    ).slice(1);
    let endHour = parseInt(hour, 10);
    if (period === "PM" && endHour !== 12) endHour += 12;
    if (period === "AM" && endHour === 12) endHour = 0;
    return new Date(year, month - 1, day, endHour, minute);
  };

  const isWithinActivePeriod = (startDate, startTime, endDate, endTime) => {
    const startDateTime = parseDateTime(startDate, startTime);
    const endDateTime = parseDateTime(endDate, endTime);
    const currentDateTime = new Date();

    if (!startDateTime || !endDateTime) {
      console.error(
        "Invalid start or end date/time:",
        startDateTime,
        endDateTime
      );
      return false;
    }

    return currentDateTime >= startDateTime && currentDateTime <= endDateTime;
  };

  const isBumpActive = (bumpTimes) => {
    if (!Array.isArray(bumpTimes)) {
      console.error("Invalid bump times array:", bumpTimes);
      return false;
    }

    const currentDateTime = moment();

    const parseDateTime = (date, time) => {
      const dateTimeString = `${date} ${time}`;
      return moment(dateTimeString, "MM/DD/YYYY h:mm A");
    };

    return bumpTimes.some((time) => {
      const startTime = parseDateTime(
        currentDateTime.format("MM/DD/YYYY"),
        time
      );
      if (!startTime.isValid()) {
        console.error("Invalid start time:", time);
        return false;
      }
      const endTime = moment(startTime).add(30, "minutes");

      return currentDateTime.isBetween(startTime, endTime, undefined, "[]");
    });
  };

  const bumpActive = isBumpActive(ad?.upgrades?.bumps?.times || []);

  return (
    <div>
      <div
        className={
          ad?.upgrades?.premiumTheme &&
          isWithinActivePeriod(
            ad?.upgrades?.premiumTheme?.startDate,
            ad?.upgrades?.premiumTheme?.startTime,
            ad?.upgrades?.premiumTheme?.endDate,
            ad?.upgrades?.premiumTheme?.endTime
          )
            ? `bg-premiumeColor text-black sm:px-4 gap-2 cursor-pointer active:opacity-50 py-12 items-center justify-center flex flex-col
          border-4 sm:border-8 border-purple w-full md:w-[750px]`
            : `bg-buttoncolor text-whitepink sm:px-4 gap-2 cursor-pointer
              w-full md:w-[750px] active:opacity-50 py-12 items-center justify-center flex flex-col `
        }
        onClick={() => {
          const _id = ad?._id;
          navigate(`/modeladd/${_id}`, {
            state: { addData: ad },
          });
        }}
      >
        <div className="flex w-full justify-between">
          {ad?.upgrades?.carousel &&
          isWithinActivePeriod(
            ad?.upgrades?.carousel?.startDate,
            ad?.upgrades?.carousel?.startTime,
            ad?.upgrades?.carousel?.endDate,
            ad?.upgrades?.carousel?.endTime
          ) ? (
            <div className="flex gap-2 max-w-[240px] sm:max-w-[400px] lg:max-w-[600px] overflow-hidden">
              <FormCarousel
                images={ad?.pictures || []}
                escortAd={true}
                premiumTheme={ad?.premiumTheme}
              />
            </div>
          ) : (
            <div className="flex gap-2 max-w-[250px] md:max-w-[400px] overflow-auto flex-wrap sm:flex-nowrap">
              {ad?.pictures &&
                ad?.pictures
                  .map((pic, index) => (
                    <img
                      key={index}
                      src={pic}
                      alt=""
                      className="w-10 h-10 md:w-20 md:h-20"
                    />
                  ))
                  .reverse()}
            </div>
          )}
          {ad?.upgrades?.featuredAdd &&
            isWithinActivePeriod(
              ad?.upgrades?.featuredAdd?.startDate,
              ad?.upgrades?.featuredAdd?.startTime,
              ad?.upgrades?.featuredAdd?.endDate,
              ad?.upgrades?.featuredAdd?.endTime
            ) && (
              <div className="mr-1">
                <img
                  src={images.premiumStar}
                  alt=""
                  className="h-5 w-5 md:h-6 md:w-6"
                />
              </div>
            )}
        </div>
        <div className="w-full">
          <div className="w-full gap-2 flex flex-col">
            <div className="flex font-bold text-xs md:text-2xl max-w-[200px] md:max-w-[500px] overflow-hidden text-wrap">
              {ad?.upgrades?.emoji?.emoji &&
              isWithinActivePeriod(
                ad?.upgrades?.emoji?.startDate,
                ad?.upgrades?.emoji?.startTime,
                ad?.upgrades?.emoji?.endDate,
                ad?.upgrades?.emoji?.endTime
              ) ? (
                <div>{ad?.upgrades?.emoji?.emoji}</div>
              ) : (
                <div>{ad?.ad_title}</div>
              )}
            </div>
            <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row w-full justify-between font-semibold text-xxs sm:text-xl">
              <div>
                {ad?.escortData?.listingDetails?.age} - {ad?.name} -{" "}
                {ad?.ethnic_origin} - {ad?.ethnicity} - {ad?.city}
              </div>
              <div className="flex flex-col gap-2">
                <div className="gap-2 flex flex-col">
                  {ad?.isVerified ? (
                    <div className="font-semibold text-xs sm:text-base rounded-2xl h-6 sm:h-9 bg-whitepink flex items-center w-28 gap-2 text-textdarkerpurpel">
                      <img
                        src={images.verified1}
                        className="h-4 w-4 sm:h-6 sm:w-6 ml-2"
                      />
                      Verified
                    </div>
                  ) : null}

                  {ad?.upgrades?.availableNow &&
                    isWithinActivePeriod(
                      ad?.upgrades?.availableNow?.startDate,
                      ad?.upgrades?.availableNow?.startTime,
                      ad?.upgrades?.availableNow?.endDate,
                      ad?.upgrades?.availableNow?.endTime
                    ) && (
                      <div className="font-semibold text-xs sm:text-base rounded-2xl h-6 sm:h-9 bg-whitepink flex items-center gap-2 w-32 sm:w-28 text-textdarkerpurpel">
                        <div className="bg-green-500 text-green-500 w-3 h-3 rounded-full flex items-center justify-center ml-3">
                          .
                        </div>
                        Available
                      </div>
                    )}
                </div>
                {bumpActive && (
                  <div className="font-semibold text-xs sm:text-base rounded-2xl h-6 sm:h-9 bg-whitepink flex items-center w-28 gap-2 text-textdarkerpurpel">
                    <img
                      src={images.verified1}
                      className="h-4 w-4 sm:h-6 sm:w-6 ml-2"
                    />
                    Bump
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
