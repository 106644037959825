import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Terms() {
  useEffect(() => {
    handleScrollToTop();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();
  return (
    <div className="flex text-whitepink flex justify-center  gap-10 pt-20 pb-20">
      <div className="w-full md:w-[60%] ">
        <div className=" bg-footercolor  flex flex-col rounded-md">
          <div className="text-2xl pl-3 p-3" id="rules">
            Terms of Service
          </div>
          <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2  pb-10 rounded-md">
            <div className="mt-4">
              <b> 1. Acceptance of Terms</b>
            </div>
            <div className="mt-4">
              Pleasury welcomes you to its online Service (as defined in Section
              2 below). By using the Service you are agreeing to the following
              Terms of Service (“TOS”) whether or not you are a registered
              customer of Pleasury or create an account on the Service.
            </div>
            <div className="mt-2">
              The TOS, and your agreement to it, incorporates the
              <span
                className="font-bold cursor-pointer active:opacity-40  pr-1 text-purple"
                onClick={() => navigate("/policy")}
              >
                {"  "} privacy policy
              </span>
              ,
              <span
                className="font-bold cursor-pointer active:opacity-40  pr-1 text-purple"
                onClick={() => navigate("/rules-and-posting-guidelines")}
              >
                {"  "} posting guidelines
              </span>
              ,
              <span
                className="font-bold cursor-pointer active:opacity-40  pr-1 text-purple"
                onClick={() => navigate("/contact")}
              >
                {"  "} copyright policy
              </span>
              ,
              <span
                className="font-bold cursor-pointer active:opacity-40  pr-1 text-purple"
                onClick={() => navigate("/report-offense")}
              >
                {"  "} reporting policies
              </span>
              referenced in this document. Your agreement to all these terms as
              they appear, or may be updated from time to time, is an absolute
              condition of your use of the Service.
            </div>
            <div className="mt-4">
              You understand the service provided by Pleasury contains content
              on this site is user generated. Pleasury provides a forum for
              informational and entertainment purposes.
            </div>
            <div className="mt-4">
              You understand that the Service provided by Pleasury contains
              content that is user generated. Pleasury provides a forum for
              informational and entertainment purposes.
            </div>
            <div className="mt-4">
              You agree that you are the age of majority in the jurisdiction in
              which you reside and where the content provided through the
              Service is accessed, and that you are legally permitted to enter
              into this agreement and are legally allowed to possess adult and
              explicit material.
            </div>
            <div className="mt-4">
              You agree to comply with all applicable laws of the jurisdiction
              in which you reside. In the interest of providing a safe and
              enjoyable service, you agree to report activity to Pleasury that
              you know to be illegal or that would violate these TOS.
            </div>
            <div className="mt-4">
              We reserve the right in our sole discretion to modify the TOS at
              any time by providing you with notice of the change. Notice
              includes but is not restricted to posting a link to the new TOS,
              posting a new TOS at Pleasury, or sending you a link to the
              amended TOS via contact information you have provided to us. Such
              change will be effective with respect to your use of the Service
              after we have given notice of the change. We also reserve the
              right to modify the price of the Service. Please review the TOS
              periodically to see whether they have changed. If you do not agree
              to the changed TOS, do not use the Service. Any use of the Service
              by you after we provide notice to you constitutes your acceptance
              of those modifications.
            </div>
            <div className="mt-4">
              When using any Pleasury Services, you shall be subject to any
              posted policies, guidelines or rules applicable to such Services.
              All such policies, guidelines and rules are hereby incorporated by
              reference into the TOS.
            </div>
            <div className="mt-4 border-b pb-10  border-whitepink border-opacity-40">
              IF YOU DO NOT AGREE TO THE CURRENT TERMS OF SERVICE, PLEASE DO NOT
              USE THIS SERVICE, SINCE YOUR USE OF IT CONSTITUTES ACCEPTANCE OF
              ALL OF THE CURRENT TERMS OF SERVICE.
            </div>
            <div className="mt-4">
              <b> 2. Description of Service</b>
            </div>
            <div className="mt-4">
              The services provided by Pleasury include, but are not limited to,
              online classified advertisements, live chat, forum, social
              networking, text messages and email services (collectively, the
              “Service”). Any new features, platforms, functionalities, services
              and enhancements offered by Pleasury will be considered part of
              the Service and subject to the TOS.
            </div>
            <div className="mt-4">
              All users must abide by the TOS. If a user fails to follow any of
              the guidelines and/or rules of behavior applicable to the Service,
              Pleasury can delete content posted by a user and/or discontinue
              their ability to use the Service.
            </div>
            <div className="mt-4">
              You are totally responsible for any activity that takes place
              under your username and password. If you become aware of any
              unauthorized use of your username or password it is your
              responsibility to notify Pleasury immediately at legal@Pleasury.
              It is your responsibility to maintain the confidentiality of your
              password and username.
            </div>
            <div className="mt-4">
              If you choose to receive mobile notifications and our sms bump
              feature concerning any ad, you agree that we may send automated
              text messages to your mobile phone or device. If you do not wish
              to receive such text messages, you should not accept the mobile
              notifications option. We will not send you any marketing or
              advertising material by text messages. Message and data rates may
              apply for mobile notifications. You are totally responsible for
              the actions that are triggered on Pleasury by the use of your
              mobile device.
            </div>
            <div className="mt-4">
              You understand that you may receive business-related
              communications from Pleasury such as Service announcements and
              account administrative notices and you agree that these
              communications are not “commercial electronic messages” within the
              meaning of Canada’s Anti-Spam Legislation, and thus you agree to
              receive them and you will not be able to opt out of receiving such
              communications.
            </div>
            <div className="mt-4">
              As a user of the Service you also understand and agree that the
              Service will include classified advertisements. You agree that
              such advertisements are not “commercial electronic messages” and
              thus you agree to receive them and you will not be able to opt out
              of receiving such communications. You understand and agree that
              your correspondence or business dealings with, or participation in
              promotions of, advertisers found on or through the Service,
              including payment and delivery of goods or services, and any other
              terms, conditions, warranties or representations associated with
              such dealings, are solely between you and the advertiser. You
              agree that Pleasury shall not be responsible or liable for any
              loss or damage incurred as the result of such dealings or as the
              result of the presence of such advertisers on the Service.
            </div>
            <div className="mt-4">
              You understand that you are responsible for obtaining access to
              the Service and that access may involve third party fees. You are
              totally responsible for obtaining such access and paying those
              fees.
            </div>
            <div className="mt-4">
              Unless explicitly stated otherwise, any new features that augment
              or enhance the Service, including the release of new Pleasury
              properties, shall be considered part of the Service and subject to
              the then current TOS.
            </div>
            <div className="mt-4  pb-10  border-b border-whitepink border-opacity-40">
              <b>
                You must have reached the age of majority in the jurisdiction in
                which you reside, to access and use the Service. If you are
                under the age of majority, do not use this Service for any
                purpose.
              </b>
            </div>
            <div className="mt-4">
              <b> 3. Rules of Behavior</b>
            </div>
            <div className="mt-4">
              • You are solely responsible for your own communications and User
              Content (defined below) that you post on the Service. You agree to
              only use the Service to post and receive communications and User
              Content that are legal.
            </div>
            <div className="mt-4">
              • You must not abuse, defame, harass, stalk, threaten, intimidate,
              or otherwise violate the legal rights of others.
            </div>
            <div className="mt-4">
              • You must not upload, post or link to any material that is
              defamatory, infringing, libelous, obscene, racially or ethnically
              hateful, objectionable, or unlawful or that promotes or provides
              instructional information about illegal activities.
            </div>
            <div className="mt-4">
              • You must respect the privacy of other users, and use the
              personal information of others solely for the purpose for which it
              was shared with you.
            </div>
            <div className="mt-4">
              • You must not upload or post any User Content that infringes any
              patent, trademark, copyright, trade secret or other intellectual
              property or other legal right of a third party without prior
              written permissions from the third party, which permissions you
              agree to disclose to us on our request. You must not download any
              material that you know or reasonably should know cannot be
              distributed legally.
            </div>
            <div className="mt-4">
              • You agree to not impersonate another person or entity.
            </div>
            <div className="mt-4">
              • You must not use the Service for any illegal or unauthorized
              purpose. This includes, but is not limited to, spamming,
              impersonation, extortion or fraud.
            </div>
            <div className="mt-4">
              • You must abide by all applicable Federal, Provincial,
              Territorial and local laws, including, without imitation, any laws
              regarding the export of data or software, patent, trademark, trade
              secret, copyright, or other intellectual property, legal rights
              (including the rights of publicity and privacy of others) and User
              Content must not contain any material that could give rise to any
              civil or criminal liability under applicable laws or regulations
              or that otherwise may be in conflict with these Terms of Use or
              any Pleasury policies or guidelines, including our privacy policy.
            </div>
            <div className="mt-4">
              • You cannot modify or delete any User Content or communications
              posted by Pleasury or other users.
            </div>
            <div className="mt-4">
              • You must not transmit any viruses, worms, defects, or any items
              and/or code of a destructive nature.
            </div>
            <div className="mt-4">
              • You must not transmit or allow access or distribution of any
              spiders, robots or other information-gathering computer programs
              or routines or their equivalents on or in the Service.
            </div>
            <div className="mt-4">
              • The contents associated with the Service, including the User
              Content, may not be modified or altered in any respect, merged
              with other data or published in any form, in whole or in part. The
              prohibited uses include "screen scraping", "database scraping" and
              any other automated or manual activity intended to collect, store,
              reorganize or manipulate data on the pages produced by, or
              displayed in associated with the Service.
            </div>
            <div className="mt-4">
              • You must not interfere or disrupt the Service or servers or
              networks connected with the Service or disobey any requirements,
              procedures, policies or regulations of networks connected to the
              Service.
            </div>
            <div className="mt-4">
              • You must not retrieve, store or collect personal information
              about other users for any unauthorized purpose.
            </div>
            <div className="mt-4">
              • You cannot use the Pleasury name or trademark, which you
              acknowledge here to be valid, subsisting and enforceable without
              impediment.
            </div>
            <div className="mt-4">
              WHILE Pleasury EXPLICITLY PROHIBITS THE ABOVE CONDUCT YOU
              ACKNOWLEDGE AND AGREE THAT YOU MAY BE EXPOSED TO SUCH CONDUCT AND
              CONTENT AND THAT YOU USE THE SERVICE ENTIRELY AT YOUR OWN RISK,
              AND THAT Pleasury SHALL HAVE NO LIABILITY OF ANY KIND FOR SUCH
              CONDUCT.
            </div>
            <div className="mt-4 pb-10 border-b border-whitepink border-opacity-40">
              YOU UNDERSTAND AND AGREE THAT IF YOU VIOLATE ANY OF THE RULES OF
              BEHAVIOR, Pleasury CAN TERMINATE YOUR USE OF THE SERVICE
              IMMEDIATELY WITHOUT PRIOR NOTICE WITHOUT ANY RIGHT OF REFUND,
              SET-OFF OR A HEARING.
            </div>
            <div className="mt-4">
              <b> 4. Content</b>
            </div>
            <div className="mt-4 ">
              As a registered user, you and all other registered users will be
              able to upload and post a great variety of content, material, and
              information on the Service, including but not limited to text,
              audio, photographs, graphics, data, images, video, email
              addresses, links, screen names and other materials (“User
              Content”). This means that you have the sole responsibility, not
              Pleasury, for all User Content that you upload, post, email,
              transmit or otherwise make available through the Service. It is
              your responsibility to ensure that any User Content you share
              through the Service complies with all applicable laws and with
              these TOS, including the Rules of Behavior in Section 3, above.
              You submit it--you’re responsible for it.
            </div>
            <div className="mt-4 ">
              By using the Service you agree not to post any obscene or lewd and
              lascivious graphics or photographs which depict genitalia, actual
              or stimulated sexual acts or nude images. User Content posted
              cannot advertise or solicit directly or in any "coded", fashion
              ANY illegal service, including an offer to provide sexual services
              for money or other consideration. User Content posted cannot
              exploit minors in any way. User Content posted cannot in any way
              constitute or assist in human trafficking.
            </div>
            <div className="mt-4 ">
              Notwithstanding the foregoing, Pleasury shall have no liability of
              any kind with respect to any User Content posted by you or other
              users of the Service. You agree that you must assess and bear all
              risks associated with the creation or use of any User Content. In
              this regard, you may not rely on any User Content created by other
              Pleasury members or otherwise created, distributed and displayed
              on any part of the Service. Pleasury does not control or monitor
              the User Content posted via the Service and, as such, does not
              guarantee the accuracy, integrity or quality of such User Content.
            </div>
            <div className="mt-4 ">
              The User Content created by you shall be owned by you, or you must
              have been granted prior permission to use it. Pleasury does not
              own any of the User Content you post. By placing User Content on
              the Service you are providing Pleasury with a worldwide,
              royalty-free, unlimited, non-exclusive license, for as long as the
              User Content is posted on the Service, to publicly display,
              modify, reproduce, make available, and distribute your User
              Content on the Service or for editorial and promotional purposes
              of the Service. Pleasury reserves the right to syndicate or
              promote this User Content in connection with the promotion of the
              Service and other services offered by Pleasury.
            </div>
            <div className="mt-4 ">
              Pleasury is not responsible for enforcing any rights you claim in
              any User Content, which shall be your responsibility entirely, and
              you agree to hold harmless and indemnify Pleasury with respect to
              any claim you have that other users are reproducing or using your
              User Content, as well as for claims by other users that you are
              reproducing or using their User Content without permission.
              Pleasury will not arbitrate, mediate or resolve any intellectual
              property or other disputes between users, and has no
              responsibility for doing same.
            </div>
            <div className="mt-4 ">
              You acknowledge that Pleasury and its designees shall have the
              right, but not the obligation, in its sole discretion, to
              pre-screen, refuse, move, or delete, any User Content that
              violates the TOS or is otherwise objectionable. Pleasury may
              verify the authenticity and appropriateness of certain User
              Content as described in detail in the TOS or the posting
              guidelines. Pleasury offers this Service but does not warrant or
              condition that it will be effective in ensuring that User Content
              is authentic or appropriate.
            </div>
            <div className="mt-4 ">
              As set out in greater detail in our Privacy Policy, Pleasury may
              preserve and store your account information and User Content if it
              believes, in good faith, that such preservation is necessary to
              comply with legal processes, respond to claims that the User
              Content violates the rights of third parties, or to protect the
              rights, property or safety of Pleasury, its users and the broader
              public.
            </div>
            <div className="mt-4 ">
              Pleasury provides services that enable authorized users to
              communicate or otherwise share information or content with other
              users or persons regarding the provision of goods or services.
              Insofar as Pleasury provides such service, and you elect to use
              the service, you AGREE to NOT post, publish, display, disseminate,
              or otherwise communicate any defamatory, inaccurate, abusive,
              threatening, offensive, obscene, fraudulent or illegal User
              Content or any User Content which would violate or infringe the
              copyright, trademark, rights of publicity, privacy rights or other
              rights of any person.
            </div>
            <div className="mt-4 border-b pb-10  border-whitepink border-opacity-40">
              You acknowledge that posting or otherwise communicating User
              Content that violates the law of any country, or political
              subdivision thereof, is strictly prohibited by Pleasury.
              Disseminating content in violation of any laws, regulations or
              these TOS shall constitute a material breach of the TOS entitling
              Pleasury, without notice and without any liability for damages or
              reimbursement to you, to immediately terminate your rights to
              access the Service.
            </div>
            <div className="mt-4">
              <b> 5. Links</b>
            </div>
            <div className="mt-4 ">
              The Service may provide, or third parties including Pleasury
              users, may provide links to other websites or online resources.
              Pleasury has no control over these sites and their content and
              therefore you acknowledge that Pleasury is not responsible for the
              availability of such links, resources and content, and does not
              endorse and is not responsible or liable for any content,
              advertising, products, or other materials made available on or
              from these linked websites. You also acknowledge and agree that
              Pleasury is not responsible or liable, directly or indirectly, for
              any damage or loss caused by or alleged to have been caused by or
              in relation to the use of content, goods or services offered
              through these links or any failures and/or disruption to your
              computer system that results from your use of any such links, or
              for any intellectual property or other third party claims relating
              to your posting or using such links.
            </div>
            <div className="mt-4 border-b pb-10  border-whitepink border-opacity-40">
              YOU AGREE THAT Pleasury CAN REQUEST YOU TO DISABLE ANY LINK YOU
              HAVE POSTED AND HAS THE RIGHT TO DISABLE THE LINK WITHOUT NOTICE
              TO YOU.
            </div>
            <div className="mt-4">
              <b> 6. Indemnity</b>
            </div>
            <div className="mt-4 border-b pb-10  border-whitepink border-opacity-40">
              To the maximum extent permitted by applicable law, you agree to
              defend, indemnify and hold harmless Pleasury, its subsidies,
              affiliates, officers, agents, and other partners and employees,
              from any and all losses, liabilities, claims, demands, damages,
              judgments, awards, losses, costs, expenses, or fees (including
              legal fees and disbursements), arising out of or relating to your
              breach of the TOS or your use of the Service, including but not
              limited to, any User Content you submit using the Service, third
              party websites or online resources, any use of Pleasury’s Service
              other than as expressly authorized in the TOS.
            </div>
            <div className="mt-4">
              <b> 7. Financial Policies</b>
            </div>
            <div className="mt-4 ">
              You agree not to, without the express prior written consent of
              Pleasury: (a) reproduce, duplicate, copy, sell, resell, or exploit
              for any commercial purposes, any portion of the Service or User
              Content you do not own, (b) use the Service or User Content you do
              not own, or (c) access to the Service or User Content you do not
              own.
            </div>
            <div className="mt-4 ">
              Pleasury retains the right to adjust, modify or supplement rates
              charged for Services. Such changes may be posted in these TOS and
              the pricing sections, without prior notice.
            </div>
            <div className="mt-4 ">
              Pleasury shall not issue cash refunds and any billing errors
              reported will be directed to our billing service provider for
              resolution. Pleasury may, in its sole discretion, grant credits in
              the form of gift cards to resolve customer service issues.
            </div>
            <div className="mt-4 border-b pb-10  border-whitepink border-opacity-40">
              Purchases made on the site via debit or credit card will appear as
              LLServices or as Pleasury in your bank statement.
            </div>
            <div className="mt-4">
              <b> 8. Modification and Termination of the Service</b>
            </div>
            <div className="mt-4 border-b pb-10  border-whitepink border-opacity-40">
              Pleasury reserves the right at any time and from time to time to
              modify, suspend or discontinue, temporarily or permanently, the
              Service (or any part thereof) with or without notice. You agree
              that Pleasury will not be liable to you or any third party for any
              modification, suspension or discontinuance of the Service.
            </div>
            <div className="mt-4">
              <b> 9. Pleasury Privacy Policy</b>
            </div>
            <div className="mt-4 border-b pb-10 border-whitepink border-opacity-40">
              Personal information and certain other information is subject to
              our Privacy Policy. As a condition of using the Service you agree
              to the terms of the Privacy Policy, as it may be changed from time
              to time. Our Privacy Policy, which is incorporated here by
              reference, is{" "}
              <span
                className="font-bold cursor-pointer active:opacity-40  pr-1 text-purple"
                onClick={() => navigate("/policy")}
              >
                {" "}
                located here.
              </span>
            </div>
            <div className="mt-4">
              <b> 10. Registration</b>
            </div>
            <div className="mt-4 ">
              In order to use certain aspects of the Service you are required to
              register. We refer to registered users as “Members”. Members are
              subject to the following specific terms in addition to all of the
              other terms in this Agreement:
            </div>
            <div className="mt-4 ">
              • In consideration of your use of the Service, you represent that
              you are of legal age to form a binding contract, of the age of
              majority in your jurisdiction, and are not a person barred from
              receiving Services under applicable laws of the jurisdiction in
              which you reside.
            </div>
            <div className="mt-4 ">
              • You agree to provide true, accurate, current and complete
              information as required on the Service’s Registration Form. If you
              provide any information that is untrue, incomplete, not current or
              inaccurate, Pleasury has the right to suspend or terminate your
              account and refuse your current or future use of the Service (or
              any portion thereof.)
            </div>
            <div className="mt-4 ">
              • You agree that Pleasury may, under certain circumstances and
              without prior notice, immediately suspend your Pleasury account,
              any associated username and/or access to the Service. Cause for
              such termination shall include, but not be limited to: i) breaches
              or violations of the TOS or other policies, guidelines or rules,
              ii) extended periods of inactivity, iii) your engagement in
              fraudulent or illegal activity, iv) unexpected technical or
              security issues, and v) requests by law enforcement or other
              government agencies. You also agree that any termination is in
              Pleasury’s sole discretion and that Pleasury will not be liable to
              you or any third party for any termination of your account,
              password, username, deletion of User Content you posted on the
              Service or access to the Service.
            </div>
            <div className="mt-4 ">
              • You agree that your account is non-transferable and any rights
              to your account, password, username, terminate upon your death.
            </div>
            <div className="mt-4 border-b pb-10 border-whitepink border-opacity-40">
              • You are responsible for any activities that take place under
              your username and password. If you become aware of any
              unauthorized use of your password or account, or any other breach
              of security, please contact Pleasury immediately. It is up to you
              to maintain the confidentiality of your password and account.
              Pleasury is not responsible or liable for any loss or damage
              arising from your failure to comply with the provisions of these
              TOS.
            </div>
            <div className="mt-4">
              <b> 11. Practices regarding Use and Storage</b>
            </div>
            <div className="mt-4  border-b pb-10 border-whitepink border-opacity-40">
              You acknowledge that Pleasury may establish general practices and
              limits regarding use of the Service including without limitation
              the maximum number of email messages which may be sent or received
              from an account of the Service, the maximum size of any email
              message that may be sent from or received by an account of the
              Service, the maximum amount of disk space that will be allotted on
              Pleasury’s servers on your behalf, and the maximum number of times
              and duration for which you may access the Service in a given time.
              You agree that Pleasury has no responsibility or liability for the
              deletion or failure to store or transmit any messages or
              communications or other User Content maintained or transmitted by
              the Service. You acknowledge and agree that Pleasury reserves the
              right to log off accounts and deactivate usernames and accounts
              that are inactive for an extended period of time. You further
              acknowledge that Pleasury has the right to modify these practices
              and limits from time to time.
            </div>
            <div className="mt-4">
              <b> 12. Pleasury Proprietary Rights</b>
            </div>
            <div className="mt-4  border-b pb-10 border-whitepink border-opacity-40">
              You acknowledge and agree that the Service and any necessary
              software used in connection with the Service (“Software”) contain
              proprietary and confidential information that is the property of
              Pleasury and its licensors and is protected by applicable
              intellectual property and other laws. You further acknowledge and
              agree that content contained in advertisements or information
              presented to you through the Service is protected by copyright,
              trademarks, patents or other proprietary rights and laws. Except
              as expressly authorized by Pleasury or advertisers on the Service,
              you agree not to modify, rent, lease, loan, sell, make available,
              distribute or create derivative works based on the Service or
              Software, in whole or in part. You also acknowledge Pleasury’s
              exclusive rights in the Pleasury trademark.
            </div>
            <div className="mt-4">
              <b> 13. Disclaimer of Warranties and Conditions</b>
            </div>
            <div className="mt-4 ">
              The use of the Service is at your sole risk. The Service is
              provided on an “as is” and “as available” basis. Pleasury
              EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND,
              WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
              IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY. MERCHANTABLE
              QUALITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
            </div>
            <div className="mt-4 ">
              Pleasury MAKES NO WARRANTY THAT 1) THE SERVICE WILL MEET YOUR
              REQUIREMENTS., (2) THE SERVICE WILL BE UNINTERRUPTED, TIMELY
              SECURE, OR ERROR-FREE, (3) THE RESULTS OF USING THE SERVICE WILL
              BE ACCURATE OR RELIABLE, (4) THE QUALITY OF ANY PRODUCTS,
              SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED
              THROUGH YOUR USE OF THE SERVICE WILL MEET YOUR EXPECTATIONS, OR
              (5) THAT ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.
            </div>
            <div className="mt-4  border-b pb-10 border-whitepink border-opacity-40">
              No advice or information, whether oral or written, obtained by you
              from Pleasury or through or from the Service shall create a
              warranty not expressly stated in the TOS.
            </div>
            <div className="mt-4">
              <b> 14. Limitation of Liability</b>
            </div>
            <div className="mt-4  border-b pb-10 border-whitepink border-opacity-40">
              Excepts where such exclusions are prohibited by law, you expressly
              understand and agree that Pleasury and, its subsidiaries,
              affiliates, officers, agents, and other partners and employees
              will not be liable for negligence, gross negligence, negligent
              misrepresentation, fundamental breach, damages of any kind, under
              any legal theory, including any direct, indirect, incidental,
              special, consequential, punitive, or exemplary damages, including
              but not limited to, personal injury, pain and suffering, emotional
              distress, loss of revenue, loss of profits, loss of business or
              anticipated savings, loss of use, loss of goodwill, loss of data,
              or other intangible losses, and whether caused by tort (including
              negligence), breach of contract, breach of privacy or otherwise,
              even if the party was allegedly advised or had reason to know,
              arising out of or in connection with your use, or the inability to
              use, or reliance on, the Service, unauthorized access to or
              alteration or alteration of your transmissions or data, statements
              or conduct of any third party including advertisers on the
              Service, the cost of procurement of substitute goods and Services
              resulting from any goods, data, information or services purchased
              or obtained or messages received or transactions entered into
              though or form the Service or any other matter relating to the
              Service, and in no event will Pleasury be liable for any amount of
              money over One Hundred Dollars ($100.00), which you acknowledge to
              be a fair and reasonable sum.
            </div>
            <div className="mt-4">
              <b> 15. Disputes</b>
            </div>
            <div className="mt-4 ">
              The TOS will be governed by the laws of the Province of Ontario,
              and the federal laws of Canada applicable therein without regard
              to conflict of law provisions. Except to the extent restricted by
              applicable law, if there is any dispute or controversy between (1)
              you and (2) Pleasury or any of our group companies, affiliates,
              employees, agents, service providers, or professional advisors
              (the “Pleasury Parties”) including any dispute or controversy
              arising out of or relating to the TOS or the Services, or in
              respect of any legal relationship associated with or derived from
              the TOS, including the validity, existence, breach, termination,
              construction or application, or the rights, duties or obligations
              of you or the Pleasury Parties, (each, a "Dispute"), the disputing
              person will serve notice on the other person and each of you and
              the applicable Pleasury Parties must use good faith efforts to
              resolve the Dispute informally.
            </div>
            <div className="mt-4 ">
              Except to the extent restricted by applicable law, if the Dispute
              is not resolved after twenty (20) business days of a person
              serving notice on the other party that there is a Dispute, you and
              us agree that the Dispute will be finally resolved by confidential
              arbitration before a single arbitrator in accordance with the
              Arbitration Rules of the ADR Institute of Canada, Inc. The seat of
              the arbitration will be Toronto, Ontario or wherever convenient or
              necessary acting reasonably. There will be no appeals of any kind.
              The language of the arbitration will be English unless otherwise
              required by applicable law or agreed to by you and us.
            </div>
            <div className="mt-4 ">
              Except to the extent restricted by applicable law, you agree that
              you and the Pleasury Parties will resolve any Dispute on an
              individual basis. Any claim you may have must be brought
              individually, in your individual capacity and not as a
              representative plaintiff or class member, and you will not join
              such claim with claims of any other person or entity, or bring,
              join or participate in a class action lawsuit, collective or
              representative proceeding of any kind (existing or future) against
              any of the Pleasury Parties.
            </div>
            <div className="mt-4  border-b pb-10 border-whitepink border-opacity-40">
              Nothing in this Section will prohibit us from seeking interim
              measures from a court, including preliminary or injunctive relief
              of breach of you of any intellectual property or privacy rights.
            </div>
            <div className="mt-4">
              <b> 16. Copyright and Trademark Policy</b>
            </div>
            <div className="mt-4 ">
              Trademarks, logos, and copyrighted works (hereinafter,
              “Intellectual Property”) appearing in this site are the property
              of Pleasury or the party that provided the Intellectual Property
              to Pleasury.
            </div>
            <div className="mt-4 ">
              Pleasury and any party that provides Intellectual Property to
              Pleasury retain all rights with respect to any of their respective
              Intellectual Property appearing on the Service. All contents of
              Pleasury’s websites are: Copyright © 2018 Pleasury Inc. All rights
              reserved.
            </div>
            <div className="mt-4 ">
              We take claims of copyright infringement seriously. We will
              respond to notices of alleged copyright infringement. If you
              believe any materials accessible on or from the Service infringe
              your copyright, you may request that we send a notice to the
              alleged infringer by submitting written notification to our
              Copyright Agent (designated below). The written notice (the
              "Notice") must include the following:
            </div>
            <div className="mt-4 ">• Your name and address.</div>
            <div>
              • Identification of the copyrighted work you believe to have been
              infringed or, if the claim involves multiple works on the Service,
              a representative list of such works.
            </div>
            <div>
              • A statement describing your interest or right with respect to
              the copyright in the work or other subject-matter.
            </div>
            <div>
              • Identification of the location of material you believe to be
              infringing in a sufficiently precise manner to allow us to locate
              that material (e.g., a link to the infringing material).
            </div>
            <div>• Description of the infringement that is claimed.</div>
            <div>
              • A statement specifying the date and time of the alleged
              infringement.
            </div>
            <div>
              • Any other information that may be prescribed by regulation that
              we may advise you of from time-to-time.
            </div>
            <div>The Notice may not contain any of the following:</div>
            <div>• An offer to settle the claimed infringement.</div>
            <div>
              • A request or demand relating to the claimed infringement for
              payment or for personal information.
            </div>
            <div>
              • A reference, including by way of hyperlink, to such an offer,
              request or demand.
            </div>
            <div>
              • Any other information that may be prescribed by regulation that
              we may advise you of from time-to-time.
            </div>
            <div>(the "Prohibited Content").</div>
            <div>
              A Notice containing Prohibited Content will not be considered a
              valid Notice under this TOS.
            </div>
            <div>
              Pleasury’s Copyright Agent for notice of claims of copyright
              infringement on the Service can be reached as follows:
            </div>
            <div>Attn: Copyright Agent</div>
            <div>
              e-mail:{" "}
              <span
                className="font-bold cursor-pointer active:opacity-40  pr-1 text-purple"
                // onClick={() => navigate("/report-offense")}
              >
                {" "}
                support@pleasurylist.com{" "}
              </span>
            </div>
            <div>
              Please be aware that if you knowingly materially misrepresent that
              material or activity on the Service is infringing your copyright,
              you may be held liable for damages (including costs and
              [all/reasonable] legal fees, disbursements and charges).
            </div>
            <div>
              If we receive a Notice in the prescribed form, we will, as soon as
              feasible, forward the Notice electronically to the person to whom
              the electronic location identified by the location data in the
              Notice belongs and inform you of our doing so. If, for some
              reason, it is not possible for us to forward the Notice to such
              person, we will confirm with you the reasons therefor.
            </div>
            <div>
              We will retain records of Notices in compliance with our
              obligations under applicable laws and regulations.
            </div>
            <div className=" border-b pb-10 border-whitepink border-opacity-40">
              Without limiting any other rights or remedies we may be entitled
              to under the TOS or otherwise at law, Pleasury reserves the right
              to remove any material or disable or terminate our users accounts
              or take such other actions deemed necessary or appropriate in the
              circumstances in our sole discretion.
            </div>
            <div className="mt-4">
              <b>17. General Information</b>
            </div>
            <div className="mt-4 ">
              This Agreement constitutes the entire agreement between you and
              Pleasury and governs your use of the Service. You may also be
              subject to additional terms and conditions, posted policies
              (including but not limited to the Privacy Policy), guidelines, or
              rules that may apply when you use or purchase certain elements of
              the Service, Affiliate or advertiser Services, third-party content
              or third-party software.
            </div>
            <div className="mt-4 ">
              The failure of Pleasury to exercise or enforce any right or
              provision of this TOS shall not operate as a waiver of such right
              or provision. If any provision of the TOS is found by a court of
              competent jurisdiction to be invalid, the parties’ intentions as
              reflected in the provision, and the other provisions of the TOS
              remain in full force and effect.
            </div>
            <div className="mt-4 ">
              The section titles in the Agreement are for convenience only and
              have no legal or contractual effect.
            </div>
            <div className="mt-4 ">
              Nothing contained in the TOS shall be construed to constitute
              either party as a partner, employee or agent of the other party,
              nor shall either party hold itself out as such. Each party has no
              right or authority to incur, assume or create, in writing or
              otherwise, any warranty, liability or other obligation of any
              kind, express or implied, in the name of or on behalf of the other
              party, it being intended by both parties that each shall remain
              independent contractors responsible for its own actions.
            </div>
            <div className="mt-4 ">
              You agree that regardless of any statute or law to the contrary,
              where permitted, any claim or cause of action arising out of or
              related to use of the Service or the TOS must be filed within one
              (1) year after such claim or cause of action arose or be forever
              barred.
            </div>
            <div className="mt-4 border-b pb-10 border-whitepink border-opacity-40">
              Please report any violations of the TOS{" "}
              <span
                className="font-bold cursor-pointer active:opacity-40  pr-1 text-purple"
                onClick={() => navigate("/report-tos")}
              >
                {" "}
                here
              </span>
              .
            </div>
            <div className="mt-4">
              <b>18. Pleasury affiliation with PleasuryGallery</b>
            </div>
            <div className="mt-4 ">
              This agreement confirms you expressly understand and agree that
              all of your data and information held within your Pleasury user
              account, will be directly transferred and replicated into our
              affiliated site, PleasuryGallery, whereby your user account will
              be duplicated for the purposes of PleasuryGallery’s features and
              viewing functionality. In the event you no longer wish to be an
              active member on PleasuryGallery or to have your data and
              information held within this affiliated site for its user purpose,
              you have the option to delete your account by contacting Support
              team.
            </div>
            <div className="mt-4 border-b pb-10 border-whitepink border-opacity-40">
              Need support with billing? Contact our support team
              <span
                className="font-bold cursor-pointer active:opacity-40  pr-1 text-purple"
                // onClick={() => navigate("/report-tos")}
              >
                {" "}
                support@pleasurylist.com
              </span>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
