import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getEscortData, selectVerified } from '../../services/API';
import { useSelector } from 'react-redux';
import { selectToken } from '../../store/token';
import { ClipLoader } from 'react-spinners';
import images from '../../services/images';

export default function VerifyEscort() {

  const navigate = useNavigate()

  const { _id } = useParams();
  const token = useSelector(selectToken)

  const [escortData, setEscortData] = useState()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [loader, setLoader] = useState(false)
  const [modal, setModal] = useState(false)
  const [isAccept, setisAccept] = useState(null)

  useEffect(() => {
    if (_id) {
      handleGetEscortData(_id)
    }
  }, [])

  useEffect(() => {
    if (loader || modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [loader, modal]);

  const handleSelectVerified = async (status) => {
    setisAccept(status)
    setLoader(true)
    try {
      const body = { status }
      const response = await selectVerified(_id, token, body)
      if (response.success) {
        setLoader(false)
        setModal(true)
      } else {
        setLoader(false)
      }
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  const handleGetEscortData = async (_id) => {
    try {
      const response = await getEscortData(_id, token)
      setEscortData(response?.escortData)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div className={
        `flex flex-col lg:flex-col  items-center mx-auto md:mx-0 gap-5 bg-purpleGrey ${loader && "opacity-50"}`
      }>
        {
          loader &&
          <div className=' fixed top-0 left-0 z-[9999] w-full h-[100vh] flex items-center justify-center'>
            <ClipLoader
              color="#BE42DE"
              cssOverride={{
                height: "40px",
                width: "40px",
              }}
            />
          </div>
        }
        <div className="shadow-md xl:w-[30%] lg:w-[50%] w-[100%] mt-10 ">
          <img
            src={escortData?.verificationPicture[selectedIndex]}
            className="object-contain lg:w-[95%] md:w-[60%] w-[90%]  mx-auto h-[50vh] md:h-[60vh]"
          />
          <div className="flex flex-row w-[95%] overflow-x-scroll gap-2 mx-auto mt-4">
            {escortData?.verificationPicture?.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item}
                  className="w-18 h-14 cursor-pointer"
                  onClick={() => setSelectedIndex(index)}
                />
              );
            })}
          </div>
        </div>
        <div className="text-whitepink xl:w-[60%] lg:w-[50%] w-[100%] px-2 mt-2 lg:mt-0">
          <div className="  text-xl lg:text-4xl font-bold pb-6">
            Escort Details
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2 ">
            <div className=" ">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity ">
                First name
              </div>
              <div className="relative mt-2">
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  <div
                    className="w-full bg-transparent text-whitepink focus:outline-none"
                  >
                    {escortData?.firstname}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity ">
                Last name
              </div>
              <div className="relative mt-2">
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  <div
                    className="w-full bg-transparent text-whitepink focus:outline-none"
                  >
                    {escortData?.lastname}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2 mt-2 md:mt-5">
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity ">
                Email
              </div>
              <div className="relative mt-2">
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  <div
                    className="w-full bg-transparent text-whitepink focus:outline-none"
                  >
                    {escortData?.email}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity ">
                About
              </div>
              <div className="relative mt-2">
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center justify-between border-whitepink border-opacity-20 lg:min-h-14 md:min-h-10 sm:min-h-10 min-h-10 px-3 `}
                >
                  <div
                    className="w-full bg-transparent text-whitepink focus:outline-none"
                  >
                    {escortData?.about}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-white xl:w-[60%] lg:w-[50%] w-[100%] px-2 flex flex-col md:flex-row md:justify-center gap-2 pb-[200px]'>
          <div className='bg-purple text-center py-2 px-14 font-semibold active:opacity-50 cursor-pointer' onClick={() => handleSelectVerified(true)
          }>Accept</div>
          <div className='bg-purple text-center py-2 px-14 font-semibold active:opacity-50 cursor-pointer' onClick={() => handleSelectVerified(false)
          }>Reject</div>
        </div>
        {modal && (
          <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="text-white rounded-xl py-4 bg-purplegray flex items-center justify-center px-5 flex flex-col gap-3">
              <img
                src={isAccept ? images.accept : images.decline}
                className="w-[20%] md:w-[30%]"
              />
              <div className="font-bold text-xl">
                {isAccept ? "Verification Successful" : "Verification Rejected"}
              </div>
              <div className="font-semibold text-center text-md" style={{ whiteSpace: 'pre' }}>
                {isAccept ? (
                  `You have successfully verified ${escortData?.firstname} ${escortData?.lastname}.
 They can now post verified ads on your platform.`
                ) : `You have rejected ${escortData?.firstname} ${escortData?.lastname} verification request. 
They will not be able to post verified ads on your platform.`}
              </div>
              <div
                className="bg-purple rounded-md px-4 py-2 cursor-pointer active:opacity-50"
                onClick={() => {
                  setModal(false)
                  navigate('/')
                }}
              >
                Done
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
