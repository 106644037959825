import React, { useState } from "react";
import images from "../../services/images";
import { Link } from "react-router-dom";

export default function Viewlisting() {
  const newimages = [
    {
      image1: images.Liveimgmodel1,
      image2: images.Liveimgmodel2,
      image3: images.Liveimgmodel3,
      image4: images.Liveimgmodel4,
      image5: images.Liveimgmodel6,
      image6: images.Liveimgmodel5,
    },
  ];

  const [uploadimages, setuploadedimg] = useState(false);

  return (
    <div className="bg-purplegray">
      <div className="w-full flex justify-between pt-10 pb-5 pl-10 lg:pr-10 pr-5">
        <Link to={"/Escorts/All"} className="flex items-center ">
          <div className="bg-white flex justify-center items-center lg:w-13 lg:h-12 w-8 md:h-7 sm:h-7 h-7 cursor-pointer active:oapcity-50 ">
            <img src={images.arrowleft} alt="" className="lg:w-5 md:w-4 sm:w-3 w-3" />
          </div>
        </Link>
        <div className="w-full flex items-center  lg:text-4xl md:text-3xl sm:text-xl text-lg font-semibold justify-center text-white ">
          View Listing
        </div>
        <Link to={"/Escorts/editlist"} className="flex items-center">
          <div className=" flex items-center  lg:text-3xl md:text-2xl sm:text-xl text-lg  font-semibold justify-center text-white cursor-pointer active:opacity-50">
            Edit
            <img  
              src={images.whitedit}
              alt=""
              className="lg:w-7 w-5  ml-2 flex mt-2  "
            />
          </div>
        </Link>
      </div>
      <div className=" lg:w-full flex-col w-92p ml-3   justify-center">
        <div className="flex-col flex   w-full ">
          <div className="flex justify-center  items-center  ">
            <div className="lg:w-[19.7%] md:w-50p w-50p  ">
              <div className="w-14 h-6 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal ">
                  Name
                </div>
              </div>
              <input
                type="text"
                className="bg-transparent lg:text-lg md:text-base sm:text-sm text-xs remove-arrow focus:outline-none text-white text-lg w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Name"
                value={"Florance"}
              />
            </div>
            <div className="lg:w-[19.7%] md:w-50p w-50p  lg:ml-5 md:ml-4 sm:ml-4 ml-2">
              <div className="w-28 h-6 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal text-nowrap ">
                  Phone Number
                </div>
              </div>
              <input
                type="number"
                className="bg-transparent lg:text-lg md:text-base sm:text-sm text-xs remove-arrow focus:outline-none text-white text-lg w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Number"
                value={+125646058}
              />
            </div>
          </div>
          <div className="flex w-full justify-center items-center ">
            <div className="lg:w-[19.7%] md:w-50p w-50p  ">
              <div className="w-14 h-6 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal ">
                  Email
                </div>
              </div>
              <input
                type="text"
                className="bg-transparent lg:text-lg md:text-base sm:text-sm text-xs remove-arrow focus:outline-none text-white text-lg w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Email"
                value={"florence123@gmail.com"}
              />
            </div>
            <div className="lg:w-[19.7%] md:w-50p w-50p lg:ml-5 md:ml-4 sm:ml-4 ml-2">
              <div className="w-14 h-6 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal ">
                  Height
                </div>
              </div>
              <input
                type="text"
                className="bg-transparent lg:text-lg md:text-base sm:text-sm text-xs remove-arrow focus:outline-none text-white text-lg w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter height"
                value={"5.2ft"}
              />
            </div>
          </div>
          <div className="flex w-full justify-center items-center">
            <div className="lg:w-[19.7%] md:w-50p w-50p ">
              <div className="w-14 h-6 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal ">
                  London
                </div>
              </div>
              <input
                type="text"
                className="bg-transparent lg:text-lg md:text-base sm:text-sm text-xs remove-arrow focus:outline-none text-white text-lg w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Location"
                value={"london"}
              />
            </div>
            <div className="lg:w-[19.7%] md:w-50p w-50p  lg:ml-5 md:ml-4 sm:ml-4 ml-2">
              <div className="w-14 h-6 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 text-xs font-normal ">
                  Age
                </div>
              </div>
              <input
                type="number"
                className="bg-transparent lg:text-lg md:text-base sm:text-sm text-xs remove-arrow focus:outline-none text-white text-lg w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Age"
                value={22}
              />
            </div>
          </div>
        </div>

        <div className="pt-10 w-full justify-center flex relative ">
          <div className="w-20 h-6 px-2 py-1 bg-purplegray justify-center absolute bottom-[75%] left-[32%] items-center inline-flex">
            <div className="text-center text-fuchsia-100 text-xs font-normal ">
              Description
            </div>
          </div>
          <div className="lg:w-40p w-90p pt-2 rounded-xl border border-whitepink border-opacity-20 h-auto   ">
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="10"
              className="w-full block lg:text-lg md:text-base sm:text-sm text-xs bg-transparent text-white opacity-60 h-40 text-xl focus:outline-none pl-4"
              placeholder="Please tell us about yourself"
            ></textarea>
          </div>
        </div>
        <div className="w-full pt-10 flex justify-center pb-40">
          {newimages.map((item, index) => (
            <div key={index}>
              <div className="flex flex-wrap ">
                <img
                  src={item.image1}
                  alt=""
                  className="lg:w-32 md:w-24 sm:w-20 w-14 ml-2 cursor-pointer active:opacity-50"
                />
                <img
                  src={item.image2}
                  alt=""
                  className="lg:w-32 md:w-24 sm:w-20 w-14 ml-2 cursor-pointer active:opacity-50"
                />
                <img
                  src={item.image3}
                  alt=""
                  className="lg:w-32 md:w-24 sm:w-20 w-14 ml-2 cursor-pointer active:opacity-50"
                />
                <img
                  src={item.image4}
                  alt=""
                  className="lg:w-32 md:w-24 sm:w-20 w-14 ml-2 cursor-pointer active:opacity-50"
                />
                <img
                  src={item.image5}
                  alt=""
                  className="lg:w-32 md:w-24 sm:w-20 w-14 ml-2 cursor-pointer active:opacity-50"
                />
                <img
                  src={item.image1}
                  alt=""
                  className="lg:w-32 md:w-24 sm:w-20 w-14 ml-2 cursor-pointer active:opacity-50"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
