import React, { useState } from "react";
import images from "../../services/images";
import { useModal } from "../../screens/Layout";
import { useAuth } from "../../services/Authcontext/Authcontext";

export default function EditlistComponent() {
  const { Modalcomponent, setmodalcomponent } = useModal();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [height, setHeight] = useState("");
  const [location, setLocation] = useState("");
  const [age, setAge] = useState("");
  const [error, setError] = useState();
  const [hourlyRate, setHourlyRate] = useState();
  const [description, setDescription] = useState();
  const toggleBack = () => {
    setmodalcomponent("Createprof");
  };

  const toggleForward = () => {
    setmodalcomponent("Uploadphotos");
  };

  const {
    UserEmail,
    takeUsername,
    setNumber,
    setAuthHeight,
    setAuthAge,
    setAuthHourlyRate,
    setAuthLocation,
    setAuthDescription,
    setTakeUsername,
    setUserEmail,
  } = useAuth();

  const validateForm = () => {
    // const { name, email, location, phoneNumber, height, age } = formData;

    // Check if any required fields are empty
    if (!location || !phone || !height || !age) {
      setError("Please fill out all required fields.");

      return false;
    }

    // Validate phone format (simple example)
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      setError("Invalid phone number format. Use 10 digits.");
      return false;
    }
    // Validate email format (simple example)
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(email)) {
    //   setError("Invalid email format.");
    //   return false;
    // }

    // Validate height format (e.g., 5'9")
    const heightRegex = /^(\d{1,})'(\d{1,2})"$/;
    if (!heightRegex.test(height)) {
      setError("Invalid height format. Use a format like 5'9\".");
      return false;
    }

    // Validate age above 18

    if (age < 18) {
      setError("Age must be a number and 18 or above.");
      return false;
    }
    // Clear error if all validations pass
    setError("");
    setNumber(phone);
    setAuthHeight(height);
    setAuthHourlyRate(hourlyRate);
    setAuthAge(age);
    setAuthLocation(location);
    setAuthDescription(description);
    toggleForward();
    return true;
  };

  return (
    <div className="text-white   w-full ">
      <div className="bg-footercolor  flex flex-col  p-4 w-full">
        <div className="text-xl sm:text-3xl font-semibold">
          Escort Listing Details
        </div>
        <div className="text-red-500 pt-2 lg:text-base md:text-sm sm:text-xs text-xs  ">
          {error}
        </div>
        <div className=" flex flex-col">
          <div className="flex gap-5 flex-row w-full">
            <div className="lg:w-full md:w-50p w-50p ">
              <div className="w-14 h-6 px-2 py-1 bg-footercolor justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 sm:text-xs text-xxs font-normal ">
                  Name
                </div>
              </div>
              <input
                type="text"
                className="bg-transparent focus:outline-none text-white lg:text-lg placeholder-whitepink placeholder-opacity-40 md:text-base sm:text-sm text-xs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder={takeUsername}
                value={takeUsername}
                // onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="lg:w-full md:w-50p w-50p ">
              <div className="w-24 h-6 px-2 py-1 bg-footercolor justify-center relative top-3 left-3 items-center  inline-flex">
                <div className=" text-nowrap text-center text-fuchsia-100 sm:text-xs text-xxs font-normal ">
                  Phone Number
                </div>
              </div>
              <input
                type="number"
                className="remove-arrow bg-transparent focus:outline-none text-white placeholder-whitepink placeholder-opacity-40 lg:text-lg md:text-base sm:text-sm text-xs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-5 flex-row w-full">
            <div className="lg:w-full md:w-50p w-50p ">
              <div className="w-14 h-6 px-2 py-1 bg-footercolor justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 sm:text-xs text-xxs font-normal ">
                  Email
                </div>
              </div>
              <input
                type="text"
                className="bg-transparent placeholder-whitepink placeholder-opacity-40 focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-xs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder={UserEmail}
                value={UserEmail}
                // onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="lg:w-full md:w-50p w-50p ">
              <div className="w-14 h-6 px-2 py-1 bg-footercolor justify-center relative top-3 left-3 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 sm:text-xs text-xxs font-normal ">
                  Location
                </div>
              </div>
              <input
                type="text"
                className="bg-transparent placeholder-whitepink placeholder-opacity-40 focus:outline-none text-white lg:text-lg md:text-base sm:text-sm sm:text-xs text-xxs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-5 flex-row w-full">
            <div className="lg:w-full md:w-50p w-50p ">
              <div className="w-14 h-6 px-2 py-1 bg-footercolor justify-center relative top-3 left-0 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 sm:text-xs text-xxs font-normal ">
                  Height
                </div>
              </div>
              <input
                type="text"
                className="remove-arrow placeholder-whitepink placeholder-opacity-40 bg-transparent focus:outline-none text-white lg:text-lg md:text-base sm:text-sm sm:text-xs text-xxs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="00"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              />
            </div>
            <div className="lg:w-full md:w-50p w-50p ">
              <div className="w-14 h-6 px-2 py-1 bg-footercolor justify-center relative top-3 left-0 items-center  inline-flex">
                <div className="text-center text-fuchsia-100 sm:text-xs text-xxs font-normal ">
                  Age
                </div>
              </div>
              <input
                type="Number"
                className="remove-arrow placeholder-whitepink placeholder-opacity-40 bg-transparent focus:outline-none text-white lg:text-lg md:text-base sm:text-sm sm:text-xs text-xxs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="18"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </div>
            <div className="lg:w-full md:w-50p w-50p ">
              <div className="w-20 h-6 px-2 py-1 bg-footercolor justify-center relative top-3 left-[-2%] items-center  inline-flex">
                <div className="text-nowrap text-center text-fuchsia-100 sm:text-xs text-xxs font-normal ">
                  Hourly Rate
                </div>
              </div>
              <input
                type="Number"
                className=" remove-arrow placeholder-whitepink placeholder-opacity-40 bg-transparent focus:outline-none text-white lg:text-lg md:text-base sm:text-sm sm:text-xs text-xxs w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                placeholder="Enter Hourly Rate"
                value={hourlyRate}
                onChange={(e) => setHourlyRate(e.target.value)}
              />
            </div>
          </div>
          <div className="pt-10 w-full justify-center flex relative ">
            <div className="w-20 h-6 px-2 py-1 bg-footercolor justify-center absolute bottom-[75%] left-[2%] items-center inline-flex">
              <div className="text-center text-fuchsia-100 sm:text-xs text-xxs font-normal ">
                Description
              </div>
            </div>
            <div className="lg:w-full w-90p pt-2 rounded-xl border border-whitepink border-opacity-20 h-auto">
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                className="w-full block bg-transparent text-white opacity-60 h-40 lg:text-lg md:text-base sm:text-sm sm:text-xs text-xxs focus:outline-none pl-4"
                placeholder="Please tell us about yourself"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="mt-10 p-2 flex w-full justify-end">
          {/* <div
            className="sm:text-lg font-semibold flex border border-whitepink cursor-pointer active:opacity-50
           sm:w-40 sm:h-14  sm:p-0 p-2 text-sm items-center  justify-center gap-4"
            onClick={toggleBack}
          >
            <img src={images.smallarrowwhiteleft} className="sm:h-4 h-3" />
            Back
          </div> */}
          <div
            className="sm:text-lg text-sm font-semibold flex bg-purple cursor-pointer active:opacity-50
            sm:w-40 sm:h-14  sm:p-0 p-2  items-center  justify-center gap-4"
            onClick={validateForm}
          >
            Next
            <img src={images.smallarrowright} className="sm:h-4 h-3" />
          </div>
        </div>
      </div>
    </div>
  );
}
