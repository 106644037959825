import React, { useEffect, useRef, useState } from "react";
import images from "../../services/images";
import { useNavigate } from "react-router-dom";

export default function Modelcards({ models, setModels }) {
  const navigate = useNavigate();
  const ImageModels = useRef();

  return (
    <div className="bg-purplegray flex flex-row pl-8 pr-8 sm:pl-6 md:pl-8 lg:pl-8 gap-2 overflow-hidden pb-40">
      <div
        ref={ImageModels}
        className="flex flex-wrap sm:justify-start justify-center w-full"
      >
        {models?.map((item, index) => (
          <div
            key={index}
            className="pt-2 w-[90%] sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pr-4 pl-4 pb-4 relative"
          >
            <div className="relative">
              <img
                src={item?.profilePhoto}
                alt={`Image ${index + 1}`}
                className="w-full sm:h-[300px] h-[400px]  object-content  rounded cursor-pointer sm:h-64 md:h-72 lg:h-80 xl:h-96"
                onClick={() => {
                  const _id = item._id;
                  navigate(`/userlivestream/${_id}`, {
                    state: { escort: item },
                  });
                }}
              />
              <div className="absolute font-semibold top-0  ml-4 mt-4 sm:ml-5 md:mt-8 lg:px-7 md:px-5 sm:px-6  px-4 py-1 text-center bg-purple text-white lg:text-base md:text-base sm:text-sm text-sm ">
                Live
              </div>
              <div className="absolute w-full text-white bottom-[2%]  ">
                <div className="flex  w-full  justify-between pl-4 pr-2 sm:pl-5 sm:pr-5 items-center">
                  <div className="bg-green-500 round rounded-full w-[14px] h-[14px]  sm:w-[17px] sm:h-[17px] items-center "></div>
                  <img
                    src={item.image3}
                    alt=""
                    className=" w-2 sm:w-4 sm:h-4 mr-5 cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
