import logo from "../assets/images/logo.png";
import headerBG from "../assets/images/headerBG.png";
import arrowleft from "../assets/images/arrowleft.png";
import arrowright from "../assets/images/arrowright.png";
import silder1 from "../assets/images/silder1.png";
import silder2 from "../assets/images/silder2.png";
import silder3 from "../assets/images/silder3.png";
import silder4 from "../assets/images/silder4.png";
import check1 from "../assets/images/check1.png";
import check2 from "../assets/images/check2.png";
import check3 from "../assets/images/check3.png";
import check4 from "../assets/images/check4.png";
import center1 from "../assets/images/center1.png";
import card1 from "../assets/images/card1.png";
import card2 from "../assets/images/card2.png";
import card3 from "../assets/images/card3.png";
import card4 from "../assets/images/card4.png";
import card5 from "../assets/images/card 5.png";
import aboutimg from "../assets/images/Aboutimg.png";
import streamimg from "../assets/images/imgstream.png";
import addbutton from "../assets/images/addbutton.png";
import profpic from "../assets/images/profpic.png";
import views from "../assets/images/views.png";
import arrowup from "../assets/images/arrowup.png";
import bells from "../assets/images/bells.png";
import profpic2 from "../assets/images/profpic2.png";
import search from "../assets/images/Search.png";
import filter from "../assets/images/Filter.png";
import modalbg from "../assets/images/modalbg.png";
import Bluredimg from "../assets/images/Bluredimg.png";
import escortdetailimg from "../assets/images/escortdetail.png";
import escortimg1 from "../assets/images/escortimg1.png";
import escortimg2 from "../assets/images/escortimg2.png";
import escortimg3 from "../assets/images/escortimg3.png";
import Heart from "../assets/images/Heart.png";
import escortimg10 from "../assets/images/escortimg10.png";
import blackwhitegirl from "../assets/images/Blackwhitegirl.png";
import sliderimg22 from "../assets/images/sliderimg22.png";
import asiangir from "../assets/images/asiangir.png";
import Liveimgmodel1 from "../assets/images/Liveimgmodel1.png";
import Liveimgmodel2 from "../assets/images/Liveimgmodel2.png";
import Liveimgmodel3 from "../assets/images/Liveimgmodel3.png";
import Liveimgmodel4 from "../assets/images/Liveimgmodel4.png";
import Liveimgmodel5 from "../assets/images/Liveimgmodel5.png";
import Liveimgmodel6 from "../assets/images/Liveimgmodel6.png";
import Heart2 from "../assets/images/Heart2.png";
import dropdown from "../assets/images/dropdown.png";
import arrowbot from "../assets/images/arrowbot.png";
import arrowdown from "../assets/images/arrowdown.png";
import diamon1 from "../assets/images/diamon1.png";
import dropdownarrow from "../assets/images/dropdownarrow.png";
import profpic3 from "../assets/images/profpic3.png";
import Setting from "../assets/images/Setting.png";
import Send from "../assets/images/send.png";
import dropdownbox from "../assets/images/dropdownbox.png";
import settingbackground from "../assets/images/settingbackground.png";
import Edit from "../assets/images/Edit.png";
import profpic4 from "../assets/images/profpic4.png";
import Vector4 from "../assets/images/Vector4.png";
import gottobutton from "../assets/images/gottobutton.png";
import hide from "../assets/images/hide.png";
import show from "../assets/images/Show.png";
import camera from "../assets/images/Camera.png";
import escortmodal from "../assets/images/escortmodal.png";
import bluredimg2 from "../assets/images/bluredimg2.png";
import X from "../assets/images/X.png";
import search2 from "../assets/images/Search2.png";
import bitcoin1 from "../assets/images/bitcoin1.png";
import bitcoin2 from "../assets/images/bitcoin2.png";
import bitcoin3 from "../assets/images/bitcoin3.png";
import copy from "../assets/images/copy.png";
import arrowwhiteright from "../assets/images/arrowwhiteright.png";
import arrowwhiteleft from "../assets/images/arrowwhiteleft.png";
import purplex from "../assets/images/purplex.png";
import pinkheart from "../assets/images/pinkheart.png";
import profpic6 from "../assets/images/profpic6.png";
import profpic37 from "../assets/images/profpic37.png";
import profpic23 from "../assets/images/profpic23.png";
import profpic5 from "../assets/images/profpic23.png";
import dots from "../assets/images/dots.png";
import livemodalbg from "../assets/images/livemodalbg.png";
import picture from "../assets/images/picture.png";
import uploadedimg from "../assets/images/uploadedimg.png";
import whitedit from "../assets/images/whitedit.png";
import uploadimg2 from "../assets/images/uploadimg2.png";
import discount from "../assets/images/Discount.png";
import neaHeart from "../assets/images/neaHeart.png";
import Lock from "../assets/images/Lock.png";
import purpleadd from "../assets/images/purpleadd.png";
import Delete from "../assets/images/Delete.png";
import Document from "../assets/images/Document.png";
import Wallet from "../assets/images/Wallet.png";
import Category from "../assets/images/Category.png";
import purplearrowright from "../assets/images/purplearrowright.png";
import Visa from "../assets/images/Visa.png";
import Visa2 from "../assets/images/Visa2.png";
import Visa3 from "../assets/images/visa3.png";
import amex from "../assets/images/amex.png";
import ad1 from "../assets/images/ad1.png";
import ad2 from "../assets/images/ad2.png";
import ad3 from "../assets/images/ad3.png";
import cross from "../assets/images/cross.png";
import bluredimg4 from "../assets/images/bluredimg4.png";
import whiteadd from "../assets/images/whiteadd.png";
import whitelock from "../assets/images/whitelock.png";
import tick from "../assets/images/tick.png";
import AMEX20 from "../assets/images/AMEX20.png";
import AMEX21 from "../assets/images/AMEX21.png";
import VISA21 from "../assets/images/VISA21.png";
import videocamera from "../assets/images/videocamera.png";
import microphone from "../assets/images/microphone.png";
import soundlisten from "../assets/images/soundlisten.png";
import purplewallet from "../assets/images/purplewallet.png";
import purpledelete from "../assets/images/purpledelete.png";
import purpledocument from "../assets/images/purpledocument.png";
import camera1 from "../assets/images/camera1.png";
import phonecall from "../assets/images/phonecall.png";
import whiteblank from "../assets/images/whiteblank.png";
import longarrowpurple from "../assets/images/longarrowpurple.png";
import uploadedpic from "../assets/images/uploadedpic.png";
import uplaodedimg22 from "../assets/images/uplaodedimg22.png";
import purplearrowleft from "../assets/images/purplearrowleft.png";
import smallarrowright from "../assets/images/smallarrowright.png";
import yellowbandi from "../assets/images/yellowbandi.png";
import purplecircle from "../assets/images/purplecircle.png";
import verified1 from "../assets/images/verified1.png";
import dropdownarrowhite from "../assets/images/dropdownarrowhite.png";
import smallarrowwhiteleft from "../assets/images/smallarrowwhiteleft.png";
import tickmarkdone from "../assets/images/tickmarkdone.png";
import whiteCross from "../assets/images/whiteCross.png";
import whiteSettings from "../assets/images/whiteSettings.png";
import premiumStar from "../assets/images/premiumStar.png";
import dropDownClosed from "../assets/images/dropDownClosed.png";
import dropDownOpen from "../assets/images/dropDownOpen.png";
import checked from "../assets/images/checked.png";
import unchecked from "../assets/images/unchecked.png";
import menu from "../assets/images/menu.png";
import standardProfile from "../assets/images/standardProfile.png";
import pic from "../assets/images/pic.png";
import ThumbsCircle from "../assets/images/ThumbsCircle.png";
import stylishCircles from "../assets/images/stylishCircles.png";
import transparentWallet from "../assets/images/transparentWallet.png";
import woman from "../assets/images/woman.png";
import thousandAds from "../assets/images/thousandAds.png";
import trust from "../assets/images/trust.png";
import piggybank from "../assets/images/piggybank.png";
import free from "../assets/images/free.png";
import deselectBtn from "../assets/images/deselectBtn.png";
import adCenter from "../assets/images/adCenter.png";
import bumps from "../assets/images/bumps.png";
import callentime from "../assets/images/callentime.png";
import bumpDocu from "../assets/images/bumpDocu.png";
import paint from "../assets/images/paint.png";
import multiImg from "../assets/images/multiImg.png";
import ladki from "../assets/images/ladki.png";
import smiling from "../assets/images/smiling.png";
import callender from "../assets/images/callender.png";
import adImages from "../assets/images/adImages.png";
import adVideos from "../assets/images/adVideos.png";
import ALERT from "../assets/images/ALERT.png";
import mail from "../assets/images/mail.png";
import postAd from "../assets/images/postAd.png";
import support from "../assets/images/support.png";
import accept from "../assets/images/accept.png";
import decline from "../assets/images/decline.png";
import time from "../assets/images/time.png";
import crossBumps from "../assets/images/crossBumps.png";
import unchecked2 from "../assets/images/unchecked2.png";
import filterunchecked from "../assets/images/filterunchecked.png";
import filterChecked from "../assets/images/filterChecked.png";
import heartOutline from "../assets/images/heartOutline.png";
import heartFilled from "../assets/images/heartFilled.png";
import blacheart from "../assets/images/blacheart.png";
import adsCenter from "../assets/images/adsCenter.png";
import giftCardIcon from "../assets/images/giftCard.png";
import purpleGiftCard from "../assets/images/purpleGiftCard.png";
import purpleCopy from "../assets/images/purpleCopy.png";
import qrCode from "../assets/images/qrCode.PNG";
import paybis from "../assets/images/paybis.png";
import paybisLogo from "../assets/images/paybisLogo.png";
import paybisTutorial from "../assets/images/paybisTutorial.png";
import tutorial2 from "../assets/images/tutorial2.png";
import paymentTutorial3 from "../assets/images/paymentTutorial3.png";
import paymentLogin from "../assets/images/paymentLogin.png";
// qrCode

const images = {
  paymentLogin,
  paymentTutorial3,
  tutorial2,
  paybisTutorial,
  paybisLogo,
  paybis,
  purpleCopy,
  qrCode,
  purpleGiftCard,
  blacheart,
  filterChecked,
  filterunchecked,
  time,
  crossBumps,
  accept,
  decline,
  unchecked2,
  support,
  postAd,
  mail,
  ALERT,
  callender,
  smiling,
  ladki,
  multiImg,
  paint,
  bumpDocu,
  callentime,
  bumps,
  adImages,
  adVideos,
  menu,
  unchecked,
  checked,
  deselectBtn,
  free,
  piggybank,
  trust,
  thousandAds,
  woman,
  transparentWallet,
  pic,
  stylishCircles,
  ThumbsCircle,
  standardProfile,
  unchecked,
  checked,
  dropDownOpen,
  dropDownClosed,
  premiumStar,
  whiteSettings,
  whiteCross,
  tickmarkdone,
  smallarrowwhiteleft,
  dropdownarrowhite,
  verified1,
  yellowbandi,
  purplecircle,
  smallarrowright,
  purplearrowleft,
  uplaodedimg22,
  uploadedpic,
  longarrowpurple,
  whiteblank,
  phonecall,
  ad1,
  camera1,
  cross,
  ad2,
  ad3,
  purpledocument,
  purpledelete,
  purplewallet,
  videocamera,
  soundlisten,
  microphone,
  AMEX21,
  VISA21,
  AMEX20,
  tick,
  Visa3,
  whitelock,
  whiteadd,
  bluredimg4,
  Visa2,
  amex,
  Visa,
  purplearrowright,
  Category,
  Wallet,
  Document,
  Delete,
  purpleadd,
  Lock,
  uploadimg2,
  discount,
  whitedit,
  neaHeart,
  uploadedimg,
  livemodalbg,
  picture,
  dots,
  profpic6,
  profpic37,
  profpic23,
  profpic5,
  pinkheart,
  purplex,
  arrowwhiteleft,
  arrowwhiteright,
  copy,
  bitcoin1,
  bitcoin2,
  bitcoin3,
  search2,
  X,
  escortmodal,
  bluredimg2,
  camera,
  show,
  hide,
  gottobutton,
  Vector4,
  profpic4,
  Edit,
  dropdownbox,
  Setting,
  Send,
  settingbackground,
  profpic3,
  dropdownarrow,
  diamon1,
  arrowdown,
  arrowbot,
  dropdown,
  Heart2,
  Liveimgmodel6,
  escortdetailimg,
  sliderimg22,
  asiangir,
  Liveimgmodel1,
  Liveimgmodel2,
  Liveimgmodel3,
  Liveimgmodel4,
  Liveimgmodel5,
  blackwhitegirl,
  escortimg10,
  escortimg1,
  escortimg2,
  escortimg3,
  Bluredimg,
  search,
  modalbg,
  filter,
  profpic2,
  bells,
  arrowup,
  Heart,
  views,
  profpic,
  addbutton,
  aboutimg,
  streamimg,
  card1,
  card2,
  card3,
  card4,
  card5,
  center1,
  logo,
  headerBG,
  arrowleft,
  arrowright,
  silder1,
  silder2,
  silder3,
  silder4,
  check1,
  check2,
  check3,
  check4,
  adCenter,
  heartOutline,
  heartFilled,
  adsCenter,
  giftCardIcon,
};

export default images;
