import React, { useState } from "react";
import images from "../../services/images";
import { Link, useNavigate } from "react-router-dom";

export default function Footer({ setIsModalOpen }) {
  const navigate = useNavigate();
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-footercolor">
      <div className="border-t border-purple border-t-4">
        <div className="flex flex-col sm:flex-row  lg:justify-evenly   ml-10 mt-10">
          <div className="flex flex-col  lg:w-82p lg:pl-20  sm:justify-evenly lg:block  ">
            <img
              src={images.logo}
              alt=""
              className="lg:w-40 cursor-pointer lg:max-w-40 w-30p md:max-w-40 sm:max-w-40 max-w-40   "
            />
            <div className="pr-2 text-white w-80p lg:w-40p md:w-60p lg:text-xl md:text-xl   text-base lg:text-base lg:pt-2 text-left mt-5 ">
            Embrace the future of pleasure with our cutting-edge platform services
            </div>
          </div>
          <div className="flex md:flex-row lg:w-full w-full justify-between sm:flex-row flex-col  mt-5 sm:mt-0 ">
            <div className="flex  lg:w-18p w-full  sm:justify-center  ">
              <div className="lg:w-86p text-nowrap w-60p flex flex-col text-start ">
                <div className="text-purple font-bold lg:text-2xl md:text-xl sm:text-base text-base  text-left">
                  Quick Links
                </div>
                <div className="flex flex-col pt-2 lg:text-left w-90p  ">
                  <Link
                    to={"/"}
                    onClick={handleScrollToTop}
                    className="text-white lg:text-xl md:text-lg sm:text-sm text-sm py-1 text-left   cursor-pointer hover:text-purple  active:opacity-50"
                  >
                    Home
                  </Link>
                  <Link
                    to={"/LiveModels/Newest"}
                    onClick={handleScrollToTop}
                    className="text-white  lg:text-xl md:text-lg sm:text-sm text-sm text-left py-1 cursor-pointer hover:text-purple active:opacity-50"
                  >
                    Live Models
                  </Link>
                  <Link
                    to={"/Escorts/All"}
                    onClick={handleScrollToTop}
                    className="text-white  lg:text-xl md:text-lg sm:text-sm text-sm  text-left py-1 cursor-pointer   hover:text-purple active:opacity-50"
                  >
                    Escorts
                  </Link>
                  <Link
                    to={"/About"}
                    onClick={handleScrollToTop}
                    className="text-white  lg:text-xl md:text-lg sm:text-sm text-sm  py-1 text-left  cursor-pointer  hover:text-purple active:opacity-50"
                  >
                    About
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex-col flex w-full  lg:items-center lg:w-50p ">
              <div className=" w-55p flex items-center hidden  lg:block ">
                {/* <div
                  className="flex items-center  lg:text-sm md:text-sm sm:text-xs text-xs  text-nowrap justify-center   lg:w-32 w-40 h-12  font-semibold  active:opacity-50"
                  onClick={() => {
              
                    // navigate("/Escorts/All");
                  }}
                >
               
                </div> */}
                <div
                  className="bg-white cursor-pointer justify-center ml-4 lg:w-12 lg:max-w-12 md:max-w-12 sm:max-w-12  items-center md:w-12 sm:w-full w-16  flex  h-12 active:opacity-50"
                  onClick={handleScrollToTop}
                >
                  <img
                    src={images.arrowup}
                    alt=""
                    className="h-5 w-3 max-w-3 max-h-5 active:opacity-50  "
                  />
                </div>
              </div>
              <div className="flex flex-col w-55p pt-10 ">
                <div className="text-white cursor-pointer lg:text-lg md:text-lg sm:text-base text-sm text-nowrap ">
                  <div
                    className="hover:text-purple active:opacity-50"
                    onClick={() => {
                      navigate("/policy");
                    }}
                  >
                    Privacy Policy
                  </div>
                  <div className="text-white cursor-pointer lg:text-lg md:text-lg sm:text-base text-sm pt-3  ">
                    <div
                      className="hover:text-purple active:opacity-50"
                      onClick={() => {
                        navigate("/terms");
                      }}
                    >
                      Terms & Conditions
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex border-t border-whitepink-500 b-14  w-90p opacity-20 mx-auto mt-10  ">
          <div className="h-20"></div>
        </div>
      </div>
    </div>
  );
}
