import React, { useEffect, useRef, useState } from "react";
import images from "../../services/images";
import ResponsiveImageGrid from "../cards/ResponsiveImageGrid";
import { useNavigate } from "react-router-dom";
import { getEscorts } from "../../services/API/index.js";

export default function HomeCards() {
  const [imageUrl, setImageurl] = useState([
    // {
    //   images: images.card1,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card2,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card3,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card2,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card4,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card1,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card2,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card3,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card1,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card2,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card3,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    // },
  ]);

  const getEscortsData = async () => {
    const data = await getEscorts();
    // console.log(data);
    setImageurl(data.escorts);
  };

  useEffect(() => {
    getEscortsData();
  }, []);

  const navigate = useNavigate();
  const imageGridRef = useRef();

  const handlePrevious = () => {
    imageGridRef.current.handlePrevious();
  };

  const handleNext = () => {
    imageGridRef.current.handleNext();
  };

  return (
    imageUrl &&
    <div className="px-7 flex flex-col items-start  pt-28 bg-purplegray">
      <div className="text-white  lg:text-5xl font-bold md:text-4xl sm:text-3xl text-3xl ">
        WE'VE GOT THE GIRLS!
      </div>
      <div className="flex items-center text-white lg:pb-20 md:pb-20 sm:pb-0 pb-0 w-full justify-between">
        <div className="lg:w-75p md:w-75p sm:w-75p w-full mt-5 lg:text-xl md:text-lg sm:text-sm text-sm">
          Our diverse selection ensures that you'll find the perfect companion
          for any occasion. From charming conversation to unforgettable moments,
          we have the girls who embody sophistication, warmth, and a genuine
          passion for creating lasting memories
        </div>
        <div className="lg:flex lg:flex md:flex sm:hidden hidden flex-row mt-10 justify-end pr-2  ">
          <div
            className="ml-2 text-sm bg-purple text-black rounded-sm px-4 py-5 font-semibold flex items-center justify-center cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
            onClick={handlePrevious}
          >
            <img src={images.arrowleft} className="w-4 max-w-4" />
          </div>
          <div
            className="ml-2 text-sm bg-whitepink text-black rounded-sm px-4 py-4  font-semibold flex items-center justify-center cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
            onClick={handleNext}
          >
            <img src={images.arrowright} className="w-4 max-w-4" />
          </div>
        </div>
      </div>
      <div className="pb-40">
        <div>
          <div className="lg:hidden lg:hidden md:hidden sm:mb-5 sm:flex flex  flex-row mt-10 justify-end pr-2  ">
            <div
              className="ml-2 text-sm bg-purple text-black rounded-sm h-10 w-10 font-semibold flex items-center justify-center cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
              onClick={handlePrevious}
            >
              <img src={images.arrowleft} className="w-4 max-w-4" />
            </div>
            <div
              className="ml-2 text-sm bg-whitepink text-black rounded-sm h-10 w-10  font-semibold flex items-center justify-center cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
              onClick={handleNext}
            >
              <img src={images.arrowright} className="w-4 max-w-4" />
            </div>
          </div>
        </div>
        <div className="lg:pt-0 md:pt-0 sm:pt-0 pt-10 w-full ">
          {imageUrl && (
            <ResponsiveImageGrid images={imageUrl} ref={imageGridRef} />
          )}
        </div>
      </div>
    </div>
  );
}
