import React, { useEffect, useState } from "react";
import images from "../../services/images";
import {
  getSmallUserDetails,
  getUserDetails,
  handlePaymentVerification,
} from "../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, setUsereData } from "../../store/userData";
import { useNavigate } from "react-router-dom";
import token, { selectToken } from "../../store/token";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { selectLogedIn } from "../../store/logedIn";

export default function GiftCard() {
  const userDataa = useSelector(selectUserData);
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState(false);
  const [enterAmount, setEnterAmount] = useState("");
  const [purchaseModal, setPurchangeModal] = useState(false);
  const [doneModal, setDoneModal] = useState(false);
  const [error, setError] = useState(false);
  const [textToCopy] = useState("LR2sPBc2UbukGwTK5ARfc9wZj3zt8U5KEe");
  const [alertText, setAlertText] = useState("");
  const authToken = useSelector(selectToken);
  const [price, setPrice] = useState(null);
  // const token = useSelector(selectToken);
  const [wallet, setWallet] = useState();
  const [userData, setUserData] = useState();
  const [loggedInEscortData, setLoggedInEscortData] = useState();

  const userlogin = useSelector(selectLogedIn);
  // console.log(placeholderData);

  // const handleGetUserDetails = async() =>{
  // }

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  // console.log("ajksdbhjqwhebqwjhedbahbdqwejh",userDataa)

  // console.log("adasd", userData);

  const firstName = userData?.firstname;
  const lastName = userData?.lastname;
  const escortEmail = userData?.email;

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setAlertText("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    const fetchEscortData = async () => {
      try {
        const token = authToken;
        let data;
        if (userlogin) {
          data = await getSmallUserDetails(token);
          setWallet(data?.userData);
          console.log(wallet);
        } else {
          data = await getUserDetails(token);
          setWallet(data);
        }
        if (data) {
          dispatch(setUsereData(data));
        } else {
          setError("Failed to fetch details.");
        }
      } catch (error) {
        setError("Failed to fetch details.");
      }
    };

    fetchEscortData();
  }, []);

  // console.log("FROM THE API",wallet?.wallet)

  const handleDoneModal = () => {
    if (purchaseModal) {
      setPurchangeModal(false);
      setDoneModal(true);
    }
  };

  useEffect(() => {
    setUserData(userDataa);
  }, [userDataa]);

  const openPurchaseModal = async () => {
    if (loader) {
      return; // Prevent multiple submissions
    }

    setLoader(true); // Show loader
    try {
      let body;

      if (userlogin) {
        body = {
          firstname: userDataa?.userData?.firstname,
          lastname: userDataa?.userData?.lastname,
          escortEmail: userDataa?.userData?.email,
          amount: enterAmount,
        };
      } else {
        body = {
          firstname: firstName,
          lastname: lastName,
          escortEmail,
          amount: enterAmount,
        };
      }

      if (enterAmount?.length > 0) {
        const response = await handlePaymentVerification(authToken, body);

        if (response.message === "Apply for payment verification") {
          console.log("Success");
          setError(false);
          setPurchangeModal(true);
          console.log("OpenTrue");
        } else {
          console.error("Payment verification failed:", response.error);
          setError(true);
        }
      } else {
        console.error("Amount is required");
        setError(true);
        setPurchangeModal(false);
      }
    } catch (error) {
      console.error("Failed to verify payment:", error);
    } finally {
      setLoader(false); // Hide loader
    }
  };

  useEffect(() => {
    const fetchLTCPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=litecoin&vs_currencies=usd"
        );
        setPrice(response.data.litecoin.usd);
      } catch (error) {
        // console.error("Error fetching the LTC price", error);
      }
    };

    fetchLTCPrice();
    const interval = setInterval(fetchLTCPrice, 60000); // Refresh price every minute

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-background") {
      setPurchangeModal(false);
    }
  };

  useEffect(() => {
    if (purchaseModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [purchaseModal]);

  return (
    <div className="w-full flex flex-col items-center justify-center text-whitepink">
      <div
        className="w-[70%] sm:w-[85%]
      bg-footercolor lg:w-[65%] xl:w-[55%] mt-20 w-full justify-start flex"
      >
        <div className="bg-footercolor flex gap-5 justify-center  sm:text-3xl p-3">
          <div className="flex flex-col gap-3  ">
            <div className="flex gap-3  ">
              Price of <span className="font-bold  text-purple">1 LTC </span> is
              = ( <span className="text-red-500">LIVE RATE </span>{" "}
              {price ? <p>${price}</p> : <p>Loading...</p>})
            </div>
            <div>
              Please make sure to enter the{" "}
              <span className="font-bold text-purple">calculated amount </span>{" "}
              accordingly{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[70%] sm:w-[85%] lg:w-[65%] xl:w-[55%] pt-8 sm:pt-10">
        <div className="flex w-full flex-col sm:flex-row justify-between gap-4 pb-40">
          <div className="flex flex-col w-full h-auto sm:w-[60%] md:w-[50%] lg:w-[44%]">
            <div className="bg-giftCardGray w-full p-4 text-xl">
              Your balance
            </div>
            <div className="bg-footercolor w-full p-4 text-xl">
              <div className="relative">
                <img src={images.purpleGiftCard} className="w-full h-full" />
              </div>
              <div className="text-footercolor w-full truncate  font-bold text-xl lg:text-2xl relative bottom-10 lg:bottom-10 pl-4 text-nowrap">
                CAD ${wallet?.wallet?.toFixed()}
              </div>
              <div className="w-[90%]">Fastest Way to Pay on Pleasury</div>
              <div className="text-sm pt-5 text-textopacity">
                Tired of entering your payment details for every transaction?
                Try our Gift Card! You can load it up with as much as you want
                and use it to quickly and easily pay for your Ads.
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="w-full p-4 text-xl bg-giftCardGray">
              Top Up your Gift Card
            </div>
            <div className="bg-footercolor text-xl h-full p-6">
              <div className="h-full w-90p flex flex-col">
                Enter The Amount of Pleasury Credits (1 pleasury credit = 1$)
                <div className="pt-4">
                  <input
                    className="w-full bg-transparent border pl-2 sm:pl-4 md:pl-6 border-whitepink border-opacity-20 focus:outline-none rounded-md sm:rounded-lg md:rounded-xl h-10 sm:h-12 md:h-14 lg:h-16 text-sm sm:text-base md:text-lg"
                    value={enterAmount}
                    onChange={(e) => setEnterAmount(e.target.value)}
                  />
                </div>
                <div className="h-full items-end mb-10 justify-center sm:justify-end flex mt-16">
                  <div
                    className="bg-purple text-base cursor-pointer active:opacity-50 py-2 px-4 sm:py-3 sm:px-6 md:py-4 md:px-10 lg:py-3 lg:px-8"
                    onClick={openPurchaseModal}
                  >
                    {loader ? (
                      <ClipLoader
                        color="#FFFF"
                        cssOverride={{
                          height: "22px",
                          width: "22px",
                        }}
                      />
                    ) : (
                      <>Purchase</>
                    )}
                  </div>

                  {purchaseModal && (
                    <div
                      className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 text-whitepink"
                      onClick={handleOutsideClick}
                    >
                      <div className="w-full w-[90%] sm:w-[80%] md:w-[60%] lg:w-[40%] xl:w-[30%] flex items-center justify-center bg-purplegray ">
                        <div
                          className=" h-auto  flex items-center justify-center p-4 sm:p-6 md:p-8 rounded-lg"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="flex flex-col items-center text-sm gap-4">
                            <div className="text-center text-xl sm:text-2xl">
                              Send the amount of LTC equivalent to the required
                              amount of pleasury credits for example ( if you
                              want 200 credits send 200$ worth of LTC to the
                              given wallet address to proceed )
                            </div>
                            <img src={images.qrCode} className="h-24 sm:h-32" />
                            <div className="text-base sm:text-lg md:text-xl">
                              Your LTC Address
                            </div>
                            <div className="flex items-center gap-4">
                              <div className="bg-footercolor text-textopacity px-4 sm:px-6 py-2 rounded-md">
                                {textToCopy}
                              </div>
                              <img
                                src={images.purpleCopy}
                                className="h-4 cursor-pointer active:opacity-40"
                                onClick={handleCopyClick}
                              />
                            </div>
                            <div className="text-textopacity text-sm sm:text-base">
                              {alertText}
                            </div>
                            <div
                              className="bg-purple px-6 py-2 sm:px-8 sm:py-3 text-md sm:text-lg cursor-pointer active:opacity-40 rounded-md"
                              onClick={handleDoneModal}
                            >
                              Done
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {doneModal && (
                    <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 text-whitepink">
                      <div className="bg-purplegray h-[40%] w-[90%] md:w-[40%] lg:w-[30%] flex items-center justify-center">
                        <div className="flex flex-col items-center text-sm gap-4">
                          <img src={images.trust} className="h-20" />
                          <div className="w-[60%] text-center">
                            Thank you for your payment. Your account will be
                            topped up after verification. We appreciate your
                            patience and continued support.
                          </div>
                          <div className="text-textopacity"></div>
                          <div
                            className="bg-purple px-10 py-2 text-md cursor-pointer active:opacity-40"
                            onClick={() => setDoneModal(false)}
                          >
                            Done
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="text-sm bg-graymore pl-3 pt-3 pb-3 flex flex-col gap-2">
                  Payment Tutorials
                  <div className="text-xs">
                    Find out how to top up your LeoList Giftcard with Credit
                    Card, Cash ATM and Interac Email.
                  </div>
                  <div
                    className="text-xs bg-purple w-16 py-1 cursor-pointer active:opacity-50 rounded-3xl text-center"
                    onClick={() => navigate("/paymenttutorial")}
                  >
                    More Info
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
