import React from "react";
import images from "../../services/images";
import { Link, useNavigate } from "react-router-dom";

export default function Joinstream() {
  const navigate = useNavigate()
  return (
    <div className="bg-purplegray">
      <div className=" p-10 w-full flex justify-between">
        <div className="bg-white sm:p-4 p-3 flex items-center justify-center cursor-pointer active:opacity-50"
          onClick={() => {
            navigate(-1);
          }}>
          <img src={images.arrowleft} alt="" className="w-4 h-3" />
        </div>
        <div className="w-full pr-10 flex justify-center items-center text-white font-semibold lg:text-4xl md:text-2xl sm:text-xl text-xl ">
          Join Stream
        </div>
      </div>
      <div className="flex w-full justify-center text-white lg:text-xl md:text-md sm:text-sm text-sm font-semibold">
        SETTINGS
      </div>

      <div className="flex w-full justify-center pt-5">
        <div className="bg-newcolor lg:h-24 lg:w-24 md:h-20 md:w-20 sm:w-16 sm:h-16 w-12 h-12 rounded-full flex items-center justify-center mr-5 sm:mr-10 cursor-pointer active:opacity-50">
          <img src={images.microphone} alt="" className="w-5" />
        </div>
        <div className="bg-newcolor lg:h-24 lg:w-24 md:h-20 md:w-20 sm:w-16 sm:h-16 w-12 h-12 rounded-full flex items-center justify-center mr-5 sm:mr-10 cursor-pointer active:opacity-50">
          <img src={images.soundlisten} alt="" className="w-5" />
        </div>
        <div className="bg-newcolor lg:h-24 lg:w-24 md:h-20 md:w-20 sm:w-16 sm:h-16 w-12 h-12 rounded-full flex items-center justify-center cursor-pointer active:opacity-50">
          <img src={images.videocamera} alt="" className="w-5" />
        </div>
      </div>

      <div className="w-full pb-10">
        <div className="pt-10 justify-center flex items-center">
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            className="resize-none lg:w-30p md:w-40p sm:w-40p w-60p pt-2 rounded-xl border border-whitepink  block bg-transparent text-white opacity-60 h-24 text-sm focus:outline-none pl-4"
            placeholder="Add Note..."
          ></textarea>
        </div>
      </div>
      <div className="w-full flex  justify-center pb-40">
        <div className="bg-purple  md:6 sm:px-4  px-3  py-2 lg:text-base md:text-sm sm:text-sm text-xs flex justify-center items-center text-white h-10 cursor-pointer active:opacity-50">
          Send live Request
        </div>
      </div>
    </div>
  );
}
