import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
    name:'token',
    initialState:{
        token:null
    },
    reducers:{
        setAuthToken: (state,action)=>{
            state.token = action.payload;
        },
        removeAuthToken:(state)=>{
            state.token  = null;                    
        }
    }

})

export const { setAuthToken, removeAuthToken} = tokenSlice.actions;
export const selectToken = (state) => state.token.token;
export default tokenSlice.reducer;