import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MeetingProvider,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import {
  escortSubscribeToEscort,
  getSmallUserDetails,
  getUserDetails,
  setPaymentDetailsForVerifyDec,
  streamingToken,
  subscribeToEscort,
} from "../../services/API";
import images from "../../services/images";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, setUsereData } from "../../store/userData";
import { selectToken } from "../../store/token";
import { ClipLoader } from "react-spinners";
import { selectLogedIn } from "../../store/logedIn";

export default function UserLiveStream() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLoggedin = useSelector(selectLogedIn);
  const [joined, setJoined] = useState(false);
  const [userData, setUserData] = useState();
  // const userData = useSelector(selectUserData);
  const [subModal, setSubModal] = useState(false);
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [loader, setLoader] = useState(false);
  let amount = 20;

  const getUserData = async () => {
    if (userLoggedin) {
      const response = await getSmallUserDetails(authToken);
      setUserData(response.userData);
      console.log("this is response data", response);
    } else {
      const response = await getUserDetails(authToken);
      setUserData(response);
    }
  };

  useEffect(() => {
    getUserData();
  }, [subModal]);

  const { escort } = location.state || {};

  let escortId = escort?._id;

  const authToken = useSelector(selectToken);

  const meetingId = escort?.meetingId;
  const token = streamingToken;

  function ParticipantView({ participantId }) {
    const micRef = useRef(null);
    const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } =
      useParticipant(participantId);

    const videoStream = useMemo(() => {
      if (webcamOn && webcamStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(webcamStream.track);
        return mediaStream;
      }
    }, [webcamStream, webcamOn]);

    useEffect(() => {
      if (micRef.current) {
        if (micOn && micStream) {
          const mediaStream = new MediaStream();
          mediaStream.addTrack(micStream.track);

          micRef.current.srcObject = mediaStream;
          micRef.current
            .play()
            .catch((error) =>
              console.error("micRef.current.play() failed", error)
            );
        } else {
          micRef.current.srcObject = null;
        }
      }
    }, [micStream, micOn]);

    return (
      <div className="flex justify-center items-center w-full">
        {webcamOn && (
          <div className="relative w-full max-w-full sm:max-w-lg md:max-w-xl lg:max-w-3xl xl:max-w-5xl">
            <div className="aspect-w-16 aspect-h-9">
              <ReactPlayer
                playsinline
                pip={false}
                light={false}
                controls={false}
                muted={true}
                playing={true}
                url={videoStream}
                width="100%"
                height="100%"
                onError={(err) => console.log(err, "participant video error")}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  function MeetingView() {
    const { join, participants, localParticipant, leave } = useMeeting({
      onMeetingJoined: () => setJoined(true),
      onMeetingLeft: () => {
        setJoined(false);
        navigate(-1);
      },
    });

    const joinMeeting = () => join();

    const [subModal, setSubModal] = useState(false);

    console.log(userData?.wallet);

    const isSubscribed = userData?.subscriptions?.includes(escortId);

    const handleSubscribeAndPayment = async () => {
      if (userData?.wallet < amount) {
        setSubModal(false);
        setInsufficientBalance(true);
        return;
      }

      setLoader(true); // Start loader

      try {
        let subscribe;
        if (userLoggedin) {
          subscribe = await subscribeToEscort(escortId, authToken);
        } else {
          subscribe = await escortSubscribeToEscort(escortId, authToken);
        }

        if (subscribe.success) {
          const walletUpdateResponse = await setPaymentDetailsForVerifyDec(
            { wallet: amount },
            authToken
          );

          if (walletUpdateResponse.success) {
            dispatch(setUsereData(walletUpdateResponse?.updatedDocument));

            // Update userData with the new subscription
            setUserData((prevData) => ({
              ...prevData,
              subscriptions: [...prevData.subscriptions, escortId],
              wallet: prevData.wallet - amount,
            }));

            setSubModal(false); // Close subscription modal
          } else {
            console.error(
              "Error updating wallet:",
              walletUpdateResponse.message
            );
            setSubModal(false); // Close subscription modal on error
          }
        } else {
          console.error("Failed to subscribe to escort:", subscribe.message);
          setSubModal(false); // Close subscription modal on error
        }
      } catch (error) {
        console.error(
          "Error processing request:",
          error.response ? error.response.data : error.message
        );
        setSubModal(false); // Close subscription modal on error
      } finally {
        setLoader(false); // Stop loader
      }
    };

    return (
      <div className="flex justify-center items-center w-full ">
        {subModal && (
          <>
            <div className="fixed bottom-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div className="bg-whitepink rounded-2xl flex flex-col md:gap-5 h-[30%] w-[90%] sm:w-[70%] md:w-[50%] lg:w-[40%] xl:w-[30%] items-center justify-center p-5">
                <div className="text-purple font-bold text-2xl sm:text-3xl md:text-4xl">
                  SUBSCRIPTION
                </div>
                <div className="text-black text-xl sm:text-2xl md:text-3xl font-bold">
                  20$
                </div>
                <div className="text-purple font-bold text-sm sm:text-lg md:text-xl">
                  No Hidden Charges
                </div>
                <div
                  className="text-white bg-purple px-4 py-2 sm:px-6 sm:py-3 font-bold rounded-lg cursor-pointer active:opacity-50"
                  onClick={handleSubscribeAndPayment}
                >
                  Pay
                </div>
              </div>
            </div>
          </>
        )}
        {insufficientBalance && (
          <>
            <div className="fixed bottom-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div className="bg-whitepink rounded-2xl flex flex-col md:gap-5 h-[30%] w-[90%] sm:w-[70%] md:w-[50%] lg:w-[40%] xl:w-[30%] items-center justify-center p-5">
                <div className="text-purple font-bold text-2xl sm:text-3xl md:text-4xl">
                  Insufficient Credits
                </div>
                <div className="text-purple font-bold text-sm sm:text-lg md:text-xl">
                  Go To GiftCard to recharge
                </div>
                <div
                  className="text-white bg-purple px-4 py-2 sm:px-6 sm:py-3 font-bold rounded-lg cursor-pointer active:opacity-50"
                  onClick={() => navigate("/giftcard")}
                >
                  GET PL Credits
                </div>
              </div>
            </div>
          </>
        )}
        {joined ? (
          <div className="relative w-full max-w-full sm:max-w-lg md:max-w-xl lg:max-w-3xl xl:max-w-5xl">
            {[...participants.keys()]
              .filter((participantId) => participantId !== localParticipant.id)
              .map((participantId) => (
                <ParticipantView
                  participantId={participantId}
                  key={participantId}
                />
              ))}
          </div>
        ) : (
          <>
            {loader ? (
              <ClipLoader
                color="#BE42DE"
                cssOverride={{
                  height: "80px",
                  width: "80px",
                }}
              />
            ) : (
              <>
                {isSubscribed ? (
                  <div
                    onClick={joinMeeting}
                    className="bg-purple text-nowrap lg:w-44 sm:w-40  w-32 py-3  flex items-center lg:text-md md:text-sm  text-xs justify-center  text-whitepink font-semibold pl-4 pr-4 cursor-pointer active:opacity-50"
                  >
                    Join
                  </div>
                ) : (
                  <div
                    onClick={() => setSubModal(true)}
                    className="bg-purple text-nowrap lg:w-44 sm:w-40  w-32 py-3  flex items-center lg:text-md md:text-sm  text-xs justify-center  text-whitepink font-semibold pl-4 pr-4 cursor-pointer active:opacity-50"
                  >
                    subscribe
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
  return (
    <div className="pt-20 pb-10 ">
      <div className="flex w-full items-center justify-between mb-5">
        <div
          className="bg-white ml-10 flex justify-center items-center w-12 h-10 sm:w-14 sm:h-12 cursor-pointer active:opacity-50"
          onClick={() => navigate(-1)}
        >
          <img
            src={images.arrowleft}
            className="w-4 sm:w-6  "
            alt="Arrow Left"
          />
        </div>
        {!joined && (
          <>
            <div className="flex w-full items-center justify-center ">
              <div className=" text-white lg:text-4xl md:text-3xl sm:text-2xl text-xl font-bold text-nowrap mr-14 ">
                Live Stream
              </div>
            </div>
          </>
        )}
        {joined && (
          <div
            onClick={() => {
              navigate(-1);
            }}
            className=" bg-purple text-nowrap mr-10  sm:h-12 h-10 flex items-center lg:text-lg md:text-md sm:text-sm text-xs justify-center w-32 text-whitepink font-semibold pl-4 pr-4 cursor-pointer active:opacity-50"
          >
            Leave Stream
          </div>
        )}
      </div>
      {!joined && (
        <>
          <div className="flex justify-center  ">
            <img
              src={escort?.profilePhoto}
              alt=""
              className="  sm:h-28 md:h-32 lg:-36 sm:28 md:w-32 lg:w-36 w-24   h-24  w-24  rounded-full align-center"
            />
          </div>
          <div className="flex justify-center ">
            <div className="text-white pt-5 lg:text-2xl md:text-xl  text-lg font-semibold cursor-pointer whitespace-nowrap">
              {escort?.firstname} {escort?.lastname}
            </div>
          </div>
        </>
      )}

      <MeetingProvider
        config={{
          meetingId,
          micEnabled: false,
          webcamEnabled: false,
          name: "Viewer", // Viewer name
        }}
        token={token}
      >
        <MeetingView />
      </MeetingProvider>

      {joined && (
        <>
          <div className=" p-5 md:p-10 lg:flex  lg:flex-col lg:items-center  ">
            <div className="flex ml-5 lg:w-50p">
              <div
                className="flex items-center cursor-pointer text-white "
                style={{
                  width: "49px",
                }}
              >
                <div className="flex relative w-full mb-10  right-5 ">
                  <div className="absolute  w-16 h-16 rounded-full  border-2 border-purple ">
                    <div className="relative pl-1 pr-1 pt-1     ">
                      <img
                        src={escort?.profilePhoto}
                        alt=""
                        className="h-14 rounded-full w-14 "
                      />
                    </div>

                    <div
                      className="absolute top-12 w-10 left-2 text-sm text-center"
                      style={{
                        background: "rgb(190,66,222)",
                        backgroundImage:
                          "linear-gradient(90deg, rgba(190,66,222,1) 0%, rgba(74,3,141,1) 67%)",
                      }}
                    >
                      Live
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              <div className="flex flex-col ">
                <div className="text-white pt-5 lg:text-xl md:text-lg sm:text-md text-xs font-semibold cursor-pointer whitespace-nowrap ">
                  {escort?.firstname} {escort?.lastname}
                </div>
              </div>
            </div>
            <div className="flex  md:flex-row bg-aboutcolor w-full rounded-2xl mt-10 p-4 lg:w-50p ">
              <div className="text-white text-center lg:text-xl md:text-2xl sm:text-lg text-lg pl-2  ">
                <span className="font-bold p-1"> About</span> {escort?.about}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
