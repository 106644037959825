import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectToken } from "../../store/token";
import { useModal } from "../../screens/Layout";

function ProtectedRoute(props) {

    const { setIsModalOpen , setmodalcomponent } = useModal();

    const { Compunent } = props;
    const navigate = useNavigate();
    const authToken = useSelector(selectToken);
    const haveUser = async () => {
        if (!authToken) {
            navigate("/");
            setIsModalOpen(true)
            setmodalcomponent("signin")
        }
    };

    useEffect(() => {
        haveUser();
    }, [authToken]);

    return (
        <>
            <Compunent />
        </>
    );
}
export default ProtectedRoute;