import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import images from "../../services/images";
import { applyVerify, handleUploadPictures } from "../../services/API";
import { useSelector } from "react-redux";
import { selectToken } from "../../store/token";
import { ClipLoader } from "react-spinners";

export default function ApplyVerify() {

  const navigate = useNavigate()

  const token = useSelector(selectToken)

  const [changepanel, setchangepanel] = useState("benefits");
  const [showBenefits, setShowBenefits] = useState("benefitClient");
  const [showRules, setShowRules] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadImgLoader, setUploadImgLoader] = useState(false)
  const [applyLoader, setApplyLoader] = useState(false)
  const [successModal, setSuccessModal] = useState(false)

  useEffect(() => {
    if (successModal || applyLoader) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [successModal, applyLoader]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (event) => {
    // event.preventDefault();
    // const files = [...event.dataTransfer.files];

    // if (uploadedImages.length + files.length > 5) {
    //   alert("You can't upload more than 5 images.");
    //   return;
    // }

    // const newImages = files.map((file) => URL.createObjectURL(file));
    // setUploadedImages((prevImages) => [...prevImages, ...newImages]);

    event.preventDefault();
    const files = [...event.dataTransfer.files];
    handleFileInputChange({ target: { files } })
  };

  // Rest of your component code...

  const handleFiles = (files) => {
    const imageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const imageDataUrl = event.target.result;
          imageFiles.push(imageDataUrl);
          setUploadedImages((prevImages) => [...prevImages, imageDataUrl]);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDeselect = (index) => {
    const updatedImages = uploadedImages.filter((_, i) => i !== index);
    setUploadedImages(updatedImages);
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  // const handleFileInputChange = (event) => {
  //   const files = [...event.target.files];

  //   if (uploadedImages.length + files.length > 5) {
  //     alert("You can only upload mroe than 5 images.");
  //   } else {
  //     handleFiles(files);
  //   }
  // };

  const handleFileInputChange = async (e) => {
    setUploadImgLoader(true);
    const files = e.target.files;
    const formData = new FormData();

    for (let i = 0; i < files?.length; i++) {
      const fileSize = files[0].size;
      if (fileSize > 5 * 1024 * 1024) {
        alert("File size exceeds 5 MB limit for each picture.");
        setUploadImgLoader(false);
        return;
      }
      formData.append("images", files[i]);
    }
    const response = await handleUploadPictures(formData, token);
    if (response?.success) {
      if (uploadedImages?.length === 0) {
        setUploadedImages(response?.images);
        setUploadImgLoader(false);
      } else {
        const remainingSpace = 15 - uploadedImages?.length;
        const imagesToAdd = response?.images?.slice(0, remainingSpace);
        setUploadedImages([...uploadedImages, ...imagesToAdd]);
        setUploadImgLoader(false);
      }
    } else {
      setUploadImgLoader(false);
      console.log(response);
    }
  };

  const handleApplyVerify = async () => {
    setApplyLoader(true)
    try {
      const body = { verificationPicture: uploadedImages }
      const response = await applyVerify(body, token)
      if (response?.success) {
        setApplyLoader(false)
        setSuccessModal(true)
      } else {
        setApplyLoader(false)
      }
    } catch (error) {
      console.log(error);
      setApplyLoader(false)
    }
  }

  return (
    <div className="bg-purplegray pb-40">
      <div className="pt-10 pl-10 w-full justify-between flex items-center">
        <Link to={"/forms"}>
          <div className="bg-white lg:w-12 lg:h-12 md:w-8 md:h-8 sm:w-6 sm:h-6 w-6 h-6 flex items-center justify-center cursor-pointer active:opacity-50">
            <img
              src={images.arrowleft}
              alt=""
              className="lg:w-5 lg:h-5 md:w-4 md:h-5 sm:w-4 w-4 h-4"
            />
          </div>
        </Link>
        <div className="w-full flex justify-center text-white gap-4">
          <div
            className={`  lg:px-2 lg:py-2 md:px-1  md:py-2 sm:px-2 sm:py-2 px-2 py-2 flex items-center justify-center rounded-3xl ${changepanel === "benefits" ? "bg-purple" : "bg-buttoncolor"
              } `}
          >
            <div className="flex lg:gap-2 md:gap-1 sm:gap-1 gap-1 items-center">
              <div
                className={` lg:w-7 lg:h-7 md:w-6 md:h-6 sm:h-8 sm:w-8 w-6 h-6  flex    items-center lg:text-base md:text-sm text-sm justify-center rounded-full ${changepanel === "benefits"
                  ? "bg-white text-black"
                  : "bg-purple text-white"
                  }`}
              >
                1
              </div>
              <div className="lg:flex md:flex hidden">Benefits</div>
            </div>
          </div>
          <div
            className={`  lg:px-2 lg:py-2 md:px-1  md:py-2 sm:px-2 sm:py-2 px-2 py-2 flex items-center justify-center rounded-3xl ${changepanel === "howToVeri" ? "bg-purple" : "bg-buttoncolor"
              } `}
          >
            <div className="flex lg:gap-2 md:gap-1 sm:gap-1 gap-1 items-center">
              <div
                className={` lg:w-7 lg:h-7 md:w-6 md:h-6 sm:h-8 sm:w-8 w-6 h-6  flex    items-center lg:text-base md:text-sm text-sm justify-center rounded-full ${changepanel === "howToVeri"
                  ? "bg-white text-black"
                  : "bg-purple text-white"
                  }`}
              >
                2
              </div>
              <div className="lg:flex md:flex hidden">How to Get Verified</div>
            </div>
          </div>
          <div
            className={`  lg:px-2 lg:py-2 md:px-1  md:py-2 sm:px-2 sm:py-2 px-2 py-2 flex items-center justify-center rounded-3xl ${changepanel === "Verified" ? "bg-purple" : "bg-buttoncolor"
              } `}
          >
            <div className="flex lg:gap-2 md:gap-1 sm:gap-1 gap-1 items-center">
              <div
                className={` lg:w-7 lg:h-7 md:w-6 md:h-6 sm:h-8 sm:w-8 w-6 h-6  flex    items-center lg:text-base md:text-sm text-sm justify-center rounded-full ${changepanel === "verified"
                  ? "bg-white text-black"
                  : "bg-purple text-white"
                  }`}
              >
                3
              </div>
              <div className="lg:flex md:flex hidden">Get Verified</div>
            </div>
          </div>
        </div>
      </div>
      {changepanel === "benefits" ? (
        <>
          <div className=" w-full flex justify-center pt-10 text-whitepink font-bold ">
            <div className="bg-footercolor w-[80%] md:w-[70%] lg:w-[50%] border border-opacity-20 border-whitepink">
              <div className="flex p-3  gap-4 md:gap-10 items-start md:items-center">
                <img
                  src={images.pic}
                  className="w-10 h-10  md:w-24 md:h-24 lg:w-36 lg:h-36"
                />
                <div className="flex flex-col gap-4">
                  <div className=" text-base md:text-2xl lg:text-4xl ">
                    What does a Verified Picture look like?
                  </div>
                  <div className="text-textopacity text-xxs sm:text-xs md:text-lg lg:text-xl">
                    <li>Pleasury sign is clearly visible</li>
                    <li> A few different poses</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className=" w-full flex justify-center pt-10 text-whitepink  ">
        <div className="bg-footercolor w-[80%] md:w-[70%] lg:w-[50%] ">
          <div>
            {changepanel === "benefits" ? (
              <div>
                <div className="flex justify-between font-bold">
                  <div
                    className={
                      showBenefits === "benefitClient"
                        ? `bg-purple  w-full flex items-center justify-center py-6 cursor-pointer active:opacity-50  text-xxs sm:text-xs md:text-xl`
                        : `  w-full flex items-center justify-center border border-whitepink border-opacity-40 py-6  text-xxs sm:text-xs md:text-xl cursor-pointer active:opacity-50`
                    }
                    onClick={() => setShowBenefits("benefitClient")}
                  >
                    Benefits for Clients
                  </div>
                  <div
                    className={
                      showBenefits === "benefitComp"
                        ? `bg-purple cursor-pointer active:opacity-50  w-full flex items-center justify-center py-6  text-xxs sm:text-xs md:text-xl`
                        : `  w-full flex items-center justify-center py-6 border border-whitepink border-opacity-40 text-xxs sm:text-xs md:text-xl cursor-pointer active:opacity-50`
                    }
                    onClick={() => setShowBenefits("benefitComp")}
                  >
                    Benefits for Companions
                  </div>
                </div>
                {showBenefits === "benefitClient" ? (
                  <div className="flex flex-col">
                    <div className="p-6 flex  ">
                      <img
                        src={images.woman}
                        className=" w-10 h-10  md:h-20 md:w-20"
                      />
                      <div className="pl-4 md:pl-20 flex flex-col gap-4">
                        <div className="text-base md:text-2xl font-bold">
                          Higher Chance of Genuine Pictures
                        </div>
                        <div className="text-textopacity  text-xs md:text-base">
                          Each picture is checked by our Verification team for
                          authenticity. Almost all Verified Users are honest
                          with their pics, but a few bad apples might use some
                          tricks.
                        </div>
                        <div className="w-full border-b  text-xs md:text-base pb-10 border-whitepink border-opacity-40">
                          <div className="text-textopacity w-[90%] sm:w-[52%]">
                            <li>
                              Bait and Switch. A user might send their friend
                              instead of themself.
                            </li>
                            <li>
                              Bait and Switch. A user might send their friend
                              instead of themself.
                            </li>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-6 flex  ">
                      <img
                        src={images.transparentWallet}
                        className=" h-10 w-10  md:h-20 md:w-20"
                      />
                      <div className="pl-4 md:pl-20 flex flex-col gap-4">
                        <div className="text-base md:text-2xl font-bold">
                          More Pictures!
                        </div>
                        <div className="text-textopacity border-b pb-10 text-xs md:text-base border-whitepink border-opacity-40">
                          A user must add at least 5 Verified Pictures each with
                          a different pose to get Verification Status. More
                          pictures, and more variety!
                        </div>
                      </div>
                    </div>
                    <div className="p-6 flex  ">
                      <img
                        src={images.stylishCircles}
                        className="w-10 h-10  md:h-20 md:w-20"
                      />
                      <div className="pl-4 md:pl-20 flex flex-col gap-4">
                        <div className="text-base md:text-2xl font-bold">
                          Full Transparency
                        </div>
                        <div className="text-textopacity border-b text-xs md:text-base border-whitepink border-opacity-40 pb-10">
                          Users who are Verified have to show all their Verified
                          Images in their Ads
                        </div>
                      </div>
                    </div>
                    <div className="bg-purple pl-4 md:pl-10 pt-10 h-full flex gap-2 md:gap-10 ">
                      <img
                        src={images.ThumbsCircle}
                        className=" w-12 h-12 md:h-28 md:w-28"
                      />
                      <div className="flex flex-col gap-2 md:gap-5 pb-10">
                        <div className="text-sm sm:text-base md:text-2xl font-bold">
                          The Verification System is one of many tools you can
                          use to help you find the ideal Companion
                        </div>
                        <div className="text-xs md:text-base">
                          Best of all, it's completely free for both Clients and
                          Companions! Try it now!
                        </div>
                        <div className="bg-btPurple  md:h-14 h-10    w-32  md:w-60 flex text-xs md:text-base items-center justify-center cursor-pointer active:opacity-50">
                          Browse Verified Ads
                        </div>
                      </div>
                    </div>
                  </div>
                ) : showBenefits === "benefitComp" ? (
                  <div className="flex flex-col">
                    <div className="p-6 flex   ">
                      <img
                        src={images.trust}
                        className="w-12 h-12 md:h-28 md:w-28"
                      />
                      <div className="pl-4 md:pl-20 flex flex-col gap-4">
                        <div className="text-base md:text-2xl font-bold">
                          Build Trust
                        </div>
                        <div className="text-textopacity border-b pb-10 border-whitepink   text-xs md:text-base border-opacity-40">
                          Tired of being asked if you're real by your
                          Clients?Getting your Verification Status will put any
                          doubt to rest.
                        </div>
                      </div>
                    </div>
                    <div className="p-6 flex  ">
                      <img
                        src={images.thousandAds}
                        className="w-12 h-12 md:h-28 md:w-28"
                      />
                      <div className="pl-4 md:pl-20 flex flex-col gap-4">
                        <div className="text-base md:text-2xl font-bold">
                          Stand out from Thousands of Ads!
                        </div>
                        <div className="text-textopacity border-b pb-10  text-xs md:text-base border-whitepink border-opacity-40">
                          Your Verified Status badge and pictures attract
                          attention from clients!
                        </div>
                      </div>
                    </div>
                    <div className="p-6 flex  ">
                      <img
                        src={images.piggybank}
                        className="w-12 h-12 md:h-28 md:w-28"
                      />
                      <div className="pl-4 md:pl-20 flex flex-col gap-4">
                        <div className="flex items-center gap-2 md:gap-5">
                          <div className="text-sm md:text-xl   bg-purple py-2 px-2">
                            UP TO 20% OFF
                          </div>
                          <div className="text-base md:text-2xl font-bold">
                            Instant Savings
                          </div>
                        </div>
                        <div className="text-textopacity border-b  text-xs md:text-base border-whitepink border-opacity-40 pb-10">
                          Users who are Verified have to show all their Verified
                          Images in their Ads
                        </div>
                      </div>
                    </div>
                    <div className="p-6 flex  ">
                      <img
                        src={images.free}
                        className="w-12 h-12 md:h-28 md:w-28"
                      />
                      <div className="pl-4 md:pl-20 flex flex-col gap-4">
                        <div className="text-base md:text-2xl font-bold">
                          Free Verified Directory Listing
                        </div>
                        <div className="text-textopacity border-b  text-xs md:text-base border-whitepink border-opacity-40 pb-10">
                          Promote yourself for free in our exclusive Verified
                          Directory
                        </div>
                      </div>
                    </div>
                    <div className="bg-purple pl-4 md:pl-10 pt-10 h-full flex  gap-2 md:gap-10 ">
                      <img
                        src={images.ThumbsCircle}
                        className="w-12 h-12 md:h-28 md:w-28"
                      />
                      <div className="flex flex-col gap-5 pb-10">
                        <div className="text-base md:text-2xl font-bold">
                          Getting Verified is 100% Free and Easy
                        </div>
                        <div className="text-sm md:text-base">
                          Get Started now and enjoy even cheaper Ads!
                        </div>
                        <div
                          className="bg-btPurple  md:h-14 h-10    w-32  md:w-60 flex text-xs md:text-base items-center justify-center cursor-pointer active:opacity-50"
                          onClick={() => setchangepanel("howToVeri")}
                        >
                          Get Verified
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : changepanel === "howToVeri" ? (
              <div className="flex flex-col border border-whitepink border-opacity-40">
                <div className="p-6 flex   border-b border-whitepink border-opacity-40 pb-10">
                  <img
                    src={images.trust}
                    className="md:h-20 md:w-20 w-10 h-10"
                  />
                  <div className=" pl-4 md:pl-20 flex flex-col gap-4">
                    <div className="text-base md:text-2xl font-bold">
                      Verified Pictures
                    </div>
                    <div className="text-textopacity text-xs md:text-base ">
                      You don't have enough verified pics yet.
                      <p>
                        You need a {" "}
                        <span className="text-purple font-bold">
                          minimum of 5 verified pics{" "}
                        </span>
                        for them to show up on your Verified ad.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-6 flex border-b-2 pb-5 border-whitepink border-opacity-40">
                  <div className=" h-20">
                    <div className="bg-purple h-10 w-10 md:h-20 md:w-20  text-2xl  md:text-6xl items-center flex justify-center rounded-full">
                      1
                    </div>
                  </div>
                  <div className="flex">
                    <div className="pl-4 md:pl-16 flex flex-col gap-1 md:gap-4">
                      <div className="text-sm md:text-2xl font-bold">
                        Make a Pleasury Verified sign
                      </div>
                      <div className="text-textopacity text-xs md:text-base  flex flex-col gap-2 md:gap-5 ">
                        <div className="w-[80%] ">
                          Go ahead and get a piece of paper and write
                          "PleasuryList.com 16/05/24" (DD/MM/YY).
                        </div>
                        <div className="text-xs md:text-sm opacity-40 w-[83%]">
                          Make sure the sign and text is big enough so it's
                          easily read from far away. Use paper that is at least
                          letter sized.
                        </div>
                      </div>
                    </div>
                    <img
                      src={images.pic}
                      className=" w-14 h-14  md:w-40 md:h-40"
                    />
                  </div>
                </div>
                <div className="p-6 flex border-b-2 pb-5 border-whitepink border-opacity-40">
                  <div className=" h-20">
                    <div className="bg-purple h-10 w-10 md:h-20 md:w-20  text-2xl  md:text-6xl items-center flex justify-center rounded-full">
                      2
                    </div>
                  </div>
                  <div className="flex">
                    <div className="pl-4 md:pl-16 flex flex-col gap-1 md:gap-4">
                      <div className="text-sm md:text-2xl font-bold">
                        Take 5 or more pictures of yourself with the sign
                      </div>
                      <div className="text-textopacity  text-xs md:text-base flex flex-col gap-1 md:gap-5 ">
                        <div className="w-[80%]">
                          The sign can be stood up, hung, stuck on a wall, or
                          you can hold it.
                        </div>
                        <div className="text-xs md:text-sm opacity-40 w-[83%]">
                          Just make sure it's visible in the pictures.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-6 flex border-b-2 pb-5 border-whitepink border-opacity-40">
                  <div className=" h-20">
                    <div className="bg-purple h-10 w-10 md:h-20 md:w-20  text-2xl  md:text-6xl items-center flex justify-center rounded-full">
                      3
                    </div>
                  </div>
                  <div className="flex">
                    <div className="pl-4 md:pl-16 flex flex-col gap-1 md:gap-4">
                      <div className="text-base md:text-2xl font-bold">
                        Make sure your pictures are in a few different poses
                      </div>
                      <div className="text-textopacity text-xs md:text-base flex flex-col gap-1 md:gap-5 ">
                        <div className="w-[80%]">
                          We won't verify you if all your pictures are in
                          similar poses.
                        </div>
                        <div className=" opacity-40 w-[83%] text-xs md:text-sm">
                          No nudity is allowed in the pictures
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-6 flex border-b-2 pb-5 border-whitepink border-opacity-40">
                  <div className=" h-20">
                    <div className="bg-purple h-10 w-10 md:h-20 md:w-20  text-2xl  md:text-6xl items-center flex justify-center rounded-full">
                      4
                    </div>
                  </div>
                  <div className="flex">
                    <div className="pl-4 md:pl-16 flex flex-col gap-1 md:gap-4">
                      <div className="text-base md:text-2xl font-bold">
                        Upload the pictures to Pleasury!
                      </div>
                      <div className="text-textopacity text-xs md:text-base  flex flex-col gap-1 md:gap-5 ">
                        <div className="w-[80%]">
                          You need to submit at least 5+ images of yourself with
                          the sign in order to be Verified. Once you submit your
                          images,{" "}
                          <span className="text-purple font-bold">
                            {" "}
                            it may take up to 24 hours to get Verified.
                          </span>
                        </div>
                        <div className="text-xs md:text-sm opacity-40 w-[83%]">
                          Please note: All your Verified Images will appear in
                          ALL your Verified Ads and Directory Profile.
                        </div>
                        <div
                          className=" text-whitepink bg-purple h-10 md:h-14 w-20 md:w-40 cursor-pointer active:opacity-50 flex items-center justify-center text-sm md:text-xl"
                          onClick={() => setchangepanel("verified")}
                        >
                          Upload Pic
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : changepanel === "verified" ? (
              <div className="border border-b-2 border-whitepink border-opacity-40">
                <div className="p-6 flex  border-b-2 border-whitepink border-opacity-40  pb-10">
                  <img
                    src={images.trust}
                    className="md:h-20 md:w-20 w-10 h-10"
                  />
                  <div className="pl-4 md:pl-20 flex flex-col gap-2 md:gap-4">
                    <div className="text-base md:text-2xl font-bold">
                      Verified Pictures
                    </div>
                    <div className="text-textopacity text-xs md:text-base">
                      You don't have enough verified pics yet.
                      <p>
                        You need a
                        <span className="text-purple font-bold">
                          {" "}
                          minimum of 5 verified pics{" "}
                        </span>
                        for them to show up on your Verified ad.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="bg-purplegray w-full flex flex-col items-center justify-center pt-10 pb-10  "
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div className="bg-footercolor w-[90%] md:w-[60%]">
                    <div className=" p-2 text-xs md:text-xl  flex justify-between items-center border border-whitepink border-opacity-40 border-b-2 px-4">
                      <div className="font-bold">
                        Drag and drop to reorganize your pics
                      </div>
                      <div className="bg-purple text-sm md:text-2xl w-4 md:w-8 flex items-center justify-center cursor-pointer active:opacity-50">
                        X
                      </div>
                    </div>
                    <div className="flex  flex-col">
                      {uploadedImages?.map((item, index) => (
                        <div
                          key={index}
                          className="flex w-full justify-between items-center px-2 md:px-8 py-4 border border-whitepink border-opacity-40"
                        >
                          <div className="flex  items-center gap-2">
                            <img
                              src={item}
                              alt={`Uploaded ${index}`}
                              className="w-28 h-36 object-contain"
                            />
                            {/* <div className="text-sm text-purple">
                              Awaiting Approval
                            </div> */}
                            <div className="text-sm font-semibold ml-2 text-purple">
                              {`${index + 1} / ${uploadedImages?.length}`}
                            </div>
                          </div>
                          <img
                            src={images?.deselectBtn}
                            className="h-4 w-4 md:w-8 md:h-8 cursor-pointer active:opacity-50"
                            onClick={() => handleDeselect(index)}
                          />
                        </div>
                      ))}
                      <div className="mt-2">
                        <div
                          className={
                            `bg-purple font-semibold text-xs md:text-lg cursor-pointer active:opacity-50 ${uploadImgLoader ? "flex items-cneter justify-center pt-2.5 pb-1.5" : "px-2 py-2"}`
                          }
                          onClick={handleClick}
                        >
                          {uploadImgLoader ?
                            <ClipLoader
                              color="#FFFF"
                              cssOverride={{
                                height: "22px",
                                width: "22px",
                              }}
                            /> :
                            "+ Add Pictures"
                          }
                        </div>
                        <input
                          id="fileInput"
                          type="file"
                          accept="image/png,image/jpeg"
                          onChange={handleFileInputChange}
                          multiple
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-t-2 border-whitepink border-opacity-40 flex items-center justify-center">
                  <div className="flex justify-between w-full">
                    <div
                      className="flex justify-center w-full items-center py-3
                   flex-col gap-3 border-r border-whitepink border-opacity-40 cursor-pointer active:opacity-50"
                      onClick={() => setShowRules(true)}
                    >
                      <div className="text-md md:text-2xl font-bold">
                        Rule 1
                      </div>
                      <div className="text-textopacity text-xs md:text-sm text-center">
                        I will only advertise myself in my Verified ad.
                      </div>
                    </div>
                    <div
                      className="flex justify-center w-full items-center py-3
                   flex-col gap-3 border-l border-whitepink border-opacity-40  cursor-pointer active:opacity-50"
                      onClick={() => setShowRules(true)}
                    >
                      <div className="text-md md:text-2xl font-bold">
                        Rule 2
                      </div>
                      <div className="text-textopacity text-xs text-center md:text-sm">
                        I agree that I will not post any fake images in my
                        Verified ad.
                      </div>
                    </div>
                  </div>
                </div>
                {showRules && (
                  <div className="fixed z-10  text-whitepink top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="flex flex-col w-[80%] md:w-[40%]   items-center justify-center">
                      <div className="bg-purple py-4 flex w-full items-center ">
                        <div className="flex  justify-start pl-4">
                          <div
                            className="bg-white px-3 p-3 cursor-pointer active:opacity-50"
                            onClick={() => setShowRules(false)}
                          >
                            <img src={images.arrowleft} className="h-3 w-5" />
                          </div>
                        </div>
                        <div className=" text-base md:text-xl flex w-full justify-center pr-10">
                          RULES & GUIDELINES
                        </div>
                      </div>
                      <div className="bg-footercolor w-full  flex flex-col border border-whitepink border-opacity-40">
                        <div className="p-2 lg:p-6 flex border-b-2 pb-5 border-whitepink border-opacity-40 ">
                          <div className="h-16 ">
                            <div className="bg-purple  h-8 w-8 lg:h-14 lg:w-14  text-2xl items-center flex justify-center rounded-full">
                              1
                            </div>
                          </div>
                          <div className="flex">
                            <div className="pl-4 lg:pl-10 flex flex-col gap-4">
                              <div className=" text-sm md:text-base lg:text-2xl font-bold ">
                                Only Advertise yourself in your Verified ad
                              </div>
                              <div className="text-textopacity text-xs md:text-sm  flex flex-col gap-5 ">
                                <div className="w-[80%]">
                                  If you have a friend who advertisers with you,
                                  post a regular ad for them.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-2 lg:p-6 flex border-b-2 pb-5 border-whitepink border-opacity-40">
                          <div className="h-16 ">
                            <div className="bg-purple h-8 w-8 lg:h-14 lg:w-14  text-2xl items-center flex justify-center rounded-full">
                              2
                            </div>
                          </div>
                          <div className="flex">
                            <div className="pl-4 lg:pl-10 flex flex-col gap-4">
                              <div className=" text-sm md:text-base lg:text-2xl font-bold ">
                                Verified users must use real photos in their ads
                              </div>
                              <div className="text-textopacity text-xs md:text-sm  flex flex-col gap-5">
                                <div className="w-[80%] text-sm" >
                                  If we notice your images in your ads
                                  are fake your Verification status may be
                                  revoked or you may be issued a warning.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" justify-center items-center py-4 flex flex-col gap-3">
                          <div className="text-xs sm:text-sm md:text-base lg:text-2xl font-bold ">
                            Verified users are held to higher standards
                          </div>
                          <div className="text-xs w-[70%] text-center">
                            In order to maintain the reputation of Verified
                            users, we will hold you to a higher standard. It is
                            important you follow all the rules and guidelines of
                            PleasuryList.
                          </div>
                          <div
                            className="bg-purple py-3  lg:text-base px-2  flex items-center justify-center 
                          cursor-pointer active:opacity-50"
                            onClick={() => setShowRules(false)}
                          >
                            I Agree to follow the Rules
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="border-t-2 border-whitepink text-xs md:text-base border-opacity-40 py-4 flex items-center justify-between px-4">
                  <div>
                    Are You an Agency?{" "}
                    <span className="text-purple font-bold cursor-pointer active:opacity-50">
                      Contact Us
                    </span>
                  </div>
                  {uploadedImages?.length >= 5 &&
                    <div
                      onClick={handleApplyVerify}
                      className={
                        `bg-purple font-semibold text-md  active:opacity-50 cursor-pointer w-[200px] h-10 flex items-center justify-center`
                      }>
                      {applyLoader ?
                        <ClipLoader
                          color="#FFFF"
                          cssOverride={{
                            height: "20px",
                            width: "20px",
                          }}
                        /> :
                        "Apply for Verification"
                      }
                    </div>
                  }
                </div>
              </div>
            ) : null}
          </div>
          {successModal && (
            <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div className="text-white rounded-xl py-4 bg-purplegray flex items-center justify-center px-5 flex flex-col gap-3">
                <img
                  src={images.tickmarkdone}
                  className="w-[50%]"
                />
                <div className="font-bold text-xl">
                  Verification Submitted!
                </div>
                <div className="font-semibold text-center text-md">
                  We'll review your details and <br />send confirmation to your email shortly.
                </div>
                <div
                  className="bg-purple rounded-md px-4 py-2 cursor-pointer active:opacity-50"
                onClick={() => {
                  setSuccessModal(false)
                  navigate('/')
                }}
                >
                  Done
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
