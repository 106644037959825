import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ReportCopyright() {
  useEffect(() => {
    handleScrollToTop();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();

  return (
    <div className="flex text-whitepink flex justify-center  gap-10 pt-20 pb-20">
      <div className="w-full md:w-[60%] ">
        <div className=" bg-footercolor  flex flex-col rounded-md">
          <div className="text-2xl pl-3 p-3" id="rules">
            Report Copyright Violations
          </div>
          <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2  pb-10 rounded-md">
            <div className="mt-2">
              The Digital Millennium Copyright Act governs complaints in which
              an individual who either owns, or represents the owner of
              copyrighted material (i.e. photos, content) claim their
              copyrighted materials have been used without their consent. As you
              will see from our Terms of Service, we take such violations very
              seriously.
            </div>

            <div className="mt-4">
              By law, only the copyright holder or their representative may make
              an infringement complaint. If you own the pictures or content in
              question PLEASE report it to us.
            </div>
            <div className="mt-4">
              If you don’t own the pictures but would like to bring to our
              attention believed misuse of photos, please use the "Report TOS
              Violation" page.
            </div>
            <div className="mt-6">
              <b>Copyright complaints must include the following:</b>
            </div>
            <div className="mt-2 ml-10">
              An electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright interest:
            </div>
            <div className=" ml-10">
              A description of the copyrighted work that you claim has been
              infringed upon;
            </div>
            <div className=" ml-10">
              A description of where the material that you claim is infringing
              is located on the site, including the current website address;
            </div>
            <div className=" ml-10">
              Your address, telephone number, and e-mail address;
            </div>
            <div className=" ml-10">
              A statement by you that you have a good-faith belief that the
              disputed use is unauthorized by the copyright owner, its agent, or
              the law;
            </div>
            <div className=" ml-10">
              A statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner's
              behalf.
            </div>
            <div className="mt-2 ml-4">
              WARNING: Fraudulent DMCA reporting to harass other users will not
              be tolerated. If a claim is made that lacks proper information we
              may seek to verify the claim. If it is revealed that a false claim
              was made, we reserve the right to pursue damages and attorney's
              fees for abuse of the DMCA takedown process.
            </div>
            <div className="mt-2 ml-4">
              DMCA complaints must contain your contact information and must be
              sent to www.pleasurylist.com attorney at{" "}
              <span className="font-bold cursor-pointer active:opacity-40  text-purple">
                support@pleasurylist.com
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
