import React, { useEffect, useState } from "react";
import images from "../../services/images";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store/userData";
import { useNavigate } from "react-router-dom";

export default function ContactUs() {
  const [dropDown, setDropDown] = useState("");
  const [category, setCatergory] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [location, setLocation] = useState("");
  const [contactUsModal, setContactUsModal] = useState(false);

  const toggleDropdown = (name) => {
    if (dropDown === name) {
      setDropDown("");
    } else {
      setDropDown(name);
    }
  };

  const [cateogryData, setCategoryData] = useState([
    "Advertising Inquiry",
    "Tech Support/Report a bug",
    "Problems with payment",
    "General Question/Suggestion",
    "Breach of Copyright (DMCA)",
    "Report Violation/Offense",
    "Verification/Directory",
    "Identity Verification",
  ]);

  const [provinceCandaData, setProvinceCanadaData] = useState([
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon",
  ]);

  const navigate = useNavigate();

  const userData = useSelector(selectUserData);

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -100; // Adjust this value as needed to scroll to the desired position within the section
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }

  useEffect(() => {
    handleScrollToTop();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="flex text-whitepink flex justify-center  gap-10 pt-20 pb-20 w-full sm:w-[80%] md:w-[605] lg:w-[50%] mx-auto">
      <div className=" w-full bg-footercolor p-3">
        {/* CONTACT US */}
        <div className="flex flex-col gap-4">
          <div className="text-2xl sm:text-4xl font-semibold ">Contact Us</div>
          <div className=" text-xs border-l-4 flex  border-purple  w-52 justify-center items-center h-10 gap-2     ">
            <div className="bg-purple rounded-full w-4  flex items-center justify-center h-5">
              <img src={images.views} className="h-4 w-3 " />
            </div>
            <div className="flex flex-col">
              <div className="font-bold">We are currently online</div>
              <div>Average reply within 15 minutes</div>
            </div>
          </div>
          <div className="">
            Use this form to contact us. Please check the following resources
            before reporting violations:
          </div>
          <div className="text-purple">
            <li
              className="cursor-pointer active:opacity-40"
              onClick={() => navigate("/report-offense")}
            >
              {" "}
              Report Offense
            </li>
            <li
              className="cursor-pointer active:opacity-40"
              onClick={() => navigate("/report-tos")}
            >
              Report ToS Violations
            </li>
          </div>
          <div className="w-full flex sm:flex-row flex-col gap-4">
            <div className="flex flex-col w-full gap-2">
              Full Name
              <input className="bg-transparent  focus:outline-none border border-whitepink border-opacity-40 h-10 w-full pl-2" />
            </div>
            <div className="flex flex-col w-full gap-2">
              Category
              <div
                className="bg-transparent relative cursor-pointer flex items-center  focus:outline-none border border-whitepink border-opacity-40 h-10 w-full"
                onClick={() => toggleDropdown("category")}
              >
                <div className="w-full flex justify-between px-3 items-center">
                  <div className="">{category}</div>
                  <img
                    src={
                      dropDown === "category"
                        ? images.dropDownOpen
                        : images.dropDownClosed
                    }
                    className="h-3 w-5"
                  />
                </div>
                {dropDown === "category" && (
                  <div className="absolute top-10 bg-buttoncolor  w-full max-h-[200px] overflow-auto z-20">
                    {cateogryData.map((item, index) => (
                      <div
                        className="  hover:bg-purple cursor-pointer border-opacity-40 border-whitepink  border-b acitve:opaicty-50"
                        onClick={() => setCatergory(item)}
                      >
                        <div className="ml-4 pt-4 pb-2 ">{item}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full flex sm:flex-row flex-col gap-4">
            <div className="flex flex-col w-full gap-2">
              Email
              <input
                className="bg-transparent  focus:outline-none border border-whitepink border-opacity-40 h-10 w-full pl-2"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              Your Location
              <div
                className="bg-transparent relative cursor-pointer flex items-center  focus:outline-none border border-whitepink border-opacity-40 h-10 w-full"
                onClick={() => toggleDropdown("location")}
              >
                <div className="w-full flex justify-between px-3 items-center">
                  <div className="">{location}</div>
                  <img
                    src={
                      dropDown === "location"
                        ? images.dropDownOpen
                        : images.dropDownClosed
                    }
                    className="h-3 w-5"
                  />
                </div>
                {dropDown === "location" && (
                  <div className="absolute top-10 bg-buttoncolor w-full max-h-[200px] overflow-auto z-20">
                    {provinceCandaData.map((item, index) => (
                      <div
                        className="  hover:bg-purple cursor-pointer acitve:opaicty-50 border-opacity-40 border-whitepink  border-b"
                        onClick={() => setLocation(item)}
                      >
                        <div className="ml-4 pt-4 pb-2 ">{item}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full gap-2 z-10">
            Ad Url (if Applicable)
            <input className="bg-transparent  focus:outline-none border border-whitepink border-opacity-40 h-10 w-full pl-2" />
          </div>
          <div className="flex flex-col w-full gap-2">
            Message
            <textarea className="bg-transparent  focus:outline-none border border-whitepink border-opacity-40 h-40 w-full pl-2" />
          </div>
          <div>
            <div className="flex flex-col w-full gap-2">Attachment</div>
            <form action="/action_page.php">
              <input type="file" id="myFile" name="filename" />
              {/* <input type="submit" /> */}
            </form>
          </div>

          <div className="bg-purple flex cursor-pointer active:opacity-40 item-center justify-center items-center w-32 py-2" 
          onClick={() => setContactUsModal(true)}>
            Send Message
          </div>

          {contactUsModal && (
            <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 text-whitepink">
              <div className="bg-footercolor  px-10 h-40p flex items-center justify-center gap-5 flex flex-col">
                <div className="text-center flex flex-col gap-4">
                  <div className="text-2xl sm:text-5xl font-semibold">Thank You</div>
                  <div className="text-center text-xs px-2 sm:text-base">
                    <div className="">
                      We appreciate that you've taken the time to write us.
                    </div>
                    <div>We'll get back to you very soon</div>
                  </div>
                </div>
                <div className="mt-10 bg-purple text-xs md:text-sm px-5 py-1 cursor-pointer active:opacity-50" onClick={()=> setContactUsModal(false)}>Done</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
