import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import images from "../../services/images";
import CreateProfile from "../CreateProfile";
import { useModal } from "../../screens/Layout";
import { useNavigate } from "react-router-dom";
import { forgotPass } from "../../services/API";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { ClipLoader } from "react-spinners";

export default function Forgotpassword({ onCloseModal, setloggedin }) {
  const { Modalcomponent, setmodalcomponent } = useModal();

  const [isAgreed, setIsAgreed] = useState(false);
  const [otherscreen, setotherscreen] = useState(false);
  const [privacySelected, setPrivacySelected] = useState(false);
  const [error, setError] = useState("validated");
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState(false)

  const handleAgreeToggle = () => {
    setIsAgreed(!isAgreed);
  };
  const navigate = useNavigate();
  const { setOtp, otp , setForgotPassEmail } = useAuth()


  const handleForgotPassword = async () => {
    setLoader(true)
    try {
      const body = {
        email,
      };
      const response = await forgotPass(body)
      if (response.success) {
        setLoader(false)
        setError("validated")
        setOtp(response.otp)
        setForgotPassEmail(email)
        return true
      } else {
        setLoader(false)
        setError(response.message)
        return false
      }
    } catch (error) {
      setLoader(false)
    }
  };


  const validateForm = () => {
    // const { username, email, password, confirmPassword, privacySelected } = formData;

    // Check if any required fields are empty
    if (!email) {
      setError("Please fill out all required fields.");
      return false;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email format.");
      return false;
    }

    // Clear error if all validations pass
    setError("validated");
    return true;
  };

  const HandleJoinnow = () => {
    onCloseModal();
    setloggedin(false);
  };

  // const handleSignInClick = () => {
  //   if (validateForm()) {
  //     setmodalcomponent("OTP");
  //   }
  // };


  const handleSignInClick = async () => {
    if (validateForm()) {
      const success = await handleForgotPassword()
      if (success) {
        setmodalcomponent("OTP");

      }
    }
  };

  return (
    <div className="lg:w-50p biggerlaptop:p-4 lg:p-3 h-full  md:p-1 sm:p-2 p-1 w-full ">
      <div
        className="bg-cover bg-center h-full flex flex-col p-10 items-start justify-between"
        style={{
          backgroundImage: `url(${images.Bluredimg})`,
        }}
      >
        <div className="w-full flex ">
          <div className="w-50p flex justify-start  flex   ">
            <img
              src={images.arrowwhiteleft}
              alt=""
              className="w-6 h-5 cursor-pointer "
              onClick={() => setmodalcomponent("signin")}
            />
          </div>
          <div className="w-60p  flex justify-end" onClick={onCloseModal}>
            <img src={images.X} alt="" className="cursor-pointer md:h-7 sm:h-6 h-5" />
          </div>
        </div>
        <div className="flex w-full justify-between lg:flex-col md:flex-col md:flex-col sm:flex-col flex-col   ">
          <div className="1470:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-2xl text-white font-semibold text-nowrap opacity-90 ">
            Forgot Password?
          </div>
          <div className="text-white lg:text-base md:text-sm sm:text-xs text-xs  w-87p pt-2">
            Please enter your email to receive a verification code
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-full border-b-2">
            <input
              className=" text-white  opacity-50  remove-arrow bg-transparent  h-10 w-full focus:outline-none placeholder-white pl-4 w-full  "
              type="Email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div
          className={
            error === "validated"
              ? "error cursor-pointer opacity-0"
              : "error cursor-pointer"
          }
        >
          {error}
        </div>

        <div className="w-full"
          onClick={() => !loader && handleSignInClick()}
        >
          <div className="bg-purple font-semibold text-lg text-white text-center py-4 w-full cursor-pointer mb-8 mt-2  active:opacity-50">
            {
              loader ?
                <ClipLoader
                  color="#FFFF"
                  cssOverride={{
                    height: "22px",
                    width: "22px",
                  }}

                /> :
                "Continue"
            }
          </div>
        </div>
      </div>
    </div>
  );
}
