import { combineReducers } from "@reduxjs/toolkit";
// import SaveData from "./reducer/addReduer";
import tokenReduces from "./token";
import userDataReducer from "./userData";
import logedInReducer from "./logedIn";
import roleSlice from "./role";

const rootReducer = combineReducers({
  token: tokenReduces,
  user: userDataReducer,
  logedIn: logedInReducer,
  role: roleSlice,
});

export default rootReducer;
