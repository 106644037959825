import React, { useState } from "react";
import images from "../../services/images";
import StarRating from "../Starating";
import { useNavigate } from "react-router-dom";

export default function EscortAvailable({ escortimage2, yellowBandidata }) {
  const navigate = useNavigate();
  // const [escortimages, setEscortimages] = useState([
  //   {
  //     images: images.escortdetailimg,
  //     name: "Florence J. Smith",
  //     location: "LONDON",
  //     age: 27,
  //     rating: 4.5,
  //     service: 'Lorem Ipsum, Lorem Ipsum',
  //     _id: 154894561218
  //   },
  //   {
  //     images: images.escortimg10,
  //     name: "Florence J. Smith",
  //     location: "LONDON",
  //     age: 27,
  //     rating: 4.5,
  //     service: 'Lorem Ipsum, Lorem Ipsum',
  //     _id: 154894561218
  //   },
  //   {
  //     images: images.escortimg1,
  //     name: "Florence J. Smith",
  //     location: "LONDON",
  //     age: 27,
  //     rating: 4.5,
  //     service: 'Lorem Ipsum, Lorem Ipsum',
  //     _id: 154894561218
  //   },
  //   {
  //     images: images.blackwhitegirl,
  //     name: "Florence J. Smith",
  //     location: "LONDON",
  //     age: 27,
  //     rating: 4.5,
  //     service: 'Lorem Ipsum, Lorem Ipsum',
  //     _id: 154894561218
  //   },
  //   {
  //     images: images.escortimg3,
  //     name: "Florence J. Smith",
  //     location: "LONDON",
  //     age: 27,
  //     rating: 4.5,
  //     service: 'Lorem Ipsum, Lorem Ipsum',
  //     _id: 154894561218
  //   },
  //   {
  //     images: images.sliderimg22,
  //     name: "Florence J. Smith",
  //     location: "LONDON",
  //     age: 27,
  //     rating: 4.5,
  //     service: 'Lorem Ipsum, Lorem Ipsum',
  //     _id: 154894561218
  //   },

  // ]);

  return (
    <div className="w-full gap-6 flex flex-col ">
      {escortimage2?.map((item, index) => (
        <div key={index} className="w-full flex flex-col gap-6">
          <div
            className="bg-buttoncolor cursor-pointer h-40 items-center gap-4 flex w-full px-4"
            onClick={() => {
              const _id = item._id;
              navigate(`/Modeldetails/${_id}`, { state: { item } });
            }}
          >
            <img src={item.images} alt="" className="h-32  " />
            <div className="flex flex-col  gap-5">
              <div className="text-4xl text-whitepink font-semibold">
                Beautiful European Expat. Hot and En...
              </div>
              <div className="text-sm text-whitepink ">
                {item.age} - {item.name} - {item.race} - {item.region}
              </div>
              <div className="font-semibold rounded-2xl h-9 text-sm bg-whitepink flex items-center justify-center w-32   text-textdarkerpurpel ">
                <img src={images.verified1} className="h-6" />
                Verified
              </div>
            </div>
          </div>
          {index === 2 && (
            <div
              className="bg-buttoncolor text-whitepink px-2   h-60 items-center justify-center  flex flex-col"
              onClick={() => navigate("/modeladd")}
            >
              <div className="flex w-full gap-4 ">
                {" "}
                {yellowBandidata.map((item, index) => (
                  <img src={item.image} />
                ))}
              </div>
              <div className="w-full  flex flex-col">
                <div className="flex w-full  font-bold  text-3xl ">
                  Beautiful European Expat. Hot and En...
                </div>
                <div className="flex  w-full justify-between  font-semibold  text-2xl">
                  <div>
                    23 - Szekeres Dalma - White/Caucasian - Durham Region
                  </div>
                  <div className=" font-semibold rounded-2xl h-9 text-sm bg-whitepink flex items-center justify-center w-32   text-textdarkerpurpel ">
                    <img src={images.verified1} className="h-6" />
                    Verified
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
