import React, { useRef, useState } from "react";
import images from "../../services/images";
import { useNavigate } from "react-router-dom";

export default function ModelPop() {
  const navigate = useNavigate()
  const ImageModels = useRef();
  const [models, setModels] = useState([
   
    
   
   
    {
      image: images.card2,
      name: "Live",
      location: "Tóth Kamilla",
      image2: images.Heart,
      _id: 874965123,
      about: "Szekeres",
      Description:
        "Join me on a journey of glamour, connection, and shared experiences in the world of escorting. I'm Szekeres Dalma, your guide to a captivating and unique streaming experience.",
    },
    {
      image: images.escortimg1,
      name: "Live",
      location: "Tóth Kamilla",
      image2: images.Heart,
      _id: 874965123,
      about: "Szekeres",
      Description:
        "Join me on a journey of glamour, connection, and shared experiences in the world of escorting. I'm Szekeres Dalma, your guide to a captivating and unique streaming experience.",
    },
    {
      image: images.escortimg2,
      name: "Live",
      location: "Tóth Kamilla",
      image2: images.Heart,
      _id: 874965123,
      about: "Szekeres",
      Description:
        "Join me on a journey of glamour, connection, and shared experiences in the world of escorting. I'm Szekeres Dalma, your guide to a captivating and unique streaming experience.",
    },
    {
      image: images.escortimg3,
      name: "Live",
      location: "Tóth Kamilla",
      image2: images.Heart,
      _id: 874965123,
      about: "Szekeres",
      Description:
        "Join me on a journey of glamour, connection, and shared experiences in the world of escorting. I'm Szekeres Dalma, your guide to a captivating and unique streaming experience.",
    },
    {
      image: images.card2,
      name: "Live",
      location: "Tóth Kamilla",
      image2: images.Heart,
      _id: 874965123,
      about: "Szekeres",
      Description:
        "Join me on a journey of glamour, connection, and shared experiences in the world of escorting. I'm Szekeres Dalma, your guide to a captivating and unique streaming experience.",
    },
    {
      image: images.escortimg2,
      name: "Live",
      location: "Tóth Kamilla",
      image2: images.Heart,
      _id: 874965123,
      about: "Szekeres",
      Description:
        "Join me on a journey of glamour, connection, and shared experiences in the world of escorting. I'm Szekeres Dalma, your guide to a captivating and unique streaming experience.",
    },
    {
      image: images.card2,
      name: "Live",
      location: "Tóth Kamilla",
      image2: images.Heart,
      _id: 874965123,
      about: "Szekeres",
      Description:
        "Join me on a journey of glamour, connection, and shared experiences in the world of escorting. I'm Szekeres Dalma, your guide to a captivating and unique streaming experience.",
    },
    {
      image: images.escortimg1,
      name: "Live",
      location: "Tóth Kamilla",
      image2: images.Heart,
      _id: 874965123,
      about: "Szekeres",
      Description:
        "Join me on a journey of glamour, connection, and shared experiences in the world of escorting. I'm Szekeres Dalma, your guide to a captivating and unique streaming experience.",
    },
    {
      image: images.card4,
      name: "Live",
      location: "Tóth Kamilla",
      image2: images.Heart,
      _id: 874965123,
      about: "Szekeres",
      Description:
        "Join me on a journey of glamour, connection, and shared experiences in the world of escorting. I'm Szekeres Dalma, your guide to a captivating and unique streaming experience.",
    },
    {
      image: images.card1,
      name: "Live",
      location: "Tóth Kamilla",
      image2: images.Heart,
      _id: 874965123,
      about: "Szekeres",
      Description:
        "Join me on a journey of glamour, connection, and shared experiences in the world of escorting. I'm Live, your guide to a captivating and unique streaming experience.",
    },


  ]);

  return (
    <div className="bg-purplegray flex flex-row pl-8 pr-8 sm:pl-6 md:pl-8 lg:pl-8 gap-2 overflow-hidden pb-40">
      <div ref={ImageModels} className="flex flex-wrap">
        {models.map((item, index) => (
          <div
            key={index}
            className="pt-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pr-2 pl-2 relative"
            onClick={() => {
              const _id = item._id
              navigate(`/LiveStream/${_id}`, { state: { item } })
            }}
          >
            <div className="relative">
              <img
                src={item.image}
                alt={`Image ${index + 1}`}
                className="w-full h-auto object-cover rounded cursor-pointer"
              />
              <div className="absolute top-0 ml-5 mt-8  px-8 py-1 text-center bg-purple text-white ">
                {item.name}
              </div>
              <div className="flex mb-4 items-center justify-evenly">
                <div className="absolute bottom-1 left-5 rounded rounded-full p-2 bg-green-500"></div>
                <div className=" text-white w-50p ml-10 text-center    ">
                  {item.location}
                </div>
                <div className="flex w-full justify-end">
                  <img src={item.image2} alt="" className="w-10p h-10p mr-5   " />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
