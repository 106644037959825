import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faL,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import images from "../../services/images";
import Signin from "../Login";
import { useModal } from "../../screens/Layout";
import { Link } from "react-router-dom";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { checkEmail, userSignup } from "../../services/API";
import { useDispatch } from "react-redux";
import { setToken } from "../../store/token";
import { ClipLoader } from "react-spinners";

export default function Signup({ onCloseModal, logout }) {
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [password, setPasswordState] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [privacySelected, setPrivacySelected] = useState(false);
  const [error, setError] = useState("validated");
  const [loader, setLoader] = useState(false);

  const [isAgreed, setIsAgreed] = useState(false);

  const { saveEmail, setUserEmail, setPassword } = useAuth();

  const handleAgreeToggle = () => {
    setIsAgreed(!isAgreed);
    setPrivacySelected(!privacySelected);
  };
  const { setmodalcomponent, Modalcomponent } = useModal();

  const [alreadyaccount, setalreadyaccount] = useState(false);

  const toggleaccount = () => {
    setalreadyaccount(!alreadyaccount);
  };

  const dispatch = useDispatch();

  const handleCheckmail = async () => {
    try {
      const body = {
        email,
      };
      const response = await checkEmail(body);
      if (response.success) {
        setError("validated");
        setLoader(false);

        // dispatch(setToken(response.token))
        // console.log('token from redux',token)
        // dispatch(setToken(response.token))
        return true;
      } else {
        return false;
      }
    } catch (error) {}
  };

  const validateForm = () => {
    // const { username, email, password, confirmPassword, privacySelected } = formData;

    // Check if any required fields are empty
    if (
      // !username ||
      !email ||
      !password ||
      !confirmPassword ||
      !privacySelected
    ) {
      setError(
        privacySelected
          ? "Please fill out all required fields."
          : "Please agree to the privacy policy."
      );
      setLoader(false);

      // setError(
      //   privacySelected
      //     ? "Please fill out all required fields."
      //     : "Please agree to the privacy policy."
      // );

      return false;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email format.");
      setLoader(false);

      return false;
    }

    // Validate password and confirm password match
    if (password !== confirmPassword) {
      setError("Password and Confirm Password must match.");
      setLoader(false);

      return false;
    }

    // Clear error if all validations pass
    handleTakeemail(email);
    setPassword(password);
    setLoader(false);
    setError("validated");
    return true;
  };
  const handlealreadyhaveacc = () => {
    setmodalcomponent("signin");
  };
  const handleVerification = async () => {
    setLoader(true);
    if (validateForm()) {
      const emailExist = await handleCheckmail(email);
      if (emailExist) {
        setmodalcomponent("verification");
        setLoader(false);
      } else {
        setError("Email Already Exists");
        setLoader(false);
      }
    }
  };

  const handleTakeemail = () => {
    setUserEmail(email);
  };

  return (
    <div className="lg:w-50p w-full lg:p-5 md:p-5 sm:p-5 p-3  h-full">
      {/* <img src={images.logo} alt="" className="w-20 mb-2 max-w-20 lg:hidden md:hidden sm:hidden flex" /> */}
      <div
        className="bg-cover bg-center  h-full  flex flex-col p-6 items-start justify-between "
        style={{
          backgroundImage: `url(${images.Bluredimg})`,
        }}
      >
        <div className="flex w-full justify-between lg:flex-row md:flex-row md:flex-row sm:flex-row flex-col-reverse   ">
          <div className="lg:text-4xl md:text-2xl sm:text-xl text-xl  text-white flex font-bold text-nowrap  ">
            Sign Up
          </div>
          <div className=" flex  w-full justify-between items-center">
            <div className="w-full flex lg:justify-end md:justify-end sm:justify-end items-center  justify-center gap-4">
              <div
                className=" lg:hidden md:hidden flex sm:hidden justify-start w-full"
                onClick={onCloseModal}
              >
                <img
                  src={images.arrowwhiteleft}
                  alt=""
                  className="md:h-5 sm:h-6 h-4 "
                />
              </div>
              <div className="w-full  flex lg:justify-end md:justify-end sm:justify-end items-center  justify-center">
                <img
                  src={images.logo}
                  alt=""
                  className="w-20 mb-2 mr-5 max-w-20 lg:hidden md:flex sm:flex flex  "
                />
              </div>
              <div className="w-60p  flex justify-end" onClick={onCloseModal}>
                <img
                  src={images.X}
                  alt=""
                  className="cursor-pointer md:h-7 sm:h-6 h-4"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <input
          className="text-white text-sm  bg-transparent opacity-50 border-b-2 w-full focus:outline-none placeholder-white pl-1 mt-2"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        /> */}
        <input
          className=" text-white text-sm bg-transparent opacity-50 border-b-2 w-full focus:outline-none placeholder-white pl-1 mt-2"
          type="Email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="    text-sm text-white  bg-transparent opacity-50 border-b-2 w-full focus:outline-none placeholder-white pl-1 mt-2"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPasswordState(e.target.value)}
        />
        <input
          className=" text-sm text-white  bg-transparent opacity-50 border-b-2 w-full focus:outline-none placeholder-white pl-1"
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        <div className="flex items-center">
          <FontAwesomeIcon
            icon={isAgreed ? faCheckSquare : faSquare}
            size="1x"
            onClick={handleAgreeToggle}
            style={{
              height: "10px",
              width: "10px",
              cursor: "pointer",
              color: isAgreed ? "#BE42DE" : "transparent",
              border: "1px solid #F9EAFD",
              marginRight: "8px",
            }}
          />
          <div className="text-white opacity-50 lg:text-sm md:text-sm sm:text-sm text-xs text-nowrap">
            I agree to Pleasury
          </div>
          <div className="text-white cursor-pointer pl-1 text-nowrap lg:text-base md:text-base sm:text-base lg:text-sm md:text-sm sm:text-sm text-xs   ">
            <Link to={"/About"}>Privacy Policy</Link>
          </div>
        </div>
        <div
          className="text-white cursor-pointer active:opacity-50 lg:text-base md:text-base sm:text-sm text-xs"
          onClick={handlealreadyhaveacc}
        >
          Already have an account?
        </div>
        <div
          className={
            error === "validated"
              ? "error cursor-pointer opacity-0"
              : "error cursor-pointer"
          }
        >
          {error}
        </div>

        {loader ? (
          <div className="bg-purple font-semibold text-lg text-white text-center py-3 w-full cursor-pointer active:opacity-50">
            <ClipLoader
              color="#FFFF"
              cssOverride={{
                height: "25px",
                width: "25px",
              }}
            />
          </div>
        ) : (
          <div
            className="bg-purple font-semibold text-lg text-white text-center py-3 w-full cursor-pointer active:opacity-50"
            onClick={handleVerification}
          >
            Join Now
          </div>
        )}
      </div>
    </div>
  );
}
