import { useEffect, useState } from "react";
import "./App.css";
import Routing from "./services/config/router";
import { AuthProvider } from "./services/Authcontext/Authcontext";
import { serverConnection, userSignin } from "./services/API";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";


// import { PersistGate } from "redux-persist/integration/react";

// import store from './store/token'
function App() {
  // const checkServer = async () => {
  //   try {
  //     const response = await serverConnection();
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handleSignin = async () => {
  //   try {
  //     const response = await userSignin();
  //     console.log(response);
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   checkServer()
  //   handleSignin()
  // }, [])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AuthProvider>
          <Routing />
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
