import { useNavigate } from "react-router-dom";
import StarRating from "../Starating";

import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";

import "./ResponsiveLivestreamcards.css"; // Import your stylesheet

const Escortslider = forwardRef(({ images }, ref) => {
  const navigate = useNavigate();
  const [visibleImages, setVisibleImages] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);



  useEffect(() => {
    const handleResize = () => {
      const breakpoints = [
        { width: 1280, visibleImages: 4 },
        { width: 1024, visibleImages: 3 },
        { width: 768, visibleImages: 3 },
        { width: 556, visibleImages: 2 },
        { width: 544, visibleImages: 1 },
      ];

      const { visibleImages: newVisibleImages } =
        breakpoints.find(
          (breakpoint) => window.innerWidth >= breakpoint.width
        ) || breakpoints[breakpoints.length - 1]; // Fallback to the last breakpoint

      setVisibleImages(newVisibleImages);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleNext = () => {
    if (startIndex + visibleImages < images.length) {
      setStartIndex(startIndex + 1);
    } else {
      setStartIndex(0);
    }
  };

  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    } else {
      setStartIndex(images.length - visibleImages);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    const touchDiff = touchEnd - touchStart;

    // Set a threshold for swipe distance to prevent accidental swipes
    const swipeThreshold = 50;

    if (touchDiff > swipeThreshold) {
      // Swipe right, go to the previous image
      handlePrevious();
    } else if (touchDiff < -swipeThreshold) {
      // Swipe left, go to the next image
      handleNext();
    }
  };

  useImperativeHandle(ref, () => ({
    handleNext,
    handlePrevious,
  }));

  return (
    <div
      className="bg-purplegray"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className="flex flex-row gap-2 overflow-hidden">
      {images
          .slice(startIndex, startIndex + visibleImages)
          .map((item, index) => (
            <div
              key={index}
              className={`w-full ${visibleImages === 8
                ? "xl:w-1/8 relative"
                : visibleImages === 6
                  ? "lg:w-1/6 relative"
                  : visibleImages === 4
                    ? "md:w-1/2 relative"
                    : "sm:w-1/2 relative"
                }`}
              onClick={() => {
                const _id = item._id;
                navigate(`/Modeldetails/${_id}`, { state: { item } });
              }}
            >
              <img
                src={item?.profilePhoto}
                alt={`Image ${index + startIndex + 1}`}
                className="w-full h-auto active:opacity-50 cursor-pointer"
                style={{ height: "450px", width: "100%" }}
              />
                <div className="absolute  bottom-0 right-0 text-white  lg:mr-2 md:mr-2 sm:mr-2 pb-2 font-semibold text-center lg:text-lg   md:text-lg sm:text-base  text-sm">
                  Age: {item?.listingDetails?.age}
                  <div className="">
                    <StarRating rating={item.rating} totalStars={5} inline />
                  </div>
                </div>
                <div className="absolute w-full bottom-0">
                  <div className="absolute text-white bottom-1 left-0 font-bold border-t w-full pb-2 border-whitepink border-opacity-20 lg:pl-3 md:pl-3 sm:pl-3">
                    <div className="lg:py-2 md:py-2 sm:py-2 lg:text-base md:text-base sm:text-base text-base">
                    {item?.listingDetails?.name}
                    </div>
                    <div className="relative  text-purple lg:text-xs md:text-xs sm:text-xs text-xs ">
                      <div style={{ letterSpacing: "4px" }}>
                      {item?.listingDetails?.location }
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          ))}
      </div>
    </div>
  );
});

export default Escortslider;
