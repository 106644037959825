import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ReportTOS() {
  useEffect(() => {
    handleScrollToTop();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();

  return (
    <div className="flex text-whitepink flex justify-center  gap-10 pt-20 pb-20">
      <div className="w-full md:w-[60%] ">
        <div className=" bg-footercolor  flex flex-col rounded-md">
          <div className="text-2xl pl-3 p-3" id="rules">
            Report TOS Violations
          </div>
          <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2  pb-10 rounded-md">
            <div className="mt-2">
              Be advised -- all content and information found on PleasuryList is
              created and provided to us by independent advertisers. As we make
              clear in our terms of service, individual advertisers are solely
              responsible for all text and images they use. We are unable to
              screen or validate all content and information posted. We rely on
              users to assist us by reporting TOS{" "}
              <b>when they are warranted.</b>
            </div>

            <div className="mt-4">
              If you believe any content or information portrays or exploits
              minors, supports the practice of human trafficking, or is
              otherwise illegal or abusive please redirect to the{" "}
              <span
                className="font-bold cursor-pointer active:opacity-40  pr-1 text-purple"
                onClick={() => navigate("/report-offense")}
              >
                report offense
              </span>
              page.
            </div>
            <div className="mt-4">
              If you believe false content or personal information has been
              posted (i.e. unauthorized use of name, likeness, email or phone
              number) in order to harass, deceive or embarrass -- please use the
              report ad button found on the page in question.
            </div>
            <div className="mt-4">
              If a user has posted content that you own or violates a copyright
              you hold, please report the violation{" "}
              <span
                className="font-bold cursor-pointer active:opacity-40 text-purple"
                onClick={() => navigate("/report-copyright")}
              >
                here.
              </span>
            </div>
            <div className="mt-2">
              If another website has used, duplicated, or posted your personal
              information or content without your permission, please report the
              violation according to that site's copyright policy and terms of
              service.
            </div>
            <div className="mt-2">
              If you would like to alert us of such unauthorized use, and we
              might be of some assistance in having it taken down -- please send
              an email to{" "}
              <span
                className="font-bold cursor-pointer active:opacity-40 text-purple"
                // onClick={() => navigate("/report-offense")}
              >
                support@pleasurylist.com
              </span>{" "}
              to tell us about the incident.
            </div>
            <div className="mt-2">
              Remember, the ads on PleasuryList are provided for entertainment and
              informational purposes only. We do not control, supervise, or
              endorse any of the content, information, or representations posted
              on our websites. We assume no responsibility or liability for any
              content. We will work to correct false information we are made
              aware of using the "report ad" function when you are able to
              provide us with information that shows a violation of our TOS has
              occurred.
            </div>
            <div className="mt-2">
              When we review violation reports; if we detect a pattern of
              repeated false or unwarranted reports in an effort to harass or
              annoy other users, we reserve the right to suspend your right to
              use PleasuryList.
            </div>
            <div className="mt-2">
              If you wish to contact the advertisers who advertise on our site,
              please use the contact information found in the individual ads --
              we cannot give you any additional contact information beyond what
              is printed in the ads.
            </div>
            <div className="mt-2">
              <b>
                All use of the content and all contact with the users is done
                voluntarily at your sole discretion and as such, you assume all
                risks in doing so
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
