import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import images from "../../services/images";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { useModal } from "../../screens/Layout";
import { baseURL, escortSignup, userSignup } from "../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { selectLogedIn } from "../../store/logedIn";
import { setUsereData } from "../../store/userData";
import { setAuthToken } from "../../store/token";

export default function CreateProfile({ onCloseModal }) {
  const [isAgreed, setIsAgreed] = useState(false);
  const [otherscreen, setotherscreen] = useState(false);

  const [ishovered, setishovered] = useState(false);
  const [changeprofilepic, setchangeprofilepic] = useState(null);
  const fileInputRef = useRef(null);
  const [privacySelected, setPrivacySelected] = useState(false);
  const [error, setError] = useState("validated");
  const [firstName, setfirstName] = useState();
  const [lastName, setlastName] = useState();
  const [about, setAbout] = useState();
  const [username, setUsername] = useState();
  const {
    isLoggedIn,
    setisLoggedIn,
    login,
    both,
    toggleboth,
    setTakeUsername,
    settakeabout,
    userType,
    UserEmail,
    password,
    escortloggedin,
    setDescription,
    userloggedin,
    saveUser,
    streamer,
  } = useAuth();

  console.log(userType);

  const { Modalcomponent, setmodalcomponent } = useModal();

  const handleEscortSignUp = async () => {
    try {
      const body = {
        email: UserEmail,
        password,
        profilePhoto: changeprofilepic,
        userType,
        firstname: firstName,
        lastname: lastName,
        username,
        about,
      };
      const response = await escortSignup(body);
      if (response.success) {
        setUsername("");
        setfirstName("");
        setlastName("");
        setAbout("");
        setError("validated");
        dispatch(setUsereData(response?.newEscort));
        dispatch(setAuthToken(response?.token));
        return true;
      } else {
        return false;
      }
    } catch (error) {}
  };

  const dispatch = useDispatch();
  const userLogin = useSelector(selectLogedIn);

  const handleUserSignup = async () => {
    try {
      const body = {
        email: UserEmail,
        password,
        profilePhoto: changeprofilepic,
        userType,
        firstname: firstName,
        lastname: lastName,
        username,
      };
      const response = await userSignup(body);
      if (response.success) {
        setUsername("");
        setfirstName("");
        setlastName("");
        setAbout("");
        dispatch(setUsereData(response?.newUser));
        dispatch(setAuthToken(response?.token));
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleUpload = async () => {
    const fileInput = fileInputRef.current;
    if (fileInput && fileInput.files.length > 0) {
      const formData = new FormData();
      formData.append("image", fileInput.files[0]);

      try {
        const response = await fetch(`${baseURL}auth/uploadProfile`, {
          method: "POST",
          body: formData,
          redirect: "follow",
        });

        const result = await response.text();
        const { url } = JSON.parse(result);

        setchangeprofilepic(url);
        console.log("image uploaded successfully  please, url:", url);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      console.log("No file selected");
    }
  };

  const validateForm = () => {
    // const { username, email, password, confirmPassword, privacySelected } = formData;

    // Check if any required fields are empty
    if (!about || !firstName || !lastName) {
      setError("Please fill out all required fields.");
      return false;
    }

    // Clear error if all validations pass
    setError("validated");
    // saveUser(firstName + lastName)

    handletakeuser();
    return true;
  };

  const opennewscreen = () => {
    setotherscreen(!otherscreen);
  };

  const handletakeuser = () => {
    // const fullname = firstName + lastName;
    setTakeUsername(username);
    settakeabout(about);
  };

  const toggleprofileimage = (e) => {
    const file = e.target.files[0];

    if (file) {
      handleUpload(file);
    }
  };

  const togglehover = () => {
    setishovered(!ishovered);
  };

  const handleAgreeToggle = () => {
    setIsAgreed(!isAgreed);
  };

  const HandleJoinnow = async () => {
    if (userLogin) {
      const success = await handleUserSignup();
      if (validateForm() && success) {
        onCloseModal();
      }
    } else {
      if (!userType) {
        setError("Select A Type");
        return;
      }

      const success = await handleEscortSignUp();
      if (validateForm() && success) {
        setmodalcomponent("Editlist");
      }
    }
  };

  return (
    <div className="lg:w-50p h-full biggerlaptop:p-4 lg:p-3  md:p-1 sm:p-2 p-1 w-full ">
      <div
        className="bg-cover bg-center h-full flex flex-col p-10 items-start justify-between"
        style={{
          backgroundImage: `url(${images.Bluredimg})`,
        }}
      >
        <div className="w-full flex ">
          <div className="w-50p flex justify-start flex   ">
            <img
              src={images.arrowwhiteleft}
              alt=""
              className="w-6 h-5 cursor-pointer"
              onClick={() => setmodalcomponent("signupOTP")}
            />
          </div>
          <div className="w-60p  flex justify-end" onClick={onCloseModal}>
            <img
              src={images.X}
              alt=""
              className="cursor-pointer md:h-7 sm:h-6 h-5"
            />
          </div>
        </div>
        <div className="flex w-full justify-between lg:flex-col md:flex-col md:flex-col sm:flex-col flex-col   ">
          <div className="lg:text-4xl md:text-2xl sm:text-2xl text-2xl text-white font-semibold text-nowrap opacity-90 ">
            Create Profile
          </div>
          <div className="flex lg:flex-row md:flex-row sm:flex-row flex-col items-center gap-5 pt-4">
            <label htmlFor="fileInput">
              <div
                className={`flex  items-center justify-center border-4 rounded-full  cursor-pointer   lg:h-28 lg:w-28 md:w-20 md:h-20 sm:w-20 sm:h-20 w-20 h-20 ${
                  ishovered ? "clicked" : ""
                }`}
                onMouseEnter={togglehover}
                onMouseLeave={togglehover}
              >
                <img
                  src={changeprofilepic || images.profpic4}
                  className="lg:w-20 lg:h-20 md:w-13 md:h-13 sm:w-13 h:h-13 w-13 h-13 cursor-pointer active:opacity-50 border rounded-full border-2"
                  alt="Profile Pic"
                />
                {ishovered && (
                  <div>
                    <div className="absolute inset-0 bg-purple-500 flex items-center justify-center">
                      <img
                        src={images.camera}
                        alt=""
                        className=" lg:w-10 md:w-7 sm:w-4 w-3"
                      />
                    </div>
                  </div>
                )}
              </div>
            </label>
            <label htmlFor="fileInput">
              <div className="text-whitepink font-bold border-b text-xl cursor-pointer active:opacity-50">
                Upload Photo
              </div>
            </label>
            <input
              type="file"
              id="fileInput"
              accept="image/"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={toggleprofileimage}
            />
          </div>
        </div>
        <input
          className=" text-white text-sm bg-transparent opacity-50 border-b-2 w-full focus:outline-none placeholder-white pl-1 "
          type="Email"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className=" text-white text-sm bg-transparent opacity-50 border-b-2 w-full focus:outline-none placeholder-white pl-1 "
          type="Email"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setfirstName(e.target.value)}
        />
        <input
          className="text-sm text-white  bg-transparent w-50p opacity-50 border-b-2 w-full focus:outline-none placeholder-white pl-1 mt-2"
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setlastName(e.target.value)}
        />
        <input
          className="text-sm text-white  bg-transparent w-50p opacity-50 border-b-2 w-full focus:outline-none placeholder-white pl-1 mt-2"
          type="text"
          placeholder="About"
          value={about}
          onChange={(e) => setAbout(e.target.value)}
        />
        <div
          className={
            error === "validated"
              ? "error cursor-pointer opacity-0"
              : "error cursor-pointer"
          }
        >
          {error}
        </div>
        <div
          className="bg-purple font-semibold text-lg text-white text-center py-4 w-full cursor-pointer mb-8 mt-2  active:opacity-50"
          onClick={HandleJoinnow}
        >
          Create
        </div>
      </div>
    </div>
  );
}
