import React, { useEffect } from "react";

export default function ReportOffense() {
  useEffect(() => {
    handleScrollToTop();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="flex text-whitepink flex justify-center  gap-10 pt-20 pb-20">
      <div className="w-full md:w-[60%] ">
        <div className=" bg-footercolor  flex flex-col rounded-md">
          <div className="text-2xl pl-3 p-3" id="rules">
            Report Offense
          </div>
          <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2  pb-10 rounded-md">
            <div className="mt-2">
              PleasuryList takes the well being and safety of our users very
              seriously. Please use the following steps to report criminal,
              abusive or harmful activities:
            </div>
            <div className="mt-2">
              If you suspect the sexual exploitation of minors and/or human
              trafficking PleasuryList urges you to report it immediately to the
              appropriate authorities.
            </div>
            <div className="mt-2">
              If you feel that a user of this site has -- or is going to -- use
              this service (directly or indirectly) to engage in illegal,
              abusive or harmful behavior,{" "}
              <b>
                please report the violation immediately to your local
                authorities
              </b>
              .
            </div>
            <div className="mt-2">
              The appropriate law enforcement agency is best equipped to assess
              the threat, resolve the issue, and prevent harm.
              <b>
                When you contact PleasuryList to report violations of law
                without first contacting the authorities, you will be referred
                to this page. PleasuryList cannot investigate and resolve
                disputes between users
              </b>
              .
              <div className="mt-4">
                Once you have filed a complaint, you may contact us at{" "}
                <span className="font-bold cursor-pointer active:opacity-40  text-purple">
                  support@pleasurylist.com
                </span>{" "}
                with the following details:
              </div>
              <div className="mt-4 ml-10">Summary of the incident</div>
              <div className="mt-3 ml-10">Your contact information</div>
              <div className="mt-3 ml-10">Case or incident number</div>
            </div>
            <div className="mt-2">
              By providing us this information we will be better prepared when
              contacted by the proper authorities. It is PleasuryList policy to
              cooperate fully when required by law to respond to search
              warrants, court orders or criminal investigations to 1) detect and
              address fraud and other illegal activity 2) protect ourselves, you
              and others, and 3) prevent death or imminent bodily harm.
            </div>
            <div className="mt-2">
              If the violation you wish to report is not of an imminent,
              dangerous or criminal nature please refer to our Report TOS
              Violation page.
            </div>
            <div className="mt-2">
              <b>
                If you think a serious threat exists or a law is being broken,
                your first step should always be to contact your local
                authorities.
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
