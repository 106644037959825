import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import userData, { selectUserData } from "../../store/userData";
import { faV } from "@fortawesome/free-solid-svg-icons";
import images from "../../services/images";
import { useNavigate } from "react-router-dom";
import { selectToken } from "../../store/token";
import { getAllFavorites } from "../../services/API";
import { ClipLoader } from "react-spinners";

export default function Favorites() {
  const [favorites, setFavorites] = useState([]);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const authToken = useSelector(selectToken);

  const userData = useSelector(selectUserData);

  useEffect(() => {
    getFavorites();
  }, []);

  const getFavorites = async () => {
    setLoader(true);
    try {
      let response = await getAllFavorites(authToken);
      setFavorites(response?.favorites);
      console.log(response);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  return (
    <div className="max-w-screen-xl mx-auto flex flex-col  items-start px-5 mt-10   ">
      <div className="flex items-center justify-center text-4xl text-white font-bold w-full pt-10 ">
        Favorite Ads
      </div>
      {loader ? (
        <div className="w-full flex items-center justify-center pt-20">
          <ClipLoader
            color="#BE42DE"
            cssOverride={{
              height: "60px",
              width: "60px",
            }}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center  w-full">
          {favorites.length > 0 ? (
            <div className="flex items-center w-full flex flex-col gap-3 pt-10 pb-20">
              {favorites?.map((ad, index) => (
                <div
                  key={index}
                  className={`bg-buttoncolor text-whitepink sm:px-4 gap-2 cursor-pointer
                    w-full md:w-[750px] active:opacity-50 py-12  items-center justify-center flex flex-col `}
                  onClick={() => {
                    const _id = ad?._id;
                    navigate(`/modeladd/${_id}`, {
                      state: { addData: ad },
                    });
                  }}
                >
                  <div className="flex w-full n">
                    <div className="flex gap-2  max-w-[250px] md:max-w-[400px] overflow-auto flex-wrap sm:flex-nowrap">
                      {ad?.pictures &&
                        ad?.pictures
                          ?.map((pic, index) => (
                            <img
                              key={index}
                              src={pic}
                              alt=""
                              className="w-10 h-10  md:w-20 md:h-20"
                            />
                          ))
                          .reverse()}
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="w-full gap-2  flex flex-col">
                      <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row w-full justify-between  font-semibold  text-xxs sm:text-xl ">
                        <div>
                          {ad?.escortData?.listingDetails?.age}- {ad?.name} -{" "}
                          {ad?.ethnic_origin}- {ad?.ethnicity} - {ad?.city}
                        </div>
                        <div className=" gap-2 flex flex-col">
                          <div className="font-semibold text-xs sm:text-base rounded-2xl  h-6 sm:h-9  bg-whitepink flex items-center  w-28 gap-2 text-textdarkerpurpel ">
                            <img
                              src={images.verified1}
                              className="h-4 w-4 sm:h-6 sm:w-6 ml-2"
                            />
                            Verified
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-whitepink flex  text-1xl pt-10 text-center ">
              You have no favorite ads at the moment.
            </div>
          )}
        </div>
      )}
    </div>
  );
}
