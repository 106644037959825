import React, { useState, useEffect, useRef } from "react";
import images from "../../services/images";
import { Country, State, City } from "country-state-city";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faSleigh,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  baseURL,
  createAdvertisement,
  getBookedSlot,
  getUserDetails,
  getVerifiedStatus,
  handleUpdatePayment,
  handleUploadPictures,
  handleVideosUpload,
  setPaymentDetailsForVerify,
  setPaymentDetailsForVerifyDec,
} from "../../services/API";
import { useDispatch, useSelector } from "react-redux";
import token, { selectToken } from "../../store/token";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { ClipLoader, FadeLoader, ScaleLoader } from "react-spinners";
import { upload } from "@testing-library/user-event/dist/upload";
import FormCarousel from "../../components/FormCarousel";
import AvatarEditor from "react-avatar-editor";
import AdCard from "../../components/adCard";
import axios from "axios";
import {
  selectUserData,
  setUsereData,
  updateUserVerified,
} from "../../store/userData";

export default function Forms() {
  const [countries, setCountries] = useState([]);
  const token = useSelector(selectToken);
  const userData = useSelector(selectUserData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [states, setStates] = useState([]);
  const [chosenCategory, setChosenCategory] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(""); // Track selected country
  const [selectedstate, setSelectedstate] = useState(null);
  const [selectedcity, setSelectedcity] = useState(null);
  const [changepanel, setchangepanel] = useState("categories");
  const [select, setselect] = useState(false);
  const [select2, setselect2] = useState(false);
  const [allowWahtsapp, setAllowhatsApp] = useState(false);
  const [inCallagree, setIncallAgree] = useState(false);
  const [outCall, setisOutcall] = useState(false);
  const [onlineagree, setIsonlineAgree] = useState(false);
  const [contactForm, setContactform] = useState(false);
  const [recentAds, setRecentads] = useState(false);
  const [Reviewsbtn, setReviewsbtn] = useState(false);
  const [hourlyRate, setHourlyRate] = useState("");
  const [city, setCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [languagesSpoken, setLanguagesSpoken] = useState("");
  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredcities, setFilteredcities] = useState([]);
  const [selectedimg, setselectedimg] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [stateCities, setStateCities] = useState([]);
  const [heightFeet, setHeightFeet] = useState("ft");
  const [heightInches, setHeightInches] = useState("in");
  const [weight, setWeight] = useState("lb");
  const [cupSize, setCupSize] = useState("...");
  const [chest, setChest] = useState("...");
  const [waist, setWaist] = useState("...");
  const [hips, setHips] = useState("...");
  const [hairColor, setHairColor] = useState("...");
  const [eyeColor, setEyeColor] = useState("...");
  const [adTitle, setAdTitle] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [ethnicOrigin, setEthnicOrigin] = useState("");
  const [videoShows, setVideoShows] = useState(false);
  const [phoneSex, setPhoneSex] = useState(false);
  const [vidsForSale, setVidsForSale] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pageOne, setPageone] = useState(false);
  const [morning, setMorning] = useState(false);
  const [afternoon, setAfternoon] = useState(false);
  const [evening, setEvening] = useState(false);
  const [lateNight, setLateNight] = useState(false);
  const [adPremiume, showPremiumeAd] = useState(false);
  const [bumps, chooseBumps] = useState("20bumps");
  const [showBumps, setShowbumps] = useState(false);
  const [bumpYes, setBumpyes] = useState(false);
  const [boost, hideBoosts] = useState("boost");
  const [availbeNowTheme, showAvaialblenowtheme] = useState(false);
  const [availbleHour, selectAvailableHour] = useState("two");
  const [textAreadata, setTextareadata] = useState("");
  const [emojiAdd, selectEmojiadd] = useState("7Days");
  const [carouselAdhour, setCarouseladHour] = useState("six");
  const [emojiShow, setEmojiShow] = useState(false);
  const [carouselAd, openCarouselAd] = useState(false);
  const [featuredAd, showFeaturedAd] = useState(false);
  const [featuredAdHour, selectedFeaturedAdhour] = useState("three");
  const [editPic, setEditpic] = useState(false);
  const [editedImage, setEditedImage] = useState(null);
  const [selectImg, setSelectedimg] = useState(null);
  const [openModal, setOpenmodal] = useState(false);
  const [time, setTime] = useState("");
  const [adAdded, setAdAdded] = useState(false); // Initialize flag
  const [selectHour, setSelecthour] = useState("five");
  const [premiumAdHour, setPremiumAdHour] = useState("");
  const [hourData, setHourdata] = useState({
    key1: { add: "Wild West Ad:3 hours", cost: 30 },
    key2: { add: "Wild West Ad:5 hours", cost: 25 },
    key3: { add: "Wild West Ad:7 hours", cost: 60 },
  });
  const [cartData, setCartdata] = useState([
    {
      title: "Advertisement",
      add: "Activsadasities/Calgary/South",
      cost: "FREE",
    },
  ]);
  const [pageTwodata, setPageTwodata] = useState(9.786);
  const [currentTime, setCurrentTime] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [pageThreedata, setPageThreedata] = useState(14.679);
  const [morningData, setMorningdata] = useState([]);
  const [afterNoondata, setAfternoonData] = useState([]);
  const [eveningData, setEveningData] = useState([]);
  const [lateNightdata, setLatenightData] = useState([]);
  const [pageOnedata, setPageoneData] = useState(4.893);
  const [emojiImages, setEmojiImages] = useState([
    { emoji: "🍓" },
    { emoji: "💓" },
    { emoji: "🌟" },
    { emoji: "🍑" },
    { emoji: "💋" },
    { emoji: "😘" },
    { emoji: "❣️" },
    { emoji: "💦" },
    { emoji: "❄️" },
    { emoji: "⚠️" },
    { emoji: "⭕" },
    { emoji: "❌" },
    { emoji: "✅" },
    { emoji: "🍆" },
    { emoji: "📱" },
    { emoji: "✈️" },
    { emoji: "👠" },
    { emoji: "🥂" },
    { emoji: "👅" },
  ]);
  const [scale, setScale] = useState(1);
  const [editor, setEditor] = useState(null);
  const [sliderData, setSliderdata] = useState([
    {
      imgData: images.yellowbandi,
    },
    {
      imgData: images.Liveimgmodel1,
    },
    {
      imgData: images.yellowbandi,
    },
    {
      imgData: images.Liveimgmodel3,
    },
    {
      imgData: images.Liveimgmodel1,
    },
    {
      imgData: images.Liveimgmodel4,
    },
    {
      imgData: images.Liveimgmodel6,
    },
  ]);
  const [payment1, setpayment1] = useState([
    {
      title: "No Upgrades chosen",
      description: "Choose an upgrade",
      cost: 0,
      title2: "",
      description2: "",
      cost2: "",
    },
  ]);
  const [addextra, setaddextra] = useState([
    {
      title2: "Bumps/ Auto Repost",
      description2: "Instantly push your ad to the top with a click!",
      cost2: 6.09,
    },
  ]);
  const [databigger, setdatabigger] = useState(false);
  const [uploadimg, setuploadedimg] = useState([]);
  const [numberOfImagesAdded, setNumberOfImagesAdded] = useState(0); // State to track number of images added
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mergedPayment, setMergedPayment] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [fiveHoursSlotMorning, setFiveHoursSlotMorning] = useState([]);
  const [fiveHoursSlotEvening, setFiveHoursSlotEvening] = useState([]);
  const [fiveHoursSlotAfternoon, setFiveHoursSlotAfternoon] = useState([]);
  const [fiveHoursSlotLateNight, setFiveHoursSlotLateNight] = useState([]);
  const [sevenHoursSlotMorning, setSevenHoursSlotMorning] = useState([]);
  const [sevenHoursSlotEvening, setSevenHoursSlotEvening] = useState([]);
  const [sevenHoursSlotAfternoon, setSevenHoursSlotAfternoon] = useState([]);
  const [sevenHoursSlotLateNight, setSevenHoursSlotLateNight] = useState([]);
  const [error, setError] = useState("validated");
  const [upgrades, setUpgrades] = useState(null);
  const [selectedPageOneSlot, setSelectedPageOneSlot] = useState(null);
  const [selectPageTwoSLot, setSelectPageTwoSLot] = useState(null);
  const [coditionalModal, setOpenCondtionalModal] = useState(false);
  const [theam, setTheam] = useState(null);
  const [totalCost, setTotalCost] = useState(0.0);
  const [conditionModalTitle, setConditionModalTitle] = useState("");
  const [conditionModalSubject, setConditionModalSubject] = useState("");
  const [conditionalModalBtnText, setConditionalModalBtnText] = useState("");
  const [themeThreeHoursCost, setThemeThreeHoursCost] = useState(6.853);
  const [themeFiveHoursCost, setThemeFiveHoursCost] = useState(13.825);
  const [themeSevenHoursCost, setThemeSevenHoursCost] = useState(20.552);
  const [selectedCarousel, setSelectedCarousel] = useState(null);
  const [carouselThreeHoursCost, setCarouselThreeHoursCost] = useState(1.449);
  const [carouselSixHoursCost, setCarouselSixHoursCost] = useState(2.898);
  const [carouselNineHoursCost, setCarouselNineHoursCost] = useState(4.347);
  const [selectedAvailableNow, setSelectedAvailableNow] = useState(null);
  const [availableNowOneHourCost, setAvailableNowOneHourCost] = useState(0.553);
  const [availableNowTwoHourCost, setAvailableNowTwoHourCost] = useState(1.106);
  const [availableNowThreeHourCost, setAvailableNowThreeHourCost] =
    useState(1.659);
  const [availableNowFourHourCost, setAvailableNowFourHourCost] =
    useState(2.212);
  const [selectedFeaturedAd, setSelectedFeaturedAd] = useState(null);
  const [featuredAddOneHourCost, setFeaturedAddOneHourCost] = useState(2.09);
  const [featuredAddThreeHourCost, setFeaturedAddThreeHourCost] =
    useState(2.73);
  const [featuredAddSevenHourCost, setFeaturedAddSevenHourCost] =
    useState(7.12);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [emoji24HoursCost, setEmoji24HoursCost] = useState(1.11);
  const [emoji7DaysCost, setEmoji7DaysCost] = useState(6.64);
  const [emoji30DaysCost, setEmoji30DaysCost] = useState(27.71);
  const [paymentModal, setPaymentModal] = useState(false);
  const [immediatelyPageOneThreeHours, setImmediatelyPageOneThreeHours] =
    useState(null);
  const [immediatelyPageOneFiveHours, setImmediatelyPageOneFiveHours] =
    useState(null);
  const [immediatelyPageOneSevenHours, setImmediatelyPageOneSevenHours] =
    useState(null);
  const [dropDownDescription, setDropDownDescription] = useState("");
  const [heightFtData, setHeightFtData] = useState([
    "ft",
    "3",
    "4",
    "5",
    "6",
    "7",
  ]);
  const [heightInchData, setHeightInchData] = useState([
    "in",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ]);
  const [weightData, setWeightData] = useState([
    "lbs",
    ...Array.from({ length: 280 }, (_, i) => String(44 + i)),
  ]);
  const [cupSizeData, setCupSizeData] = useState([
    "...",
    "AA",
    "A",
    "B",
    "C",
    "D",
    "DD+",
    "DDD (E)",
    "F",
  ]);
  const [chestData, setChestData] = useState([
    "...",
    ...Array.from({ length: 53 }, (_, i) => String(27 + i)),
  ]);
  const [waistData, setWaistData] = useState([
    "...",
    ...Array.from({ length: 61 }, (_, i) => String(19 + i)),
  ]);
  const [hipsData, setHipsData] = useState([
    "...",
    ...Array.from({ length: 70 }, (_, i) => String(31 + i)),
  ]);
  const [hairData, setHairData] = useState([
    "...",
    "Black",
    "Brown",
    "Blond",
    "Auburn",
    "Chestnut",
    "Red",
    "Gray",
    "Other",
  ]);
  const [eyeColorData, setEyeColorData] = useState([
    "...",
    "Brown",
    "Hazel",
    "Blue",
    "Green",
    "Silver",
    "Amber",
    "Other",
  ]);

  const [selectedEthnicOrigin, setSelectedEthnicOrigin] = useState([]);
  // const [limitModal, setLimitModal] = useState("");
  const [limitModal, setLimitModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [languageLimit, setLanguageLimit] = useState(false);
  const [search, setSearch] = useState("");
  const [ethnicOriginData, setEthnicOriginData] = useState([
    "Canadian",
    "American",
    "French",
    "Chinese",
    "Afghan",
    "Albanian",
    "Algerian",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "Dutchman",
    "Dutchwoman",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivan",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "Netherlander",
    "New Zealander",
    "Ni-Vanuatu",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
  ]);
  const [ethnicityData, setEthnicityData] = useState([
    "Asian",
    "Black",
    "Canadian Born Chinese",
    "Caucasian/White",
    "Indo Canadian",
    "Latino/Hispanic",
    "Middle Eastern",
    "Mixed",
    "Native",
  ]);
  const [verfiedClicked, setVerfiedClicked] = useState(false);
  const [languageData, setLanguageData] = useState([
    "English",
    "Chinese",
    "Spanish",
    "French",
    "Portuguese",
    "Russian",
    "Indonesian",
    "Arabic",
    "Bulgarian",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "Finnish",
    "Greek",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Italian",
    "Japanese",
    "Korean",
    "Latvian",
    "Lithuanian",
    "Norwegian",
    "Polish",
    "Romanian",
    "Serbian",
    "Slovak",
    "Slovenian",
    "Swedish",
    "Tagalog",
    "Thai",
    "Turkish",
    "Ukrainian",
    "Vietnamese",
  ]);
  const [firstBumpsCost, setFirstBumpsCost] = useState(4.893);
  const [secondBumpsCost, setSecondBumpsCost] = useState(4.683);
  const [thirdBumpsCost, setThirdBumpsCost] = useState(4.26);

  const [video, setVideo] = useState([]);
  const [videoLoader, setVideoLoader] = useState(false);
  const [uploadImgLoader, setUploadImgLoader] = useState(false);
  const [selectedBumps, setSelectedBumps] = useState(null);
  const [scheduleBumps, setScheduleBumps] = useState(false);
  // const [selectedScheduleBumps, setSelectedScheduleBumps] = useState(null)
  const [bumpsDayOption, setBumpsDayOption] = useState([
    { day: 1, selected: true, enable: true },
    { day: 3, selected: true, enable: true },
    { day: 7, selected: true, enable: true },
    { day: 14, selected: true, enable: true },
    { day: 30, selected: true, enable: true },
  ]);
  const [showBumpsAddTime, setShowBumpsAddTime] = useState(null);
  const [bumpsTab, setBumpsTab] = useState([
    { qty: 5, cost: 4.89, selected: false, enable: true },
    { qty: 10, cost: 4.89, selected: false, enable: true },
    { qty: 20, cost: 4.68, selected: false, enable: true },
    { qty: 50, cost: 4.26, selected: false, enable: true },
  ]);
  const [bumpsTimeDropdown, setBumpsTimeDropdown] = useState(null);
  const [hoursForBumps, setHoursForBumps] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);
  const [minutesForBumps, setMinutesForBumps] = useState([
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
  ]);
  const [adDescription, setAdDescription] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (uploadimg?.length > 0) {
      setSelectedimg(uploadimg[0]);
    }
  }, [uploadimg]);

  useEffect(() => {
    handleScrollToTop();
  }, [changepanel, boost]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSetTitle = (e) => {
    setAdTitle(e.target.value);
    setTextareadata(e.target.value);
  };

  const handleAddEmoji = async (hour, cost) => {
    setEmojiShow(true);
    if (selectedEmoji) {
      setTotalCost((prevCost) => prevCost - selectedEmoji?.cost);
      selectEmojiadd(hour);
      setEmojiShow(true);
      const dates =
        hour === "24"
          ? await getCurrentFormattedDate(24)
          : hour === "7Days"
          ? await getCurrentFormattedDate(168)
          : await getCurrentFormattedDate(720);
      const obj = {
        hour,
        cost,
        emoji: "",
      };
      const selected = { ...dates, ...obj };
      setTotalCost((prevCost) => prevCost + cost);
      setSelectedEmoji(selected);
    } else {
      selectEmojiadd(hour);
      setEmojiShow(true);
      const dates =
        hour === "24"
          ? await getCurrentFormattedDate(24)
          : hour === "7Days"
          ? await getCurrentFormattedDate(168)
          : await getCurrentFormattedDate(720);
      const obj = {
        hour,
        cost,
        emoji: "",
      };
      const selected = { ...dates, ...obj };
      setSelectedEmoji(selected);
      setTotalCost((prevCost) => prevCost + cost);
    }
  };

  const handleRemoveEmoji = () => {
    if (selectedEmoji) {
      setTotalCost((prevCost) => prevCost - selectedEmoji?.cost);
      setSelectedEmoji(null);
      setEmojiShow(false);
      setTextareadata(adTitle);
    }
  };

  const handleRemoveFeaturedAd = () => {
    if (selectedFeaturedAd) {
      setTotalCost((prevCost) => prevCost - selectedFeaturedAd?.cost);
      setSelectedFeaturedAd(null);
      showFeaturedAd(false);
      setOpenmodal(false);
    }
  };
  const handleAddFeaturedAdd = async (hour, cost) => {
    showFeaturedAd(true);
    if (selectedFeaturedAd) {
      setTotalCost((prevCost) => prevCost - selectedFeaturedAd?.cost);
      selectedFeaturedAdhour(hour);
      showFeaturedAd(true);
      const dates =
        hour === "three"
          ? await getCurrentFormattedDate(3)
          : hour === "seven"
          ? await getCurrentFormattedDate(7)
          : await getCurrentFormattedDate(1);
      const obj = {
        hour,
        cost,
      };
      const selected = { ...dates, ...obj };
      setTotalCost((prevCost) => prevCost + cost);
      setSelectedFeaturedAd(selected);
    } else {
      selectedFeaturedAdhour(hour);
      showFeaturedAd(true);
      const dates =
        hour === "three"
          ? await getCurrentFormattedDate(3)
          : hour === "seven"
          ? await getCurrentFormattedDate(7)
          : await getCurrentFormattedDate(1);
      const obj = {
        hour,
        cost,
      };
      const selected = { ...dates, ...obj };
      setSelectedFeaturedAd(selected);
      setTotalCost((prevCost) => prevCost + cost);
    }
  };

  const handleAddAvailableNow = async (hour, cost) => {
    console.log(hour);
    showAvaialblenowtheme(true);
    if (selectedAvailableNow) {
      setTotalCost((prevCost) => prevCost - selectedAvailableNow?.cost);
      selectAvailableHour(hour);
      showAvaialblenowtheme(true);
      const dates =
        hour === "two"
          ? await getCurrentFormattedDate(2)
          : hour === "one"
          ? await getCurrentFormattedDate(1)
          : hour === "three"
          ? await getCurrentFormattedDate(3)
          : await getCurrentFormattedDate(4);
      const obj = {
        hour,
        cost,
      };
      const selected = { ...dates, ...obj };
      setTotalCost((prevCost) => prevCost + cost);
      setSelectedAvailableNow(selected);
    } else {
      selectAvailableHour(hour);
      showAvaialblenowtheme(true);
      const dates =
        hour === "two"
          ? await getCurrentFormattedDate(2)
          : hour === "one"
          ? await getCurrentFormattedDate(1)
          : hour === "three"
          ? await getCurrentFormattedDate(3)
          : await getCurrentFormattedDate(4);
      const obj = {
        hour,
        cost,
      };
      const selected = { ...dates, ...obj };
      setSelectedAvailableNow(selected);
      setTotalCost((prevCost) => prevCost + cost);
    }
  };

  const handleRemoveAvailableNow = () => {
    if (selectedAvailableNow) {
      setTotalCost((prevCost) => prevCost - selectedAvailableNow?.cost);
      setSelectedAvailableNow(null);
      showAvaialblenowtheme(false);
    }
  };

  const handleRemoveCerousel = () => {
    if (selectedCarousel) {
      setTotalCost((prevCost) => prevCost - selectedCarousel?.cost);
      setSelectedCarousel(null);
      openCarouselAd(false);
    }
  };

  const handleAddCarouselAd = async (hour, cost) => {
    if (selectedCarousel) {
      setTotalCost((prevCost) => prevCost - selectedCarousel?.cost);
      setCarouseladHour(hour);
      openCarouselAd(true);
      const dates =
        hour === "six"
          ? await getCurrentFormattedDate(6)
          : hour === "three"
          ? await getCurrentFormattedDate(3)
          : await getCurrentFormattedDate(9);
      const obj = {
        hour,
        cost,
      };
      const selected = { ...dates, ...obj };
      setTotalCost((prevCost) => prevCost + cost);
      setSelectedCarousel(selected);
    } else {
      setCarouseladHour(hour);
      openCarouselAd(true);
      const dates =
        hour === "six"
          ? await getCurrentFormattedDate(6)
          : hour === "three"
          ? await getCurrentFormattedDate(3)
          : await getCurrentFormattedDate(9);
      const obj = {
        hour,
        cost,
      };
      const selected = { ...dates, ...obj };
      setSelectedCarousel(selected);
      setTotalCost((prevCost) => prevCost + cost);
    }
  };

  const handleRemovePageOne = () => {
    setPageone(false);
    setSelectedPageOneSlot(null);
    setOpenCondtionalModal(false);
    setTotalCost((prevTotalCost) => prevTotalCost - selectedPageOneSlot?.cost);
    setTime("");
  };

  const closeModal = () => {
    if (selectedPageOneSlot) {
      setTotalCost(
        (prevTotalCost) => prevTotalCost - selectedPageOneSlot?.cost
      );
      setSelectedPageOneSlot(null);
      handleAddThemeWithoutCondition(themeFiveHoursCost, "five");
      setOpenCondtionalModal(false);
      setPageone(false);
    } else {
      setTotalCost((prevCost) => prevCost - selectPageTwoSLot?.cost);
      setSelectPageTwoSLot(null);
      showPremiumeAd(false);
      setOpenCondtionalModal(false);
      setPageone(true);
      setTime("");
    }
  };

  const closeThemeAd = () => {
    if (selectPageTwoSLot) {
      setTotalCost((prevCost) => prevCost - selectPageTwoSLot?.cost);
      setSelectPageTwoSLot(null);
      showPremiumeAd(false);
    }
  };

  const handleEditPageOne = () => {
    setSelectedPageOneSlot(null);
    setTotalCost((prevTotalCost) => prevTotalCost - selectedPageOneSlot?.cost);
    setTime("");
  };

  const handleSetPageOne = (selectedSlot) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toDateString();
    selectedSlot.date = formattedDate;
    if (selectHour === "three") {
      selectedSlot.hour = selectHour;
      selectedSlot.cost = pageOnedata;
      setSelectedPageOneSlot(selectedSlot);
      setTotalCost(totalCost + pageOnedata);
    } else if (selectHour === "five") {
      selectedSlot.cost = pageTwodata;
      selectedSlot.hour = selectHour;
      setSelectedPageOneSlot(selectedSlot);
      setTotalCost(totalCost + pageTwodata);
    } else {
      selectedSlot.hour = selectHour;
      selectedSlot.cost = pageThreedata;
      setSelectedPageOneSlot(selectedSlot);
      setTotalCost(totalCost + pageThreedata);
    }
  };

  const bumpsCost = async (selectedTabs) => {
    if (selectedTabs === "5bumps") {
      const cost = firstBumpsCost * 5;
      if (selectedBumps) {
        setTotalCost((prevTotalCost) => prevTotalCost - selectedBumps?.cost);
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        return cost.toFixed(2);
      } else {
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        return cost.toFixed(2);
      }
    } else if (selectedTabs === "10bumps") {
      const cost = firstBumpsCost * 10;
      if (selectedBumps) {
        setTotalCost((prevTotalCost) => prevTotalCost - selectedBumps?.cost);
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        return cost.toFixed(2);
      } else {
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        return cost.toFixed(2);
      }
    } else if (selectedTabs === "20bumps") {
      const cost = secondBumpsCost * 20;
      if (selectedBumps) {
        setTotalCost((prevTotalCost) => prevTotalCost - selectedBumps?.cost);
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        return cost.toFixed(2);
      } else {
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        return cost.toFixed(2);
      }
    } else {
      const cost = thirdBumpsCost * 50;
      if (selectedBumps) {
        setTotalCost((prevTotalCost) => prevTotalCost - selectedBumps?.cost);
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        return cost.toFixed(2);
      } else {
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        return cost.toFixed(2);
      }
    }
  };
  const getToalBumps = async (selectedTabs) => {
    if (selectedTabs === "5bumps") {
      return 5;
    }
    if (selectedTabs === "10bumps") {
      return 10;
    }
    if (selectedTabs === "20bumps") {
      return 20;
    }
    if (selectedTabs === "50bumps") {
      return 50;
    }
  };
  const handleAddBumps = async (index) => {
    if (selectedBumps) {
      handleCalculateDaysSecond(selectedBumps?.times, bumpsTab[index]?.qty);
      setShowbumps(true);
      setBumpsTab((prevState) => {
        return prevState.map((item, i) => ({
          ...item,
          selected: i === index ? true : false,
        }));
      });
      const cost = bumpsTab[index].cost * bumpsTab[index].qty.toFixed(2);
      const totalBumps = bumpsTab[index].qty;
      setTotalCost((prevTotalCost) => prevTotalCost - selectedBumps?.cost);
      setTotalCost((prevTotalCost) => prevTotalCost + cost);
      setSelectedBumps((prevState) => {
        return {
          ...prevState,
          cost,
          totalBumps,
          remainingBumps: totalBumps,
        };
      });
    } else {
      setShowbumps(true);
      setBumpsTab((prevState) => {
        return prevState.map((item, i) => ({
          ...item,
          selected: i === index ? true : false,
        }));
      });
      const cost = bumpsTab[index].cost * bumpsTab[index].qty.toFixed(2);
      const totalBumps = bumpsTab[index].qty;
      setTotalCost((prevTotalCost) => prevTotalCost + cost);
      const dataaa = {
        cost,
        totalBumps,
        selectedScheduleBumps: null,
        remainingBumps: totalBumps,
        times: [],
      };
      setSelectedBumps(dataaa);
    }
  };

  const handleRemoveBumps = async () => {
    handleRemoveScheduleBumps();
    setTotalCost((prevTotalCost) => prevTotalCost - selectedBumps?.cost);
    setSelectedBumps(null);
    setShowbumps(false);
    setScheduleBumps(false);
  };

  const handleAddThemeWithoutCondition = (cost, time) => {
    setPremiumAdHour(time);
    showPremiumeAd(true);
    const currentDate = new Date();
    const formattedDate = currentDate.toDateString();
    const selectedTheme = { cost, hour: time, date: formattedDate };
    if (selectPageTwoSLot) {
      setTotalCost((prevTotalCost) => prevTotalCost - selectPageTwoSLot?.cost);
      setTotalCost((prevTotalCost) => prevTotalCost + cost);
      setSelectPageTwoSLot(selectedTheme);
    } else {
      setTotalCost((prevTotalCost) => prevTotalCost + cost);
      setSelectPageTwoSLot(selectedTheme);
    }
  };

  
  const getCurrentFormattedDate = async (hoursToAdd) => {
    const currentDate = new Date();

    // Get the start date and time
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const currentDay = String(currentDate.getDate()).padStart(2, "0");
    const currentYear = currentDate.getFullYear();
    const currentHours = currentDate.getHours();
    const currentMinutes = String(currentDate.getMinutes()).padStart(2, "0");
    const ampmStart = currentHours >= 12 ? "PM" : "AM";
    const startHours = String(
      currentHours % 12 === 0 ? 12 : currentHours % 12
    ).padStart(2, "0"); // Convert to 12-hour format
    const startTime = `${startHours}:${currentMinutes} ${ampmStart}`;
    const currentFormattedDate = `${currentMonth}/${currentDay}/${currentYear}`;

    // Get the end date and time
    const futureDate = new Date(
      currentDate.getTime() + hoursToAdd * 60 * 60 * 1000
    );
    const futureMonth = String(futureDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const futureDay = String(futureDate.getDate()).padStart(2, "0");
    const futureYear = futureDate.getFullYear();
    const futureHours = futureDate.getHours();
    const futureMinutes = String(futureDate.getMinutes()).padStart(2, "0");
    const ampmEnd = futureHours >= 12 ? "PM" : "AM";
    const endHours = String(
      futureHours % 12 === 0 ? 12 : futureHours % 12
    ).padStart(2, "0"); // Convert to 12-hour format
    const endTime = `${endHours}:${futureMinutes} ${ampmEnd}`;
    const futureFormattedDate = `${futureMonth}/${futureDay}/${futureYear}`;

    return {
      startDate: currentFormattedDate,
      startTime: startTime,
      endDate: futureFormattedDate,
      endTime: endTime,
    };
  };

  const getCurrentFormattedDateWithDays = async (daysToAdd) => {
    const currentDate = new Date();

    // Get the start date and time
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const currentDay = String(currentDate.getDate()).padStart(2, "0");
    const currentYear = currentDate.getFullYear();
    const currentHours = currentDate.getHours();
    const currentMinutes = String(currentDate.getMinutes()).padStart(2, "0");
    const ampmStart = currentHours >= 12 ? "PM" : "AM";
    const startHours = String(
      currentHours % 12 === 0 ? 12 : currentHours % 12
    ).padStart(2, "0"); // Convert to 12-hour format
    const startTime = `${startHours}:${currentMinutes} ${ampmStart}`;
    const currentFormattedDate = `${currentMonth}/${currentDay}/${currentYear}`;

    // Get the end date and time
    const futureDate = new Date(
      currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000
    );
    const futureMonth = String(futureDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const futureDay = String(futureDate.getDate()).padStart(2, "0");
    const futureYear = futureDate.getFullYear();
    const futureHours = futureDate.getHours();
    const futureMinutes = String(futureDate.getMinutes()).padStart(2, "0");
    const ampmEnd = futureHours >= 12 ? "PM" : "AM";
    const endHours = String(
      futureHours % 12 === 0 ? 12 : futureHours % 12
    ).padStart(2, "0"); // Convert to 12-hour format
    const endTime = `${endHours}:${futureMinutes} ${ampmEnd}`;
    const futureFormattedDate = `${futureMonth}/${futureDay}/${futureYear}`;

    return {
      startDate: currentFormattedDate,
      startTime: startTime,
      endDate: futureFormattedDate,
      endTime: endTime,
    };
  };

  const handleAddTheme = async (cost, time) => {
    console.log(time);

    setPageone(false);
    if (selectedPageOneSlot) {
      setConditionModalTitle(
        "You can’t have Love Ad and Page1 at the same time"
      );
      setConditionModalSubject(
        "If you add Love Ad, it will remove your Page1 upgrade.Are you sure you want to add the Love Ad?"
      );
      setConditionalModalBtnText("Yes, Add Love Ad anyway");
      setOpenCondtionalModal(true);
    } else {
      setPremiumAdHour(time);
      showPremiumeAd(true);
      const dates =
        time === "five"
          ? await getCurrentFormattedDate(5)
          : time === "three"
          ? await getCurrentFormattedDate(3)
          : await getCurrentFormattedDate(7);
      const currentDate = new Date();
      const formattedDate = currentDate.toDateString();
      const obj = { cost, hour: time, date: formattedDate };
      const selectedTheme = { ...obj, ...dates };
      if (selectPageTwoSLot) {
        setTotalCost(
          (prevTotalCost) => prevTotalCost - selectPageTwoSLot?.cost
        );
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        setSelectPageTwoSLot(selectedTheme);
      } else {
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        setSelectPageTwoSLot(selectedTheme);
      }
    }
  };

  useEffect(() => {
    handleGetBookedSlot();
  }, []);

  const createTodayAndTomowworDate = () => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const todayMonth = now.getMonth() + 1;
    const todayDay = now.getDate();
    const todayYear = now.getFullYear();
    const todayDate = `${todayMonth}/${todayDay}/${todayYear}`;

    const tomorrowMonth = tomorrow.getMonth() + 1;
    const tomorrowDay = tomorrow.getDate();
    const tomorrowYear = tomorrow.getFullYear();
    const tomorrowDate = `${tomorrowMonth}/${tomorrowDay}/${tomorrowYear}`;
    return {
      todayDate,
      tomorrowDate,
    };
  };

  const handleGetBookedSlot = async () => {
    try {
      const date = createTodayAndTomowworDate();
      const response = await getBookedSlot(date, token);
      if (response.success) {
        getCurrentTime(response.bookedSlot);
      } else {
        getCurrentTime(response.bookedSlot);
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentTime = (bookedSlot) => {
    const now = new Date();
    const minutes = now.getMinutes();
    const date = now.getDate();
    let roundedMinutes;
    if (minutes < 30) {
      roundedMinutes = 30;
    } else {
      roundedMinutes = 0;
      now.setHours(now.getHours() + 1);
    }
    now.setMinutes(roundedMinutes);
    const options = {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const formattedTime = now.toLocaleTimeString("en-US", options);
    createThreeHoursTimeSlot(formattedTime, bookedSlot);
    createFiveHoursTimeSlot(formattedTime, bookedSlot);
    createSevenHoursTimeSlot(formattedTime, bookedSlot);
  };

  const createThreeHoursTimeSlot = async (timeString, bookedSlot) => {
    // Parse the time string into a Date object
    const now = new Date();
    console.log(now)
    const [hours, minutes] = timeString.split(":")?.map((str) => parseInt(str));
    const isPM = timeString.includes("PM");
    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours + (isPM && hours !== 12 ? 12 : 0), // Adjusting hours for PM
      minutes
    );
    // Initialize an array to store the time slots
    let timeSlots = [];
    // Define the duration of each time slot in milliseconds (3 hours)
    const slotDuration = 3 * 60 * 60 * 1000;
    // Define the gap between each time slot in milliseconds (30 minutes)
    const gap = 30 * 60 * 1000;
    // Calculate the start time of the first time slot
    let slotStartTime = today;
    // Calculate the end time of the last time slot (24 hours from now)
    const endTime = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    // Iterate until we reach the end time
    while (slotStartTime < endTime) {
      // Calculate the end time of the current time slot
      const slotEndTime = new Date(slotStartTime.getTime() + slotDuration);
      // Format start and end times without seconds
      const startTimeString = slotStartTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const endTimeString = slotEndTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      // Set start date based on current time
      let startDate = slotStartTime;
      let endDate = new Date(startDate);
      // Check if end time crosses midnight
      if (slotEndTime.getDate() !== startDate.getDate()) {
        // End time crosses midnight, update end date
        endDate.setDate(endDate.getDate() + 1);
      }
      // Push the formatted time slot object into the array
      timeSlots.push({
        startDate: startDate.toLocaleDateString(),
        endDate: endDate.toLocaleDateString(),
        startTime: startTimeString,
        endTime: endTimeString,
      });
      // Move to the start time of the next slot, 30 minutes after the current slot starts
      slotStartTime = new Date(slotStartTime.getTime() + gap);
    }
    // Filter out time slots that overlap with booked slots
    timeSlots = timeSlots.filter((slot) => {
      const slotStartTime = new Date(slot.startDate + " " + slot.startTime);
      const slotEndTime = new Date(slot.endDate + " " + slot.endTime);
      return !bookedSlot?.some((booked) => {
        const bookedStartTime = new Date(
          booked.startDate + " " + booked.startTime
        );
        const bookedEndTime = new Date(booked.endDate + " " + booked.endTime);
        return (
          (slotStartTime >= bookedStartTime && slotStartTime < bookedEndTime) ||
          (slotEndTime > bookedStartTime && slotEndTime <= bookedEndTime) ||
          (slotStartTime <= bookedStartTime && slotEndTime >= bookedEndTime)
        );
      });
    });
    setImmediatelyPageOneThreeHours(timeSlots[0]);
    const data = divideTimeSlots(timeSlots);
    setMorningdata(data?.morning);
    setAfternoonData(data?.afternoon);
    setEveningData(data?.evening);
    setLatenightData(data?.lateNight);
  };

  const createFiveHoursTimeSlot = async (timeString, bookedSlot) => {
    // Parse the time string into a Date object
    const now = new Date();
    const [hours, minutes] = timeString.split(":")?.map((str) => parseInt(str));
    const isPM = timeString.includes("PM");
    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours + (isPM && hours !== 12 ? 12 : 0), // Adjusting hours for PM
      minutes
    );
    // Initialize an array to store the time slots
    let timeSlots = [];
    // Define the duration of each time slot in milliseconds (3 hours)
    const slotDuration = 5 * 60 * 60 * 1000;
    // Define the gap between each time slot in milliseconds (30 minutes)
    const gap = 30 * 60 * 1000;
    // Calculate the start time of the first time slot
    let slotStartTime = today;
    // Calculate the end time of the last time slot (24 hours from now)
    const endTime = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    // Iterate until we reach the end time
    while (slotStartTime < endTime) {
      // Calculate the end time of the current time slot
      const slotEndTime = new Date(slotStartTime.getTime() + slotDuration);
      // Format start and end times without seconds
      const startTimeString = slotStartTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const endTimeString = slotEndTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      // Set start date based on current time
      let startDate = slotStartTime;
      let endDate = new Date(startDate);
      // Check if end time crosses midnight
      if (slotEndTime.getDate() !== startDate.getDate()) {
        // End time crosses midnight, update end date
        endDate.setDate(endDate.getDate() + 1);
      }
      // Push the formatted time slot object into the array
      timeSlots.push({
        startDate: startDate.toLocaleDateString(),
        endDate: endDate.toLocaleDateString(),
        startTime: startTimeString,
        endTime: endTimeString,
      });
      // Move to the start time of the next slot, 30 minutes after the current slot starts
      slotStartTime = new Date(slotStartTime.getTime() + gap);
    }
    // Filter out time slots that overlap with booked slots
    timeSlots = timeSlots.filter((slot) => {
      const slotStartTime = new Date(slot.startDate + " " + slot.startTime);
      const slotEndTime = new Date(slot.endDate + " " + slot.endTime);
      return !bookedSlot?.some((booked) => {
        const bookedStartTime = new Date(
          booked.startDate + " " + booked.startTime
        );
        const bookedEndTime = new Date(booked.endDate + " " + booked.endTime);
        return (
          (slotStartTime >= bookedStartTime && slotStartTime < bookedEndTime) ||
          (slotEndTime > bookedStartTime && slotEndTime <= bookedEndTime) ||
          (slotStartTime <= bookedStartTime && slotEndTime >= bookedEndTime)
        );
      });
    });
    setImmediatelyPageOneFiveHours(timeSlots[0]);
    const data = divideTimeSlots(timeSlots);
    console.log("This is the data ",data)
    setFiveHoursSlotMorning(data?.morning);
    setFiveHoursSlotAfternoon(data?.afternoon);
    setFiveHoursSlotEvening(data?.evening);
    setFiveHoursSlotLateNight(data?.lateNight);
  };
  const createSevenHoursTimeSlot = async (timeString, bookedSlot) => {
    // Parse the time string into a Date object
    const now = new Date();
    const [hours, minutes] = timeString.split(":")?.map((str) => parseInt(str));
    const isPM = timeString.includes("PM");
    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours + (isPM && hours !== 12 ? 12 : 0), // Adjusting hours for PM
      minutes
    );
    // Initialize an array to store the time slots
    let timeSlots = [];
    // Define the duration of each time slot in milliseconds (3 hours)
    const slotDuration = 7 * 60 * 60 * 1000;
    // Define the gap between each time slot in milliseconds (30 minutes)
    const gap = 30 * 60 * 1000;
    // Calculate the start time of the first time slot
    let slotStartTime = today;
    // Calculate the end time of the last time slot (24 hours from now)
    const endTime = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    // Iterate until we reach the end time
    while (slotStartTime < endTime) {
      // Calculate the end time of the current time slot
      const slotEndTime = new Date(slotStartTime.getTime() + slotDuration);
      // Format start and end times without seconds
      const startTimeString = slotStartTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const endTimeString = slotEndTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      // Set start date based on current time
      let startDate = slotStartTime;
      let endDate = new Date(startDate);
      // Check if end time crosses midnight
      if (slotEndTime.getDate() !== startDate.getDate()) {
        // End time crosses midnight, update end date
        endDate.setDate(endDate.getDate() + 1);
      }
      // Push the formatted time slot object into the array
      timeSlots.push({
        startDate: startDate.toLocaleDateString(),
        endDate: endDate.toLocaleDateString(),
        startTime: startTimeString,
        endTime: endTimeString,
      });
      // Move to the start time of the next slot, 30 minutes after the current slot starts
      slotStartTime = new Date(slotStartTime.getTime() + gap);
    }
    // Filter out time slots that overlap with booked slots
    timeSlots = timeSlots.filter((slot) => {
      const slotStartTime = new Date(slot.startDate + " " + slot.startTime);
      const slotEndTime = new Date(slot.endDate + " " + slot.endTime);
      return !bookedSlot?.some((booked) => {
        const bookedStartTime = new Date(
          booked.startDate + " " + booked.startTime
        );
        const bookedEndTime = new Date(booked.endDate + " " + booked.endTime);
        return (
          (slotStartTime >= bookedStartTime && slotStartTime < bookedEndTime) ||
          (slotEndTime > bookedStartTime && slotEndTime <= bookedEndTime) ||
          (slotStartTime <= bookedStartTime && slotEndTime >= bookedEndTime)
        );
      });
    });
    setImmediatelyPageOneSevenHours(timeSlots[0]);
    const data = divideTimeSlots(timeSlots);
    setSevenHoursSlotMorning(data?.morning);
    setSevenHoursSlotAfternoon(data?.afternoon);
    setSevenHoursSlotEvening(data?.evening);
    setSevenHoursSlotLateNight(data?.lateNight);
  };
  const divideTimeSlots = (timeSlots) => {
    const morning = [];
    const afternoon = [];
    const evening = [];
    const lateNight = [];
    timeSlots.forEach((slot) => {
      const startTime = new Date(slot.startDate + " " + slot.startTime);
      const endTime = new Date(slot.endDate + " " + slot.endTime);
      if (
        (startTime.getHours() >= 6 && startTime.getHours() < 12) ||
        (startTime.getHours() === 12 && startTime.getMinutes() === 0)
      ) {
        morning.push(slot);
      } else if (
        (startTime.getHours() >= 12 && startTime.getHours() < 18) ||
        (startTime.getHours() === 18 && startTime.getMinutes() === 0)
      ) {
        afternoon.push(slot);
      } else if (
        (startTime.getHours() >= 18 && startTime.getHours() < 24) ||
        (startTime.getHours() === 0 && startTime.getMinutes() === 0)
      ) {
        evening.push(slot);
      } else {
        lateNight.push(slot);
      }
    });
    return { morning, afternoon, evening, lateNight };
  };

  const editorRef = useRef(null);

  const handleZoomChange = (e) => {
    const newScale = parseFloat(e.target.value);
    setScale(newScale);
  };

  const handleSave = () => {
    if (editorRef.current) {
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      const editedImgData = canvasScaled.toDataURL("image/jpeg");
      setEditedImage(editedImgData);
    }
  };

  const handleChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 60) {
      setTextareadata(newText);
      setSelectedEmoji((prevState) => ({ ...prevState, emoji: newText }));
    }
  };

  const handleEmojiClick = (emoji) => {
    const finalTitle = textAreadata + emoji;
    setTextareadata((prevData) => prevData + emoji);
    setSelectedEmoji((prevState) => ({ ...prevState, emoji: finalTitle }));
  };

  const handleClickimg = (item) => {
    setSelectedimg(item);
    setEditpic(item);
  };

  const handlePrevious = () => {
    imageGridRef.current.handlePrevious();
  };

  const handleNext = () => {
    imageGridRef.current.handleNext();
  };

  const imageGridRef = useRef();
  const handleOnlineServices = () => {
    const options = [];
    if (vidsForSale) {
      options.push("Vid/Pics For Sale");
    }
    if (videoShows) {
      options.push("Video Shows");
    }
    if (phoneSex) {
      options.push("Phone Sex");
    }

    return options;
  };

  const handleAvailability = () => {
    const options = [];

    if (inCallagree) {
      options.push("In-call");
    }
    if (outCall) {
      options.push("Out-call");
    }
    if (onlineagree) {
      options.push("Online");
    }

    return options;
  };

  const handleCreateLanguageArray = async () => {
    const languagesArray = languagesSpoken.split(" ");
    return languagesArray;
  };


  const handleWalletUpdate = async (amount, token) => {
    try {
      const body = {
        wallet: amount,
      };

      // Include token in the headers
      const paymentUpdateResponse = await setPaymentDetailsForVerifyDec(
        body,
        token
      );

      if (paymentUpdateResponse.success) {
        return paymentUpdateResponse;
      } else {
        console.error("Error updating wallet:", paymentUpdateResponse.message);
        return paymentUpdateResponse;
      }
    } catch (error) {
      console.error(
        "Error updating wallet:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  const [insufficentBalance, setInsufficientBalance] = useState(false);

  const handlePostAdvertisement = async () => {
    if (loader) {
      return;
    }

    if (userData?.wallet < totalCost) {
      setInsufficientBalance(true);
      console.log(
        "Insufficient balance. Cannot proceed with the advertisement posting."
      );
      return;
    }

    setInsufficientBalance(false);

    try {
      console.log("Posting advertisement...");
      setLoader(true);

      const success = await handleAdvertisement();
      console.log("Advertisement posted successfully:", success);

      if (success) {
        console.log("Updating wallet...");

        const paymentUpdateResponse = await handleWalletUpdate(
          totalCost, // Amount to deduct
          token // Pass escort data
        );

        if (paymentUpdateResponse.success) {
          console.log("Wallet updated successfully.");
          // dispatch(setUsereData(paymentUpdateResponse.updatedEscort));
          console.log(
            "Updated user data:",
            paymentUpdateResponse.updatedEscort
          );
        } else {
          console.error(
            "Error updating wallet:",
            paymentUpdateResponse.message
          );
        }

        setPaymentModal(true);
      } else {
        console.error(
          "Failed to post advertisement: Advertisement not successful"
        );
      }
    } catch (error) {
      console.error("Failed to post advertisement:", error);
    } finally {
      setLoader(false);
      console.log("Loader set to false");
    }
  };

  const handleAdvertisement = async () => {
    const online_services = handleOnlineServices();
    const availability = handleAvailability();
    const body = {
      country: selectedCountry,
      cateogires: chosenCategory,
      state: selectedstate,
      city: selectedcity,
      height_feet: heightFeet,
      height_inches: heightInches,
      weight,
      cup_size: cupSize,
      chest,
      waist,
      hair_color: hairColor,
      eye_color: eyeColor,
      online_services,
      ad_title: adTitle,
      ad_description: adDescription,
      name,
      age,
      ethnicity,
      ethnic_origin: ethnicOrigin,
      hourly_rate: hourlyRate,
      phone_number: phoneNumber,
      email_address: emailAddress,
      website,
      language_spoken: languagesSpoken,
      allow_message_via_whatsapp: allowWahtsapp,
      anonymous_contact_form: contactForm,
      show_recent_ads: recentAds,
      show_lyla_ch_reviews: Reviewsbtn,
      availability,
      pictures: uploadimg,
      totalCost: totalCost?.toFixed(2),
      upgrades,
      videos: video,
      hips,
      hidden: false,
      isVerified,
    };

    console.log(body)
    try {
      const response = await createAdvertisement(body, token);
      if (response.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateDescription = () => {
    if (!adTitle || !name || !age || !ethnicity) {
      setError("*Please fill out all required fields.");
      handleScrollToTop();
      return false;
    }

    if (!(videoShows || phoneSex || vidsForSale)) {
      setError("*Please select an online service");
      handleScrollToTop();
      return false;
    }
    if (age < 18) {
      setError("*Age must be a number and 18 or above.");
      handleScrollToTop();
      return false;
    }
    // Clear error if all validations pass
    setError("validated");

    return true;
  };
  const emptyError = () => {
    setError("validated");
  };

  const validateContacts = () => {
    if (!(inCallagree || outCall || onlineagree)) {
      setError("*Please select an availibility option ");
      handleScrollToTop();
      return false;
    }
    if (
      // !hourlyRate ||
      !selectedcity ||
      // !phoneNumber ||
      !emailAddress
      // !languagesSpoken
    ) {
      setError("*Please fill Remaining Fields");
      handleScrollToTop();
      return false;
    }
    // if (
    //   !hourlyRate ||
    //   !city ||
    //   !phoneNumber ||
    //   !emailAddress ||
    //   !languagesSpoken
    // ) {
    //   setError("*Please fill Remaining Fields");
    //   handleScrollToTop();
    //   return false;
    // }
    // Clear error if all validations pass
    setError("validated");

    return true;
  };

  const handleAddExtraClick = () => {
    // Merge page1 and addextra arrays together and update the mergedPayment state
    setMergedPayment([...payment1, ...addextra]);
  };

  useEffect(() => {
    // Fetch all countries
    const fetchCountries = async () => {
      const allCountries = await Country.getAllCountries();
      setCountries(allCountries);
    };

    // Fetch all states
    const fetchStates = async () => {
      const allStates = await State.getAllStates();
      setStates(allStates);
      setFilteredStates([]);
    };

    // Fetch cities by state
    const fetchCities = async (countryCode, stateCode) => {
      const allCities = await City.getCitiesOfState(countryCode, stateCode);
      setCities(allCities);
      setFilteredcities([]);
    };
    // Fetch countries, states, and cities when component mounts
    fetchCountries();
    fetchStates();
    fetchCities(countryCode, stateCode); // Pass appropriate countryCode and stateCode
  }, [countryCode, stateCode]); // Include countryCode and stateCode in the dependency array

  const handleCountryClick = (countryName) => {
    setSelectedCountry(countryName);
    const countryStates = State.getStatesOfCountry(
      countries.find((country) => country.name === countryName).isoCode
    );
    setFilteredStates(countryStates);
    setFilteredcities([]);
    setSelectedcity("");
    setSelectedstate("");
  };

  const handleStateClick = (state) => {
    setSelectedstate(state.name);
    const cities = City.getCitiesOfState(state.countryCode, state.isoCode);
    setStateCities(cities);
    setFilteredcities(cities);
    setSelectedcity("");
  };

  const handlewhatsappAgree = () => {
    setAllowhatsApp(!allowWahtsapp);
  };
  const handleIncallAgree = () => {
    setIncallAgree(!inCallagree);
  };
  const handleOutcall = () => {
    setisOutcall(!outCall);
  };
  const handleOncall = () => {
    setIsonlineAgree(!onlineagree);
  };

  const inputFile = useRef(null);
  const inputFileVideo = useRef(null);

  const handleUpload = async (files) => {
    const uploadedUrls = [];

    // Iterate over each image in the array
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("image", files[i]);

      try {
        const response = await fetch(`${baseURL}auth/uploadProfile`, {
          method: "POST",
          body: formData,
          redirect: "follow",
        });

        const result = await response.json();
        const { url } = result;

        uploadedUrls.push(url); // Store the URL in the array
      } catch (error) {
        console.error("Error:", error);
      }
    }

    // Return the array of uploaded URLs
    return uploadedUrls;
  };
  const handleDeleteImage = (index) => {
    setuploadedimg((prevuploadimg) =>
      prevuploadimg.filter((_, i) => i !== index)
    );
    setNumberOfImagesAdded((prevCount) => prevCount - 1); // Decrease total count when deleting an image
  };

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const getTitle = () => {
    const data = [];
    if (databigger) {
      data.push(payment1[0].title);
      data.push("Bumps / Auto Repost");
    }
    return data;
  };

  const validateCountry = () => {
    if (selectedCountry === "") {
      setError("*Please select a country before proceeding.");
      handleScrollToTop();
      return false;
    }
    if (selectedstate === "") {
      setError("*Please select a state before proceeding.");
      handleScrollToTop();
      return false;
    }
    if (selectedcity === "") {
      setError("*Please select a city before proceeding.");
      handleScrollToTop();
      return false;
    }
    setError(""); // Clear any previous errors
    return true;
  };

  const validateUploadImg = () => {
    if (uploadimg.length === 0) {
      setError("*Please upload your image");
      handleScrollToTop();
      return false;
    }
    // Clear error if all validations pass
    setError("");
    return true;
  };

  // Function to handle form submission
  const handleSubmit = () => {
    // Validate uploadimg before submitting form
    if (validateUploadImg()) {
      // Proceed with form submission
      setchangepanel("chooseupgrade");
    } else {
      // Display error message or take appropriate action
      console.error("Validation failed!");
    }
  };

  const validateAddselect = () => {
    if (payment1.length > 0 && payment1[0].cost === 0) {
      setError("*Please select your Ads");
      handleScrollToTop();
      return false;
    }
    setError("");
    return true;
  };

  // Function to handle form submission
  const handleAddsubmit = async () => {
    // alert("next");
    await handleCreateUpgradesArray();
  };

  const handleCreateUpgradesArray = async () => {
    if (selectedBumps) {
      if (selectedBumps?.selectedScheduleBumps) {
        const bumps = { ...selectedBumps };
        bumps.remainingBumps =
          selectedBumps?.totalBumps -
          calculateTotalScheduleBumps(selectedBumps?.selectedScheduleBumps);
        setSelectedBumps((prevState) => {
          const updatedRemainingBumps =
            prevState?.totalBumps -
            calculateTotalScheduleBumps(prevState?.selectedScheduleBumps);
          return {
            ...prevState,
            remainingBumps: updatedRemainingBumps,
          };
        });
        const obj = {
          pageOne: selectedPageOneSlot,
          premiumTheme: selectPageTwoSLot,
          carousel: selectedCarousel,
          availableNow: selectedAvailableNow,
          featuredAdd: selectedFeaturedAd,
          emoji: selectedEmoji,
          bumps: bumps,
        };
        setUpgrades(obj);
        setchangepanel("finalize&post");
      } else {
        const obj = {
          pageOne: selectedPageOneSlot,
          premiumTheme: selectPageTwoSLot,
          carousel: selectedCarousel,
          availableNow: selectedAvailableNow,
          featuredAdd: selectedFeaturedAd,
          emoji: selectedEmoji,
          bumps: selectedBumps,
        };
        setUpgrades(obj);
        setchangepanel("finalize&post");
      }
    } else {
      const obj = {
        pageOne: selectedPageOneSlot,
        premiumTheme: selectPageTwoSLot,
        carousel: selectedCarousel,
        availableNow: selectedAvailableNow,
        featuredAdd: selectedFeaturedAd,
        emoji: selectedEmoji,
        bumps: null,
      };
      setUpgrades(obj);
      setchangepanel("finalize&post");
    }

    // bumps.remainingBumps = selectedBumps?.totalBumps - calculateTotalScheduleBumps(selectedBumps?.selectedScheduleBumps)
    // setSelectedBumps((prevState) => {
    //   const updatedRemainingBumps = prevState?.totalBumps - calculateTotalScheduleBumps(prevState?.selectedScheduleBumps)
    //   return {
    //     ...prevState,
    //     remainingBumps: updatedRemainingBumps
    //   }
    // })
    // const obj = {
    //   pageOne: selectedPageOneSlot,
    //   premiumTheme: selectPageTwoSLot,
    //   carousel: selectedCarousel,
    //   availableNow: selectedAvailableNow,
    //   featuredAdd: selectedFeaturedAd,
    //   emoji: selectedEmoji,
    //   bumps: bumps,
    // };
    // setUpgrades(obj);
    // setchangepanel("finalize&post");
  };
  const handleImageChange = async (e) => {
    setUploadImgLoader(true);
    const files = e.target.files;
    const formData = new FormData();

    if (files?.length <= 15) {
      for (let i = 0; i < files?.length; i++) {
        const fileSize = files[0].size;
        if (fileSize > 5 * 1024 * 1024) {
          alert("File size exceeds 5 MB limit for each picture.");
          setUploadImgLoader(false);
          return;
        }
        formData.append("images", files[i]);
      }
      const response = await handleUploadPictures(formData, token);
      if (response?.success) {
        if (uploadimg?.length === 0) {
          setuploadedimg(response?.images);
          setUploadImgLoader(false);
        } else if (uploadimg?.length >= 15) {
          alert("You can select up to 15 images.");
          setUploadImgLoader(false);
        } else {
          const remainingSpace = 15 - uploadimg?.length;
          const imagesToAdd = response?.images?.slice(0, remainingSpace);
          setuploadedimg([...uploadimg, ...imagesToAdd]);
          setUploadImgLoader(false);
        }
      } else {
        setUploadImgLoader(false);
      }
    } else {
      alert("You can select up to 15 images.");
      setUploadImgLoader(false);
    }
  };
  const handleVideoChange = async (event) => {
    try {
      setVideoLoader(true);
      const files = event.target.files;
      const formData = new FormData();

      if (files?.length <= 2) {
        for (let i = 0; i < files.length; i++) {
          const fileSize = files[i].size;

          if (fileSize > 50 * 1024 * 1024) {
            alert("File size exceeds 50 MB limit for each video.");
            setVideoLoader(false);
            return;
          }
          formData.append("videos", files[i]);
        }

        const response = await handleVideosUpload(formData, token);
        if (response?.success) {
          if (video?.length === 0) {
            setVideo(response?.urls);
            setVideoLoader(false);
          } else if (video?.length === 1) {
            const updatedVideo = [...video];
            updatedVideo.push(response?.urls[0]);
            setVideo(updatedVideo);
            setVideoLoader(false);
          } else {
            setVideo(response?.urls);
            setVideoLoader(false);
          }
        } else {
          setVideoLoader(false);
        }
      } else {
        alert("Please select only up to 2 videos.");
        setVideoLoader(false);
      }
    } catch (error) {
      console.log("error in upload videos");
      setVideoLoader(false);
    }
  };

  const onVideoButtonClick = () => {
    inputFileVideo.current.click();
  };

  const handleRemoveVideo = async (indexToRemove) => {
    const updatedVideo = video?.filter((_, index) => index !== indexToRemove);
    setVideo(updatedVideo);
  };

  // ETHNIC ORIGIN FUNCTIONS

  const handleEthnicOrigin = (item) => {
    setSelectedEthnicOrigin((prevEthnicOrigin) => {
      // Check if the item is already selected
      if (prevEthnicOrigin.includes(item)) {
        // Item is already selected, deselect it
        return prevEthnicOrigin.filter((origin) => origin !== item);
      } else if (prevEthnicOrigin.length < 6) {
        // Item is not selected and limit is not reached, select it
        return [...prevEthnicOrigin, item];
      } else if (prevEthnicOrigin.length === 5) {
        setLimitModal(true);
      } else {
        setLimitModal(true);
        return prevEthnicOrigin;
      }
    });
  };
  const deselectMostRecent = () => {
    if (selectedEthnicOrigin.length === 6) {
      const updatedArray = [...selectedEthnicOrigin];
      updatedArray.pop();
      setSelectedEthnicOrigin(updatedArray);
      setLimitModal(false);
    }
  };

  const handleDone = () => {
    const selectedItems = ethnicOriginData.filter((item) =>
      selectedEthnicOrigin.includes(item)
    );
    const unselectedItems = ethnicOriginData.filter(
      (item) => !selectedEthnicOrigin.includes(item)
    );
    const updatedEthnicOriginData = [...selectedItems, ...unselectedItems];
    setEthnicOriginData(updatedEthnicOriginData);
    setEthnicOrigin(selectedEthnicOrigin);
    setDropDownDescription("");
  };

  const clear = () => {
    setSelectedEthnicOrigin("");
    setEthnicOrigin("");
    setDropDownDescription("");
  };

  const closeEthicalOriginModal = () => {
    if (ethnicOrigin !== null) {
      setSelectedEthnicOrigin(ethnicOrigin);
      setDropDownDescription("");
    } else {
      setDropDownDescription("");
    }
  };

  useEffect(() => {
    if (selectedEthnicOrigin.length < 6) {
      setLimitModal(false);
    }
  }, [selectedEthnicOrigin]);

  // SELECTLANGUAGE FUNCTIONS

  const handleLanguage = (item) => {
    setSelectedLanguage((prevLanguage) => {
      if (prevLanguage.includes(item)) {
        return prevLanguage.filter((origin) => origin !== item);
      } else if (prevLanguage.length < 6) {
        return [...prevLanguage, item];
      } else if (prevLanguage.length === 5) {
        setLanguageLimit(true);
      } else {
        setLanguageLimit(true);
        return prevLanguage;
      }
    });
  };
  const deselectMostRecentLanguage = () => {
    if (selectedLanguage.length === 6) {
      const updatedArray = [...selectedLanguage];
      updatedArray.pop();
      setSelectedLanguage(updatedArray);
      setLanguageLimit(false);
    }
  };

  const handleDoneLanguage = () => {
    const selectedItems = languageData.filter((item) =>
      selectedLanguage.includes(item)
    );
    const unselectedItems = languageData.filter(
      (item) => !selectedLanguage.includes(item)
    );
    const updatedLanguageOriginData = [...selectedItems, ...unselectedItems];
    setLanguageData(updatedLanguageOriginData);
    setLanguagesSpoken(selectedLanguage);
    setDropDownDescription("");
  };

  const clearLanguage = () => {
    setSelectedLanguage("");
    setLanguagesSpoken("");
    setDropDownDescription("");
  };

  const closeLanguageModal = () => {
    if (languagesSpoken !== null) {
      setSelectedLanguage(languagesSpoken);
      setDropDownDescription("");
    } else {
      setDropDownDescription("");
    }
  };

  useEffect(() => {
    if (selectedLanguage.length < 6) {
      setLanguageLimit(false);
    }
  }, [selectedLanguage]);

  const generateRandomTime = (previousArray) => {
    // Function to generate a random integer between min (inclusive) and max (inclusive)
    const getRandomInt = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    let randomTime;
    let isUnique = false;

    while (!isUnique) {
      // Generate random indices for hours and minutes
      const randomHourIndex = Math.floor(Math.random() * hoursForBumps.length);
      const randomMinuteIndex = Math.floor(
        Math.random() * minutesForBumps.length
      );

      // Select random hours and minutes using the generated indices
      let randomHour = hoursForBumps[randomHourIndex];
      let randomMinute = minutesForBumps[randomMinuteIndex];

      // Ensure that randomHour and randomMinute are always two-digit strings
      randomHour = randomHour < 10 ? `0${randomHour}` : randomHour.toString();
      randomMinute =
        randomMinute < 10 ? `0${randomMinute}` : randomMinute.toString();

      // Randomly select AM or PM
      const randomPeriod = Math.random() < 0.5 ? "AM" : "PM";

      // Construct the time string in "HH:MM AM/PM" format
      randomTime = `${randomHour}:${randomMinute} ${randomPeriod}`;

      // Check if the generated time is not in the previous array
      if (!previousArray.includes(randomTime)) {
        isUnique = true;
      }
    }

    return randomTime;
  };

  const handleAddTimeWithoutChangesDays = () => {
    const selectedTime = generateRandomTime(selectedBumps.times);
    setSelectedBumps((prevState) => {
      const updatedTimes = [...prevState.times, selectedTime];
      const updatedBumps = prevState?.selectedScheduleBumps?.map(
        (schedule) => ({
          ...schedule,
          bumps: schedule.bumps + 1,
        })
      );
      handleCalculateDays(updatedTimes);
      return {
        ...prevState,
        times: updatedTimes,
        selectedScheduleBumps: updatedBumps,
      };
    });
  };

  const handleRemoveTimeWithoutChangesDays = (index) => {
    setSelectedBumps((prevState) => {
      const updatedTimes = prevState?.times?.filter((_, i) => i !== index);
      const updatedBumps = prevState?.selectedScheduleBumps?.map(
        (schedule) => ({
          ...schedule,
          bumps: schedule.bumps - 1,
        })
      );
      handleCalculateDays(updatedTimes);
      return {
        ...prevState,
        times: updatedTimes,
        selectedScheduleBumps: updatedBumps,
      };
    });
    // handleCalculateDays()
  };

  const handleCalculateDaysSecond = (timesArray, totalBumps) => {
    let days = Math.floor(totalBumps / timesArray?.length);
    let matchedOption = bumpsDayOption
      .filter((option) => option.day <= days)
      .pop();
    const selectedIndex = bumpsDayOption.findIndex(
      (option) => option.day === matchedOption.day
    );
    setOnlyEnable(selectedIndex);
  };

  const handleCalculateDays = (timesArray) => {
    const totalBumps = selectedBumps?.totalBumps;
    let days = Math.floor(totalBumps / timesArray?.length);
    let matchedOption = bumpsDayOption
      .filter((option) => option.day <= days)
      .pop();
    const selectedIndex = bumpsDayOption.findIndex(
      (option) => option.day === matchedOption.day
    );
    setOnlyEnable(selectedIndex);
  };

  const generateRandomTimesArray = () => {
    const count = 2;
    const randomTimesArray = [];
    for (let i = 0; i < count; i++) {
      // Generate random indices for hours and minutes
      const randomHourIndex = Math.floor(Math.random() * hoursForBumps.length);
      const randomMinuteIndex = Math.floor(
        Math.random() * minutesForBumps.length
      );

      // Select random hours and minutes using the generated indices
      let randomHour = hoursForBumps[randomHourIndex];
      let randomMinute = minutesForBumps[randomMinuteIndex];

      // Ensure that randomHour and randomMinute are always two-digit strings
      randomHour = randomHour < 10 ? `0${randomHour}` : randomHour.toString();
      randomMinute =
        randomMinute < 10 ? `0${randomMinute}` : randomMinute.toString();

      // Randomly select AM or PM
      const randomPeriod = Math.random() < 0.5 ? "AM" : "PM";

      // Construct the time string in "HH:MM AM/PM" format
      const randomTime = `${randomHour}:${randomMinute} ${randomPeriod}`;

      randomTimesArray.push(randomTime);
    }
    return randomTimesArray;
  };

  const handleCreateInitialBumpsSchedule = async () => {
    const timesArray = generateRandomTimesArray();
    setSelectedBumps((prevState) => {
      return {
        ...prevState,
        times: timesArray,
      };
    });
    const totalBumps = selectedBumps?.totalBumps;
    let days = Math.floor(totalBumps / timesArray?.length);
    let matchedOption = bumpsDayOption
      .filter((option) => option.day <= days)
      .pop();
    const selectedIndex = bumpsDayOption.findIndex(
      (option) => option.day === matchedOption.day
    );
    setSelectedAndEnable(selectedIndex);
    const date = await handleGetDateForBumps();
    const arrayOfBumps = await handleSetBumpsSchedule(
      matchedOption.day,
      date,
      timesArray?.length
    );
    setSelectedBumps((prevState) => {
      return {
        ...prevState,
        selectedScheduleBumps: arrayOfBumps,
      };
    });
    setScheduleBumps(true);
  };

  const selectBumpsDays = async (index) => {
    setBumpsDayOption((prevOptions) => {
      const updatedOptions = prevOptions?.map((option, i) => ({
        ...option,
        selected: i === index,
      }));
      const selectedItem = updatedOptions[index];
      const timesLength = selectedBumps.times?.length;
      handleAgainSetBumpSchedule(selectedItem, timesLength);
      return updatedOptions;
    });
  };

  // const date = await handleGetDateForBumps()
  //     const arrayOfBumps = await handleSetBumpsSchedule(matchedOption.day, date, timesArray?.length)
  //     setSelectedBumps((prevState) => {
  //       return {
  //         ...prevState,
  //         selectedScheduleBumps: arrayOfBumps
  //       }
  //     })

  const handleAgainSetBumpSchedule = async (selectedItem, timesLength) => {
    const date = await handleGetDateForBumps();
    const arrayOfBumps = await handleSetBumpsSchedule(
      selectedItem.day,
      date,
      timesLength
    );
    setSelectedBumps((prevState) => {
      return {
        ...prevState,
        selectedScheduleBumps: arrayOfBumps,
      };
    });
  };

  const handleCheckCanAddTime = () => {
    const totalBumps = selectedBumps?.totalBumps;
    const totalScheduleBumps = selectedBumps?.selectedScheduleBumps?.reduce(
      (total, option) => total + option.bumps,
      0
    );
    const days = selectedBumps?.selectedScheduleBumps?.length;
    const timePerDay = selectedBumps?.times?.length;

    const newTotalScheduledBumps = timePerDay * days;

    if (newTotalScheduledBumps + days <= totalBumps) {
      setShowBumpsAddTime(true);
    } else {
      setShowBumpsAddTime(false);
    }
  };

  useEffect(() => {
    handleCheckCanAddTime();
    handleDisableBumpsTabs();
  }, [selectedBumps]);

  const handleDisableBumpsTabs = async () => {
    const totalScheduleBumbs = calculateTotalScheduleBumps(
      selectedBumps?.selectedScheduleBumps
    );
    if (totalScheduleBumbs || totalScheduleBumbs?.length > 0) {
      setBumpsTab((prevState) =>
        prevState.map((item) => ({
          ...item,
          enable: item.qty >= totalScheduleBumbs,
        }))
      );
    } else {
      setBumpsTab((prevState) =>
        prevState.map((item) => ({
          ...item,
          enable: true,
        }))
      );
    }
  };

  const handleRemoveScheduleBumps = () => {
    setSelectedBumps((prevState) => {
      const updatedSelectedScheduleBumps = null;
      const updatedTime = [];
      return {
        ...prevState,
        selectedScheduleBumps: updatedSelectedScheduleBumps,
        times: updatedTime,
      };
    });
    setScheduleBumps(false);
  };

  const handleGetDateForBumps = async () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // Add 1 to get tomorrow's date

    const month = tomorrow.getMonth() + 1; // Add 1 because getMonth() returns zero-based index
    const day = tomorrow.getDate();
    const year = tomorrow.getFullYear();

    // Pad single digit month and day with leading zero if necessary
    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedDay = day < 10 ? "0" + day : day;

    // Format the date as "MM-DAY-YEAR"
    const formattedDate = `${formattedMonth}-${formattedDay}-${year}`;

    return formattedDate;
  };

  const handleSetBumpsSchedule = async (number, dateString, bumps) => {
    const currentDate = new Date(dateString); // Get the date from the provided dateString
    const objectsArray = [];

    for (let i = 0; i < number; i++) {
      const newDate = new Date(currentDate); // Create a new date object to avoid modifying the original
      newDate.setDate(currentDate.getDate() + i); // Increment date by the provided number of days

      const formattedDate = `${
        newDate.getMonth() + 1
      }-${newDate.getDate()}-${newDate.getFullYear()}`;
      objectsArray.push({ date: formattedDate, bumps });
    }

    return objectsArray;
  };

  const setSelectedAndEnable = (index) => {
    setBumpsDayOption((prevOptions) => {
      const updatedOptions = prevOptions?.map((option, i) => ({
        ...option,
        selected: i === index,
        enable: i <= index,
      }));
      return updatedOptions;
    });
  };

  const setOnlyEnable = (index) => {
    setBumpsDayOption((prevOptions) => {
      const updatedOptions = prevOptions?.map((option, i) => ({
        ...option,
        // selected: i === index,
        enable: i <= index,
      }));
      return updatedOptions;
    });
  };

  const calculateTotalScheduleBumps = (options) => {
    return options?.reduce((total, option) => total + option.bumps, 0);
  };

  const handleShowTimeDropdown = async (index) => {
    setBumpsTimeDropdown((prevState) => (prevState === index ? null : index));
  };

  const extractHour = (item) => {
    const hour = parseInt(item.split(":")[0], 10); // Extract the hour part and convert it to an integer
    return hour;
  };

  const extractMinutes = (item) => {
    const minutes = parseInt(item.split(":")[1].split(" ")[0], 10); // Extract the minutes part and convert it to an integer
    return minutes;
  };

  const extractFormat = (item) => {
    const period = item.split(" ")[1]; // Split the time string and extract the second part
    return period;
  };

  const formatHourAndMinutes = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const handleIncreaseBumpsHours = (item, index) => {
    const [time, periodInitial] = item.split(" ");
    let period = periodInitial; // Reassign to a let variable to allow changes
    let [hour, minute] = time.split(":").map(Number);
    hour += 1;
    if (hour === 12) {
      period = period === "AM" ? "PM" : "AM";
    } else if (hour > 12) {
      hour = 1;
    }
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const newTime = `${formattedHour}:${
      minute < 10 ? "0" : ""
    }${minute} ${period}`;
    setSelectedBumps((prevState) => {
      const newTimesArray = [...prevState.times];
      newTimesArray[index] = newTime;
      return {
        ...prevState,
        times: newTimesArray,
      };
    });
  };

  const handleIncreaseBumpsMinutes = (item, index) => {
    const [time, periodInitial] = item.split(" ");
    let period = periodInitial; // Reassign to a let variable to allow changes
    let [hour, minute] = time.split(":").map(Number);

    minute += 5; // Increment minutes by 15

    if (minute >= 60) {
      // If minutes exceed 60, increment hour and reset minutes
      hour += 1;
      minute -= 60;
    }

    if (hour === 12 && minute === 0) {
      // Toggle period if hour becomes 12 and minutes become 0
      period = period === "AM" ? "PM" : "AM";
    } else if (hour > 12) {
      // Reset hour to 1 if it exceeds 12
      hour = 1;
    }

    // Format hour and minutes
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;

    const newTime = `${formattedHour}:${formattedMinute} ${period}`;
    // Update selectedBumps state
    setSelectedBumps((prevState) => {
      const newTimesArray = [...prevState.times];
      newTimesArray[index] = newTime;
      return {
        ...prevState,
        times: newTimesArray,
      };
    });
  };

  const handleDecreaseBumpsMinutes = (item, index) => {
    const [time, periodInitial] = item.split(" ");
    let period = periodInitial; // Reassign to a let variable to allow changes
    let [hour, minute] = time.split(":").map(Number);

    minute -= 5; // Decrement minutes by 5

    if (minute < 0) {
      // If minutes go below 0, decrement hour and adjust minutes
      hour -= 1;
      minute += 60;
    }

    if (hour === 11 && minute === 55) {
      // Toggle period if hour becomes 11 and minutes become 55
      period = period === "AM" ? "PM" : "AM";
    } else if (hour < 1) {
      // Reset hour to 12 if it goes below 1
      hour = 12;
    }

    // Format hour and minutes
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;

    const newTime = `${formattedHour}:${formattedMinute} ${period}`;
    // Update selectedBumps state
    setSelectedBumps((prevState) => {
      const newTimesArray = [...prevState.times];
      newTimesArray[index] = newTime;
      return {
        ...prevState,
        times: newTimesArray,
      };
    });
  };

  const handleDecreaseBumpsHours = (item, index) => {
    const [time, periodInitial] = item.split(" ");
    let period = periodInitial;
    let [hour, minute] = time.split(":").map(Number);

    hour -= 1;

    if (hour === 0) {
      hour = 12;
      period = period === "AM" ? "PM" : "AM";
    }
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const newTime = `${formattedHour}:${
      minute < 10 ? "0" : ""
    }${minute} ${period}`;

    setSelectedBumps((prevState) => {
      const newTimesArray = [...prevState.times];
      newTimesArray[index] = newTime;
      return {
        ...prevState,
        times: newTimesArray,
      };
    });
  };

  const handleChangeFormat = (item, index) => {
    const [time, format] = item.split(" ");

    // Toggle the format between AM and PM
    const newFormat = format === "AM" ? "PM" : "AM";

    // Construct the new time string with the updated format
    const newTime = `${time} ${newFormat}`;

    setSelectedBumps((prevState) => {
      const newTimesArray = [...prevState.times];
      newTimesArray[index] = newTime;
      return {
        ...prevState,
        times: newTimesArray,
      };
    });
  };

  useEffect(() => {
    handleGetVerifiedStatus();
  }, []);

  const handleGetVerifiedStatus = async () => {
    try {
      const response = await getVerifiedStatus(token);
      if (response.success) {
        dispatch(updateUserVerified(response.verify));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckVerified = async () => {
    if (userData.verify) {
      setIsVerified(true);
      setchangepanel("Description");
    } else {
      setVerfiedClicked(true);
      setIsVerified(false);
    }
  };


  const checkChosenCategories = () => {
    if (chosenCategory === '') {
      setError('Choose any Categories')
    } else {
      emptyError()
      setchangepanel('Country')
    }
  }

  const handleNavigateSugerBabies = () => {
    window.open('https://www.secretbenefits.com', '_blank');
  };
  const handleNavigateHookUp = () => {
    window.open('https://www.canimeether.com/107411717166687030/?subid=10&lander=1186&uclick=ocsca0p2&uclickhash=ocsca0p2-ocsca1m7-2t-0-bz-ydd5-kt9r-cbf1bd', '_blank');
  };

  return (
    <div
      className="bg-purplegray h-full"
      onClick={() => setBumpsTimeDropdown(null)}
    >
      <div className="flex w-full p-6 md:p-10   ">
        <div className="w-full  flex items-start">
          <Link to={"/"}>
            <div className="bg-white lg:w-12 lg:h-12 md:w-8 md:h-8 sm:w-6 sm:h-6 w-6 h-6 flex items-center justify-center cursor-pointer active:opacity-50">
              <img
                src={images.arrowleft}
                alt=""
                className="lg:w-5 lg:h-5 md:w-4 md:h-5 sm:w-3 w-3 h-3"
              />
            </div>
          </Link>
          {changepanel === "selectAd" ? (
            <></>
          ) : (
            <>
              <div className="flex flex-col w-full items-center text-white ">
                <div className="w-full flex flex-col items-center  justify-center ">
                  <div className="text-white w-full flex flex-col items-center justify-center">
                    <div className="lg:text-4xl md:text-2xl sm:text-xl text-xl font-semibold w-full  flex justify-center gap-2  pb-4">
                      Escort Form
                    </div>
                  </div>
                  <div className="lg:w-30p lg:text-base md:text-sm sm:text-xs text-xxs text-center pb-4">
                    To apply, please fill out this form below. your application
                    with luxury escort will be kept strictly confidential.
                  </div>
                  <div
                    className={`text-red-500 text-center w-full max-w-full font-semibold ${
                      error === "validated" && "opacity-0"
                    }`}
                    // style={{ wordBreak: "break-all" }}
                  >
                    {error}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className=" w-full  flex justify-center   ">
        <div className="  lg:w-auto  md:w-full w-full  ">
          {changepanel === "selectAd" ? (
            <></>
          ) : (
            <>
              <div
                className="lg:w-full gap-3 lg:text-base md:text-sm sm:text-sm text-xs  flex lg:flex-row md:flex-row sm:flex-row flex-wrap 
            justify-center  text-white text-nowrap  
           h-auto md:items-start sm:items-start items-start"
              >
                <div
                  className={`  lg:px-2 lg:py-2 md:px-1 cursor-pointer md:py-2 sm:px-2 sm:py-2 px-2 py-2 flex items-center justify-center rounded-3xl ${
                    changepanel === "Country" ? "bg-purple" : "bg-buttoncolor"
                  } `}
                  onClick={() => setchangepanel("Country")}
                >
                  <div className="flex lg:gap-2 md:gap-1 sm:gap-1 gap-1 items-center">
                    <div
                      className={` lg:w-7 lg:h-7 md:w-6 md:h-6 sm:h-8 sm:w-8 w-6 h-6  flex    items-center lg:text-base md:text-sm text-sm justify-center rounded-full ${
                        changepanel === "Country"
                          ? "bg-white text-black"
                          : "bg-purple text-white"
                      }`}
                    >
                      1
                    </div>
                    <div className="lg:flex md:flex hidden">
                      Country & Region
                    </div>
                  </div>
                </div>
                <div
                  className={`  lg:px-2  lg:py-2 cursor-pointer md:px-1 md:py-2 sm:px-2 sm:py-2 px-2 py-2 flex items-center justify-center rounded-3xl  ${
                    changepanel === "Description"
                      ? "bg-purple"
                      : "bg-buttoncolor"
                  } `}
                  onClick={() => setchangepanel("Description")}
                >
                  {" "}
                  <div className="flex lg:gap-2 gap-1 items-center">
                    <div
                      className={`  lg:w-7 lg:h-7 md:w-6 md:h-6 sm:h-8 sm:w-8 w-6 h-6   flex  items-center justify-center rounded-full ${
                        changepanel === "Description"
                          ? "bg-white text-black"
                          : "bg-purple text-white"
                      }`}
                    >
                      2
                    </div>
                    <div className="lg:flex md:flex hidden">Description</div>
                  </div>
                </div>
                <div
                  className={`  cursor-pointer lg:px-2 lg:py-2 md:px-1 md:py-2 sm:px-2 sm:py-2 px-2 py-2 flex items-center justify-center rounded-3xl ${
                    changepanel === "Contact Details"
                      ? "bg-purple"
                      : "bg-buttoncolor"
                  } `}
                  onClick={() => setchangepanel("Contact Details")}
                >
                  {" "}
                  <div className="flex lg:gap-2 gap-1 items-center">
                    <div
                      className={` lg:w-7 lg:h-7 md:w-6 md:h-6 sm:h-8 sm:w-8 w-6 h-6     flex  items-center justify-center rounded-full ${
                        changepanel === "Contact Details"
                          ? "bg-white text-black"
                          : "bg-purple text-white"
                      }`}
                    >
                      3
                    </div>
                    <div className="lg:flex md:flex hidden">
                      Contact Details
                    </div>
                  </div>
                </div>
                <div
                  className={`cursor-pointer  lg:px-2 lg:py-2 md:px-1 md:py-2 sm:px-2 sm:py-2 px-2 py-2 flex items-center justify-center rounded-3xl ${
                    changepanel === "uploadimg" ? "bg-purple" : "bg-buttoncolor"
                  } `}
                  onClick={() => setchangepanel("uploadimg")}
                >
                  <div className="flex lg:gap-2 gap-1 items-center">
                    <div
                      className={` lg:w-7 lg:h-7 md:w-6 md:h-6 sm:h-8 sm:w-8 w-6 h-6  flex  items-center justify-center rounded-full ${
                        changepanel === "uploadimg"
                          ? "bg-white text-black"
                          : "bg-purple text-white"
                      }`}
                    >
                      4
                    </div>
                    <div className="lg:flex md:flex hidden">Upload Images</div>
                  </div>{" "}
                </div>
                <div
                  className={`cursor-pointer lg:px-2 lg:py-2 md:px-1 md:py-2 sm:px-2 sm:py-2 px-2 py-2 flex items-center justify-center rounded-3xl ${
                    changepanel === "chooseupgrade"
                      ? "bg-purple"
                      : "bg-buttoncolor"
                  } `}
                  onClick={() => setchangepanel("chooseupgrade")}
                >
                  <div className="flex lg:gap-2 gap-1 items-center">
                    <div
                      className={` lg:w-7 lg:h-7 md:w-6 md:h-6 sm:h-8 sm:w-8 w-6 h-6 flex  items-center justify-center rounded-full ${
                        changepanel === "chooseupgrade"
                          ? "bg-white text-black"
                          : "bg-purple text-white"
                      }`}
                    >
                      5
                    </div>
                    <div className="lg:flex md:flex hidden">
                      Choose Upgrades
                    </div>
                  </div>{" "}
                </div>
                <div
                  className={`  lg:px-2 lg:py-2 md:px-1 md:py-2 sm:px-2 sm:py-2 px-2 py-2 flex items-center justify-center rounded-3xl ${
                    changepanel === "finalize&post"
                      ? "bg-purple"
                      : "bg-buttoncolor"
                  }`}
                  // onClick={() => setchangepanel('finalize&post')}
                >
                  <div className="flex lg:gap-2 gap-1 items-center">
                    <div
                      className={` lg:w-7 lg:h-7 md:w-6 md:h-6 sm:h-8 sm:w-8 w-6 h-6  flex   items-center justify-center rounded-full ${
                        changepanel === "finalize&post"
                          ? "bg-white text-black"
                          : "bg-purple text-white"
                      }`}
                    >
                      6
                    </div>
                    <div className="lg:flex md:flex hidden">
                      Finalize & Post
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="lg:flex lg:w-full w-full mr-2 items-center h-full flex">
            {changepanel === "selectAd" ? (
              <></>
            ) : (
              <>
                <div className="pt-5 w-50p h-full lg:hidden hidden  flex-col items-start justify-start 1470:space-y-4 lg:space-y-4 md:space-y-10 sm:space-y-10 space-y-10 text-white text-nowrap pb-10 md:text-sm sm:text-xs text-xxs   ">
                  <div
                    className={` lg:pl-2 lg:pr-2 lg:py-2 md:pl-2 md:pr-2 md:py-2 sm:pl-2 sm:py-2 sm:pr-2 pl-2 py-2 pr-2 rounded-3xl ${
                      changepanel === "Country" ? "bg-purple" : "bg-buttoncolor"
                    } `}
                    onClick={() => setchangepanel("Country")}
                  >
                    <div className="flex gap-2 items-center">
                      <div
                        className={` md:w-5 flex md:h-5 sm:w-4 sm:h-4 w-5 h-5 items-center lg:text-base  justify-center rounded-full ${
                          changepanel === "Country"
                            ? "bg-white text-black"
                            : "bg-purple text-white"
                        }`}
                      >
                        1
                      </div>
                      <div className="lg:flex md:flex sm:flex hidden">
                        Country & Region
                      </div>
                    </div>
                  </div>
                  <div
                    className={`  lg:pl-2 lg:pr-2 lg:py-2 md:pl-2 md:pr-2 md:py-2  sm:pl-2 sm:py-2 sm:pr-2 pl-2 py-2 pr-2 rounded-3xl ${
                      changepanel === "Description"
                        ? "bg-purple"
                        : "bg-buttoncolor"
                    } `}
                    onClick={() => setchangepanel("Description")}
                  >
                    {" "}
                    <div className="flex gap-2 items-center">
                      <div
                        className={`  md:w-5 flex md:h-5 sm:w-4 sm:h-4 w-5 h-5 items-center lg:text-base  justify-center rounded-full  ${
                          changepanel === "Description"
                            ? "bg-white text-black"
                            : "bg-purple text-white"
                        }`}
                      >
                        2
                      </div>
                      <div className="lg:flex md:flex sm:flex hidden">
                        Description
                      </div>
                    </div>
                  </div>
                  <div
                    className={` px-2 py-2 rounded-3xl ${
                      changepanel === "Contact Details"
                        ? "bg-purple"
                        : "bg-buttoncolor"
                    } `}
                    onClick={() => setchangepanel("Contact Details")}
                  >
                    {" "}
                    <div className="flex gap-2 items-center">
                      <div
                        className={`  md:w-5 flex md:h-5 sm:w-4 sm:h-4 w-5 h-5 items-center lg:text-base  justify-center rounded-full ${
                          changepanel === "Contact Details"
                            ? "bg-white text-black"
                            : "bg-purple text-white"
                        }`}
                      >
                        3
                      </div>
                      <div className="lg:flex md:flex sm:flex hidden">
                        Contact Details
                      </div>
                    </div>
                  </div>
                  <div
                    className={` px-2 py-2 rounded-3xl ${
                      changepanel === "uploadimg"
                        ? "bg-purple"
                        : "bg-buttoncolor"
                    } `}
                    onClick={() => setchangepanel("uploadimg")}
                  >
                    <div className="flex gap-2 items-center">
                      <div
                        className={`  md:w-5 flex md:h-5 sm:w-4 sm:h-4 w-5 h-5 items-center lg:text-base  justify-center rounded-full ${
                          changepanel === "uploadimg"
                            ? "bg-white text-black"
                            : "bg-purple text-white"
                        }`}
                      >
                        4
                      </div>
                      <div className="lg:flex md:flex sm:flex hidden">
                        Upload images
                      </div>
                    </div>{" "}
                  </div>
                  <div
                    className={` px-2 py-2 rounded-3xl ${
                      changepanel === "chooseupgrade"
                        ? "bg-purple"
                        : "bg-buttoncolor"
                    } `}
                    onClick={() => setchangepanel("chooseupgrade")}
                  >
                    <div className="flex gap-2 items-center">
                      <div
                        className={`  md:w-5 flex md:h-5 sm:w-4 sm:h-4 w-5 h-5 items-center lg:text-base  justify-center rounded-full ${
                          changepanel === "chooseupgrade"
                            ? "bg-white text-black"
                            : "bg-purple text-white"
                        }`}
                      >
                        5
                      </div>
                      <div className="lg:flex md:flex sm:flex hidden">
                        Choose Upgrades
                      </div>
                    </div>{" "}
                  </div>
                  <div
                    className={` px-2 py-2 rounded-3xl ${
                      changepanel === "finalize&post"
                        ? "bg-purple"
                        : "bg-buttoncolor"
                    }`}
                    // onClick={() => setchangepanel('finalize&post')}
                  >
                    <div className="flex gap-2 items-center">
                      <div
                        className={`  md:w-5 flex md:h-5 sm:w-4 sm:h-4 w-5 h-5 items-center lg:text-base  justify-center rounded-full ${
                          changepanel === "finalize&post"
                            ? "bg-white text-black"
                            : "bg-purple text-white"
                        }`}
                      >
                        6
                      </div>
                      <div className="lg:flex md:flex sm:flex hidden">
                        Finalize & Post
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="flex w-full items-center  justify-center">
              <div className="w-full flex justify-center">
                <div className=" rounded-3xl w-full rounded-4xl">
                  {changepanel === "Country" ? (
                    <>
                      <div className="w-full">
                        <div className="w-full flex lg:flex-row justify-center px-4 lg:gap-4 md:gap-5 sm:gap-5 gap-2 text-white">
                          <div className=" text-white flex gap-4  w-[30%]">
                            <div className=" w-full">
                              <div
                                className={`cursor-pointer w-full md:text-sm sm:text-sm text-xxs bg-graymore lg:p-4 md:p-2 p-2  ${
                                  selectedCountry === ""
                                    ? "active:opacity-50"
                                    : ""
                                }`}
                                onClick={() => setSelectedCountry("")}
                              >
                                {selectedCountry || "Select Country"}
                              </div>
                              <div
                                className="text-white md:text-sm sm:text-sm text-xxs lg:h-full md:h-full sm:h-52 h-52  overflow-y-scroll w-full "
                                style={{ maxHeight: "600px" }}
                              >
                                {countries?.map((country, index) => (
                                  <div
                                    className={`cursor-pointer hover:bg-purple bg-buttoncolor border-b border-whitepink border-opacity-40 lg:p-4 md:p-2 p-2 ${
                                      selectedCountry === country.name
                                        ? "active:opacity-50 bg-purple"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleCountryClick(country.name)
                                    } // Call handleCountryClick with the country name when clicked
                                    key={index}
                                  >
                                    {country.name}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="w-[30%]">
                            <div
                              className={`w-full cursor-pointer md:text-sm sm:text-sm text-xxs bg-graymore lg:p-4 md:p-2 p-2
                                 ${
                                   selectedstate === ""
                                     ? "active:opacity-50"
                                     : ""
                                 }`}
                              onClick={() => setSelectedstate("")}
                            >
                              {selectedstate || "Select State"}
                            </div>
                            <div
                              className="text-white overflow-y-auto flex-wrap  md:text-sm sm:text-sm text-xxs lg:h-full md:h-full sm:h-52 h-52 w-full"
                              style={{ maxHeight: "600px" }}
                            >
                              {filteredStates?.map((state, index) => (
                                <div
                                  className={`cursor-pointer hover:bg-purple bg-buttoncolor border-b border-whitepink border-opacity-40 lg:p-4 md:p-2 p-2 w-full ${
                                    selectedstate === state.name
                                      ? "active:opacity-50 bg-purple"
                                      : ""
                                  }`}
                                  onClick={() => handleStateClick(state)} // Call handleStateClick with the state name when clicked
                                  key={index}
                                >
                                  {state.name}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="lg:pb-0 md:pb-0 sm:pb-20 pb-20 w-[30%]">
                            <div
                              className={`cursor-pointer w-full md:text-sm sm:text-sm text-xxs bg-graymore flex  lg:p-4 md:p-2 p-2  ${
                                selectedcity === "" ? "active:opacity-50" : ""
                              }`}
                              onClick={() => setSelectedcity("")}
                            >
                              {selectedcity || "Select City"}
                            </div>
                            <div
                              className="text-white overflow-y-auto h-20 lg:h-full md:h-full sm:h-52 h-52 w-full"
                              style={{ maxHeight: "600px" }}
                            >
                              {filteredcities?.map((city, index) => (
                                <div
                                  className={`cursor-pointer hover:bg-purple bg-buttoncolor  text-white md:text-sm sm:text-xs
                                     text-xxs overflow-y-auto
                                     w-full border-b border-whitepink border-opacity-40 lg:p-4 md:p-2 p-2 ${
                                       selectedcity === city.name
                                         ? "active:opacity-50 bg-purple"
                                         : ""
                                     }`}
                                  onClick={() => {
                                    setSelectedcity(city.name); // Set the selected city when clicked
                                  }}
                                  key={index} // Ensure each city has a unique key
                                >
                                  {city.name}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full justify-end pb-20 mt-10">
                          <div
                            className="mr-5 text-white bg-purple lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8 w-24 ml-5 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
                            onClick={() => {
                              if (validateCountry()) {
                                setchangepanel("selectAd");
                                // handleScrollToTop();
                              }
                            }}
                          >
                            <div className="text-white flex justify-between font-semibold lg:w-40p w-50p  items-center">
                              Next
                              <img
                                src={images.smallarrowright}
                                alt=""
                                className="lg:w-2 lg:h-3  md:h-2 sm:h-2 h-2 "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : changepanel === "selectAd" ? (
                    <div className="text-whitepink h-screen  flex ">
                      <div className="flex flex-col gap-2 w-full h-full  items-center">
                        <div className=" bg-footercolor w-[300px] md:w-[700px] lg:w-[800px] py-6 border  border-whitepink border-opacity-40">
                          <div className="pl-4  pb-1 gap-4 flex items-center">
                            <div className=" flex gap-2 text-md md:text-2xl font-bold items-center">
                              <img
                                src={images.standardProfile}
                                className="h-4 w-3 md:h-5 md:w-4 "
                              />
                              <div>Standard </div>
                              <div>Ad</div>
                            </div>
                            <div className="text-md md:text-xl text-textopacity ">
                              CAD $6.99
                            </div>
                          </div>
                          <div className=" pl-4 flex flex-col gap-2 md:gap-0 md:flex-row pr-4 justify-between text-xs md:text-base">
                            <div className="text-textopacity w-full">
                              The classic Pleasury ad. Add up to 12 non-verified
                              pictures to your Standard Ad to attract potential
                              clients. Available to everyone.
                            </div>
                            <div
                              className="bg-purple flex items-center w-20 h-10 md:w-44 md:h-14 cursor-pointer active:opacity-50 font-bold justify-center"
                              onClick={() => {
                                setchangepanel("Description");
                                setIsVerified(false);
                              }}
                            >
                              Select
                            </div>
                          </div>
                        </div>
                        <div className=" bg-footercolor w-[300px] md:w-[700px] lg:w-[800px] py-6  border  border-whitepink border-opacity-40">
                          {verfiedClicked ? (
                            <>
                              <div className="pl-4 flex-col flex gap-2  ">
                                <div className="text-purple font-bold text-md md:text-xl">
                                  Not Available to Non-Verified Users
                                </div>
                                <div className="text-textopacity  text-sm  w-[90%]">
                                  Don't worry, getting Verified is easy. Just
                                  take a picture of yourself holding up or
                                  displaying a sign that says "Pleasury".{" "}
                                  <span className="text-purple font-bold cursor-pointer active:opacity-50 ">
                                    <Link to={"/applyverify"}>
                                      {" "}
                                      Learn more.
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="pl-4  pb-1 gap-4 flex items-center">
                                <div className="flex gap-2 text-md md:text-2xl font-bold items-center">
                                  <img
                                    src={images.verified1}
                                    className="md:h-8 md:w-8 h-5 w-5"
                                  />
                                  <div>Verified</div>
                                  <div>Ad</div>
                                </div>
                                <div className="text-md md:text-xl text-textopacity ">
                                  CAD $5.99
                                </div>
                              </div>
                              <div className="pl-4 flex flex-col gap-2 md:gap-0 md:flex-row pr-4 justify-between text-xs md:text-base">
                                <div className="opacity-40 w-full">
                                  Earn your potential clients trust by assuring
                                  them your pictures are 100% genuine. Available
                                  to Verified Users Only.
                                </div>
                                <div
                                  className="bg-purple flex items-center w-20 h-10 md:w-44 md:h-14 cursor-pointer active:opacity-50 font-bold justify-center"
                                  // onClick={() => setVerfiedClicked(true)}
                                  onClick={handleCheckVerified}
                                >
                                  Select
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : changepanel === "Description" ? (
                    <>
                      <div className="flex  flex-col items-center">
                        <div className="bg-weirdgray lg:w-full w-[90%]  rounded-lg">
                          <div className="flex p-2   items-center gap-4">
                            <div className="text-whitepink  items-center gap-2 p-2 flex ">
                              <div
                                className="lg:text-2xl md:text-xl sm:text-sm text-sm flex gap-2
                              items-end "
                              >
                                <div>Your Details</div>
                                <div className="text-purple text-sm font-bold ">
                                  (optional)
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bg-buttoncolor text-white p-2 rounded-b-lg">
                            <div className="flex flex-col bg-buttoncolor w-full rounded-lg">
                              <div className="flex lg:flex-row md:flex-row sm:flex-row flex-col  items-center w-full pt-5">
                                <div className="flex p-1 w-full ">
                                  <div className=" lg:ml-5 lg:ml-4  w-full ">
                                    <div className="lg:pb-4 pb-2 lg:text-base md:text-base sm:text-base text-xs ">
                                      HEIGHT
                                    </div>
                                    <div className="relative">
                                      <div
                                        className={`bg-buttoncolor remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                       w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 
                                       `}
                                        onClick={() =>
                                          setDropDownDescription(
                                            dropDownDescription === "heightft"
                                              ? ""
                                              : "heightft"
                                          )
                                        }
                                      >
                                        <div>{heightFeet}</div>
                                        <img
                                          src={
                                            dropDownDescription === "heightft"
                                              ? images.dropDownOpen
                                              : images.dropDownClosed
                                          }
                                          alt=""
                                          className="md:h-3 md:w-4 h-2 w-3"
                                        />
                                      </div>
                                      {dropDownDescription === "heightft" && (
                                        <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                                          <div>
                                            {heightFtData?.map(
                                              (item, index) => (
                                                <div
                                                  key={index}
                                                  onClick={() => {
                                                    setHeightFeet(item);
                                                    setDropDownDescription("");
                                                  }}
                                                  className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                                                >
                                                  {item}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="lg:mt-4 md:mt-4 sm:mt-4 w-full">
                                  <div className="relative lg:pt-6 pt-4 lg:pl-4 pl-1">
                                    <div
                                      className={`bg-buttoncolor remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                       w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 
                                       `}
                                      onClick={() =>
                                        setDropDownDescription(
                                          dropDownDescription === "heightinch"
                                            ? ""
                                            : "heightinch"
                                        )
                                      }
                                    >
                                      <div>{heightInches}</div>
                                      <img
                                        src={
                                          dropDownDescription === "heightinch"
                                            ? images.dropDownOpen
                                            : images.dropDownClosed
                                        }
                                        alt=""
                                        className="md:h-3 md:w-4 h-2 w-3"
                                      />
                                    </div>
                                    {dropDownDescription === "heightinch" && (
                                      <div className="absolute bg-weirdgray w-[100%] lg:w-[95%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                                        {heightInchData?.map((item, index) => (
                                          <div
                                            key={index}
                                            onClick={() => {
                                              setHeightInches(item);
                                              setDropDownDescription("");
                                            }}
                                            className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                                          >
                                            {item}
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="flex lg:pl-4 md:pl-6 sm:pl-6 pl-1 lg:pt-0 md:pt-0 sm:pt-0 pt-2  w-full  lg:text-lg md:text-sm sm:text-sm text-xxs  ">
                                  <div className="w-full">
                                    <div className="lg:pb-4 pb-2 lg:text-base md:text-base sm:text-base text-xs w-full mt-1">
                                      WEIGHT
                                    </div>
                                    <div className="relative">
                                      <div
                                        className={`bg-buttoncolor remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                       w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  px-3
                                       relative`}
                                        onClick={() =>
                                          setDropDownDescription(
                                            dropDownDescription === "weight"
                                              ? ""
                                              : "weight"
                                          )
                                        }
                                      >
                                        <div>{weight}</div>
                                        <img
                                          src={
                                            dropDownDescription === "weight"
                                              ? images.dropDownOpen
                                              : images.dropDownClosed
                                          }
                                          alt=""
                                          className="md:h-3 md:w-4 h-2 w-3"
                                        />
                                      </div>
                                      {dropDownDescription === "weight" && (
                                        <div className="absolute bg-graymore w-[100%] z-20 mt-1 max-h-[200px] overflow-auto rounded-lg scroll-m-0 ">
                                          {weightData?.map((item, index) => (
                                            <div
                                              key={index}
                                              onClick={() => {
                                                setWeight(item);
                                                setDropDownDescription("");
                                              }}
                                              className="hover:bg-purple cursor-pointer active:opacity-50 text-base border-whitepink border-b  px-4 border-opacity-10 p-2"
                                            >
                                              {item}
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4  gap-4 p-1 lg:ml-5 mt-4 ">
                                <div className=" ">
                                  <div className="lg:pb-4 pb-2 lg:text-base md:text-base sm:text-base text-xs ">
                                    CUP SIZE
                                  </div>
                                  <div className="relative">
                                    <div
                                      className={`bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                         border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  px-3
                                       relative w-full`}
                                      onClick={() =>
                                        setDropDownDescription(
                                          dropDownDescription === "cupsize"
                                            ? ""
                                            : "cupsize"
                                        )
                                      }
                                    >
                                      <div>{cupSize}</div>
                                      <img
                                        src={
                                          dropDownDescription === "cupsize"
                                            ? images.dropDownOpen
                                            : images.dropDownClosed
                                        }
                                        alt=""
                                        className="md:h-3 md:w-4 h-2 w-3"
                                      />
                                    </div>
                                    {dropDownDescription === "cupsize" && (
                                      <div className="absolute bg-graymore w-[100%]  z-20 max-h-[200px]  overflow-auto rounded-lg mt-1">
                                        <div>
                                          {cupSizeData?.map((item, index) => (
                                            <div
                                              key={index}
                                              onClick={() => {
                                                setCupSize(item);
                                                setDropDownDescription("");
                                              }}
                                              className="hover:bg-purple cursor-pointer active:opacity-50  text-sm md:text-base   border-whitepink border-b  px-4 border-opacity-10 p-2"
                                            >
                                              {item}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className=" ">
                                  <div className="lg:pb-4 pb-2 lg:text-base md:text-base sm:text-base text-xs ">
                                    CHEST "
                                  </div>
                                  <div className="relative">
                                    <div
                                      className={`bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                         border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  px-3
                                       relative w-full`}
                                      onClick={() =>
                                        setDropDownDescription(
                                          dropDownDescription === "chest"
                                            ? ""
                                            : "chest"
                                        )
                                      }
                                    >
                                      <div>{chest}</div>
                                      <img
                                        src={
                                          dropDownDescription === "chest"
                                            ? images.dropDownOpen
                                            : images.dropDownClosed
                                        }
                                        alt=""
                                        className="md:h-3 md:w-4 h-2 w-3"
                                      />
                                    </div>
                                    {dropDownDescription === "chest" && (
                                      <div className="absolute bg-graymore w-[100%] z-20 max-h-[200px]  overflow-auto rounded-lg  mt-1">
                                        <div>
                                          {chestData?.map((item, index) => (
                                            <div
                                              key={index}
                                              onClick={() => {
                                                setChest(item);
                                                setDropDownDescription("");
                                              }}
                                              className="hover:bg-purple cursor-pointer active:opacity-50  text-sm md:text-base  border-whitepink border-b  px-4 border-opacity-10 p-2"
                                            >
                                              {item}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className=" ">
                                  <div className="lg:pb-4 pb-2 lg:text-base md:text-base sm:text-base text-xs ">
                                    WAIST "
                                  </div>
                                  <div className="relative">
                                    <div
                                      className={`bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                         border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  px-3
                                       relative w-full`}
                                      onClick={() =>
                                        setDropDownDescription(
                                          dropDownDescription === "waist"
                                            ? ""
                                            : "waist"
                                        )
                                      }
                                    >
                                      <div>{waist}</div>
                                      <img
                                        src={
                                          dropDownDescription === "waist"
                                            ? images.dropDownOpen
                                            : images.dropDownClosed
                                        }
                                        alt=""
                                        className="md:h-3 md:w-4 h-2 w-3"
                                      />
                                    </div>
                                    {dropDownDescription === "waist" && (
                                      <div className="absolute bg-graymore w-[100%] z-20 max-h-[200px]  overflow-auto rounded-md mt-1 ">
                                        <div>
                                          {waistData?.map((item, index) => (
                                            <div
                                              key={index}
                                              onClick={() => {
                                                setWaist(item);
                                                setDropDownDescription("");
                                              }}
                                              className="hover:bg-purple cursor-pointer active:opacity-50  text-sm md:text-base  border-whitepink border-b  px-4 border-opacity-10 p-2"
                                            >
                                              {item}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className=" ">
                                  <div className="lg:pb-4 pb-2 lg:text-base md:text-base sm:text-base text-xs ">
                                    HIPS "
                                  </div>
                                  <div className="relative">
                                    <div
                                      className={`bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                         border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  px-3
                                       relative w-full`}
                                      onClick={() =>
                                        setDropDownDescription(
                                          dropDownDescription === "hips"
                                            ? ""
                                            : "hips"
                                        )
                                      }
                                    >
                                      <div>{hips}</div>
                                      <img
                                        src={
                                          dropDownDescription === "hips"
                                            ? images.dropDownOpen
                                            : images.dropDownClosed
                                        }
                                        alt=""
                                        className="md:h-3 md:w-4 h-2 w-3"
                                      />
                                    </div>
                                    {dropDownDescription === "hips" && (
                                      <div className="absolute bg-graymore w-[100%]  z-20 max-h-[200px]   overflow-auto rounded-lg  mt-1">
                                        <div>
                                          {hipsData?.map((item, index) => (
                                            <div
                                              key={index}
                                              onClick={() => {
                                                setHips(item);
                                                setDropDownDescription("");
                                              }}
                                              className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base   border-whitepink border-b  px-4 border-opacity-10 p-2"
                                            >
                                              {item}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 p-1 pb-5 md:ml-5 md:mt-5">
                                <div className="w-full ">
                                  <div className="lg:pb-4 pb-2 lg:text-base md:text-base sm:text-base text-xs lg:pt-0 md:pt-2 sm:pt-2 pt-2 ">
                                    HAIR COLOR
                                  </div>
                                  <div className="relative">
                                    <div
                                      className={`bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                         border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  px-3
                                       relative w-full`}
                                      onClick={() =>
                                        setDropDownDescription(
                                          dropDownDescription === "haircolor"
                                            ? ""
                                            : "haircolor"
                                        )
                                      }
                                    >
                                      <div>{hairColor}</div>
                                      <img
                                        src={
                                          dropDownDescription === "haircolor"
                                            ? images.dropDownOpen
                                            : images.dropDownClosed
                                        }
                                        alt=""
                                        className="md:h-3 md:w-4 h-2 w-3"
                                      />
                                    </div>
                                    {dropDownDescription === "haircolor" && (
                                      <div className="absolute bg-graymore w-[100%]  z-20 max-h-[200px]  overflow-auto rounded-lg mt-1 ">
                                        <div>
                                          {hairData?.map((item, index) => (
                                            <div
                                              key={index}
                                              onClick={() => {
                                                setHairColor(item);
                                                setDropDownDescription("");
                                              }}
                                              className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base   border-whitepink border-b  px-4 border-opacity-10 p-2"
                                            >
                                              {item}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="w-full ">
                                  <div className="lg:pb-4 pb-2 lg:text-base md:text-base sm:text-base text-xs lg:pt-0 md:pt-2 sm:pt-2 pt-2 ">
                                    EYE COLOR
                                  </div>
                                  <div className="relative">
                                    <div
                                      className={`bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                         border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3
                                       relative w-full`}
                                      onClick={() =>
                                        setDropDownDescription(
                                          dropDownDescription === "eyeColor"
                                            ? ""
                                            : "eyeColor"
                                        )
                                      }
                                    >
                                      <div>{eyeColor}</div>
                                      <img
                                        src={
                                          dropDownDescription === "eyeColor"
                                            ? images.dropDownOpen
                                            : images.dropDownClosed
                                        }
                                        alt=""
                                        className="md:h-3 md:w-4 h-2 w-3"
                                      />
                                    </div>
                                    {dropDownDescription === "eyeColor" && (
                                      <div className="absolute bg-graymore w-[100%] z-20 max-h-[200px] overflow-auto rounded-lg mt-1">
                                        <div>
                                          {eyeColorData?.map((item, index) => (
                                            <div
                                              key={index}
                                              onClick={() => {
                                                setEyeColor(item);
                                                setDropDownDescription("");
                                              }}
                                              className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base   border-whitepink border-b  px-4 border-opacity-10 p-2"
                                            >
                                              {item}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col lg:w-full w-[90%] bg-buttoncolor text-white mt-10 rounded-lg ">
                          <div className="lg:text-base md:text-sm sm:text-xxs text-sm p-4 bg-graypurple rounded-t-lg mb-3 text-2xl rounded-b-lg">
                            Online Services
                          </div>
                          <div className="p-2 pb-5 px-5 flex md:flex-row  flex-col  gap-3">
                            <div
                              onClick={() => {
                                setVideoShows(!videoShows);
                              }}
                              className={`border rounded-xl lg:w-40p w-full  lg:text-xl md:text-lg sm:text-base text-base flex items-center border-whitepink border-opacity-20 gap-2 h-14 active:border-opacity-80 cursor-pointer ${
                                videoShows && "bg-purple"
                              }`}
                            >
                              <div className="w-full flex items-center gap-2 ml-4 font-semibold ">
                                <img
                                  src={images.camera1}
                                  alt=""
                                  className="w-5 h-4 ml-2"
                                />
                                Video Shows
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                setPhoneSex(!phoneSex);
                              }}
                              className={`border rounded-xl lg:w-40p w-full lg:text-xl md:text-lg sm:text-base text-base flex items-center border-whitepink border-opacity-20 gap-2 h-14 active:border-opacity-80 cursor-pointer ${
                                phoneSex && "bg-purple"
                              }`}
                            >
                              <div
                                className={`w-full flex items-center gap-2 ml-4 font-semibol`}
                              >
                                <img
                                  src={images.phonecall}
                                  alt=""
                                  className="w-5 h-4 ml-2"
                                />
                                Phone Sex
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                setVidsForSale(!vidsForSale);
                              }}
                              className={`border rounded-xl lg:w-40p w-full lg:text-xl md:text-lg sm:text-base text-base flex items-center border-whitepink border-opacity-20 gap-2 h-14 active:border-opacity-80 cursor-pointer ${
                                vidsForSale && "bg-purple"
                              }`}
                            >
                              <div className="w-full flex items-center gap-2 ml-4 font-semibold">
                                <img
                                  src={images.uploadimg2}
                                  alt=""
                                  className="w-5 h-5 ml-2"
                                />
                                Vids/Pics for Sale
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col  lg:w-full w-[90%] lg:text-base md:text-base sm:text-base text-xs   bg-buttoncolor mt-10 text-white rounded-lg">
                          <div className="pl-5 py-5 ">
                            ADVERTISEMENT TITLE
                            <div className="lg:pt-4 pt-2">
                              <input
                                type="text"
                                className="bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm  w-[94%] border rounded-lg border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  pl-3 "
                                placeholder="..."
                                value={adTitle}
                                onChange={handleSetTitle}
                              />
                            </div>
                          </div>
                          <div className="pl-5 py-5 ">
                            ADVERTISEMENT DESCRIPTION
                            <div className="lg:pt-4 pt-2">
                              {/* <input
                                type="text"
                                className="bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm  w-[94%] border rounded-lg border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  pl-3 "
                                placeholder="..."
                                value={adTitle}
                                onChange={handleSetTitle}
                              /> */}
                              <textarea
                                name="message"
                                id="message"
                                cols="80"
                                rows="10"
                                className="resize-none bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm h-96 pt-3  w-[94%] border rounded-lg border-whitepink border-opacity-20  h-20  pl-3 "
                                // className="w-full block bg-transparent text-white opacity-60 h-40 lg:text-lg md:text-base sm:text-sm sm:text-xs text-xxs focus:outline-none pl-4"
                                placeholder="..."
                                value={adDescription}
                                onChange={(e) =>
                                  setAdDescription(e.target.value)
                                }
                              ></textarea>
                            </div>
                          </div>

                          <div className="flex lg:flex-row md:flex-row lg:text-base md:text-base sm:text-base text-xs flex-col p-1 md:pb-5 pr-5">
                            <div className="w-full ml-5">
                              NAME
                              <div className="lg:pt-4 md:pt-2 sm:pt-2 pt-2">
                                <input
                                  type="text"
                                  className="bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm  w-[93%]  border rounded-lg border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  pl-3"
                                  placeholder="..."
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="w-full ml-5 lg:text-base md:text-base sm:text-base text-xs ">
                              AGE
                              <div className="lg:pt-4 md:pt-2 sm:pt-2 pt-2">
                                <input
                                  type="number"
                                  className="bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm  w-[93%] border rounded-lg border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  pl-3"
                                  placeholder="..."
                                  value={age}
                                  onChange={(e) => setAge(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex lg:flex-row md:flex-col flex-col p-1 pb-5 pr-5 lg:text-base md:text-base sm:text-base text-xs">
                            <div className="w-full ml-5">
                              ETHNICITY
                              <div className="lg:pt-4 md:pt-2 sm:pt-2 pt-2">
                                <div className="relative w-[93%]">
                                  <div
                                    className={`bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
     border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  px-3
   relative w-full`}
                                    onClick={() =>
                                      setDropDownDescription(
                                        dropDownDescription === "ethnicity"
                                          ? ""
                                          : "ethnicity"
                                      )
                                    }
                                  >
                                    <div>{ethnicity}</div>
                                    <img
                                      src={
                                        dropDownDescription === "ethnicity"
                                          ? images.dropDownOpen
                                          : images.dropDownClosed
                                      }
                                      alt=""
                                      className="md:h-3 md:w-4 h-2 w-3"
                                    />
                                  </div>
                                  {dropDownDescription === "ethnicity" && (
                                    <div className="absolute bg-graymore w-[100%]  z-20 max-h-[200px]   overflow-auto rounded-lg  mt-1">
                                      <div>
                                        {ethnicityData?.map((item, index) => (
                                          <div
                                            key={index}
                                            onClick={() => {
                                              setEthnicity(item);
                                              setDropDownDescription("");
                                            }}
                                            className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base   border-whitepink border-b  px-4 border-opacity-10 p-2"
                                          >
                                            {item}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="w-full ml-5 lg:text-base md:text-base sm:text-base text-xs lg:pt-0 pt-2">
                              <div className="flex items-center gap-2 ">
                                <div>ETHNIC ORIGIN</div>
                                <div className="text-sm text-purple">
                                  (Optional)
                                </div>
                              </div>
                              <div className="lg:pt-4 md:pt-2 sm:pt-2 pt-2">
                                <div className="">
                                  <div
                                    className={`bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                         border rounded-lg flex items-center cursor-pointer justify-between  border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  px-3
                                       relative w-[93%]   `}
                                    onClick={() =>
                                      setDropDownDescription(
                                        dropDownDescription === "ethnicOrigin"
                                          ? ""
                                          : "ethnicOrigin"
                                      )
                                    }
                                  >
                                    <div className="truncate max-w-[300px]">
                                      {ethnicOrigin === null ? (
                                        <div></div>
                                      ) : (
                                        <div className="">
                                          {`${ethnicOrigin}`}
                                        </div>
                                      )}
                                    </div>
                                    {dropDownDescription === "ethnicOrigin" ? (
                                      <div>
                                        <img
                                          src={images.dropDownOpen}
                                          alt=""
                                          className="h-2 w-3 md:h-3 md:w-4"
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <img
                                          src={images.dropDownClosed}
                                          alt=""
                                          className="h-2 w-3 md:h-3 md:w-4"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {dropDownDescription === "ethnicOrigin" && (
                                    <div
                                      className="fixed z-10 text-black  top-5 md:top-0 left-0 w-full h-[100vh] flex items-center justify-center bg-gray-800 bg-opacity-50"
                                      onClick={closeEthicalOriginModal}
                                    >
                                      <div
                                        className="bg-purplegray w-[90%] sm:w-[70%] md:w-[40%] lg:w-[30%] max-w-[480px] pb-4 text-white rounded-lg  "
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <div className="p-4 text-sm">
                                          <div>Ethnic Origin:</div>
                                          <div>
                                            {selectedEthnicOrigin.length ===
                                            0 ? (
                                              <div>....</div>
                                            ) : (
                                              <div className="text-xxs">
                                                {`${selectedEthnicOrigin} `}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="flex items-center justify-center">
                                          <div className=" w-[90%] flex items-center pl-2  bg-aboutcolor h-8 rounded-lg">
                                            <img
                                              src={images.search}
                                              alt=""
                                              className="w-3 h-3 cursor-pointer "
                                            />
                                            <input
                                              type="text"
                                              className="bg-transparent  focus:outline-none pl-2 text-sm "
                                              value={search}
                                              placeholder="Search"
                                              onChange={(e) =>
                                                setSearch(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className=" flex w-full flex-col overflow-auto max-h-[330px] pt-4 pl-5 pb-4 ">
                                          {ethnicOriginData
                                            .filter((item) =>
                                              item
                                                .toLowerCase()
                                                .includes(search.toLowerCase())
                                            )
                                            ?.map((item, index) => (
                                              <div
                                                key={index}
                                                className="flex w-[100%] my-2 "
                                                onClick={() =>
                                                  handleEthnicOrigin(item)
                                                }
                                              >
                                                <div className=" items-center flex justify-center pr-5">
                                                  <div>
                                                    {selectedEthnicOrigin.includes(
                                                      item
                                                    ) ? (
                                                      <img
                                                        src={images.checked}
                                                        alt=""
                                                        className="h-5 w-5"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={images.unchecked}
                                                        alt=""
                                                        className="h-5 w-5"
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="relative w-full ">
                                                  {item}
                                                  {limitModal === item ? (
                                                    <div
                                                      className="shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]
                                                   w-[95%] bg-purple   absolute bottom-[80%] right-[5%]  text-xs h-10 flex items-center justify-center gap-2  px-4  rounded-3xl"
                                                    >
                                                      <div>
                                                        You’ve selected the
                                                        maximum of 6 additional
                                                        ethnicities.
                                                      </div>
                                                      <div
                                                        onClick={
                                                          deselectMostRecent
                                                        }
                                                        className="border rounded-full text-xxs w-4 h-4 cursor-pointer flex items-center justify-center"
                                                      >
                                                        X
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </div>
                                            ))}
                                        </div>
                                        <div className="flex items-center flex-col justify-center gap-2   border-t border-aboutcolor   ">
                                          <div>
                                            {limitModal && (
                                              <div
                                                className="shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]
                                                    flex items-center justify-center gap-2  px-4 text-sm text-red-500  rounded-3xl"
                                              >
                                                <div>
                                                  You’ve selected the maximum of
                                                  6 additional ethnicities.
                                                </div>
                                                <div
                                                  onClick={deselectMostRecent}
                                                  className="border rounded-full text-xxs w-4 h-4 cursor-pointer flex items-center border-red-500 justify-center"
                                                >
                                                  X
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          <div className="flex gap-5 mt-2">
                                            <div
                                              className="px-10 py-2 border flex items-center justify-center border-aboutcolor active:opacity-50 cursor-pointer"
                                              onClick={clear}
                                            >
                                              Clear
                                            </div>
                                            <div
                                              className="px-10 py-2  flex items-center justify-center active:opacity-50 cursor-pointer bg-purple"
                                              onClick={handleDone}
                                            >
                                              Done
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" w-full flex justify-between pt-20 pb-20 ">
                        <div
                          className="ml-5 bg-white lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8 w-24 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50 "
                          onClick={() => {
                            setchangepanel("Country");
                            // handleScrollToTop();
                          }}
                        >
                          <div className=" text-purple flex justify-between font-semibold lg:w-50p md:w-50p sm:w-75p w-68p items-center  ">
                            <img
                              src={images.purplearrowleft}
                              alt=""
                              className="w-2 h-3 "
                            />
                            Previous
                          </div>
                        </div>
                        <div
                          className="mr-5 text-white bg-purple lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8 w-24 ml-5 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
                          onClick={() => {
                            if (validateDescription()) {
                              setchangepanel("Contact Details");
                            }
                          }}
                        >
                          <div className="text-white flex justify-between font-semibold lg:w-40p w-50p  items-center">
                            Next
                            <img
                              src={images.smallarrowright}
                              alt=""
                              className="w-2 h-3"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : changepanel === "Contact Details" ? (
                    <>
                      <div className=" w-full  flex flex-col  items-center">
                        <div className="bg-weirdgray w-[90%] lg:w-full rounded-lg">
                          <div className="flex p-3 items-center gap-4 ">
                            <div className="text-white lg:text-2xl md:text-xl sm:text-sm text-sm ">
                              Contact Details
                            </div>
                          </div>
                          <div className="bg-buttoncolor text-white pb-5 rounded-bl-lg rounded-br-lg">
                            <div className="flex md:flex-row flex-col flex-col pt-5 ml-3 lg:ml-5">
                              <div className="w-full mt-5">
                                <div className=" lg:text-base md:text-base sm:text-xxs text-xxs pb-2">
                                  <div className="flex items-center gap-2">
                                    <div>Hour/Rate</div>
                                    <div className="text-purple text-sm  ">
                                      (optional)
                                    </div>
                                  </div>
                                </div>
                                <input
                                  type="number"
                                  className="bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-sm  w-[93%] border rounded-lg border-whitepink border-opacity-20 lg:h-12 md:h-10 h-10 pl-3 "
                                  placeholder="..."
                                  value={hourlyRate}
                                  onChange={(e) => {
                                    setHourlyRate(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="w-full lg:ml-5 lg:pt-0">
                                <div className="lg:text-base md:text-base sm:text-xxs text-xxs mt-5 pb-2">
                                  City
                                </div>
                                <div className="relative w-[93%]">
                                  <div
                                    className="bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                border rounded-lg flex items-center cursor-pointer justify-between  border-whitepink border-opacity-20 lg:h-12 md:h-10 sm:h-10 h-10  px-3
                              relative  "
                                    onClick={() => {
                                      setDropDownDescription(
                                        dropDownDescription === "city"
                                          ? ""
                                          : "city"
                                      );
                                    }}
                                  >
                                    {selectedcity}
                                  </div>
                                  <div className="absolute w-full mt-1 z-10  max-h-[200px] overflow-auto mt-1 rounded-lg ">
                                    {dropDownDescription === "city" && (
                                      <div className="bg-weirdgray w-full ">
                                        {filteredcities?.map((item, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base   border-whitepink border-b  px-4 border-opacity-10 p-2"
                                              onClick={() => {
                                                setSelectedcity(item.name);
                                                setDropDownDescription("");
                                              }}
                                            >
                                              {item.name}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex md:flex-row flex-col flex-col pt-5 ml-3 lg:ml-5">
                              <div className="w-full">
                                <div className="pb-2 lg:text-base md:text-base sm:text-xxs text-xxs">
                                  <div className="flex items-center gap-2">
                                    <div>Phone Number</div>
                                    <div className="text-purple text-sm  ">
                                      (optional)
                                    </div>
                                  </div>
                                </div>
                                <input
                                  type="number"
                                  className="bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-sm w-[93%] border rounded-lg border-whitepink border-opacity-20 lg:h-12 md:h-10 h-10 pl-3"
                                  placeholder="Enter Phone Number"
                                  value={phoneNumber}
                                  onChange={(e) => {
                                    setPhoneNumber(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="w-full lg:ml-5 lg:pt-0">
                                <div className="pb-2 lg:text-base md:text-base sm:text-xxs text-xxs mt-5 md:mt-0">
                                  Email Address
                                </div>
                                <input
                                  type="text"
                                  className="bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-sm w-[93%] border rounded-lg border-whitepink border-opacity-20 lg:h-12 md:h-10 h-10 pl-3 "
                                  placeholder="Enter Email Address "
                                  value={emailAddress}
                                  onChange={(e) => {
                                    setEmailAddress(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="pt-4 ml-3 lg:ml-5 lg:text-base md:text-base sm:text-xxs text-xxs">
                              <FontAwesomeIcon
                                icon={allowWahtsapp ? faCheckSquare : faSquare}
                                size="1x"
                                onClick={handlewhatsappAgree}
                                style={{
                                  height: "12px",
                                  width: "12px",
                                  cursor: "pointer",
                                  color: allowWahtsapp
                                    ? "#BE42DE"
                                    : "transparent",
                                  border: "1px solid #615770",
                                  marginRight: "8px",
                                }}
                              />
                              Allow Message via Whatsapp
                            </div>
                            <div className="flex md:flex-row flex-col flex-col pt-5 ml-3 lg:ml-5">
                              <div className="w-full">
                                <div className="pb-2 lg:text-base md:text-base sm:text-xxs text-xxs">
                                  <div className="flex items-center gap-2">
                                    <div>Website</div>
                                    <div className="text-purple text-sm">
                                      (optional)
                                    </div>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className="bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-sm w-[93%] border rounded-lg border-whitepink border-opacity-20 lg:h-12 md:h-10  h-10 pl-3 "
                                  placeholder="..."
                                  value={website}
                                  onChange={(e) => {
                                    setWebsite(e.target.value);
                                  }}
                                />
                              </div>

                              <div className="w-full lg:ml-5 lg:pt-0 pt-2">
                                <div className="pb-2 lg:text-base md:text-base sm:text-xxs text-xxs">
                                  <div className="flex items-center gap-2">
                                    <div>Language Spoken</div>
                                    <div className="text-purple text-sm  ">
                                      (optional)
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className={`bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                         border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-12 md:h-10 sm:h-10 h-10  px-3
                                       relative w-[93%] `}
                                    onClick={() =>
                                      setDropDownDescription(
                                        dropDownDescription === "language"
                                          ? ""
                                          : "language"
                                      )
                                    }
                                  >
                                    <div className="truncate max-w-[300px]">
                                      {languagesSpoken === null ? (
                                        <div></div>
                                      ) : (
                                        <div className="">
                                          {`${languagesSpoken}`}
                                        </div>
                                      )}
                                    </div>
                                    {dropDownDescription === "language" ? (
                                      <div>
                                        <img
                                          src={images.dropDownOpen}
                                          alt=""
                                          className="h-2 w-3 md:h-3 md:w-4"
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <img
                                          src={images.dropDownClosed}
                                          alt=""
                                          className="h-2 w-3 md:h-3 md:w-4"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {dropDownDescription === "language" && (
                                    <div
                                      className="fixed z-10 text-black  top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50"
                                      onClick={closeLanguageModal}
                                    >
                                      <div
                                        className="bg-purplegray w-[90%] sm:w-[70%] md:w-[40%] lg:w-[30%] max-w-[480px] pb-4 text-white rounded-lg"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <div className="p-4 text-sm">
                                          <div>languages:</div>
                                          <div>
                                            {selectedLanguage.length === 0 ? (
                                              <div>....</div>
                                            ) : (
                                              <div className="text-xxs">
                                                {`${selectedLanguage} `}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="flex items-center justify-center">
                                          <div className=" w-[90%] flex items-center pl-2  bg-aboutcolor h-8 rounded-lg">
                                            <img
                                              src={images.search}
                                              alt=""
                                              className="w-3 h-3 cursor-pointer "
                                            />
                                            <input
                                              type="text"
                                              className="bg-transparent  focus:outline-none pl-2 text-sm "
                                              value={search}
                                              placeholder="Search"
                                              onChange={(e) =>
                                                setSearch(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className=" flex w-full flex-col overflow-auto max-h-[330px] pt-4 pl-5 pb-4 ">
                                          {languageData
                                            .filter((item) =>
                                              item
                                                .toLowerCase()
                                                .includes(search.toLowerCase())
                                            )
                                            ?.map((item, index) => (
                                              <div
                                                key={index}
                                                className="flex w-[100%] my-2 "
                                                onClick={() =>
                                                  handleLanguage(item)
                                                }
                                              >
                                                <div className=" items-center flex justify-center pr-5">
                                                  <div>
                                                    {selectedLanguage.includes(
                                                      item
                                                    ) ? (
                                                      <img
                                                        src={images.checked}
                                                        alt=""
                                                        className="h-5 w-5"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={images.unchecked}
                                                        alt=""
                                                        className="h-5 w-5"
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                                {item}
                                              </div>
                                            ))}
                                        </div>
                                        <div className="flex items-center flex-col justify-center gap-2   border-t border-aboutcolor   ">
                                          <div>
                                            {languageLimit && (
                                              <div
                                                className="shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]
                                                    flex items-center justify-center gap-2  px-4 text-sm text-red-500  rounded-3xl"
                                              >
                                                <div>
                                                  You’ve sel ected the maximum
                                                  of 6 additional ethnicities.
                                                </div>
                                                <div
                                                  onClick={
                                                    deselectMostRecentLanguage
                                                  }
                                                  className="border rounded-full text-xxs w-4 h-4 cursor-pointer flex items-center border-red-500 justify-center"
                                                >
                                                  X
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          <div className="flex gap-5 mt-2">
                                            <div
                                              className="px-10 py-2 border flex items-center justify-center border-aboutcolor active:opacity-50 cursor-pointer"
                                              onClick={clearLanguage}
                                            >
                                              Clear
                                            </div>
                                            <div
                                              className="px-10 py-2  flex items-center justify-center active:opacity-50 cursor-pointer bg-purple"
                                              onClick={handleDoneLanguage}
                                            >
                                              Done
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-buttoncolor text-white w-[90%] lg:w-full  mt-10 rounded-lg pt-2">
                          <div className="flex lg:flex-row   md:flex-row sm:flex-col flex-col  w-full justify-center items-center p-4">
                            <div className="flex flex-col w-full  lg:pl-2 ">
                              <div className="lg:text-center text-start lg:text-base md:text-base sm:text-xxs pb-2 text-xxs">
                                Anonymous Contact Form
                              </div>
                              <div className="flex w-full gap-3 flex-row  lg:gap-10  mr-5">
                                <div
                                  className={`lg:w-50p w-full  h-10 cursor-pointer active:opacity-50 items-center  flex justify-center text-center border rounded-lg ${
                                    contactForm
                                      ? "bg-purple text-white"
                                      : "bg-transparent"
                                  }`}
                                  onClick={() => setContactform(true)}
                                >
                                  Yes
                                </div>
                                <div
                                  className={`lg:w-50p w-full h-10 cursor-pointer active:opacity-50 items-center  flex justify-center text-center border rounded-lg ${
                                    !contactForm
                                      ? "bg-purple text-white"
                                      : "bg-transparent"
                                  }`}
                                  onClick={() => setContactform(false)}
                                >
                                  No
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col w-full mt-3 md:mt-0 md:ml-2 md:mr-2">
                              <div className="lg:text-center md:text-center sm:text-start text-start lg:text-base md:text-base sm:text-xxs text-xxs pb-2">
                                Show my Recent ads
                              </div>
                              <div className="flex flex-row w-full gap-3 lg:gap-8 mr-5">
                                <div
                                  className={`lg:text-base md:text-base sm:text-base text-sm lg:w-50p w-full h-10 cursor-pointer active:opacity-50 items-center flex justify-center text-center border rounded-lg ${
                                    recentAds
                                      ? "bg-purple text-white"
                                      : "bg-transparent"
                                  }`}
                                  onClick={() => setRecentads(true)}
                                >
                                  Yes
                                </div>
                                <div
                                  className={`lg:text-center md:text-center sm:text-start text-start md:text-base sm:text-base text-sm lg:w-50p w-full h-10 cursor-pointer active:opacity-50 items-center  flex justify-center text-center border rounded-lg ${
                                    !recentAds
                                      ? "bg-purple text-white"
                                      : "bg-transparent"
                                  }`}
                                  onClick={() => setRecentads(false)}
                                >
                                  No
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col w-full mt-3 md:mt-0">
                              <div className="lg:text-center md:text-center sm:text-start text-start lg:text-base md:text-base sm:text-xxs text-xxs pb-2">
                                Show my Lyla.ch Reviews
                              </div>
                              <div className="flex flex-row w-full gap-3 lg:gap-8  mr-0 md:mr-5 sm:mr-5 mr-5">
                                <div
                                  className={`lg:text-base md:text-base sm:text-base text-sm lg:w-50p w-full h-10 items-center flex justify-center text-center cursor-pointer active:opacity-50 border rounded-lg ${
                                    Reviewsbtn
                                      ? "bg-purple text-white"
                                      : "bg-transparent"
                                  }`}
                                  onClick={() => setReviewsbtn(true)}
                                >
                                  Yes
                                </div>
                                <div
                                  className={`lg:text-base md:text-base sm:text-base text-sm lg:w-50p w-full h-10 items-center  flex justify-center text-center cursor-pointer active:opacity-50 border rounded-lg ${
                                    !Reviewsbtn
                                      ? "bg-purple text-white"
                                      : "bg-transparent"
                                  }`}
                                  onClick={() => setReviewsbtn(false)}
                                >
                                  No
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pt-3 pl-4 fk">
                            Availability
                            <div className="pb-10 flex flex flex-wrap items-center lg:gap-40 md:gap-20 sm:gap-10 gap-10">
                              <div className="pt-2 flex items-center ">
                                <FontAwesomeIcon
                                  icon={inCallagree ? faCheckSquare : faSquare}
                                  size="1x"
                                  onClick={handleIncallAgree}
                                  style={{
                                    height: "14px",
                                    width: "14px",
                                    cursor: "pointer",
                                    color: inCallagree
                                      ? "#BE42DE"
                                      : "transparent",
                                    border: "1px solid #615770",
                                    marginRight: "8px",
                                  }}
                                />
                                <div className="lg:text-xl md:text-lg sm:text-base text-sm">
                                  Incall
                                </div>
                              </div>
                              <div className="pt-2 flex items-center ">
                                <FontAwesomeIcon
                                  icon={outCall ? faCheckSquare : faSquare}
                                  size="1x"
                                  onClick={handleOutcall}
                                  style={{
                                    height: "14px",
                                    width: "14px",
                                    cursor: "pointer",
                                    color: outCall ? "#BE42DE" : "transparent",
                                    border: "1px solid #615770",
                                    marginRight: "8px",
                                  }}
                                />
                                <div className="lg:text-xl md:text-lg sm:text-base text-sm">
                                  Out call
                                </div>
                              </div>
                              <div className="pt-2 flex items-center ">
                                <FontAwesomeIcon
                                  icon={onlineagree ? faCheckSquare : faSquare}
                                  size="1x"
                                  onClick={handleOncall}
                                  style={{
                                    height: "14px",
                                    width: "14px",
                                    cursor: "pointer",
                                    color: onlineagree
                                      ? "#BE42DE"
                                      : "transparent",
                                    border: "1px solid #615770",
                                    marginRight: "8px",
                                  }}
                                />
                                <div className="lg:text-xl md:text-lg sm:text-base text-sm">
                                  Online
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" w-full flex justify-between pt-20 pb-20 ">
                          <div
                            className="ml-5 bg-white lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8 w-24 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
                            onClick={() => {
                              setchangepanel("Description");
                              emptyError();
                            }}
                          >
                            <div className="text-purple flex justify-between font-semibold lg:w-50p md:w-0p sm:w-75p w-68p items-center  ">
                              <img
                                src={images.purplearrowleft}
                                alt=""
                                className="w-2 h-3 "
                              />
                              Previous
                            </div>
                          </div>
                          <div
                            className="mr-5 text-white bg-purple lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8 w-24 ml-5 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
                            onClick={() => {
                              if (validateContacts()) {
                                setchangepanel("uploadimg");
                              }
                            }}
                          >
                            <div className="text-white flex justify-between font-semibold lg:w-40p w-50p  items-center">
                              Next
                              <img
                                src={images.smallarrowright}
                                alt=""
                                className="w-2 h-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : changepanel === "uploadimg" ? (
                    <>
                      <div className="w-full flex flex-col items-center ">
                        <div className="bg-weirdgray w-[90%] smx:w-full text-whitepink  lg:text-2xl md:text-xl sm:text-sm text-sm p-4 rounded-t-xl ">
                          Upload your Images
                        </div>
                        <div className="bg-buttoncolor   w-[90%] text-white lg:p-10 md:p-10 sm:p-10  py-10   flex flex-col justify-evenly rounded-b-xl">
                          <div className="flex pb-10 gap-10">
                            <label htmlFor="fileInput1" className="w-full">
                              <div
                                className="bg-purple  w-[90%] mx-auto lg:h-60 md:h-60 sm:h-60 p-4 text-nowrap rounded-xl active:opacity-50 items-center justify-center cursor-pointer flex-col flex"
                                onClick={() =>
                                  !uploadImgLoader && onButtonClick()
                                }
                              >
                                {uploadImgLoader ? (
                                  <div>
                                    <ClipLoader
                                      color="#FFFF"
                                      cssOverride={{
                                        height: "25px",
                                        width: "25px",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col items-center gap-2 ">
                                    <img
                                      src={images.uplaodedimg22}
                                      alt=""
                                      className="lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-3"
                                    />
                                    <div className="font-bold lg:text-2xl ">
                                      Add Pictures
                                    </div>
                                  </div>
                                )}
                                <input
                                  style={{ display: "none" }}
                                  type="file"
                                  onChange={handleImageChange}
                                  ref={inputFile}
                                  multiple
                                  accept="image/png,image/jpeg"
                                />
                              </div>
                            </label>
                          </div>

                          <div className="flex gap-10">
                            <label htmlFor="fileInput2" className="w-full">
                              <div
                                className="bg-purple  w-[90%] mx-auto lg:h-60 md:h-60 sm:h-60 p-4 text-nowrap rounded-xl active:opacity-50 items-center justify-center cursor-pointer flex-col flex"
                                onClick={() =>
                                  !videoLoader && onVideoButtonClick()
                                }
                              >
                                {videoLoader ? (
                                  <div>
                                    <ClipLoader
                                      color="#FFFF"
                                      cssOverride={{
                                        height: "25px",
                                        width: "25px",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col items-center gap-2 ">
                                    <img
                                      src={images.uplaodedimg22}
                                      alt=""
                                      className="lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-3"
                                    />
                                    <div className="font-bold lg:text-2xl ">
                                      Add Videos
                                    </div>
                                  </div>
                                )}
                                <input
                                  style={{ display: "none" }}
                                  type="file"
                                  onChange={handleVideoChange}
                                  ref={inputFileVideo}
                                  multiple
                                  accept="video/mp4,video/x-m4v,video/*"
                                />
                              </div>
                            </label>
                          </div>

                          <div className="flex w-[100%] mt-10">
                            <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-2 items-center justify-center w-full">
                              {uploadimg?.map((image, index) => {
                                return (
                                  <div key={index} className="">
                                    <div
                                      className="h-20"
                                      onClick={() => handleImageClick(index)}
                                    >
                                      <div className="relative">
                                        <img
                                          key={image}
                                          src={image}
                                          className="h-20 w-20"
                                          // alt={`Uploaded ${index + 1}`}
                                        />
                                        <div
                                          className="absolute active:opacity-50 cursor-pointer top-0 right-0  w-5 h-5  text-xs bg-purple text-white border-2 font-semibold rounded-full items-center justify-center flex"
                                          onClick={() =>
                                            handleDeleteImage(index)
                                          }
                                        >
                                          X
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="flex w-full justify-center text-lg pt-10">
                            Pictures {uploadimg?.length}/15
                          </div>
                          {video?.length > 0 && (
                            <div className="flex flex-row gap-2">
                              {video?.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="gap-5 mt-5 w-[50%] flex flex-row w-[150px] sm:w-[250px] md:w-[300px] lg:w-[350px] relative mx-auto"
                                  >
                                    <div
                                      onClick={() => handleRemoveVideo(index)}
                                      className="z-10 absolute active:opacity-50 cursor-pointer top-1 right-1  w-6 h-6  text-xs bg-purple text-white border-2 font-semibold rounded-full items-center justify-center flex"
                                    >
                                      X
                                    </div>
                                    <video
                                      controls
                                      key={item}
                                      className="w-[100%]"
                                    >
                                      <source src={item} />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          <div className="flex w-full justify-center text-lg pt-10">
                            Videos {video?.length}/2 (optional)
                          </div>
                        </div>
                        <div className=" w-full flex justify-between pt-20 pb-20 ">
                          <div
                            className="bg-white ml-4 lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8 w-24 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
                            onClick={() => {
                              setchangepanel("Contact Details");
                              emptyError();
                            }}
                          >
                            <div className="text-purple flex justify-between font-semibold lg:w-50p md:w-0p sm:w-75p w-68p items-center  ">
                              <img
                                src={images.purplearrowleft}
                                alt=""
                                className="w-2 h-3 "
                              />
                              Previous
                            </div>
                          </div>
                          <div
                            className="mr-5 text-white bg-purple lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8 w-24 ml-5 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
                            onClick={() => handleSubmit()}
                          >
                            <div className="text-white flex justify-between font-semibold lg:w-40p w-50p  items-center">
                              Next
                              <img
                                src={images.smallarrowright}
                                alt=""
                                className="w-2 h-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : changepanel === "chooseupgrade" ? (
                    <>
                      <div className="text-white flex flex-col items-center  ">
                        <div className="lg:w-full w-[95%]">
                          <div className="bg-weirdgray rounded-lg">
                            <div className="flex p-3 items-center gap-4">
                              <div className="text-white lg:text-2xl md:text-xl sm:text-sm text-sm">
                                Choose Your Upgrades
                              </div>
                            </div>
                            <div className="bg-buttoncolor flex text-white flex flex-col lg:flex-row gap-2 py-4">
                              {/* BOOSTS CONTENT */}
                              <div className="w-full flex flex-col gap-4 px-2">
                                <div className="w-full shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] p-4">
                                  <div
                                    className="cursor-pointer active:opacity-50"
                                    onClick={() =>
                                      hideBoosts(
                                        boost === "boost" ? "" : "boost"
                                      )
                                    }
                                  >
                                    <div className="lg:text-2xl md:text-xl sm:text-sm text-sm font-semibold cursor-pointer ">
                                      Boosts
                                    </div>
                                    <div className="text-whitepink  opacity-80 lg:text-base md:text-base sm:text-sm text-xs">
                                      Upgrades that move your ad to the top of
                                      the list
                                    </div>
                                  </div>
                                  {boost === "boost" && (
                                    <div>
                                      <div className="pt-5 ">
                                        <div className="gap-20 flex w-full">
                                          <div className="lg:w-90p w-full ">
                                            <div className="flex flex-col w-full ">
                                              <div className="bg-purple w-full pl-3 md:pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                                                page 1
                                              </div>
                                              <div className="flex flex-col ">
                                                <div className="border p-3 md:p-10">
                                                  {selectedPageOneSlot ===
                                                  null ? (
                                                    <div className="pb-4 lg:text-base lg:text-base sm:text-base text-sm  flex flex-row items-center justify-between">
                                                      Automatically keeps your
                                                      ad on Page 1!
                                                      {pageOne && (
                                                        <div
                                                          className="px-2 py-1 text-xs cursor-pointer border flex md:hidden items-center justify-center"
                                                          onClick={() => {
                                                            setPageone(false);
                                                            setTime("");
                                                          }}
                                                        >
                                                          X
                                                        </div>
                                                      )}
                                                    </div>
                                                  ) : null}

                                                  <div>
                                                    {selectedPageOneSlot ===
                                                    null ? (
                                                      <div>
                                                        {pageOne ? (
                                                          <div className="flex flex-col">
                                                            <div className="flex gap-2 w-full">
                                                              <div className="flex gap-2 w-full">
                                                                <div
                                                                  className={
                                                                    selectHour ===
                                                                    "three"
                                                                      ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                                      : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                                  }
                                                                  onClick={() =>
                                                                    setSelecthour(
                                                                      "three"
                                                                    )
                                                                  }
                                                                >
                                                                  3 hours
                                                                </div>
                                                                <div
                                                                  className={
                                                                    selectHour ===
                                                                    "five"
                                                                      ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                                      : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                                  }
                                                                  onClick={() =>
                                                                    setSelecthour(
                                                                      "five"
                                                                    )
                                                                  }
                                                                >
                                                                  5 hours
                                                                </div>
                                                                <div
                                                                  className={
                                                                    selectHour ===
                                                                    "seven"
                                                                      ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                                      : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                                  }
                                                                  onClick={() =>
                                                                    setSelecthour(
                                                                      "seven"
                                                                    )
                                                                  }
                                                                >
                                                                  7 hours
                                                                </div>
                                                              </div>
                                                              <div
                                                                className=" h-13 w-16 cursor-pointer border hidden md:flex items-center justify-center"
                                                                onClick={() => {
                                                                  setPageone(
                                                                    false
                                                                  );
                                                                  setTime("");
                                                                }}
                                                              >
                                                                X
                                                              </div>
                                                            </div>
                                                            <div className="mt-10 flex flex-col gap-4 ">
                                                              <div
                                                                onClick={() => {
                                                                  if (
                                                                    selectHour ===
                                                                    "three"
                                                                  ) {
                                                                    handleSetPageOne(
                                                                      immediatelyPageOneThreeHours
                                                                    );
                                                                  } else if (
                                                                    selectHour ===
                                                                    "five"
                                                                  ) {
                                                                    handleSetPageOne(
                                                                      immediatelyPageOneFiveHours
                                                                    );
                                                                  } else {
                                                                    handleSetPageOne(
                                                                      immediatelyPageOneSevenHours
                                                                    );
                                                                  }
                                                                }}
                                                                className=" border h-24 cursor-pointer justify-between px-4 active:opacity-50 items-center  flex  "
                                                              >
                                                                <div className="text-sm flex flex-col ">
                                                                  <div className="font-bold">
                                                                    Now
                                                                  </div>
                                                                  <div className="flex ">
                                                                    Activates
                                                                    immediately
                                                                    after
                                                                    payment
                                                                    <div className="text-purple pl-1">
                                                                      {selectHour ===
                                                                      "three"
                                                                        ? pageOnedata
                                                                        : selectHour ===
                                                                          "five"
                                                                        ? pageTwodata
                                                                        : selectHour ===
                                                                          "seven"
                                                                        ? pageThreedata
                                                                        : null}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="text-2xl ml-1">
                                                                  +
                                                                </div>
                                                              </div>
                                                              <div
                                                                className="border h-auto py-6 cursor-pointer justify-between flex-col   px-4
                                                           flex  "
                                                              >
                                                                <div
                                                                  className="flex  w-full justify-between"
                                                                  onClick={() =>
                                                                    setTime(
                                                                      time ===
                                                                        "morning"
                                                                        ? ""
                                                                        : "morning"
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-sm">
                                                                    <div className="font-bold">
                                                                      Morning
                                                                    </div>
                                                                    <div className="flex gap-1">
                                                                      {`6am - 12pm -   ${
                                                                        selectHour ===
                                                                        "three"
                                                                          ? morningData?.length
                                                                          : selectHour ===
                                                                            "five"
                                                                          ? fiveHoursSlotMorning?.length
                                                                          : selectHour ===
                                                                            "seven"
                                                                          ? sevenHoursSlotMorning.length
                                                                          : null
                                                                      } slots Available from `}
                                                                      <div className="text-purple">
                                                                        {selectHour ===
                                                                        "three"
                                                                          ? pageOnedata
                                                                          : selectHour ===
                                                                            "five"
                                                                          ? pageTwodata
                                                                          : selectHour ===
                                                                            "seven"
                                                                          ? pageThreedata
                                                                          : null}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="flex items-center  ">
                                                                    <img
                                                                      src={
                                                                        images.dropdownarrow
                                                                      }
                                                                      alt=""
                                                                      className="h-4 w-6"
                                                                    />
                                                                  </div>
                                                                </div>
                                                                {selectHour ===
                                                                "three" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "morning" && (
                                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                        {morningData?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              key={
                                                                                index
                                                                              }
                                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2 "
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : selectHour ===
                                                                  "five" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "morning" && (
                                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                        {fiveHoursSlotMorning?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              key={
                                                                                index
                                                                              }
                                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2 "
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : selectHour ===
                                                                  "seven" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "morning" && (
                                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                        {sevenHoursSlotMorning?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              key={
                                                                                index
                                                                              }
                                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2                                                                                "
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                              <div className="border py-4 cursor-pointer  justify-center px-4 flex flex-col  ">
                                                                <div
                                                                  className="flex  w-full justify-between"
                                                                  onClick={() =>
                                                                    setTime(
                                                                      time ===
                                                                        "afternoon"
                                                                        ? ""
                                                                        : "afternoon"
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-sm">
                                                                    <div className="font-bold">
                                                                      Afternoon
                                                                    </div>
                                                                    <div className="flex gap-1">
                                                                      12pm - 6pm
                                                                      -
                                                                      {selectHour ===
                                                                      "three"
                                                                        ? afterNoondata?.length
                                                                        : selectHour ===
                                                                          "five"
                                                                        ? fiveHoursSlotAfternoon?.length
                                                                        : selectHour ===
                                                                          "seven"
                                                                        ? sevenHoursSlotAfternoon.length
                                                                        : null}{" "}
                                                                      slots
                                                                      Available
                                                                      from
                                                                      <div className="text-purple">
                                                                        {selectHour ===
                                                                        "three"
                                                                          ? pageOnedata
                                                                          : selectHour ===
                                                                            "five"
                                                                          ? pageTwodata
                                                                          : selectHour ===
                                                                            "seven"
                                                                          ? pageThreedata
                                                                          : null}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="flex items-center w-6 ">
                                                                    <img
                                                                      src={
                                                                        images.dropdownarrow
                                                                      }
                                                                      alt=""
                                                                      className="h-4"
                                                                    />
                                                                  </div>
                                                                </div>
                                                                {selectHour ===
                                                                "three" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "afternoon" && (
                                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                        {afterNoondata?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              className="bg-slightydarkerBackgorund  justify-center cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                              key={
                                                                                index
                                                                              }
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : selectHour ===
                                                                  "five" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "afternoon" && (
                                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                        {fiveHoursSlotAfternoon?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                              key={
                                                                                index
                                                                              }
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : selectHour ===
                                                                  "seven" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "afternoon" && (
                                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                        {sevenHoursSlotAfternoon?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                              key={
                                                                                index
                                                                              }
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                              <div className=" border py-4 cursor-pointer justify-center px-4 flex flex-col  ">
                                                                <div
                                                                  className="flex  w-full justify-between"
                                                                  onClick={() =>
                                                                    setTime(
                                                                      time ===
                                                                        "evening"
                                                                        ? ""
                                                                        : "evening"
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-sm">
                                                                    <div className="font-bold">
                                                                      Evenings
                                                                    </div>
                                                                    <div className="flex gap-1">
                                                                      6pm - 12am
                                                                      -{" "}
                                                                      {selectHour ===
                                                                      "three"
                                                                        ? eveningData?.length
                                                                        : selectHour ===
                                                                          "five"
                                                                        ? fiveHoursSlotEvening?.length
                                                                        : selectHour ===
                                                                          "seven"
                                                                        ? sevenHoursSlotEvening.length
                                                                        : null}{" "}
                                                                      slots
                                                                      Available
                                                                      from
                                                                      <div className="text-purple">
                                                                        {selectHour ===
                                                                        "three"
                                                                          ? pageOnedata
                                                                          : selectHour ===
                                                                            "five"
                                                                          ? pageTwodata
                                                                          : selectHour ===
                                                                            "seven"
                                                                          ? pageThreedata
                                                                          : null}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="flex items-center w-6 ">
                                                                    <img
                                                                      src={
                                                                        images.dropdownarrow
                                                                      }
                                                                      alt=""
                                                                      className="h-4"
                                                                    />
                                                                  </div>
                                                                </div>
                                                                {selectHour ===
                                                                "three" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "evening" && (
                                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                        {eveningData?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                              key={
                                                                                index
                                                                              }
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : selectHour ===
                                                                  "five" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "evening" && (
                                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                        {fiveHoursSlotEvening?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2 "
                                                                              key={
                                                                                index
                                                                              }
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : selectHour ===
                                                                  "seven" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "evening" && (
                                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                        {sevenHoursSlotEvening?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2                                                                                "
                                                                              key={
                                                                                index
                                                                              }
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                              <div className=" border py-4 cursor-pointer justify-center px-4 flex flex-col  ">
                                                                <div
                                                                  className="flex  w-full justify-between"
                                                                  onClick={() =>
                                                                    setTime(
                                                                      time ===
                                                                        "night"
                                                                        ? ""
                                                                        : "night"
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="text-sm">
                                                                    <div className="font-bold">
                                                                      Late Night
                                                                    </div>
                                                                    <div className="flex gap-1">
                                                                      12am - 6am
                                                                      -{" "}
                                                                      {selectHour ===
                                                                      "three"
                                                                        ? lateNightdata?.length
                                                                        : selectHour ===
                                                                          "five"
                                                                        ? fiveHoursSlotLateNight?.length
                                                                        : selectHour ===
                                                                          "seven"
                                                                        ? sevenHoursSlotLateNight.length
                                                                        : null}{" "}
                                                                      slots
                                                                      Available
                                                                      from
                                                                      <div className="text-purple">
                                                                        {selectHour ===
                                                                        "three"
                                                                          ? pageOnedata
                                                                          : selectHour ===
                                                                            "five"
                                                                          ? pageTwodata
                                                                          : selectHour ===
                                                                            "seven"
                                                                          ? pageThreedata
                                                                          : null}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="flex items-center w-6 ">
                                                                    <img
                                                                      src={
                                                                        images.dropdownarrow
                                                                      }
                                                                      alt=""
                                                                      className="h-4"
                                                                    />
                                                                  </div>
                                                                </div>

                                                                {selectHour ===
                                                                "three" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "night" && (
                                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                        {lateNightdata?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                              key={
                                                                                index
                                                                              }
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : selectHour ===
                                                                  "five" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "night" && (
                                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                        {fiveHoursSlotLateNight?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                              key={
                                                                                index
                                                                              }
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : selectHour ===
                                                                  "seven" ? (
                                                                  <div>
                                                                    {time ===
                                                                      "night" && (
                                                                      <div
                                                                        className=" grid grid-cols-2 md:grid-cols-3 gap-4 mt-4
                                                                        "
                                                                      >
                                                                        {sevenHoursSlotLateNight?.map(
                                                                          (
                                                                            item,
                                                                            index
                                                                          ) => (
                                                                            <div
                                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2
                                                                                "
                                                                              key={
                                                                                index
                                                                              }
                                                                              onClick={() => {
                                                                                handleSetPageOne(
                                                                                  item
                                                                                );
                                                                              }}
                                                                            >
                                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div
                                                            className="bg-purple mx-auto md:mx-0 text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full  h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50 lg:text-sm md:text-sm sm:text-sm text-xs"
                                                            onClick={() => {
                                                              if (
                                                                selectPageTwoSLot
                                                              ) {
                                                                setConditionModalTitle(
                                                                  "You can’t have Page1 and Love Ad at the same time"
                                                                );
                                                                setConditionModalSubject(
                                                                  "If you add Page1, it will remove yourLove Ad. Are you sure you want to add Page1?"
                                                                );
                                                                setConditionalModalBtnText(
                                                                  "Yes, Add Page1 anyway"
                                                                );
                                                                setOpenCondtionalModal(
                                                                  true
                                                                );
                                                                setSelecthour(
                                                                  "five"
                                                                );
                                                              } else {
                                                                setPageone(
                                                                  true
                                                                );
                                                                setSelecthour(
                                                                  "five"
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            Add Now from CAD
                                                            $1.631/hour
                                                            <img
                                                              src={
                                                                images.Vector4
                                                              }
                                                              alt=""
                                                              className="lg:w-2 lg:h-2 w-auto h-2 "
                                                            />
                                                          </div>
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div className="w-full">
                                                        {selectedPageOneSlot && (
                                                          <div className="shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-full h-auto p-2 md:p-5 text-xxs sm:text-sm">
                                                            <div className="flex flex-col border-b pb-2">
                                                              <div>{`${selectedPageOneSlot?.startTime} - ${selectedPageOneSlot?.endTime}`}</div>
                                                              <div className="flex gap-2 items-end">
                                                                <div>{`Guaranteed to stay on Page 1 for ${
                                                                  selectedPageOneSlot?.hour ===
                                                                  "five"
                                                                    ? `5`
                                                                    : selectedPageOneSlot?.hour ===
                                                                      "three"
                                                                    ? `3`
                                                                    : `7`
                                                                }  hours.`}</div>
                                                                <div className="whitespace-nowrap">{`$ ${selectedPageOneSlot?.cost}`}</div>
                                                              </div>
                                                            </div>
                                                            <div className="flex w-full gap-10 pt-2">
                                                              <div
                                                                className="cursor-pointer active:opacity-50 items-center flex gap-2"
                                                                onClick={
                                                                  handleEditPageOne
                                                                }
                                                              >
                                                                <img
                                                                  src={
                                                                    images.whiteSettings
                                                                  }
                                                                  alt=""
                                                                  className="w-4 h-4"
                                                                />
                                                                <div>Edit</div>
                                                              </div>
                                                              <div
                                                                className="cursor-pointer active:opacity-50 items-center flex gap-2"
                                                                onClick={
                                                                  handleRemovePageOne
                                                                }
                                                              >
                                                                <img
                                                                  src={
                                                                    images.whiteCross
                                                                  }
                                                                  alt=""
                                                                  className="w-4 h-4"
                                                                />
                                                                <div>
                                                                  Cancel
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {coditionalModal && (
                                        <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                                          <div className="bg-white w-full max-w-lg mx-4 sm:mx-auto h-auto sm:h-[400px] rounded-lg">
                                            <div className="w-full flex-col gap-6 mx-auto mt-8 sm:mt-16 flex items-center justify-center border-b-4 border-purple pb-10 sm:pb-20">
                                              <div className="text-lg sm:text-3xl font-bold w-full sm:w-[500px] text-center">
                                                {conditionModalTitle}
                                              </div>
                                              <div className="text-sm sm:text-xl text-opacity-20 w-full sm:w-[500px] text-center">
                                                {conditionModalSubject}
                                              </div>
                                            </div>
                                            <div className="bg-darkerpurple h-auto sm:h-[25%] text-white items-center px-4 py-2 sm:py-0 flex justify-between w-full">
                                              <div
                                                className="border border-purple p-2 sm:p-4 rounded-xl active:bg-purple cursor-pointer"
                                                onClick={() => {
                                                  setOpenCondtionalModal(false);
                                                }}
                                              >
                                                Cancel
                                              </div>
                                              <div
                                                onClick={() => closeModal()}
                                                className="text-sm sm:text-xl p-2 sm:p-3 bg-purple rounded-xl cursor-pointer active:opacity-50"
                                              >
                                                {conditionalModalBtnText}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      <div className="pt-10">
                                        <div className="flex flex-col w-full">
                                          <div className="bg-purple lg:w-90p w-full pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                                            Page 1 + Floral Ad
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="border p-3 md:p-10 lg:w-90p w-full text-sm  ">
                                              <div className="pb-5 text-whitepink lg:text-base sm:text-base text-sm   flex flex-row items-center justify-between">
                                                Eye catching Floral Ad theme!
                                                includes Page 1 upgrade
                                                {adPremiume && (
                                                  <div
                                                    className="px-2 py-1 text-xs cursor-pointer border flex md:hidden items-center justify-center"
                                                    onClick={closeThemeAd}
                                                  >
                                                    X
                                                  </div>
                                                )}
                                              </div>
                                              {adPremiume ? (
                                                <div className="flex flex-col gap-2">
                                                  <AdCard />

                                                  <div className="bg-premiumeColor text-black border-4 border-purple p-4 gap-2 items-center justify-center cursor-pointer active:opacity-50     flex flex-col">
                                                    <div className="flex  w-full justify-between ">
                                                      <div className=" flex  gap-2  max-w-[240px] overflow-hidden  ">
                                                        {uploadimg?.map(
                                                          (image, index) => (
                                                            <img
                                                              key={index}
                                                              src={image}
                                                              alt=""
                                                              className="w-10 h-10 md:w-8 md:h-8"
                                                            />
                                                          )
                                                        )}
                                                      </div>
                                                      <div>
                                                        {/* <img
                                                          src={
                                                            images.premiumStar
                                                          }
                                                          alt=""
                                                          className="h-4 w-4"
                                                        /> */}
                                                      </div>
                                                    </div>
                                                    <div className="w-full">
                                                      <div className="w-full gap-2  flex flex-col">
                                                        <div className="flex w-full  font-bold  text-sm sm:text-sm ">
                                                          {adTitle}
                                                        </div>
                                                        <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row w-full justify-between  font-semibold  text-xxs sm:text-sm">
                                                          <div>
                                                            {age} - {name} -
                                                            {ethnicity}-{" "}
                                                            {ethnicOrigin}-{" "}
                                                            {selectedcity}
                                                          </div>
                                                          <div className="font-semibold text-xs sm:text-sm rounded-2xl gap-1 sm:gap-1 h-5 sm:h-9  bg-whitepink flex items-center justify-center w-20 sm:w-20   text-textdarkerpurpel ">
                                                            <img
                                                              src={
                                                                images.verified1
                                                              }
                                                              className="h-4 sm:h-4"
                                                            />
                                                            Verified
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <AdCard />

                                                  <div className="flex  gap-2 w-full md:mt-6">
                                                    <div className="flex w-full gap-2">
                                                      <div
                                                        className={
                                                          premiumAdHour ===
                                                          "three"
                                                            ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex flex-col items-center justify-center`
                                                        }
                                                        onClick={() => {
                                                          handleAddTheme(
                                                            themeThreeHoursCost,
                                                            "three"
                                                          );
                                                        }}
                                                      >
                                                        <div>3 hours</div>
                                                        <div>
                                                          {`$ ${themeThreeHoursCost}`}
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          premiumAdHour ===
                                                          "five"
                                                            ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex  flex-col items-center justify-center`
                                                        }
                                                        onClick={() => {
                                                          handleAddTheme(
                                                            themeFiveHoursCost,
                                                            "five"
                                                          );
                                                        }}
                                                      >
                                                        <div>5 hours</div>
                                                        <div>
                                                          {`$ ${themeFiveHoursCost}`}
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          premiumAdHour ===
                                                          "seven"
                                                            ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex flex-col items-center justify-center`
                                                        }
                                                        onClick={() => {
                                                          handleAddTheme(
                                                            themeSevenHoursCost,
                                                            "seven"
                                                          );
                                                        }}
                                                      >
                                                        <div>7 hours</div>
                                                        <div>
                                                          {` $ ${themeSevenHoursCost}`}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className=" h-13 w-16 cursor-pointer border hidden md:flex items-center justify-center"
                                                      onClick={closeThemeAd}
                                                    >
                                                      X
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="gap-4 flex flex-col">
                                                  <div>
                                                    <div className="bg-premiumeColor text-black border-4 border-purple p-4 gap-2 items-center justify-center   flex flex-col">
                                                      <div className="flex  w-full justify-between ">
                                                        <div className=" flex  gap-2  max-w-[240px] overflow-hidden  ">
                                                          {uploadimg?.map(
                                                            (image, index) => (
                                                              <img
                                                                key={index}
                                                                src={image}
                                                                alt=""
                                                                className="w-10 h-10  md:w-8 md:h-8"
                                                              />
                                                            )
                                                          )}
                                                        </div>
                                                        <div>
                                                          {/* <img
                                                            src={
                                                              images.premiumStar
                                                            }
                                                            alt=""
                                                            className="h-4 w-4"
                                                          /> */}
                                                        </div>
                                                      </div>
                                                      <div className="w-full">
                                                        <div className="w-full gap-2  flex flex-col">
                                                          <div className="flex w-full  font-bold  text-sm sm:text-sm ">
                                                            {adTitle}
                                                          </div>
                                                          <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row w-full justify-between  font-semibold  text-xxs sm:text-sm">
                                                            <div>
                                                              {age} - {name} -
                                                              {ethnicity}-{" "}
                                                              {ethnicOrigin}-{" "}
                                                              {selectedcity}
                                                            </div>
                                                            <div className="font-semibold text-xs sm:text-sm rounded-2xl gap-1 sm:gap-1 h-5 sm:h-9  bg-whitepink flex items-center justify-center w-20 sm:w-20   text-textdarkerpurpel ">
                                                              <img
                                                                src={
                                                                  images.verified1
                                                                }
                                                                className="h-4 sm:h-4"
                                                              />
                                                              Verified
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="bg-purple text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50
                                          lg:text-sm md:text-sm sm:text-sm text-xs"
                                                    // onClick={() => {
                                                    //   openFloralAd()
                                                    // }}
                                                    onClick={() =>
                                                      handleAddTheme(
                                                        themeFiveHoursCost,
                                                        "five"
                                                      )
                                                    }
                                                  >
                                                    Add Now from CAD $2.28/hour
                                                    <img
                                                      src={images.Vector4}
                                                      alt=""
                                                      className="lg:w-2 lg:h-2 w-auto h-2 "
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                            <div className="bg-purple flex lg:w-90p w-full pl-10 justify-between mt-8 py-2">
                                              <div className="lg:text-2xl md:text-xl sm:text-sm text-sm font-semibold">
                                                {addextra[0].title2}
                                              </div>
                                              <div className="bg-white text-purple font-bold flex justify-center items-center w-24p mr-4 lg:gap-2 md:gap-2 sm:gap-2 gap-1">
                                                <img
                                                  src={images.purplecircle}
                                                  alt=""
                                                  className="w-3 h-3"
                                                />
                                                <div className="lg:text-lg md:text-lg  sm:text-sm text-sm">
                                                  New
                                                </div>
                                              </div>
                                            </div>
                                            {/* bumpsStart */}
                                            <div className="border p-3 md:p-10 lg:w-90p w-full">
                                              <div className="pb-4 lg:text-base sm:text-base text-sm  flex flex-row items-center justify-between">
                                                {addextra[0].description2}
                                                {showBumps && (
                                                  <div
                                                    className="px-2 py-1 text-xs cursor-pointer border flex md:hidden items-center justify-center"
                                                    onClick={handleRemoveBumps}
                                                  >
                                                    X
                                                  </div>
                                                )}
                                              </div>
                                              {showBumps ? (
                                                <div className="flex flex-col">
                                                  <div className="flex   gap-2 w-full mt-6">
                                                    <div className="grid grid-cols-2 md:grid-cols-4 w-full gap-2">
                                                      {bumpsTab?.map(
                                                        (item, index) => {
                                                          return (
                                                            <div
                                                              key={index}
                                                              onClick={() =>
                                                                item.enable &&
                                                                handleAddBumps(
                                                                  index
                                                                )
                                                              }
                                                              className={
                                                                item.selected
                                                                  ? "w-full  border-2 cursor-pointer flex text-purple border-purple items-center justify-center"
                                                                  : item.enable
                                                                  ? "w-full cursor-pointer border flex items-center justify-center"
                                                                  : "w-full  border flex items-center justify-center opacity-50"
                                                              }
                                                            >
                                                              <div className="flex flex-col text-sm">
                                                                <div className="font-bold">
                                                                  {`${item.qty} Bumps`}
                                                                </div>
                                                                <div>
                                                                  {`$ ${item.cost}/each`}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                    <div
                                                      className=" h-13 w-16 cursor-pointer border hidden md:flex items-center justify-center"
                                                      onClick={
                                                        handleRemoveBumps
                                                      }
                                                    >
                                                      X
                                                    </div>
                                                  </div>
                                                  <div className="mt-10 flex">
                                                    <div className=" w-full">
                                                      Schedule your bumps
                                                    </div>
                                                    <div className="flex w-full gap-1 ">
                                                      <div
                                                        className={
                                                          scheduleBumps
                                                            ? `w-full border cursor-pointer active:opacity-50 flex items-center justify-center h-12`
                                                            : `w-full border border-purple flex items-center cursor-pointer active:opacity-50 justify-center h-12`
                                                        }
                                                        onClick={
                                                          handleRemoveScheduleBumps
                                                        }
                                                      >
                                                        No
                                                      </div>
                                                      <div
                                                        className={
                                                          scheduleBumps
                                                            ? `w-full border border-purple  flex items-center cursor-pointer active:opacity-50 justify-center h-12`
                                                            : `w-full border flex items-center cursor-pointer active:opacity-50 justify-center h-12`
                                                        }
                                                        onClick={
                                                          () =>
                                                            handleCreateInitialBumpsSchedule()
                                                          // setBumpyes(true)
                                                        }
                                                      >
                                                        Yes
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {scheduleBumps && (
                                                    <div>
                                                      <div className="flex w-full mt-2 jsutify-between gap-2">
                                                        <div className="w-full bg-lighterpurple flex flex-col items-center justify-center h-20 p-4 sm:p-0">
                                                          <div className="font-semibold ">
                                                            Remaining Bumps
                                                          </div>
                                                          <div className="font-bold text-2xl  sm:text-4xl">
                                                            {selectedBumps?.totalBumps -
                                                              calculateTotalScheduleBumps(
                                                                selectedBumps?.selectedScheduleBumps
                                                              )}
                                                          </div>
                                                        </div>
                                                        <div className="w-full bg-lighterpurple flex flex-col flex items-center justify-center h-20 p-4 sm:p-0">
                                                          <div className="font-semibold">
                                                            Scheduled Bumps
                                                          </div>
                                                          <div className="font-bold text-2xl  sm:text-4xl">
                                                            {calculateTotalScheduleBumps(
                                                              selectedBumps?.selectedScheduleBumps
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="w-full mt-2 flex items-center md:justify-between flex-row flex-wrap gap-1">
                                                        {/* timesPrint */}
                                                        {selectedBumps?.times?.map(
                                                          (item, index) => {
                                                            const itemHour =
                                                              extractHour(item);
                                                            const itemMinutes =
                                                              extractMinutes(
                                                                item
                                                              );
                                                            const itemFormat =
                                                              extractFormat(
                                                                item
                                                              );
                                                            return (
                                                              <div
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.stopPropagation();
                                                                  handleShowTimeDropdown(
                                                                    index
                                                                  );
                                                                }}
                                                                key={index}
                                                                className="border relative border-purple cursor-pointer w-[49%] md:w-[32%] h-10 flex items-center justify-between px-3"
                                                              >
                                                                <img
                                                                  src={
                                                                    images.time
                                                                  }
                                                                  className="w-5"
                                                                />
                                                                <div className="w-20 text-center">
                                                                  {item}
                                                                </div>
                                                                <img
                                                                  src={
                                                                    images.crossBumps
                                                                  }
                                                                  className="w-4 "
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.stopPropagation();
                                                                    handleRemoveTimeWithoutChangesDays(
                                                                      index
                                                                    );
                                                                  }}
                                                                />
                                                                {bumpsTimeDropdown ===
                                                                  index && (
                                                                  <div className="absolute bg-graymore top-10 w-full p-1 right-0 z-50 mt-1 shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]">
                                                                    <div className="flex flex-row items-center justify-between border-b border-white-500">
                                                                      <div
                                                                        className=" w-full flex items-center justify-center  border-r border-white-500"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.stopPropagation();
                                                                          handleIncreaseBumpsHours(
                                                                            item,
                                                                            index
                                                                          );
                                                                        }}
                                                                      >
                                                                        <img
                                                                          src={
                                                                            images.dropDownClosed
                                                                          }
                                                                          className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                                        />
                                                                      </div>
                                                                      <div
                                                                        className=" w-full flex items-center justify-center border-r border-white-500"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.stopPropagation();
                                                                          handleIncreaseBumpsMinutes(
                                                                            item,
                                                                            index
                                                                          );
                                                                        }}
                                                                      >
                                                                        <img
                                                                          src={
                                                                            images.dropDownClosed
                                                                          }
                                                                          className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                                        />
                                                                      </div>
                                                                      <div
                                                                        className=" w-full flex items-center justify-center"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.stopPropagation();
                                                                          handleChangeFormat(
                                                                            item,
                                                                            index
                                                                          );
                                                                        }}
                                                                      >
                                                                        <img
                                                                          src={
                                                                            images.dropDownClosed
                                                                          }
                                                                          className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                                        />
                                                                      </div>
                                                                    </div>

                                                                    <div className="flex flex-row items-center justify-between border-b border-white-500">
                                                                      <div className=" w-full flex items-center justify-center border-r border-white-500">
                                                                        <div className="my-2">
                                                                          {hoursForBumps.includes(
                                                                            itemHour
                                                                          ) &&
                                                                            formatHourAndMinutes(
                                                                              itemHour
                                                                            )}
                                                                        </div>
                                                                      </div>
                                                                      <div className=" w-full flex items-center justify-center border-r border-white-500">
                                                                        <div className="my-2">
                                                                          {minutesForBumps.includes(
                                                                            itemMinutes
                                                                          ) &&
                                                                            formatHourAndMinutes(
                                                                              itemMinutes
                                                                            )}
                                                                        </div>
                                                                      </div>
                                                                      <div className=" w-full flex items-center justify-center">
                                                                        <div className="my-2">
                                                                          {
                                                                            itemFormat
                                                                          }
                                                                        </div>
                                                                      </div>
                                                                    </div>

                                                                    <div className="flex flex-row items-center justify-between">
                                                                      <div
                                                                        className=" w-full flex items-center justify-center border-r border-white-500"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.stopPropagation();
                                                                          handleDecreaseBumpsHours(
                                                                            item,
                                                                            index
                                                                          );
                                                                        }}
                                                                      >
                                                                        <img
                                                                          src={
                                                                            images.dropDownOpen
                                                                          }
                                                                          className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                                        />
                                                                      </div>
                                                                      <div
                                                                        className=" w-full flex items-center justify-center border-r border-white-500"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.stopPropagation();
                                                                          handleDecreaseBumpsMinutes(
                                                                            item,
                                                                            index
                                                                          );
                                                                        }}
                                                                      >
                                                                        <img
                                                                          src={
                                                                            images.dropDownOpen
                                                                          }
                                                                          className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                                        />
                                                                      </div>
                                                                      <div
                                                                        className=" w-full flex items-center justify-center"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.stopPropagation();
                                                                          handleChangeFormat(
                                                                            item,
                                                                            index
                                                                          );
                                                                        }}
                                                                      >
                                                                        <img
                                                                          src={
                                                                            images.dropDownOpen
                                                                          }
                                                                          className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                )}
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                        <div
                                                          className={
                                                            showBumpsAddTime
                                                              ? "flex-grow bg-purple  flex flex-col flex items-center justify-center  h-10 cursor-pointer active:opacity-50"
                                                              : "flex-grow bg-graymore opacity-50 flex flex-col flex items-center justify-center h-10"
                                                          }
                                                          onClick={() =>
                                                            showBumpsAddTime &&
                                                            handleAddTimeWithoutChangesDays()
                                                          }
                                                        >
                                                          + Add
                                                        </div>
                                                      </div>

                                                      {/* daysOptions */}
                                                      <div className=" sm:flex sm:flex-row gap-2 grid  grid-cols-3 mt-2">
                                                        {bumpsDayOption?.map(
                                                          (item, index) => {
                                                            return (
                                                              <div
                                                                key={index}
                                                                onClick={() =>
                                                                  item.enable &&
                                                                  selectBumpsDays(
                                                                    index
                                                                  )
                                                                }
                                                                className={
                                                                  item.selected
                                                                    ? "bg-purple px-3 py-2 cursor-pointer active:opacity-50 font-semibold"
                                                                    : item.enable
                                                                    ? "bg-transparent border border-purple px-3 py-2 cursor-pointer active:opacity-50"
                                                                    : "bg-graymore opacity-50 px-3 py-2 cursor-pointer"
                                                                }
                                                              >
                                                                {item.day == 1
                                                                  ? `${item.day} day`
                                                                  : `${item.day} days`}
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              ) : (
                                                <div
                                                  className="bg-purple text-white lg:w-70p md:w-70p sm:w-70p w-full lg:text-sm md:text-sm sm:text-sm text-xs w-75p h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50"
                                                  onClick={() => {
                                                    handleAddBumps(2);
                                                  }}
                                                >
                                                  Add Now from CAD $
                                                  {thirdBumpsCost}
                                                  /bump
                                                  <img
                                                    src={images.Vector4}
                                                    alt=""
                                                    className="lg:w-2 lg:h-2 w-auto h-2  "
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {/* UPGRADES */}
                                <div className="w-full  shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] p-4 ">
                                  <div
                                    className="cursor-pointer active:opacity-50"
                                    onClick={() =>
                                      hideBoosts(
                                        boost === "upgrades" ? "" : "upgrades"
                                      )
                                    }
                                  >
                                    <div className="lg:text-2xl md:text-xl sm:text-sm text-sm font-semibold cursor-pointer ">
                                      Visual Upgrades
                                    </div>
                                    <div className="text-whitepink  opacity-80 lg:text-base md:text-base sm:text-sm text-xs">
                                      Spice up your ad by customising the look
                                      of your ad
                                    </div>
                                  </div>
                                  {boost === "upgrades" && (
                                    <div>
                                      <div className="pt-10">
                                        <div className="gap-20 flex w-full">
                                          <div className="lg:w-90p w-full ">
                                            <div className="flex flex-col w-full">
                                              <div className="bg-purple w-full pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                                                Carousel
                                              </div>
                                              <div className="flex flex-col">
                                                <div className="border p-3 md:p-10">
                                                  <div className="mb:pb-4 lg:text-base sm:text-base text-sm flex flex-row items-center justify-between">
                                                    Double your Ad size with a
                                                    delightful scrollable
                                                    carousel!
                                                    {carouselAd && (
                                                      <div
                                                        className="px-2 py-1 text-xs cursor-pointer border flex md:hidden items-center justify-center"
                                                        onClick={() =>
                                                          handleRemoveCerousel()
                                                        }
                                                      >
                                                        X
                                                      </div>
                                                    )}
                                                  </div>
                                                  {carouselAd ? (
                                                    <div>
                                                      <div className="h-full flex-col flex items-center justify-center ">
                                                        <div className=" h-full gap-2 mb-4 w-[100%] ">
                                                          <AdCard />
                                                          <div className="overflow-hidden">
                                                            <AdCard
                                                              age={age}
                                                              name={name}
                                                              ethnicity={
                                                                ethnicity
                                                              }
                                                              ethnicOrigin={
                                                                ethnicOrigin
                                                              }
                                                              selectedcity={
                                                                selectedcity
                                                              }
                                                              uploadimg={
                                                                uploadimg
                                                              }
                                                              adTitle={adTitle}
                                                              carousel={true}
                                                            />
                                                          </div>
                                                          <AdCard />
                                                        </div>
                                                      </div>
                                                      <div className="mt-4">
                                                        <div className="flex  gap-2 w-full mt-6">
                                                          <div className="flex gap-2 w-full">
                                                            <div
                                                              className={
                                                                carouselAdhour ===
                                                                "three"
                                                                  ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                                  : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                              }
                                                              onClick={() =>
                                                                // setCarouseladHour(
                                                                //   "three"
                                                                // )
                                                                handleAddCarouselAd(
                                                                  "three",
                                                                  carouselThreeHoursCost
                                                                )
                                                              }
                                                            >
                                                              <div className="flex flex-col text-sm">
                                                                <div className="font-bold">
                                                                  3 hours
                                                                </div>
                                                                <div>
                                                                  {`$ ${carouselThreeHoursCost}`}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className={
                                                                carouselAdhour ===
                                                                "six"
                                                                  ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                                  : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                              }
                                                              onClick={() =>
                                                                // setCarouseladHour(
                                                                //   "six"
                                                                // )
                                                                handleAddCarouselAd(
                                                                  "six",
                                                                  carouselSixHoursCost
                                                                )
                                                              }
                                                            >
                                                              <div className="flex flex-col text-sm">
                                                                <div className="font-bold">
                                                                  6 hours
                                                                </div>
                                                                <div>
                                                                  {`$ ${carouselSixHoursCost}`}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className={
                                                                carouselAdhour ===
                                                                "nine"
                                                                  ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                                  : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                              }
                                                              onClick={() =>
                                                                // setCarouseladHour(
                                                                //   "nine"
                                                                // )
                                                                handleAddCarouselAd(
                                                                  "nine",
                                                                  carouselNineHoursCost
                                                                )
                                                              }
                                                            >
                                                              <div className="flex flex-col text-sm">
                                                                <div className="font-bold">
                                                                  9 hours
                                                                </div>
                                                                <div>
                                                                  {`$ ${carouselNineHoursCost}`}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className=" h-13 w-16 cursor-pointer border hidden md:flex items-center justify-center"
                                                            onClick={() =>
                                                              handleRemoveCerousel()
                                                            }
                                                          >
                                                            X
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="h-full  items-center justify-center ">
                                                      <div className=" flex flex-col  my-4 w-[100%] md:w-96 ">
                                                        <AdCard
                                                          age={age}
                                                          name={name}
                                                          ethnicity={ethnicity}
                                                          ethnicOrigin={
                                                            ethnicOrigin
                                                          }
                                                          selectedcity={
                                                            selectedcity
                                                          }
                                                          uploadimg={uploadimg}
                                                          adTitle={adTitle}
                                                          carousel={true}
                                                        />
                                                      </div>
                                                      <div>
                                                        <div
                                                          className="bg-purple text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full  h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50
                                          lg:text-sm md:text-sm sm:text-sm text-xs"
                                                          onClick={() =>
                                                            handleAddCarouselAd(
                                                              "six",
                                                              carouselSixHoursCost
                                                            )
                                                          }
                                                        >
                                                          Add Now from CAD
                                                          $0.48/hour
                                                          <img
                                                            src={images.Vector4}
                                                            alt=""
                                                            className="lg:w-2 lg:h-2 w-auto h-2 "
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pt-10">
                                        <div className="flex flex-col w-full">
                                          <div className="bg-purple lg:w-90p w-full pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                                            Available Now
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="border p-3 md:p-10 lg:w-90p w-full text-sm  ">
                                              <div className="pb-5 text-whitepink lg:text-base sm:text-base text-sm flex flex-row items-center justify-between">
                                                Let clients know you’re
                                                available + appear in a special
                                                search filter
                                                {availbeNowTheme && (
                                                  <div
                                                    className="px-2 py-1 text-xs cursor-pointer border flex md:hidden items-center justify-center"
                                                    onClick={
                                                      handleRemoveAvailableNow
                                                    }
                                                  >
                                                    X
                                                  </div>
                                                )}
                                              </div>
                                              {availbeNowTheme ? (
                                                <div className="flex flex-col gap-2">
                                                  <AdCard />
                                                  <AdCard
                                                    available={true}
                                                    age={age}
                                                    name={name}
                                                    ethnicity={ethnicity}
                                                    ethnicOrigin={ethnicOrigin}
                                                    selectedcity={selectedcity}
                                                    uploadimg={uploadimg}
                                                    adTitle={adTitle}
                                                  />
                                                  <AdCard />
                                                  <div className="flex  gap-1 w-full mt-6">
                                                    <div className="grid grid-cols-2  sm:flex w-full gap-1 ">
                                                      <div
                                                        className={
                                                          availbleHour === "one"
                                                            ? `w-full h-13 border-2 cursor-pointer flex text-purple  border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                        }
                                                        onClick={() =>
                                                          handleAddAvailableNow(
                                                            "one",
                                                            availableNowOneHourCost
                                                          )
                                                        }
                                                      >
                                                        <div className="flex flex-col items-center">
                                                          <div
                                                            className={
                                                              availbleHour ==
                                                              "one"
                                                                ? `font-bold text-purple  text-md`
                                                                : `font-bold  text-md`
                                                            }
                                                          >
                                                            1 hour
                                                          </div>
                                                          <div>
                                                            {`$ ${availableNowOneHourCost}`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          availbleHour === "two"
                                                            ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                        }
                                                        onClick={() =>
                                                          handleAddAvailableNow(
                                                            "two",
                                                            availableNowTwoHourCost
                                                          )
                                                        }
                                                      >
                                                        <div className="flex flex-col items-center">
                                                          <div
                                                            className={
                                                              availbleHour ==
                                                              "two"
                                                                ? `font-bold text-purple  text-md`
                                                                : `font-bold  text-md`
                                                            }
                                                          >
                                                            2 hour
                                                          </div>
                                                          <div>
                                                            {`$ ${availableNowTwoHourCost}`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          availbleHour ===
                                                          "three"
                                                            ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                        }
                                                        onClick={() =>
                                                          handleAddAvailableNow(
                                                            "three",
                                                            availableNowThreeHourCost
                                                          )
                                                        }
                                                      >
                                                        <div className="flex flex-col items-center">
                                                          <div
                                                            className={
                                                              availbleHour ==
                                                              "three"
                                                                ? `font-bold text-purple  text-md`
                                                                : `font-bold  text-md`
                                                            }
                                                          >
                                                            3 hour
                                                          </div>
                                                          <div>
                                                            {`$ ${availableNowThreeHourCost}`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          availbleHour ===
                                                          "four"
                                                            ? `w-full h-13 border-2 cursor-pointer flex text-purple  border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                        }
                                                        onClick={() =>
                                                          handleAddAvailableNow(
                                                            "four",
                                                            availableNowFourHourCost
                                                          )
                                                        }
                                                      >
                                                        <div className="flex flex-col items-center">
                                                          <div
                                                            className={
                                                              availbleHour ==
                                                              "four"
                                                                ? `font-bold text-purple  text-md`
                                                                : `font-bold  text-md`
                                                            }
                                                          >
                                                            4 hour
                                                          </div>
                                                          <div>
                                                            {`$ ${availableNowFourHourCost}`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className=" h-13 w-16 cursor-pointer border hidden md:flex items-center justify-center"
                                                      onClick={
                                                        handleRemoveAvailableNow
                                                      }
                                                    >
                                                      X
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="gap-4 flex flex-col">
                                                  <div>
                                                    <AdCard
                                                      available={true}
                                                      age={age}
                                                      name={name}
                                                      ethnicity={ethnicity}
                                                      ethnicOrigin={
                                                        ethnicOrigin
                                                      }
                                                      selectedcity={
                                                        selectedcity
                                                      }
                                                      uploadimg={uploadimg}
                                                      adTitle={adTitle}
                                                    />
                                                  </div>
                                                  <div
                                                    className="bg-purple text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full  h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50
                                          lg:text-sm md:text-sm sm:text-sm text-xs"
                                                    onClick={() =>
                                                      handleAddAvailableNow(
                                                        "two",
                                                        availableNowTwoHourCost
                                                      )
                                                    }
                                                  >
                                                    Add Now from CAD $0.55/hour
                                                    <img
                                                      src={images.Vector4}
                                                      alt=""
                                                      className="lg:w-2 lg:h-2 w-auto h-2 "
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* FEATURED AD */}
                                      <div className="pt-10">
                                        <div className="flex flex-col w-full">
                                          <div className="bg-purple lg:w-90p w-full pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                                            Featured Ad
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="border p-3 md:Lp-10 lg:w-90p w-full text-sm  ">
                                              <div className="pb-5 text-whitepink lg:text-base sm:text-base text-sm flex justify-between items-center ">
                                                <div>
                                                  Featured Ad on Page 1+Regular
                                                  Ad!
                                                </div>
                                                {featuredAd && (
                                                  <div>
                                                    <div className="flex gap-1 ">
                                                      {/* <div
                                                        className="border p-2 cursor-pointer active:opacity-50 relative"
                                                        onClick={() =>
                                                          setOpenmodal(
                                                            !openModal
                                                          )
                                                        }
                                                      >
                                                        Preview
                                                      </div> */}
                                                      <div
                                                        className="px-3 py-1 text-xs cursor-pointer border flex md:hidden items-center justify-center"
                                                        onClick={
                                                          handleRemoveFeaturedAd
                                                        }
                                                      >
                                                        X
                                                      </div>
                                                    </div>
                                                    {openModal && (
                                                      <div className="bg-white absolute right-[47%] mt-2">
                                                        <div className="w-96 h-[460px] mt-2 mx-2  ">
                                                          <AdCard />
                                                          <div className="mt-2 flex gap-2">
                                                            <div
                                                              className="bg-white bg-cover bg-center  h-44 w-40 flex justify-end flex-col  font-bold"
                                                              style={{
                                                                backgroundImage: `url(${editedImage})`,
                                                              }}
                                                            >
                                                              <div className="p-2 text-white">
                                                                {adTitle}
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="bg-white bg-cover bg-center text-white  h-44 w-40 flex justify-end flex-col  font-bold"
                                                              style={{
                                                                backgroundImage: `url(${images.yellowbandi})`,
                                                              }}
                                                            >
                                                              <div className="p-2">
                                                                {adTitle}
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <AdCard />
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                              {featuredAd ? (
                                                <div className="flex flex-col gap-2">
                                                  {editPic ? (
                                                    <div className="bg-lighterpurple h-60 flex items-center justify-center">
                                                      <AvatarEditor
                                                        ref={editorRef}
                                                        image={selectImg}
                                                        width={160}
                                                        height={160}
                                                        color={[
                                                          255, 255, 255, 0.6,
                                                        ]}
                                                        scale={1.4}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div className="bg-lighterpurple h-60 flex items-center justify-center">
                                                      {editedImage === null ? (
                                                        <img
                                                          src={selectImg}
                                                          alt=""
                                                          className="h-40 w-40"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={editedImage}
                                                          alt=""
                                                          className="h-40 w-40"
                                                        />
                                                      )}
                                                    </div>
                                                  )}
                                                  <div className="flex justify-center  border-b pb-4">
                                                    {editPic ? (
                                                      <div
                                                        className="flex justify-center h-10 items-center border w-24 text-md cursor-pointer active:opacity-50"
                                                        onClick={() => {
                                                          // handleSave();
                                                          setEditpic(!editPic);
                                                        }}
                                                      >
                                                        Done
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="flex justify-center h-10 items-center border w-24 text-md cursor-pointer active:opacity-50"
                                                        onClick={() =>
                                                          setEditpic(!editPic)
                                                        }
                                                      >
                                                        Edit Pic
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div>PICTURE</div>
                                                  <div className="flex item-start gap-2 justify-start overflow-x-scroll">
                                                    {uploadimg?.map(
                                                      (item, index) => (
                                                        // <div
                                                        //   key={index}
                                                        //   className="bg-green-500"
                                                        // >
                                                        <img
                                                          src={item}
                                                          className="h-14 w-14 cursor-pointer active:opacity-50"
                                                          onClick={() =>
                                                            handleClickimg(item)
                                                          }
                                                        />
                                                        // </div>
                                                      )
                                                    )}
                                                  </div>

                                                  <div className="flex  gap-2 w-full mt-6">
                                                    <div className="flex w-full gap-2">
                                                      <div
                                                        className={
                                                          featuredAdHour ===
                                                          "one"
                                                            ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                        }
                                                        onClick={() =>
                                                          handleAddFeaturedAdd(
                                                            "one",
                                                            featuredAddOneHourCost
                                                          )
                                                        }
                                                      >
                                                        <div className="flex flex-col items-center">
                                                          <div
                                                            className={
                                                              featuredAdHour ==
                                                              "one"
                                                                ? `font-bold text-purple  text-md`
                                                                : `font-bold  text-md`
                                                            }
                                                          >
                                                            1 hour
                                                          </div>
                                                          <div>
                                                            {`$ ${featuredAddOneHourCost}`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          featuredAdHour ===
                                                          "three"
                                                            ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                        }
                                                        onClick={() =>
                                                          handleAddFeaturedAdd(
                                                            "three",
                                                            featuredAddThreeHourCost
                                                          )
                                                        }
                                                      >
                                                        <div className="flex flex-col items-center">
                                                          <div
                                                            className={
                                                              featuredAdHour ==
                                                              "three"
                                                                ? `font-bold text-purple  text-md`
                                                                : `font-bold  text-md`
                                                            }
                                                          >
                                                            3 hour
                                                          </div>
                                                          <div>
                                                            {`$ ${featuredAddThreeHourCost}`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          featuredAdHour ===
                                                          "seven"
                                                            ? `w-full h-13 border-2 cursor-pointer flex text-purple  border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                        }
                                                        onClick={() =>
                                                          handleAddFeaturedAdd(
                                                            "seven",
                                                            featuredAddSevenHourCost
                                                          )
                                                        }
                                                      >
                                                        <div className="flex flex-col items-center">
                                                          <div
                                                            className={
                                                              featuredAdHour ==
                                                              "seven"
                                                                ? `font-bold text-purple  text-md`
                                                                : `font-bold  text-md`
                                                            }
                                                          >
                                                            7 hour
                                                          </div>
                                                          <div>
                                                            {`$ ${featuredAddSevenHourCost}`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className=" h-13 w-16 cursor-pointer border hidden md:flex items-center justify-center"
                                                      onClick={
                                                        handleRemoveFeaturedAd
                                                      }
                                                    >
                                                      X
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="gap-4 flex flex-col">
                                                  <div className="bg-lighterpurple h-60 flex items-center justify-center">
                                                    {editedImage !== null ? (
                                                      <div
                                                        className="bg-white bg-cover bg-center h-44 w-40 flex justify-end flex-col text-black font-bold"
                                                        style={{
                                                          backgroundImage: `url(${editedImage})`,
                                                        }}
                                                      >
                                                        <div className="p-2 text-white">
                                                          {}
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="bg-white bg-cover bg-center h-44 w-44 flex justify-end flex-col text-black font-bold"
                                                        style={{
                                                          backgroundImage: `url(${selectImg})`,
                                                        }}
                                                      >
                                                        <div className="p-2 text-white "></div>
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div
                                                    className="bg-purple text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full  h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50
                                          lg:text-sm md:text-sm sm:text-sm text-xs"
                                                    onClick={() => {
                                                      handleAddFeaturedAdd(
                                                        "three",
                                                        featuredAddThreeHourCost
                                                      );
                                                    }}
                                                  >
                                                    Add Now from CAD $0.91/hour
                                                    <img
                                                      src={images.Vector4}
                                                      alt=""
                                                      className="lg:w-2 lg:h-2 w-auto h-2 "
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* EMOJI */}
                                      <div className="pt-10">
                                        <div className="flex flex-col w-full">
                                          <div className="bg-purple lg:w-90p w-full pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                                            Emoji
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="border p-3 md:p-10 lg:w-90p w-full text-sm  ">
                                              <div className="pb-5 text-whitepink lg:text-base sm:text-base text-sm flex justify-between items-center">
                                                Show off your playful (or
                                                naughty) side with emojis!
                                                {emojiShow && (
                                                  <div
                                                    className="px-2 py-1 text-xs cursor-pointer border flex md:hidden items-center justify-center"
                                                    onClick={handleRemoveEmoji}
                                                  >
                                                    X
                                                  </div>
                                                )}
                                              </div>
                                              {emojiShow ? (
                                                <div className="flex flex-col gap-2">
                                                  <AdCard />
                                                  <AdCard
                                                    age={age}
                                                    name={name}
                                                    ethnicity={ethnicity}
                                                    ethnicOrigin={ethnicOrigin}
                                                    selectedcity={selectedcity}
                                                    uploadimg={uploadimg}
                                                    adTitle={adTitle}
                                                    textAreadata={textAreadata}
                                                  />
                                                  <AdCard />
                                                  <div className=" ">
                                                    <div className="flex justify-between w-full ">
                                                      <div>Title</div>
                                                      {/* <div className="">
                                                          {textAreadata.length}/60
                                                          Characters left
                                                        </div> */}
                                                    </div>
                                                    <div className="bg-red-500">
                                                      <input
                                                        value={textAreadata}
                                                        onChange={handleChange}
                                                        className="resize-none  focus:outline-none h-10 w-full p-2 text-lg text-black font-semibold"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="flex flex-row w-full text-xl flex-wrap items-center justify-start md:justify-between ">
                                                    {emojiImages?.map(
                                                      (item, index) => {
                                                        return (
                                                          <div
                                                            className="cursor-pointer"
                                                            key={index}
                                                            onClick={() =>
                                                              handleEmojiClick(
                                                                item.emoji
                                                              )
                                                            }
                                                          >
                                                            {item.emoji}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                  <div className="flex  gap-2 w-full mt-6">
                                                    <div className="flex w-full gap-2">
                                                      <div
                                                        className={
                                                          emojiAdd === "24"
                                                            ? `w-full h-13 border-2 cursor-pointer flex  text-purple  border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                        }
                                                        onClick={() =>
                                                          handleAddEmoji(
                                                            "24",
                                                            emoji24HoursCost
                                                          )
                                                        }
                                                      >
                                                        <div className="flex flex-col items-center">
                                                          <div
                                                            className={
                                                              emojiAdd == "one"
                                                                ? `font-bold text-purple  text-md`
                                                                : `font-bold  text-md`
                                                            }
                                                          >
                                                            24 hours
                                                          </div>
                                                          <div>
                                                            {`$ ${emoji24HoursCost}`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          emojiAdd === "7Days"
                                                            ? `w-full h-13 border-2 cursor-pointer flex  text-purple border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                        }
                                                        onClick={() =>
                                                          handleAddEmoji(
                                                            "7Days",
                                                            emoji7DaysCost
                                                          )
                                                        }
                                                      >
                                                        <div className="flex flex-col items-center">
                                                          <div
                                                            className={
                                                              emojiAdd == "two"
                                                                ? `font-bold text-purple  text-md`
                                                                : `font-bold  text-md`
                                                            }
                                                          >
                                                            7 days
                                                          </div>
                                                          <div>
                                                            {`$ ${emoji7DaysCost}`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          emojiAdd === "30days"
                                                            ? `w-full h-13 border-2 cursor-pointer flex  text-purple border-purple items-center justify-center`
                                                            : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                        }
                                                        onClick={() =>
                                                          handleAddEmoji(
                                                            "30days",
                                                            emoji30DaysCost
                                                          )
                                                        }
                                                      >
                                                        <div className="flex flex-col items-center">
                                                          <div
                                                            className={
                                                              emojiAdd ==
                                                              "three"
                                                                ? `font-bold text-purple  text-md`
                                                                : `font-bold  text-md`
                                                            }
                                                          >
                                                            30 days
                                                          </div>
                                                          <div>
                                                            {`$ ${emoji30DaysCost}`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className=" h-13 w-16 cursor-pointer border hidden md:flex items-center justify-center"
                                                      onClick={
                                                        handleRemoveEmoji
                                                      }
                                                    >
                                                      X
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="gap-4 flex flex-col">
                                                  <AdCard
                                                    age={age}
                                                    name={name}
                                                    ethnicity={ethnicity}
                                                    ethnicOrigin={ethnicOrigin}
                                                    selectedcity={selectedcity}
                                                    uploadimg={uploadimg}
                                                    adTitle={adTitle}
                                                    textAreadata={textAreadata}
                                                  />
                                                  <div
                                                    className="bg-purple text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full  h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50
                                          lg:text-sm md:text-sm sm:text-sm text-xs"
                                                    onClick={() =>
                                                      handleAddEmoji(
                                                        "7Days",
                                                        emoji7DaysCost
                                                      )
                                                    }
                                                  >
                                                    Add Now from CAD $0.92/hour
                                                    <img
                                                      src={images.Vector4}
                                                      alt=""
                                                      className="lg:w-2 lg:h-2 w-auto h-2 "
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* Cost */}
                              <div className="lg:flex flex-col lg:w-46p w-[95%] mr-2 mx-auto">
                                <div className="sticky top-[90px] ">
                                  <div className="text-black bg-transparent shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-full flex-col h-auto flex items-center p-5 pt-5 pb-5 gap-2 ">
                                    <div
                                      className="flex w-full justify-between border-b
                                      text-white"
                                    >
                                      <div className="w-full flex flex-col  ">
                                        <div className="w-full text-xl font-semibold ">
                                          <div className="flex gap-2 flex-col ">
                                            <div
                                              className=""
                                              onClick={() =>
                                                console.log(selectedBumps)
                                              }
                                            >
                                              Advertisement
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex gap-2 flex-col pt-2">
                                          <div>{selectedcity}</div>
                                        </div>
                                      </div>
                                      <div className=" flex font-bold text-xl flex-col items-center text-white ">
                                        <div className="w-80p text-end">
                                          {"FREE"}
                                        </div>
                                      </div>
                                    </div>
                                    {selectedPageOneSlot && (
                                      <div className="w-full flex flex-col border-b gap-2 relative text-white group-hover/item ">
                                        <div className="flex justify-between w-full">
                                          <div className="w-full  text-xl font-semibold">
                                            Page 1
                                          </div>
                                          <div className="w-full text-end  font-bold text-xl ">{`$ ${selectedPageOneSlot?.cost}`}</div>
                                        </div>
                                        <div
                                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%]
                                       flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100  transition-opacity duration-300
                                       active:opacity-50"
                                          onClick={handleRemovePageOne}
                                        >
                                          X
                                        </div>
                                        <div>
                                          {`${
                                            selectedPageOneSlot?.hour === "five"
                                              ? "5"
                                              : selectedPageOneSlot?.hour ===
                                                "three"
                                              ? "3"
                                              : "7"
                                          } Hours from ${
                                            selectedPageOneSlot?.startTime
                                          }`}
                                        </div>
                                      </div>
                                    )}
                                    {selectPageTwoSLot && (
                                      <div className="w-full flex flex-col border-b relative py-3 text-white">
                                        <div className="flex justify-between w-full">
                                          <div className="w-full font-semibold">
                                            {`
                                        Floral Ad theme :
                                        ${
                                          selectPageTwoSLot?.hour === "five"
                                            ? "5"
                                            : selectPageTwoSLot?.hour ===
                                              "three"
                                            ? "3"
                                            : "7"
                                        }
                                          hours
                                          `}
                                          </div>
                                          <div className="w-[85px]  text-end  font-bold text-lg text-white">{`$ ${selectPageTwoSLot?.cost}`}</div>
                                        </div>
                                        <div
                                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%]
                                       flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300
                                       active:opacity-50"
                                          // onClick={() => {
                                          //   setSelectPageTwoSLot(null);
                                          //   showPremiumeAd(false);
                                          // }}
                                          onClick={closeThemeAd}
                                        >
                                          X
                                        </div>
                                      </div>
                                    )}
                                    {selectedCarousel && (
                                      <div className="flex w-full justify-between border-b relative text-white">
                                        <div className="w-full flex flex-col">
                                          <div className="w-full text-xl font-semibold ">
                                            <div className="flex gap-2 flex-col ">
                                              <div>Carosoul</div>
                                            </div>
                                          </div>
                                          <div className="flex gap-2 flex-col pt-2">
                                            <div>
                                              {`
                                        ${
                                          selectedCarousel?.hour === "three"
                                            ? "3"
                                            : selectedCarousel?.hour === "six"
                                            ? "6"
                                            : "9"
                                        }
                                          hours
                                          `}
                                            </div>
                                          </div>
                                        </div>
                                        <div className=" flex font-bold text-lg flex-col items-center text-white ">
                                          <div className="w-[60px] text-end">
                                            {`$ ${selectedCarousel?.cost}`}
                                          </div>
                                        </div>
                                        <div
                                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%]
                                       flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300
                                       active:opacity-50"
                                          onClick={() => {
                                            handleRemoveCerousel();
                                          }}
                                        >
                                          X
                                        </div>
                                      </div>
                                    )}
                                    {selectedAvailableNow && (
                                      <div className="flex w-full justify-between border-b relative text-white">
                                        <div className="w-full flex flex-col">
                                          <div className="w-full text-xl font-semibold ">
                                            <div className="flex gap-2 flex-col ">
                                              <div>Available Now</div>
                                            </div>
                                          </div>
                                          <div className="flex gap-2 flex-col pt-2">
                                            <div>
                                              {`${
                                                selectedAvailableNow?.hour ===
                                                "one"
                                                  ? "1 hour"
                                                  : selectedAvailableNow?.hour ===
                                                    "two"
                                                  ? "2 hours"
                                                  : selectedAvailableNow?.hour ===
                                                    "three"
                                                  ? "3 hours"
                                                  : "4 hours"
                                              }`}
                                            </div>
                                          </div>
                                        </div>
                                        <div className=" flex font-bold text-lg flex-col items-center text-white">
                                          <div className="w-[60px] text-end">
                                            {`$ ${selectedAvailableNow?.cost}`}
                                          </div>
                                        </div>
                                        <div
                                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%]
                                       flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300
                                       active:opacity-50"
                                          onClick={handleRemoveAvailableNow}
                                        >
                                          X
                                        </div>
                                      </div>
                                    )}
                                    {selectedFeaturedAd && (
                                      <div className="flex w-full justify-between border-b relative text-white">
                                        <div className="w-full flex flex-col">
                                          <div className="w-full text-xl font-semibold ">
                                            <div className="flex gap-2 flex-col ">
                                              <div>Featured Ad</div>
                                            </div>
                                          </div>
                                          <div className="flex gap-2 flex-col pt-2">
                                            <div>
                                              {`${
                                                selectedFeaturedAd?.hour ===
                                                "one"
                                                  ? "1 hour"
                                                  : selectedFeaturedAd?.hour ===
                                                    "three"
                                                  ? "3 hours"
                                                  : "7 hours"
                                              }`}
                                            </div>
                                          </div>
                                        </div>
                                        <div className=" flex font-bold text-lg flex-col items-center text-white ">
                                          <div className="w-[60px] text-end">
                                            {`$ ${selectedFeaturedAd?.cost}`}
                                          </div>
                                        </div>
                                        <div
                                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%]
                                       flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300
                                       active:opacity-50"
                                          onClick={handleRemoveFeaturedAd}
                                        >
                                          X
                                        </div>
                                      </div>
                                    )}
                                    {selectedEmoji && (
                                      <div className="flex w-full justify-between border-b relative text-white">
                                        <div className="w-full flex flex-col">
                                          <div className="w-full text-xl font-semibold ">
                                            <div className="flex gap-2 flex-col ">
                                              <div>Emoji for Ad Title</div>
                                            </div>
                                          </div>
                                          <div className="flex gap-2 flex-col pt-2 ">
                                            <div>
                                              {`${
                                                selectedEmoji?.hour === "24"
                                                  ? "24 hours"
                                                  : selectedEmoji?.hour ===
                                                    "7Days"
                                                  ? "7 days"
                                                  : "30 days"
                                              }`}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="text-black flex font-bold text-lg flex-col items-center  text-white">
                                          <div className="w-[65px] text-end">
                                            {`$ ${selectedEmoji?.cost}`}
                                          </div>
                                        </div>
                                        <div
                                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%]
                                       flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300
                                       active:opacity-50"
                                          onClick={handleRemoveEmoji}
                                        >
                                          X
                                        </div>
                                      </div>
                                    )}
                                    {selectedBumps && (
                                      <div className="w-full flex flex-col border-b relative py-3 text-white">
                                        <div className="flex justify-between w-full">
                                          <div className="w-full font-semibold">
                                            {`${selectedBumps?.totalBumps} Bumps Package`}
                                          </div>
                                          <div className="w-[105px]  text-end  font-bold text-md text-white">{`$ ${selectedBumps?.cost}`}</div>
                                        </div>
                                        {selectedBumps?.selectedScheduleBumps
                                          ?.length > 0 && (
                                          <div className="text-sm">
                                            {`${calculateTotalScheduleBumps(
                                              selectedBumps?.selectedScheduleBumps
                                            )} Scheduled Bumps`}
                                          </div>
                                        )}
                                        <div
                                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%]
                                       flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300
                                       active:opacity-50"
                                          onClick={handleRemoveBumps}
                                        >
                                          X
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div></div>
                                  <div className="bg-purple p-3 md:p-6 flex justify-between text-xl md:text-2xl">
                                    <div className="text-white font-semibold">
                                      Total
                                    </div>
                                    <div className="text-white font-semibold">
                                      {`$ ${totalCost?.toFixed(2)}`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* BUTTONS  */}

                          <div className=" w-full flex justify-between pt-20 pb-20 ">
                            <div
                              className="bg-white ml-4 lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8 w-24 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
                              onClick={() => {
                                setchangepanel("uploadimg");
                                emptyError();
                              }}
                            >
                              <div className="text-purple flex justify-between  font-semibold lg:w-50p md:w-0p sm:w-75p w-68p items-center  ">
                                <img
                                  src={images.purplearrowleft}
                                  alt=""
                                  className="w-2 h-3 "
                                />
                                Previous
                              </div>
                            </div>
                            <div
                              className="mr-5 text-white bg-purple lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8 w-24 ml-5 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
                              onClick={handleAddsubmit}
                            >
                              <div className="text-white flex justify-between font-semibold lg:w-40p w-50p  items-center">
                                Next
                                <img
                                  src={images.smallarrowright}
                                  alt=""
                                  className="w-2 h-3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : changepanel === "finalize&post" ? (
                    <>
                      <div>
                        <div className="bg-weirdgray rounded-lg ">
                          <div className="flex p-3 items-center gap-4 ">
                            <div className="text-white  lg:text-xl md:text-xl sm:text-sm text-sm ">
                              Preview
                            </div>
                          </div>
                          <div className="flex flex-col  bg-buttoncolor rounded-b-lg p-3 md:p-10">
                            <div className=" text-white flex flex-row  md:grid md:grid-cols-10 sm:grid sm:grid-cols-10  grid grid-cols-6 ">
                              {uploadimg?.map((image, index) => (
                                <div key={index}>
                                  <div
                                    className="h-20"
                                    onClick={() => handleImageClick(index)}
                                  >
                                    <img
                                      src={image}
                                      className="h-20 w-20"
                                      alt={`Uploaded ${index + 1}`}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="text-white lg:text-4xl md:text-2xl sm:text-xl text-lg  font-semibold pt-3">
                              {adTitle}
                            </div>
                            <div className="flex lg:flex-row flex-col items-center justify-between w-full">
                              <div className="text-white lg:text-2xl md:text-2xl sm:text-lg text-sm font-bold pt-5">
                                {age} - {name} - {ethnicity} - {ethnicOrigin}
                                Region
                              </div>
                              <div className="bg-white mt-5 lg:text-base md:text-sm text-xs flex w-36 md:w-36 sm:w-36 w-96 max-w-36 justify-center items-center h-12 rounded-full gap-2 text-textdarkerpurpel font-semibold lg:mr-6">
                                <img
                                  src={images.verified1}
                                  alt=""
                                  className="w-7 h-7"
                                />{" "}
                                Verified
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="bg-weirdgray rounded-lg mt-4">
                          <div className="flex p-3 items-center gap-4">
                            <div className="text-white lg:text-xl md:text-xl sm:text-sm text-sm ">
                              Finalize & Post
                            </div>
                          </div>
                          <div className="bg-buttoncolor text-white p-3 md:p-10 ">
                            <div className="bg-buttoncolor rounded-3xl text-white ">
                              <div className="flex gap-2 items-center   pb-10 text-white border-b-2 border-whitepink border-opacity-10 justify-between">
                                <div className="flex gap-3 flex-col w-full">
                                  <div className="lg:text-4xl md:text-2xl sm:text-xl text-lg font-semibold flex flex-row items-center justify-between">
                                    <div
                                      onClick={() =>
                                        console.log(upgrades, selectedBumps)
                                      }
                                    >
                                      Advertisement
                                    </div>
                                    {/* <div>FREE</div> */}
                                  </div>
                                  {Object.values(upgrades).every(
                                    (value) => value === null
                                  ) ? (
                                    <div className="text-whitepink lg:text-base md:text-base text-sm opacity-80">
                                      No Upgrades chosen
                                    </div>
                                  ) : (
                                    <div className="gap-1 flex flex-col">
                                      {upgrades?.pageOne && (
                                        <div className="border border-weirdgray border-1 rounded-lg flex flex-row items-center justify-between px-2 py-2 text-lg font-semibold">
                                          <div>
                                            {`Page 1 : ${
                                              upgrades?.pageOne?.hour === "five"
                                                ? "5"
                                                : upgrades?.pageOne?.hour ===
                                                  "three"
                                                ? "3"
                                                : "7"
                                            } Hours from  ${
                                              upgrades.pageOne?.startTime
                                            }`}
                                          </div>
                                          <div>
                                            {`$ ${upgrades?.pageOne?.cost}`}
                                          </div>
                                        </div>
                                      )}
                                      {upgrades?.premiumTheme && (
                                        <div className="border border-weirdgray border-1 rounded-lg flex flex-row items-center justify-between px-2 py-2 text-lg font-semibold">
                                          <div>
                                            {`Floral Ad theme : ${
                                              upgrades?.premiumTheme?.hour ===
                                              "five"
                                                ? "5"
                                                : upgrades?.premiumTheme
                                                    ?.hour === "three"
                                                ? "3"
                                                : "7"
                                            } Hours`}
                                          </div>
                                          <div>
                                            {`$ ${upgrades?.premiumTheme?.cost}`}
                                          </div>
                                        </div>
                                      )}
                                      {upgrades?.carousel && (
                                        <div className="border border-weirdgray border-1 rounded-lg flex flex-row items-center justify-between px-2 py-2 text-lg font-semibold">
                                          <div>
                                            {`carousel : ${
                                              upgrades?.carousel?.hour ===
                                              "three"
                                                ? "3"
                                                : upgrades?.carousel?.hour ===
                                                  "six"
                                                ? "6"
                                                : "9"
                                            } Hours`}
                                          </div>
                                          <div>
                                            {`$ ${upgrades?.carousel?.cost}`}
                                          </div>
                                        </div>
                                      )}
                                      {upgrades?.availableNow && (
                                        <div className="border border-weirdgray border-1 rounded-lg flex flex-row items-center justify-between px-2 py-2 text-lg font-semibold">
                                          <div>
                                            {`Available Now : ${
                                              upgrades?.availableNow?.hour ===
                                              "one"
                                                ? "1"
                                                : upgrades?.availableNow
                                                    ?.hour === "two"
                                                ? "2"
                                                : upgrades?.availableNow
                                                    ?.hour === "three"
                                                ? "3"
                                                : "4"
                                            } Hours`}
                                          </div>
                                          <div>
                                            {`$ ${upgrades?.availableNow?.cost}`}
                                          </div>
                                        </div>
                                      )}
                                      {upgrades?.featuredAdd && (
                                        <div className="border border-weirdgray border-1 rounded-lg flex flex-row items-center justify-between px-2 py-2 text-lg font-semibold">
                                          <div>
                                            {`Featured Ad: ${
                                              upgrades?.featuredAdd?.hour ===
                                              "five"
                                                ? "5"
                                                : upgrades?.featuredAdd
                                                    ?.hour === "three"
                                                ? "3"
                                                : "7"
                                            } Hours`}
                                          </div>
                                          <div>
                                            {`$ ${upgrades?.featuredAdd?.cost}`}
                                          </div>
                                        </div>
                                      )}
                                      {upgrades?.emoji && (
                                        <div className="border border-weirdgray border-1 rounded-lg flex flex-row items-center justify-between px-2 py-2 text-lg font-semibold">
                                          <div>
                                            {`Emoji for Ad Title: ${
                                              upgrades?.emoji?.hour === "five"
                                                ? "5"
                                                : upgrades?.emoji?.hour ===
                                                  "three"
                                                ? "3"
                                                : "7"
                                            } Hours`}
                                          </div>
                                          <div>
                                            {`$ ${upgrades?.emoji?.cost}`}
                                          </div>
                                        </div>
                                      )}
                                      {upgrades?.bumps && (
                                        <div className="border border-weirdgray border-1 rounded-lg flex flex-row items-center justify-between px-2 py-2 text-lg font-semibold">
                                          {selectedBumps?.selectedScheduleBumps
                                            ?.length > 0 ? (
                                            <div>
                                              {`${
                                                selectedBumps?.totalBumps
                                              } Bumps Package (${calculateTotalScheduleBumps(
                                                selectedBumps?.selectedScheduleBumps
                                              )} Scheduled Bumps)`}
                                            </div>
                                          ) : (
                                            <div>
                                              {`${selectedBumps?.totalBumps} Bumps Package`}
                                            </div>
                                          )}

                                          <div>
                                            {`$ ${upgrades?.bumps?.cost}`}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  <div className="w-full justify-between items-center flex"></div>
                                </div>
                              </div>
                              <div className="pt-10  text-2xl flex justify-between w-full ">
                                <div className="lg:text-4xl md:text-2xl sm:text-xl text-xl font-semibold">
                                  Total
                                </div>
                                <div className="lg:text-4xl md:text-2xl sm:text-xl text-xl font-semibold">
                                  {`$ ${totalCost?.toFixed(2)}`}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" w-full flex justify-between pt-20 pb-20 ">
                          <div
                            className="bg-white ml-4 lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8 w-24 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
                            onClick={() => setchangepanel("chooseupgrade")}
                          >
                            <div className="text-purple flex justify-between font-semibold lg:w-50p md:w-50p sm:w-75p w-68p items-center ">
                              <img
                                src={images.purplearrowleft}
                                alt=""
                                className="w-2 h-3 "
                              />
                              Previous
                            </div>
                          </div>
                          <div
                            className="mr-5  text-white bg-purple lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8  w-32 ml-5 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
                            onClick={() => {
                              handlePostAdvertisement();
                            }}
                          >
                            <div className="text-white text-nowrap flex justify-center  sm:text-xs font-semibold w-full items-center">
                              {
                                loader ? (
                                  <ClipLoader
                                    color="#FFFF"
                                    cssOverride={{
                                      height: "22px",
                                      width: "22px",
                                    }}
                                  />
                                ) : Object.values(upgrades).every(
                                    (value) => value === null
                                  ) ? (
                                  "Post"
                                ) : (
                                  "Pay by PC"
                                )
                                // "Pay by Lite coin"
                              }
                            </div>
                          </div>
                          {/* </Link> */}
                        </div>
                        {paymentModal && (
                          <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                            <div className="text-white rounded-xl py-4 bg-purplegray flex items-center justify-center px-5 flex flex-col gap-5">
                              {/* <div>Ad posted successfully 🎉 </div> */}
                              <img
                                src={images.tickmarkdone}
                                className="w-[50%]"
                              />
                              <div className="font-semibold font-lg">
                                Payment confirmed and <br /> ad successfully
                                posted.
                              </div>
                              <div
                                className="bg-purple rounded-md px-4 py-2 cursor-pointer active:opacity-50"
                                onClick={() => {
                                  setPaymentModal(false);
                                  navigate("/");
                                }}
                              >
                                Go to Home
                              </div>
                            </div>
                          </div>
                        )}
                        {insufficentBalance && (
                          <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                            <div className="text-white rounded-xl py-4 bg-purplegray flex items-center justify-center px-5 flex flex-col gap-5">
                              {/* <div>Ad posted successfully 🎉 </div> */}
                              {/* <img
                                src={images.tickmarkdone}
                                className="w-[50%]"
                              /> */}
                              <div className="font-semibold font-lg">
                                Insufficient balance. Cannot proceed with the
                                advertisement posting.
                              </div>
                              <div
                                className="bg-purple rounded-md px-4 py-2 cursor-pointer active:opacity-50"
                                onClick={() => {
                                  setPaymentModal(false);
                                  navigate("/giftcard");
                                }}
                              >
                                Add Pleasury Credits
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ) : changepanel === "categories" ? (
                    <>
                      <div className="text-whitepink mb-20 flex flex-col items-center h-full">
                        <div className="text-2xl">Choose your Categories</div>
                        <div className="my-20 grid grid-cols-2 sm:grid-cols-5 md:grid-cols-6  w-95p  text-sm h-40p bg-footercolor ">
                          <div
                            className={
                              chosenCategory === "Gigs&jobs"
                                ? `cursor-pointer  bg-purple  active:opacity-50 flex items-center justify-center`
                                : `cursor-pointer  active:opacity-50  flex items-center justify-center`
                            }
                            onClick={() => setChosenCategory("Gigs&jobs")}
                          >
                            Gigs & Jobs
                          </div>
                          <div
                            className={
                              chosenCategory === "Dom&Fetish"
                                ? `cursor-pointer  bg-purple  active:opacity-50 flex items-center justify-center`
                                : `cursor-pointer   active:opacity-50 flex items-center justify-center`
                            }
                            onClick={() => setChosenCategory("Dom&Fetish")}
                          >
                            Dom & Fetish
                          </div>
                          <div
                            className={
                              chosenCategory === "Female&Escorts"
                                ? `cursor-pointer  bg-purple  active:opacity-50 flex items-center justify-center`
                                : `cursor-pointer   active:opacity-50 flex items-center justify-center`
                            }
                            onClick={() => setChosenCategory("Female&Escorts")}
                          >
                            Female & Escorts
                          </div>
                          <div
                            className={
                              chosenCategory === "FemaleMessage"
                                ? `cursor-pointer  bg-purple  active:opacity-50 flex items-center justify-center`
                                : `cursor-pointer   active:opacity-50 flex items-center justify-center`
                            }
                            onClick={() => setChosenCategory("FemaleMessage")}
                          >
                            Female Message
                          </div>
                          <div
                            className={
                              chosenCategory === "MaleEscorts"
                                ? `cursor-pointer  bg-purple  active:opacity-50 flex items-center justify-center`
                                : `cursor-pointer   active:opacity-50 flex items-center justify-center`
                            }
                            onClick={() => setChosenCategory("MaleEscorts")}
                          >
                            Male Escorts
                          </div>
                          <div
                            className={
                              chosenCategory === "TranssexualEscorts"
                                ? `cursor-pointer bg-purple   active:opacity-50 flex items-center justify-center`
                                : `cursor-pointer   active:opacity-50 flex items-center justify-center`
                            }
                            onClick={() =>
                              setChosenCategory("TranssexualEscorts")
                            }
                          >
                            Transsexual Escorts
                          </div>
                          <div
                            className={
                                `cursor-pointer gap-1   text-nowrap flex-wrap active:opacity-50 flex items-center justify-center`
                            }
                            onClick={handleNavigateSugerBabies}
                          >
                            Sugar Babies <span className="bg-purple text-sm px-1">SPONSOR</span>
                          </div>
                          <div
                            className={
                                `cursor-pointer gap-1 pl-5 text-nowrap flex-wrap  active:opacity-50 flex items-center justify-center`
                            }
                            onClick={handleNavigateHookUp}
                          >
                            Hook Up <span className="bg-purple text-sm px-1">SPONSOR</span>
                          </div>
                        </div>
                        <div className="flex w-full justify-end pb-20 mt-10">
                          <div
                            className="mr-5 text-white bg-purple lg:h-12 lg:w-40 md:h-10 md:w-22 sm:h-10 sm:w-22 h-8 w-24 ml-5 flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
                            onClick={checkChosenCategories}
                          >
                            <div className="text-white flex justify-between font-semibold lg:w-40p w-50p  items-center">
                              Next
                              <img
                                src={images.smallarrowright}
                                alt=""
                                className="lg:w-2 lg:h-3  md:h-2 sm:h-2 h-2 "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
