import React, { useState, createContext, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AuthModal from "../../components/AuthModal";


const ModalContext = createContext();

const Layout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Modalcomponent, setmodalcomponent] = useState('');
  const [isAuth, setIsAuth] = useState(true);

  return (
    <div className="flex flex-col justify-between h-[100vh] bg-purplegray">
      <ModalContext.Provider
        value={{ isModalOpen, setIsModalOpen, Modalcomponent, setmodalcomponent }}
      >
        <div className="bg-purplegray">

          <Header
            useModal={useModal}
          />
          <Outlet />
        </div>
        <Footer />
        <AuthModal
          isOpen={isModalOpen}
          useModal={useModal}
          Modalcomponent={Modalcomponent}
          setmodalcomponent={setmodalcomponent}
          isAuth={isAuth}
        />
      </ModalContext.Provider>
    </div>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

export { useModal, Layout as default };
