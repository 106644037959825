import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import images from "../../services/images";
import CreateProfile from "../CreateProfile";
import { useModal } from "../../screens/Layout";
import { verifyEmail } from "../../services/API";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { ClipLoader } from "react-spinners";

export default function Verification({ onCloseModal, setloggedin }) {
  const { Modalcomponent, setmodalcomponent } = useModal();

  const [isAgreed, setIsAgreed] = useState(false);
  const [otherscreen, setotherscreen] = useState(false);
  const [privacySelected, setPrivacySelected] = useState(false);
  const [error, setError] = useState("validated");
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState(false);

  const { setOtp, otp } = useAuth();

  const handleAgreeToggle = () => {
    setIsAgreed(!isAgreed);
  };

  const handleForgotPassword = async () => {
    try {
      const body = {
        email,
      };
      const response = await verifyEmail(body);
      if (response.success) {
        setError("validated");
        setOtp(response.otp);

        return true;
      } else {
        setError(response.message);
        return false;
      }
    } catch (error) {}
  };

  const validateForm = () => {
    // const { username, email, password, confirmPassword, privacySelected } = formData;

    // Check if any required fields are empty
    if (!email) {
      setError("Please fill out all required fields.");
      setLoader(false);

      return false;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email format.");
      setLoader(false);

      return false;
    }

    // Clear error if all validations pass
    setError("validated");
    return true;
  };

  const HandleJoinnow = () => {
    onCloseModal();
    setloggedin(false);
  };

  const handleSignInClick = async () => {
    setLoader(true);
    if (validateForm()) {
      const success = await handleForgotPassword();
      if (success) {
        setmodalcomponent("signupOTP");
        setLoader(false);
      }
    }
  };

  return (
    <div className="lg:w-50p biggerlaptop:p-4 lg:p-3 h-full md:p-1 sm:p-2 p-1 w-full ">
      <div
        className="bg-cover bg-center h-full flex flex-col p-10 items-start justify-between"
        style={{
          backgroundImage: `url(${images.Bluredimg})`,
        }}
      >
        <div className="w-full flex ">
          <div className="w-50p flex justify-start  flex   ">
            <img
              src={images.arrowwhiteleft}
              alt=""
              className="w-6 h-5 cursor-pointer"
              onClick={() => setmodalcomponent("signup")}
            />
          </div>
          <div className="w-60p  flex justify-end" onClick={onCloseModal}>
            <img
              src={images.X}
              alt=""
              className=" cursor-pointer md:h-7 sm:h-6 h-5"
            />
          </div>
        </div>
        <div className="flex w-full justify-between lg:flex-col md:flex-col md:flex-col sm:flex-col flex-col  pt-5 ">
          <div className="lg:text-4xl md:text-2xl sm:text-2xl text-2xl text-white font-semibold text-nowrap opacity-90 ">
            Verification
          </div>
          <div className="text-white w-87p pt-2">
            Please enter your email to receive a verification code
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-full border-b-2">
            <input
              className=" text-white  opacity-50  remove-arrow bg-transparent  h-10 w-full focus:outline-none placeholder-white pl-4 w-full  "
              type="Email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div
          className={
            error === "validated"
              ? "error cursor-pointer opacity-0"
              : "error cursor-pointer"
          }
        >
          {error}
        </div>
        <div className="w-full" onClick={handleSignInClick}>
          {loader ? (
            <div className="bg-purple font-semibold text-lg text-white text-center py-4 w-full cursor-pointer mb-8 mt-2  active:">
              <ClipLoader
                color="#FFFF"
                cssOverride={{
                  height: "25px",
                  width: "25px",
                }}
              />
            </div>
          ) : (
            <div className="bg-purple font-semibold text-lg text-white text-center py-4 w-full cursor-pointer mb-8 mt-2  active:">
              Send
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
