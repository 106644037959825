import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import images from "../../services/images";
import CreateProfile from "../CreateProfile";
import { useModal } from "../../screens/Layout";
import { resetPass } from "../../services/API";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { ClipLoader } from "react-spinners";

export default function Resetpassword({ onCloseModal, setloggedin }) {
  const [isAgreed, setIsAgreed] = useState(false);
  const [otherscreen, setotherscreen] = useState(false);
  const [show, setshow] = useState(true);
  const [show2, setshow2] = useState(true);
  const [newPass, setNewpass] = useState("");
  const [reEnterNewPass, setReEnterNewPass] = useState("");
  const [loader, setLoader] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const handleAgreeToggle = () => {
    setIsAgreed(!isAgreed);
  };

  const HandleJoinnow = () => {
    onCloseModal();
  };

  const { forgotPassEmail } = useAuth()

  const resetPassword = async () => {
    setLoader(true)
    try {
      if (newPass === reEnterNewPass) {
        const body = { password: newPass, email: forgotPassEmail }
        const response = await resetPass(body);
        if (response.success) {
          setLoader(false)
          return true
        } else {
          setLoader(false)
          alert(response?.message)
          return false
        }
      } else {
        setLoader(false)
        alert('Passwords do not match');
        return false
      }
    } catch (error) {
      setLoader(false)
      alert('Password reset failed:');
      return false
    }
  };




  const { setmodalcomponent, Modalcomponent } = useModal();

  const handleSignInClick = async () => {
    const response = await resetPassword()
    if (response) {
      setShowModal(true)
    }
  };

  return (
    <div className="lg:w-50p h-full biggerlaptop:p-4 lg:p-3  md:p-1 sm:p-2 p-1 w-full ">
      <div
        className="bg-cover bg-center h-full flex flex-col p-10 items-start justify-between"
        style={{
          backgroundImage: `url(${images.Bluredimg})`,
        }}
      >
        <div className="w-full flex ">
          <div className="w-50p flex justify-start  flex   ">
            <img
              src={images.arrowwhiteleft}
              alt=""
              className="w-6 h-5 cursor-pointer"
              onClick={() => setmodalcomponent("OTP")}
            />
          </div>
          <div className="w-60p  flex justify-end" onClick={onCloseModal}>
            <img
              src={images.X}
              alt=""
              className="cursor-pointer md:h-7 sm:h-6 h-5"
            />
          </div>
        </div>
        <div className="flex  w-full justify-between lg:flex-col md:flex-col md:flex-col sm:flex-col flex-col   ">
          <div className="lg:text-4xl md:text-2xl sm:text-2xl text-2xl text-white font-semibold text-nowrap opacity-90 ">
            Reset Password
          </div>
          <div className="text-white w-87p pt-2">
            Enter a new password to reset the password on your account.
          </div>
        </div>
        <div className="flex flex-col w-full space-y-10">
          <div className=" border-b w-full flex  items-center ">
            <input
              className=" text-white   text-base  bg-transparent placeholder-opacity-50 h-10 w-full focus:outline-none placeholder-white  w-10  "
              type={show ? `password` : `text`}
              placeholder="New password"
              onChange={(e) => setNewpass(e.target.value)}
            />
            <img
              src={show ? images.hide : images.show}
              alt=""
              onClick={() => {
                setshow(!show);
              }}
              className=" w-5 h-4 cursor-pointer"
            />
          </div>
          <div className="border-b w-full flex items-center ">
            <input
              className=" text-white text-base bg-transparent placeholder-opacity-50 h-10 w-full focus:outline-none placeholder-white  w-10  "
              type={show2 ? `password` : `text`}
              placeholder="Re-Enter Password "
              onChange={(e) => setReEnterNewPass(e.target.value)}
            />
            <img
              src={show2 ? images.hide : images.show}
              alt=""
              onClick={() => {
                setshow2(!show2);
              }}
              className=" w-5 h-4 cursor-pointer"
            />
          </div>
        </div>
        <div
          className="bg-purple font-semibold text-lg text-white text-center py-4 w-full cursor-pointer mb-8 mt-2  active:"
          onClick={handleSignInClick}
        >
          {
            loader ?
              <ClipLoader
                color="#FFFF"
                cssOverride={{
                  height: "22px",
                  width: "22px",
                }}

              /> :
              "Submit"
          }
        </div>
      </div>
      {
        showModal &&
        <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="text-white rounded-xl py-4 bg-purplegray flex items-center justify-center px-5 flex flex-col gap-5">
            <img
              src={images.tickmarkdone}
              className="w-[50%]"
            />
            <div className="font-semibold font-lg">
              Password change successfully.
            </div>
            <div
              className="bg-purple rounded-md px-4 py-2 cursor-pointer active:opacity-50"
              onClick={() => setmodalcomponent("signin")}
            >
              Done
            </div>
          </div>
        </div>
      }
    </div>
  );
}
