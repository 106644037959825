import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import images from "../../services/images";
import Signin from "../Login";
import Signup from "../Register";
import CreateProfile from "../CreateProfile";
import OTP from "../OTP";
import Forgotpassword from "../ForgotPassword";
import Resetpassword from "../ResetPassword";
import { useAuth } from "../../services/Authcontext/Authcontext";
import Verification from "../Verification";
import OTPsignup from "../OTPSignup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faLeaf } from "@fortawesome/free-solid-svg-icons";
import EditlistComponent from "../EditlistComponent";
import Uplaodphotoscomponent from "../Uploadphotos";
import Donemodal from "../Donemodal";
import { useDispatch, useSelector } from "react-redux";
import {
  removeUserLogedIn,
  selectLogedIn,
  setUserLogedIn,
} from "../../store/logedIn";

const AuthModal = ({ isOpen, useModal, Modalcomponent }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const userLogin = useSelector(selectLogedIn);

  const { setIsModalOpen } = useModal();
  const [modalOpen, setModalOpen] = useState(true);
  const [Escort, Setisescort] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const {
    isLoggedIn,
    logout,
    setisLoggedIn,
    toggleescortloggin,
    toggleuserloggedin,
    userloggedin,
    escortloggedin,
    streamer,
    toggleStreamerLogin,
    both,
    toggleboth,
    takeUsername,
    UserEmail,
    saveUser,
    saveEmail,
  } = useAuth();

  useEffect(() => {
    setModalOpen(isOpen);
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const toggleuser = () => {
    toggleuserloggedin();
  };
  const togglescort = () => {
    toggleescortloggin();
  };

  const togglestreamer = () => {
    toggleStreamerLogin();
  };

  const togglebothh = () => {
    toggleboth();
  };

  return (
    <div
      className={`fixed bottom-14 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 ${
        modalOpen ? "" : "hidden"
      }`}
      onClick={() => {
        setIsModalOpen(false);
      }}
      style={{
        zIndex: isOpen ? 50 : -1,
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`
        ${
          Modalcomponent === "done"
            ? `flex flex-row bg-cover bg-center sm:h-96 h-96   biggerlaptop:w-[30%] laptop:w-62p lg:w-60p md:w-60p sm:w-70p w-full`
            : Modalcomponent === "Uploadphotos"
            ? `flex flex-row bg-cover bg-center bg-red-500 lg:h-60vh md:h-94vh sm:h-94vh h-94vh p-4 biggerlaptop:w-50p laptop:w-62p lg:w-60p md:w-80p sm:w-70p w-full`
            : `flex flex-row bg-cover bg-center lg:h-80vh md:h-94vh sm:h-94vh h-94vh p-4 biggerlaptop:w-50p laptop:w-62p lg:w-60p md:w-80p sm:w-70p w-full`
        } 
        `}
        style={{
          backgroundImage: `url(${images.modalbg})`,
        }}
      >
        <div className="flex  lg:flex-row md:flex-col flex-col w-full">
          <div
            className={`flex flex-col justify-between     text-white ${
              Modalcomponent === "Editlist"
                ? `w-0`
                : Modalcomponent === "Uploadphotos"
                ? `w-0`
                : Modalcomponent === "done"
                ? `w-0`
                : `lg:w-50p md:w-full sm:w-full  w-full`
            } `}
          >
            <div
              className={`flex  flex-row font-semibold text-lg cursor-pointer items-center text-nowrap justify-between w-full 
              ${
                Modalcomponent === "Createprof"
                  ? ``
                  : Modalcomponent === "done"
                  ? ``
                  : `pt-10 pl-10 pr-10`
              }
              `}
            >
              {Modalcomponent === "signin" ? (
                <div className="w-full flex">
                  <div className="flex items-center w-80p justify-center  h-10   ">
                    <div
                      className={
                        userLogin
                          ? `flex items-center bg-purple lg:h-14 md:h-10 sm:h-10 h-10 w-90p justify-center`
                          : `flex items-center gap-1 backdrop-blur-md bg-whitepink/20 lg:h-14 md:h-10 sm:h-10 h-10 w-90p justify-center`
                      }
                      onClick={() => {
                        dispatch(setUserLogedIn());
                      }}
                    >
                      <div className={`px-1 py-1 rounded text-center flex `}>
                        <div className="border-2 rounded-full  items-center justify-center flex lg:h-5 lg:w-5 md:h-5 md:w-5 sm:h-5 sm:w-5 h-5 w-5">
                          <div
                            className={
                              userLogin
                                ? `border-1 rounded-full lg:w-3 lg:h-3 md:w-3 md:h-3 sm:w-3 sm:h-3 w-3 h-3 flex items-center justify-center bg-white`
                                : `rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4  flex items-center justify-center`
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              size="1x"
                              style={{
                                borderRadius: "100%",
                                height: "10px",
                                width: "10px",
                                cursor: "pointer",
                                color: "transparent",
                                "@media(maxWidth: 444px)": {
                                  height: "5px",
                                  width: "5px",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="lg:text-base md:text-sm sm:text-xs text-xs">
                        User
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center w-80p justify-center h-10 ">
                    <div
                      className={
                        !userLogin
                          ? `flex items-center bg-purple lg:h-14 md:h-10 sm:h-10 h-10 w-90p justify-center`
                          : `flex items-center gap-1 backdrop-blur-md bg-whitepink/20 lg:h-14 md:h-10 sm:h-10 h-10 w-90p justify-center`
                      }
                      onClick={() => {
                        dispatch(removeUserLogedIn());
                      }}
                    >
                      <div className={`px-1 py-1 rounded text-center flex `}>
                        <div className=" border-2 rounded-full  items-center justify-center flex lg:h-5 lg:w-5 md:h-5 md:w-5 sm:h-5 sm:w-5 h-5 w-5 ">
                          <div
                            className={
                              !userLogin
                                ? `border-1 rounded-full lg:w-3 lg:h-3 md:w-3 md:h-3 sm:w-3 sm:h-3 w-3 h-3 flex items-center justify-center bg-white`
                                : `rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4  flex items-center justify-center`
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              size="1x"
                              style={{
                                borderRadius: "100%",
                                height: "10px",
                                width: "10px",
                                cursor: "pointer",
                                color: "transparent",
                                "@media(maxWidth: 444px)": {
                                  height: "5px",
                                  width: "5px",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="lg:text-base md:text-sm sm:text-xs text-xs">
                        Escort
                      </div>
                    </div>
                  </div>
                </div>
              ) : Modalcomponent === "signup" ? (
                <div className="w-full flex">
                  <div className="flex items-center w-80p justify-center  h-10   ">
                    <div
                      className={
                        userLogin
                          ? `flex items-center bg-purple lg:h-14 md:h-10 sm:h-10 h-10 w-90p justify-center`
                          : `flex items-center gap-1 backdrop-blur-md bg-whitepink/20 lg:h-14 md:h-10 sm:h-10 h-10 w-90p justify-center`
                      }
                      onClick={() => {
                        dispatch(setUserLogedIn());
                      }}
                    >
                      <div className={`px-1 py-1 rounded text-center flex `}>
                        <div className="border-2 rounded-full  items-center justify-center flex lg:h-5 lg:w-5 md:h-5 md:w-5 sm:h-5 sm:w-5 h-5 w-5">
                          <div
                            className={
                              userLogin
                                ? `border-1 rounded-full lg:w-3 lg:h-3 md:w-3 md:h-3 sm:w-3 sm:h-3 w-3 h-3 flex items-center justify-center bg-white`
                                : `rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4  flex items-center justify-center`
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              size="1x"
                              style={{
                                borderRadius: "100%",
                                height: "10px",
                                width: "10px",
                                cursor: "pointer",
                                color: "transparent",
                                "@media(maxWidth: 444px)": {
                                  height: "5px",
                                  width: "5px",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="lg:text-base md:text-sm sm:text-xs text-xs">
                        User
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center w-80p justify-center h-10 ">
                    <div
                      className={
                        !userLogin
                          ? `flex items-center bg-purple lg:h-14 md:h-10 sm:h-10 h-10 w-90p justify-center`
                          : `flex items-center gap-1 backdrop-blur-md bg-whitepink/20 lg:h-14 md:h-10 sm:h-10 h-10 w-90p justify-center`
                      }
                      onClick={() => {
                        dispatch(removeUserLogedIn());
                      }}
                    >
                      <div className={`px-1 py-1 rounded text-center flex `}>
                        <div className=" border-2 rounded-full  items-center justify-center flex lg:h-5 lg:w-5 md:h-5 md:w-5 sm:h-5 sm:w-5 h-5 w-5 ">
                          <div
                            className={
                              !userLogin
                                ? `border-1 rounded-full lg:w-3 lg:h-3 md:w-3 md:h-3 sm:w-3 sm:h-3 w-3 h-3 flex items-center justify-center bg-white`
                                : `rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4  flex items-center justify-center`
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              size="1x"
                              style={{
                                borderRadius: "100%",
                                height: "10px",
                                width: "10px",
                                cursor: "pointer",
                                color: "transparent",
                                "@media(maxWidth: 444px)": {
                                  height: "5px",
                                  width: "5px",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="lg:text-base md:text-sm sm:text-xs text-xs">
                        Escort
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            {Modalcomponent === "Createprof" ? null : Modalcomponent ===
              "Editlist" ? null : Modalcomponent ===
              "Uploadphotos" ? null : Modalcomponent === "done" ? null : (
              <div className="text-white lg:flex flex-col hidden mt-96">
                <img src={images.logo} alt="" className="w-20 mb-2 max-w-20" />
                Indulge in the epitome of companionship with our exquisite
                escorts who redefine the art of connection
              </div>
            )}

            {!userLogin ? (
              <div className="w-full">
                {Modalcomponent === "Createprof" ? (
                  <div className="text-white flex flex-col   w-full  gap-2">
                    <div className="text-3xl font-semibold lg:pl-10 ">
                      Choose Option{" "}
                    </div>
                    <div className="flex lg:flex-col flex-wrap items-center w-full gap-2">
                      <div
                        className={`1560:w-96 biggerlaptop:w-80 w-42 flex cursor-pointer items-center justify-between 
                              ${
                                escortloggedin == true
                                  ? `bg-purple`
                                  : `backdrop-blur-md bg-whitepink/20`
                              }`}
                        onClick={togglescort}
                      >
                        <div className="flex justify-center p-4 flex-col ">
                          <div className="text-3xl font-semibold lg:text-2xl md:text-xl text-xl ">
                            Escort
                          </div>
                          <div className="text-xxs 1560:w-60 lg:w-32 sm:w-32 lg:flex md:flex  hidden">
                            Expand your clientele, showcase unique services, and
                            connect discreetly with Pleasury.
                          </div>
                        </div>
                        <div className="pr-10">
                          {escortloggedin ? (
                            <div
                              className={` rounded text-center flex  `}
                              onClick={togglescort}
                            >
                              <div className="border-2 rounded-full  items-center justify-center flex lg:h-5 lg:w-5 md:h-5 md:w-5 sm:h-5 sm:w-5 h-5 w-5">
                                <div className="border-1 rounded-full lg:w-3 lg:h-3 md:w-3 md:h-3 sm:w-3 sm:h-3 w-3 h-3 flex items-center justify-center bg-white">
                                  <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    size="1x"
                                    style={{
                                      borderRadius: "100%",
                                      height: "10px",
                                      width: "10px",
                                      cursor: "pointer",
                                      color: "transparent",
                                      "@media(maxWidth: 444px)": {
                                        height: "5px",
                                        width: "5px",
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="border-2   rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4  flex items-center justify-center "
                              onClick={togglescort}
                            >
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="1x"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                  color: "transparent",
                                  border: "2px solid transparent ",
                                  "@media(maxWidth: 444px)": {
                                    height: "7px",
                                    width: "7px",
                                  },
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={`1560:w-96 biggerlaptop:w-80 w-42 flex cursor-pointer items-center justify-between
                              ${
                                streamer == true
                                  ? `bg-purple`
                                  : `backdrop-blur-md bg-whitepink/20`
                              }`}
                        onClick={togglestreamer}
                      >
                        <div className="flex justify-center p-4 flex-col ">
                          <div className="lg:text-2xl md:text-xl text-xl font-semibold ">
                            Streamer
                          </div>
                          <div className="text-xxs 1560:w-60 lg:w-32 sm:w-32 lg:flex md:flex  hidden">
                            Join Pleasury to thrive as a streamer in a
                            supportive community that values your unique style.
                          </div>
                        </div>
                        <div className="pr-10">
                          {streamer == true ? (
                            <div
                              className={` rounded text-center flex `}
                              onClick={togglestreamer}
                            >
                              <div className="border-2 rounded-full  items-center justify-center flex lg:h-5 lg:w-5 md:h-5 md:w-5 sm:h-5 sm:w-5 h-5 w-5">
                                <div className="border-1 rounded-full lg:w-3 lg:h-3 md:w-3 md:h-3 sm:w-3 sm:h-3 w-3 h-3 flex items-center justify-center bg-white">
                                  <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    size="1x"
                                    style={{
                                      borderRadius: "100%",
                                      height: "10px",
                                      width: "10px",
                                      cursor: "pointer",
                                      color: "transparent",
                                      "@media(maxWidth: 444px)": {
                                        height: "5px",
                                        width: "5px",
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="border-2 rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4  flex items-center justify-center "
                              onClick={togglestreamer}
                            >
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="1x"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                  color: "transparent",
                                  border: "2px solid transparent ",
                                  "@media(maxWidth: 444px)": {
                                    height: "7px",
                                    width: "7px",
                                  },
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={`1560:w-96 biggerlaptop:w-80 w-42 flex  cursor-pointer  items-center justify-between
                              ${
                                both == true
                                  ? `bg-purple`
                                  : `backdrop-blur-md bg-whitepink/20`
                              }`}
                        onClick={togglebothh}
                      >
                        <div className="flex justify-center p-4 flex-col ">
                          <div className="lg:text-2xl md:text-xl text-xl  font-semibold ">
                            Both
                          </div>
                          <div className="text-xxs 1560:w-60 lg:w-32 sm:w-32 lg:flex md:flex  hidden">
                            At Pleasury, diversify, expand, and indulge.
                          </div>
                        </div>
                        <div className="pr-10">
                          {both == true ? (
                            <div
                              className={`rounded text-center flex `}
                              onClick={togglebothh}
                            >
                              <div className="border-2 rounded-full  items-center justify-center flex lg:h-5 lg:w-5 md:h-5 md:w-5 sm:h-5 sm:w-5 h-5 w-5">
                                <div className="border-1 rounded-full lg:w-3 lg:h-3 md:w-3 md:h-3 sm:w-3 sm:h-3 w-3 h-3 flex items-center justify-center bg-white">
                                  <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    size="1x"
                                    style={{
                                      borderRadius: "100%",
                                      height: "10px",
                                      width: "10px",
                                      cursor: "pointer",
                                      color: "transparent",
                                      "@media(maxWidth: 444px)": {
                                        height: "5px",
                                        width: "5px",
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="border-2 rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4  flex items-center justify-center "
                              onClick={togglebothh}
                            >
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="1x"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                  color: "transparent",
                                  border: "2px solid transparent ",
                                  "@media(maxWidth: 444px)": {
                                    height: "7px",
                                    width: "7px",
                                  },
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          {/* {signin  ? (<Signin/>) : (<Signup/>) } */}
          {Modalcomponent === "signin" ? (
            <Signin onCloseModal={closeModal} />
          ) : Modalcomponent === "signup" ? (
            <Signup onCloseModal={closeModal} />
          ) : Modalcomponent === "forgotPass" ? (
            <Forgotpassword />
          ) : Modalcomponent === "OTP" ? (
            <OTP onCloseModal={closeModal} />
          ) : Modalcomponent === "ResetPassword" ? (
            <Resetpassword onCloseModal={closeModal} />
          ) : Modalcomponent === "Createprof" ? (
            <CreateProfile onCloseModal={closeModal} />
          ) : Modalcomponent === "verification" ? (
            <Verification onCloseModal={closeModal} />
          ) : Modalcomponent === "signupOTP" ? (
            <OTPsignup onCloseModal={closeModal} />
          ) : Modalcomponent === "Editlist" ? (
            <EditlistComponent />
          ) : Modalcomponent === "Uploadphotos" ? (
            <Uplaodphotoscomponent />
          ) : Modalcomponent === "done" ? (
            <Donemodal onCloseModal={closeModal} />
          ) : // Render a default component if none of the conditions match
          null}
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
