import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import HomeIntro from "../../components/HomeIntro";
import Homebanner from "../../components/Homebanner";
import HomeCards from "../../components/HomeCards";
import Footer from "../../components/Footer";
import AuthModal from "../../components/AuthModal";
import { useModal } from "../Layout";
import images from "../../services/images";
// import { animated } from '@react-spring/web'
import { useSpring, animated } from "@react-spring/web";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../store/token";
import userData, { selectUserData } from "../../store/userData";
import { selectLogedIn } from "../../store/logedIn";

export default function Home() {

  const dispatch = useDispatch()
  const userData = useSelector(selectUserData)

  console.log("this is suer data",userData)


  const [signedin, Setsignedin] = useState(false);
  const [showAd, setShowAd] = useState(true);
  const authentication = () => {
    Setsignedin(!signedin);
  };

  const userLogin = useSelector(selectUserData);

  const { setIsModalOpen } = useModal();

  useEffect(() => {
    handleScrollToTop()
  }, [])

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }


  return (
    <>
      <div className="mx-auto">
        <HomeIntro />
        <Homebanner />
        <HomeCards />
      </div>
    </>
  );
}
