import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import images from "../../services/images";
import { City, Country, State } from "country-state-city";
import {
  faCheckSquare,
  faL,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  handleUploadPictures,
  handleVideosUpload,
  updateAd,
} from "../../services/API";
import { ClipLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { selectToken } from "../../store/token";

export default function MyAdsDetails() {
  const navigate = useNavigate();

  const token = useSelector(selectToken);

  const { _id } = useParams();
  const location = useLocation();
  const { isEdit, item } = location?.state || {};

  const inputFile = useRef(null);
  const inputFileVideo = useRef(null);

  const [pictures, setPictures] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setselectedCity] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [description, setdescription] = useState("");
  const [selectDropDown, setSelectDropDown] = useState("");
  const [selectedCupSize, setSelectedCupSize] = useState(null);
  const [selectedChest, setSelectedChest] = useState(null);
  const [selectedWaist, setSelectedWaist] = useState(null);
  const [selectedHips, setSelectedHips] = useState(null);
  const [selectedHiarColor, setSelectedHairColor] = useState(null);
  const [selectedEyeColor, setSelectedEyeColor] = useState(null);
  const [selectedEthnicity, setSelectedEthnicity] = useState(null);
  const [selectedEthnicOrigin, setSelectedEthnicOrigin] = useState([]);
  const [limitModal, setLimitModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [languageLimit, setLanguageLimit] = useState(false);
  const [search, setSearch] = useState("");
  const [dropDownDescription, setDropDownDescription] = useState("");
  const [ethnicOrigin, setEthnicOrigin] = useState("");
  const [adTitle, setAdTitle] = useState("");
  const [name, setName] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [email, setEmailAdress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [languagesSpoken, setLanguagesSpoken] = useState("");
  const [anonymousContactForm, setAnonymousContactForm] = useState(null);
  const [allowViaWhatsapp, setAllowViaWhatsapp] = useState(null);
  const [allowReview, setAllowReview] = useState(null);
  const [availability, setAvailability] = useState("");
  const [onlineServices, setOnlineServices] = useState([]);
  const [showrecentads, setShowRecentAds] = useState("");
  const [upgrades, setUpgrades] = useState("");
  const [inCallagree, setIncallAgree] = useState(false);
  const [outCall, setisOutcall] = useState(false);
  const [onlineagree, setIsonlineAgree] = useState(false);
  const [videoShows, setVideoShows] = useState(false);
  const [phoneSex, setPhoneSex] = useState(false);
  const [vidsForSale, setVidsForSale] = useState(false);
  const [heightFeet, setHeightFeet] = useState("ft");
  const [heightInches, setHeightInches] = useState("in");
  const [weight, setWeight] = useState("");
  const [uploadImgLoader, setUploadImgLoader] = useState(false);

  const [ethnicOriginData, setEthnicOriginData] = useState([
    "Canadian",
    "American",
    "French",
    "Chinese",
    "Afghan",
    "Albanian",
    "Algerian",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "Dutchman",
    "Dutchwoman",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivan",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "Netherlander",
    "New Zealander",
    "Ni-Vanuatu",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
  ]);
  const [languageData, setLanguageData] = useState([
    "English",
    "Chinese",
    "Spanish",
    "French",
    "Portuguese",
    "Russian",
    "Indonesian",
    "Arabic",
    "Bulgarian",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "Finnish",
    "Greek",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Italian",
    "Japanese",
    "Korean",
    "Latvian",
    "Lithuanian",
    "Norwegian",
    "Polish",
    "Romanian",
    "Serbian",
    "Slovak",
    "Slovenian",
    "Swedish",
    "Tagalog",
    "Thai",
    "Turkish",
    "Ukrainian",
    "Vietnamese",
  ]);
  const [cupSizeData, setCupSizeData] = useState([
    "...",
    "AA",
    "A",
    "B",
    "C",
    "D",
    "DD+",
    "DDD (E)",
    "F",
  ]);
  const [chestData, setChestData] = useState([
    "...",
    ...Array.from({ length: 53 }, (_, i) => String(27 + i)),
  ]);
  const [waistData, setWaistData] = useState([
    "...",
    ...Array.from({ length: 61 }, (_, i) => String(19 + i)),
  ]);
  const [hipsData, setHipsData] = useState([
    "...",
    ...Array.from({ length: 70 }, (_, i) => String(31 + i)),
  ]);
  const [hairData, setHairData] = useState([
    "...",
    "Black",
    "Brown",
    "Blond",
    "Auburn",
    "Chestnut",
    "Red",
    "Gray",
    "Other",
  ]);
  const [eyeColorData, setEyeColorData] = useState([
    "...",
    "Brown",
    "Hazel",
    "Blue",
    "Green",
    "Silver",
    "Amber",
    "Other",
  ]);
  const [ethnicityData, setEthnicityData] = useState([
    "Asian",
    "Black",
    "Canadian Born Chinese",
    "Caucasian/White",
    "Indo Canadian",
    "Latino/Hispanic",
    "Middle Eastern",
    "Mixed",
    "Native",
  ]);
  const [heightFtData, setHeightFtData] = useState([
    "ft",
    "3",
    "4",
    "5",
    "6",
    "7",
  ]);
  const [heightInchData, setHeightInchData] = useState([
    "in",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ]);
  const [weightData, setWeightData] = useState([
    "lbs",
    ...Array.from({ length: 280 }, (_, i) => String(44 + i)),
  ]);
  const [age, setAge] = useState("");
  const [selectedImgIndex, setSelectedImgIndex] = useState();
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [videoLoader, setVideoLoader] = useState(false);

  useEffect(() => {
    if (selectedEthnicOrigin.length < 6) {
      setLimitModal(false);
    }
  }, [selectedEthnicOrigin]);

  useEffect(() => {
    if (selectedLanguage.length < 6) {
      setLanguageLimit(false);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (location?.state && location?.state?.item) {
      setPictures(item?.pictures);
      setSelectedImgIndex(0);
      setVideos(item?.videos);
      setSelectedCountry(item?.country);
      setSelectedState(item?.state);
      setselectedCity(item?.city);
      setSelectedCupSize(item?.cup_size);
      setSelectedChest(item?.chest);
      setSelectedWaist(item?.weight);
      setSelectedHips(item?.hips);
      setSelectedHairColor(item?.hair_color);
      setSelectedEyeColor(item?.eye_color);
      setSelectedEthnicity(item?.ethnicity);
      setEthnicOrigin(item?.ethnic_origin);
      fetchCountries(item?.country, item?.state);
      setAdTitle(item?.ad_title);
      setAge(item?.age);
      setName(item?.name);
      setHourlyRate(item?.hourly_rate);
      setPhoneNumber(item?.phone_number);
      setEmailAdress(item?.email_address);
      setWebsite(item?.website);
      setLanguagesSpoken(item?.language_spoken);
      setAnonymousContactForm(item?.anonymous_contact_form);
      setAllowViaWhatsapp(item?.allow_message_via_whatsapp);
      setAllowReview(item?.show_lyla_ch_reviews);
      setAvailability(item?.availability);
      setShowRecentAds(item?.show_recent_ads);
      setUpgrades(item?.upgrades);
      setHeightFeet(item?.height_feet);
      setWeight(item?.weight);
      setHeightInches(item?.height_inches);
      setEthnicOrigin(item?.ethnic_origin);
      setdescription(item?.ad_description);
      if (item?.availability.includes("Out-call")) {
        setisOutcall(true);
      }
      if (item?.availability.includes("In-call")) {
        setIncallAgree(true);
      }
      if (item?.availability.includes("Online")) {
        setIsonlineAgree(true);
      }

      if (item?.online_services.includes("Vid/Pics For Sale")) {
        setVidsForSale(true);
      }
      if (item?.online_services.includes("Video Shows")) {
        setVideoShows(true);
      }
      if (item?.online_services.includes("Phone Sex")) {
        setPhoneSex(true);
      }
      console.log(item);
    }
  }, [location.state]);

  const fetchCountries = async (countryName, oldState) => {
    const allCountries = await Country.getAllCountries();
    setCountries(allCountries);
    const country = allCountries.find(
      (country) => country.name === countryName
    );
    if (country) {
      fetchStates(country.isoCode, oldState);
      return country.isoCode;
    } else {
      return null;
    }
  };

  const fetchStates = async (countryCode, oldState) => {
    const allStates = await State.getStatesOfCountry(countryCode);
    setStates(allStates);
    const filteredState = allStates.find((states) => states.name === oldState);
    if (filteredState) {
      fetchCities(filteredState.countryCode, filteredState.isoCode);
    }
  };

  const fetchStatesSecond = async (countryCode, oldState) => {
    const allStates = await State.getStatesOfCountry(countryCode);
    setStates(allStates);
    setCities([]);
  };

  const fetchCities = async (countryCode, isoCode) => {
    const allCities = await City.getCitiesOfState(countryCode, isoCode);
    setCities(allCities);
  };

  const fetchCountriesSecond = async (countryName) => {
    setSelectedCountry(countryName);
    setSelectedState("");
    setselectedCity("");
    const allCountries = await Country.getAllCountries();
    setCountries(allCountries);
    const country = allCountries.find(
      (country) => country.name === countryName
    );
    if (country) {
      fetchStatesSecond(country.isoCode);
      return country.isoCode;
    } else {
      return null;
    }
  };

  const fetchCitiesSecond = async (item) => {
    setSelectedState(item.name);
    const allCities = await City.getCitiesOfState(
      item.countryCode,
      item.isoCode
    );
    setCities(allCities);
  };

  const toggleDropdown = (name) => {
    if (selectDropDown === name) {
      setSelectDropDown("");
    } else {
      setSelectDropDown(name);
    }
  };

  const handleEthnicOrigin = (item) => {
    setSelectedEthnicOrigin((prevEthnicOrigin) => {
      // Check if the item is already selected
      if (prevEthnicOrigin.includes(item)) {
        // Item is already selected, deselect it
        return prevEthnicOrigin.filter((origin) => origin !== item);
      } else if (prevEthnicOrigin.length < 6) {
        // Item is not selected and limit is not reached, select it
        return [...prevEthnicOrigin, item];
      } else if (prevEthnicOrigin.length === 5) {
        setLimitModal(true);
      } else {
        setLimitModal(true);
        return prevEthnicOrigin;
      }
    });
  };

  const deselectMostRecent = () => {
    if (selectedEthnicOrigin.length === 6) {
      const updatedArray = [...selectedEthnicOrigin];
      updatedArray.pop();
      setSelectedEthnicOrigin(updatedArray);
      setLimitModal(false);
    }
  };

  const handleDone = () => {
    const selectedItems = ethnicOriginData.filter((item) =>
      selectedEthnicOrigin.includes(item)
    );
    const unselectedItems = ethnicOriginData.filter(
      (item) => !selectedEthnicOrigin.includes(item)
    );
    const updatedEthnicOriginData = [...selectedItems, ...unselectedItems];
    setEthnicOriginData(updatedEthnicOriginData);
    setEthnicOrigin(selectedEthnicOrigin);
    setDropDownDescription("");
  };

  const clear = () => {
    setSelectedEthnicOrigin("");
    setEthnicOrigin("");
    setDropDownDescription("");
  };

  const closeEthicalOriginModal = () => {
    if (ethnicOrigin !== null) {
      setSelectedEthnicity(item?.ethnic_origin);
      setSelectedEthnicOrigin(ethnicOrigin);
      setDropDownDescription("");
    } else {
      setDropDownDescription("");
    }
  };

  const handleLanguage = (item) => {
    setSelectedLanguage((prevLanguage) => {
      if (prevLanguage.includes(item)) {
        return prevLanguage.filter((origin) => origin !== item);
      } else if (prevLanguage.length < 6) {
        return [...prevLanguage, item];
      } else if (prevLanguage.length === 5) {
        setLanguageLimit(true);
      } else {
        setLanguageLimit(true);
        return prevLanguage;
      }
    });
  };
  const deselectMostRecentLanguage = () => {
    if (selectedLanguage.length === 6) {
      const updatedArray = [...selectedLanguage];
      updatedArray.pop();
      setSelectedLanguage(updatedArray);
      setLanguageLimit(false);
    }
  };

  const handleDoneLanguage = () => {
    const selectedItems = languageData.filter((item) =>
      selectedLanguage.includes(item)
    );
    const unselectedItems = languageData.filter(
      (item) => !selectedLanguage.includes(item)
    );
    const updatedLanguageOriginData = [...selectedItems, ...unselectedItems];
    setLanguageData(updatedLanguageOriginData);
    setLanguagesSpoken(selectedLanguage);
    setDropDownDescription("");
  };

  const clearLanguage = () => {
    setSelectedLanguage("");
    setLanguagesSpoken("");
    setDropDownDescription("");
  };

  const closeLanguageModal = () => {
    if (languagesSpoken !== null) {
      setSelectedLanguage(languagesSpoken);
      setDropDownDescription("");
    } else {
      setDropDownDescription("");
    }
  };

  const handleRemoveImageAndVideo = async () => {
    if (showVideo) {
      handleDeleteVideos();
    } else {
      handleDeleteImage();
    }
  };

  const handleDeleteVideos = async () => {
    setVideos((prevVideos) => {
      const indexToDelete = selectedVideoIndex;
      return prevVideos.filter((_, i) => i !== indexToDelete);
    });
  };

  useEffect(() => {
    setSelectedVideoIndex(0);
  }, [videos]);

  const handleDeleteImage = () => {
    setPictures((prevuploadimg) =>
      prevuploadimg.filter((_, i) => i !== selectedImgIndex)
    );
  };

  const handleOnlineServices = () => {
    const options = [];
    if (vidsForSale) {
      options.push("Vid/Pics For Sale");
    }
    if (videoShows) {
      options.push("Video Shows");
    }
    if (phoneSex) {
      options.push("Phone Sex");
    }

    return options;
  };

  const handleAvailability = () => {
    const options = [];

    if (inCallagree) {
      options.push("In-call");
    }
    if (outCall) {
      options.push("Out-call");
    }
    if (onlineagree) {
      options.push("Online");
    }

    return options;
  };

  const handleUpdateAd = async () => {
    setLoader(true);
    try {
      const online_services = handleOnlineServices();
      const availability = handleAvailability();
      const body = {
        _id,
        ad_description: description,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
        height_feet: heightFeet,
        height_inches: heightInches,
        weight,
        cup_size: selectedCupSize,
        chest: selectedChest,
        waist: selectedWaist,
        hair_color: selectedHiarColor,
        eye_color: selectedEyeColor,
        online_services,
        ad_title: adTitle,
        name: name,
        age: age,
        ethnicity: selectedEthnicity,
        ethnic_origin: selectedEthnicOrigin,
        hourly_rate: hourlyRate,
        phone_number: phoneNumber,
        email_address: email,
        website: website,
        hips: selectedHips,
        language_spoken: languagesSpoken,
        allow_message_via_whatsapp: allowViaWhatsapp,
        anonymous_contact_form: anonymousContactForm,
        show_recent_ads: showrecentads,
        show_lyla_ch_reviews: allowReview,
        availability,
        pictures,
        videos,
      };
      console.log(body);
      const response = await updateAd(body);
      setLoader(false);
      if (response?.success) {
        alert("Update successfully.");
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (selectedImgIndex > pictures?.length - 1) {
      setSelectedImgIndex(pictures?.length - 1);
    }
  }, [pictures]);

  const handleIncallAgree = () => {
    setIncallAgree(!inCallagree);
  };
  const handleOutcall = () => {
    setisOutcall(!outCall);
  };
  const handleOncall = () => {
    setIsonlineAgree(!onlineagree);
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onVideoButtonClick = () => {
    inputFileVideo.current.click();
  };

  const handleImageChange = async (e) => {
    setUploadImgLoader(true);
    const files = e.target.files;
    const formData = new FormData();

    if (files?.length <= 15) {
      for (let i = 0; i < files?.length; i++) {
        const fileSize = files[0].size;
        if (fileSize > 5 * 1024 * 1024) {
          alert("File size exceeds 5 MB limit for each picture.");
          setUploadImgLoader(false);
          return;
        }
        formData.append("images", files[i]);
      }
      const response = await handleUploadPictures(formData, token);
      if (response?.success) {
        if (pictures?.length === 0) {
          setPictures(response?.images);
          setUploadImgLoader(false);
        } else if (pictures?.length >= 15) {
          alert("You can select up to 15 images.");
          setUploadImgLoader(false);
        } else {
          const remainingSpace = 15 - pictures?.length;
          const imagesToAdd = response?.images?.slice(0, remainingSpace);
          setPictures([...pictures, ...imagesToAdd]);
          setUploadImgLoader(false);
        }
      } else {
        console.log(response);
        setUploadImgLoader(false);
      }
    } else {
      alert("You can select up to 15 images.");
      setUploadImgLoader(false);
    }
  };

  const handleVideoChange = async (event) => {
    try {
      setVideoLoader(true);
      const files = event.target.files;
      const formData = new FormData();

      if (files?.length <= 2) {
        for (let i = 0; i < files.length; i++) {
          const fileSize = files[i].size;

          if (fileSize > 50 * 1024 * 1024) {
            alert("File size exceeds 50 MB limit for each video.");
            setVideoLoader(false);
            return;
          }
          formData.append("videos", files[i]);
        }

        const response = await handleVideosUpload(formData, token);
        if (response?.success) {
          if (videos?.length === 0) {
            setVideos(response?.urls);
            setVideoLoader(false);
          } else if (videos?.length === 1) {
            const updatedVideo = [...videos];
            updatedVideo.push(response?.urls[0]);
            setVideos(updatedVideo);
            setVideoLoader(false);
          } else {
            alert("You can only select up to two videos.");
            setVideoLoader(false);
          }
        } else {
          console.log(response);
          setVideoLoader(false);
        }
      } else {
        alert("Please select only up to 2 videos.");
        setVideoLoader(false);
      }
    } catch (error) {
      console.log("error in upload videos");
      setVideoLoader(false);
    }
  };
  useEffect(() => {
    handleScrollToTop();
  }, []);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="pb-40">
      <div className="flex pt-10 items-center text-center pb-10">
        <div
          className="text-sm lg:ml-10 ml-2 active:bg-purple bg-whitepink biggerlaptop:w-14 lg:w-10 md:w-10 sm:w-10 w-8 biggerlaptop:h-12 lg:h-10 md:h-10 sm:h-10 h-8 font-semibold flex items-center justify-center cursor-pointer active:opacity-50"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            src={images.arrowleft}
            className="w-4 max-w-4"
            alt="Arrow Left"
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-col  items-center mx-auto md:mx-0  gap-14">
        <div className="xl:w-[30%] lg:w-[50%] w-[100%] ">
          <div className="shadow-md group relative">
            {isEdit && (
              <div
                onClick={() => handleRemoveImageAndVideo()}
                className="z-10 absolute active:opacity-20 top-[20%] right-[50%] w-10 h-10 text-xs bg-purple text-white border-2 font-semibold rounded-full items-center justify-center flex opacity-0 group-hover:opacity-100 group-hover:animate-top-to-bottom transition-opacity duration-200 cursor-pointer"
              >
                X
              </div>
            )}
            {showVideo ? (
              <video
                autoPlay
                controls
                key={`${videos[selectedVideoIndex]}_${selectedVideoIndex}`}
                className="w-[95%] mx-auto h-[50vh] md:h-[60vh]"
              >
                <source src={videos[selectedVideoIndex]} />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={pictures[selectedImgIndex]}
                className="lg:w-[95%] md:w-[60%] w-[90%]  mx-auto h-[50vh] md:h-[60vh]"
              />
            )}
          </div>
          <div className="flex flex-row w-[95%] overflow-x-scroll gap-2 mx-auto mt-4">
            {pictures?.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item}
                  className="w-18 h-14 cursor-pointer"
                  onClick={() => {
                    setShowVideo(false);
                    setSelectedImgIndex(index);
                  }}
                />
              );
            })}
            {videos?.map((item, index) => {
              return (
                <video
                  key={`${item}_${index}`}
                  className="w-18 h-14 cursor-pointer"
                  onClick={() => {
                    setShowVideo(true);
                    setSelectedVideoIndex(index);
                  }}
                >
                  <source src={item} />
                  Your browser does not support the video tag.
                </video>
              );
            })}
          </div>
          {isEdit && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 px-4 mt-2 text-white font-semibold">
              <label htmlFor="fileInput1" className="">
                <div
                  className=" bg-purple flex flex-row items-center justify-between p-1 cursor-pointer active:opacity-50"
                  onClick={() => !uploadImgLoader && onButtonClick()}
                >
                  {uploadImgLoader ? (
                    <div className="w-full flex items-center justify-center">
                      <ClipLoader
                        color="#FFFF"
                        cssOverride={{
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="flex flex-row items-center justify-between w-full">
                      {`Picture 15/${pictures?.length}`}
                      <img src={images.adImages} className="w-8" />
                    </div>
                  )}
                  <input
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleImageChange}
                    ref={inputFile}
                    multiple
                    accept="image/png,image/jpeg"
                  />
                </div>
              </label>
              <label htmlFor="fileInput2" className="">
                <div
                  className=" bg-purple flex flex-row items-center justify-between p-1 py-2 cursor-pointer active:opacity-50"
                  onClick={() => !videoLoader && onVideoButtonClick()}
                >
                  {videoLoader ? (
                    <div className="w-full flex items-center justify-center">
                      <ClipLoader
                        color="#FFFF"
                        cssOverride={{
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="flex flex-row items-center justify-between w-full">
                      {`Videos 2/${videos?.length}`}
                      <img src={images.adVideos} className="w-8" />
                    </div>
                  )}
                  <input
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleVideoChange}
                    ref={inputFileVideo}
                    multiple
                    accept="video/mp4,video/x-m4v,video/*"
                  />
                </div>
              </label>
            </div>
          )}
        </div>
        <div className="text-whitepink xl:w-[70%] lg:w-[50%] w-[100%] px-2 mt-2 lg:mt-0 ">
          <div className="  text-xl lg:text-4xl font-bold pb-6">Ad Details</div>
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-2 ">
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity ">
                Ad Title
              </div>
              <div className="relative mt-2">
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  <input
                    className="w-full bg-transparent text-whitepink focus:outline-none"
                    value={adTitle}
                    onChange={(e) => setAdTitle(e.target.value)}
                    disabled={!isEdit}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Name
              </div>
              <div className="relative mt-2">
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  <input
                    className="w-full bg-transparent text-whitepink focus:outline-none"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={!isEdit}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Age
              </div>
              <div className="relative mt-2">
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  <input
                    className="w-full bg-transparent text-whitepink focus:outline-none"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    disabled={!isEdit}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-2 mt-2">
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Country
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("country")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {selectedCountry}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "country"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "country" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                    {countries?.map((item, index) => {
                      return (
                        <div
                          onClick={() => {
                            item.name !== selectedCountry &&
                              fetchCountriesSecond(item.name);
                          }}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item?.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                State
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("state")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {selectedState}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "state"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "state" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg">
                    {states?.map((item, index) => {
                      return (
                        <div
                          onClick={() => fetchCitiesSecond(item)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item?.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                City
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("city")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {selectedCity}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "city"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "city" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                    {cities?.map((item, index) => {
                      return (
                        <div
                          onClick={() => setselectedCity(item.name)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item?.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-2 mt-2">
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Cup Size
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("cupsize")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {selectedCupSize}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "cupsize"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "cupsize" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                    {cupSizeData?.map((item, index) => {
                      return (
                        <div
                          onClick={() => setSelectedCupSize(item)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Chest "
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("chest")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {selectedChest}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "chest"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "chest" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg">
                    {chestData?.map((item, index) => {
                      return (
                        <div
                          onClick={() => setSelectedChest(item)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Waist "
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("waist")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {selectedWaist}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "waist"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "waist" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                    {waistData?.map((item, index) => {
                      return (
                        <div
                          onClick={() => setSelectedWaist(item)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Hips "
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("hips")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {selectedHips}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "hips"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "hips" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                    {hipsData?.map((item, index) => {
                      return (
                        <div
                          onClick={() => setSelectedHips(item)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-2 mt-2">
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Hair Color
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("haircolor")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {selectedHiarColor}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "haircolor"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "haircolor" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                    {hairData?.map((item, index) => {
                      return (
                        <div
                          onClick={() => setSelectedHairColor(item)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Eye Color
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("eyecolor")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {selectedEyeColor}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "eyecolor"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "eyecolor" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg">
                    {eyeColorData?.map((item, index) => {
                      return (
                        <div
                          onClick={() => setSelectedEyeColor(item)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Ethnicity
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("ethnicity")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {selectedEthnicity}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "ethnicity"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "ethnicity" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                    {ethnicityData?.map((item, index) => {
                      return (
                        <div
                          onClick={() => setSelectedEthnicity(item)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full  lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  lg:pt-0 pt-2">
              <div className="flex items-center gap-2 ">
                <div>Ethnic Origin</div>
              </div>
              <div className="pt-2">
                <div className="">
                  <div
                    className={`bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                                         border rounded-lg flex items-center cursor-pointer justify-between  border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  px-3
                                       relative w-[100%]   `}
                    onClick={() => {
                      setDropDownDescription(
                        dropDownDescription === "ethnicOrigin"
                          ? ""
                          : "ethnicOrigin"
                      );
                    }}
                  >
                    <div className="truncate max-w-[300px]">
                      {ethnicOrigin === null ? (
                        <div></div>
                      ) : (
                        <div className="truncate">{`${ethnicOrigin}`}</div>
                      )}
                    </div>
                    {isEdit === true && (
                      <div>
                        <img
                          src={
                            dropDownDescription === "ethnicOrigin"
                              ? images.dropDownOpen
                              : images.dropDownClosed
                          }
                          alt=""
                          className="h-2 w-3 md:h-3 md:w-4"
                        />
                      </div>
                    )}
                  </div>
                  {dropDownDescription === "ethnicOrigin" &&
                    isEdit === true && (
                      <div
                        className="fixed z-10 text-black  top-5 md:top-0 left-0 w-full h-[100vh] flex items-center justify-center bg-gray-800 bg-opacity-50"
                        onClick={closeEthicalOriginModal}
                      >
                        <div
                          className="bg-purplegray w-[90%] sm:w-[70%] md:w-[40%] lg:w-[30%] max-w-[480px] pb-4 text-white rounded-lg  "
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div className="p-4 text-sm">
                            <div>Ethnic Origin:</div>
                            <div>
                              {selectedEthnicOrigin.length === 0 ? (
                                <div>....</div>
                              ) : (
                                <div className="text-xxs">
                                  {`${selectedEthnicOrigin} `}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex items-center justify-center">
                            <div className=" w-[90%] flex items-center pl-2  bg-aboutcolor h-8 rounded-lg">
                              <img
                                src={images.search}
                                alt=""
                                className="w-3 h-3 cursor-pointer "
                              />
                              <input
                                type="text"
                                className="bg-transparent  focus:outline-none pl-2 text-sm "
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className=" flex w-full flex-col overflow-auto max-h-[330px] pt-4 pl-5 pb-4 ">
                            {ethnicOriginData
                              .filter((item) =>
                                item
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              )
                              .map((item, index) => (
                                <div
                                  key={index}
                                  className="flex w-[100%] my-2 "
                                  onClick={() => handleEthnicOrigin(item)}
                                >
                                  <div className=" items-center flex justify-center pr-5">
                                    <div>
                                      {selectedEthnicOrigin.includes(item) ? (
                                        <img
                                          src={images.checked}
                                          alt=""
                                          className="h-5 w-5"
                                        />
                                      ) : (
                                        <img
                                          src={images.unchecked}
                                          alt=""
                                          className="h-5 w-5"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="relative w-full ">
                                    {item}
                                    {limitModal === item ? (
                                      <div
                                        className="shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]
                                                   w-[95%] bg-purple   absolute bottom-[80%] right-[5%]  text-xs h-10 flex items-center justify-center gap-2  px-4  rounded-3xl"
                                      >
                                        <div>
                                          You’ve selected the maximum of 6
                                          additional ethnicities.
                                        </div>
                                        <div
                                          onClick={deselectMostRecent}
                                          className="border rounded-full text-xxs w-4 h-4 cursor-pointer flex items-center justify-center"
                                        >
                                          X
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="flex items-center flex-col justify-center gap-2   border-t border-aboutcolor   ">
                            <div>
                              {limitModal && (
                                <div
                                  className="shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]
                                                    flex items-center justify-center gap-2  px-4 text-sm text-red-500  rounded-3xl"
                                >
                                  <div>
                                    You’ve selected the maximum of 6 additional
                                    ethnicities.
                                  </div>
                                  <div
                                    onClick={deselectMostRecent}
                                    className="border rounded-full text-xxs w-4 h-4 cursor-pointer flex items-center border-red-500 justify-center"
                                  >
                                    X
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="flex gap-5 mt-2">
                              <div
                                className="px-10 py-2 border flex items-center justify-center border-aboutcolor active:opacity-50 cursor-pointer"
                                onClick={clear}
                              >
                                Clear
                              </div>
                              <div
                                className="px-10 py-2  flex items-center justify-center active:opacity-50 cursor-pointer bg-purple"
                                onClick={handleDone}
                              >
                                Done
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-2 mt-2">
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Hour/Rate
              </div>
              <div className="relative mt-2">
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  <input
                    className="w-full bg-transparent text-whitepink focus:outline-none"
                    value={hourlyRate}
                    onChange={(e) => setHourlyRate(e.target.value)}
                    disabled={!isEdit}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Phone Number
              </div>
              <div className="relative mt-2">
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  <input
                    className="w-full bg-transparent text-whitepink focus:outline-none"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    disabled={!isEdit}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Website
              </div>
              <div className="relative mt-2">
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  <input
                    className="w-full bg-transparent text-whitepink focus:outline-none"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    disabled={!isEdit}
                  />
                </div>
              </div>
            </div>
            <div className="w-full  ">
              <div className="pb-2 lg:text-base md:text-base sm:text-xxs text-xxs">
                <div className="flex items-center gap-2 lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity">
                  <div>Language Spoken</div>
                </div>
              </div>
              <div>
                <div
                  className={`bg-buttoncolor  remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm text-xxs 
                  border rounded-lg flex items-center cursor-pointer justify-between  border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  px-3
                relative w-[100%]   `}
                  onClick={() =>
                    setDropDownDescription(
                      dropDownDescription === "language" ? "" : "language"
                    )
                  }
                >
                  <div className="truncate max-w-[300px]">
                    {languagesSpoken === null ? (
                      <div></div>
                    ) : (
                      <div className="truncate">{`${languagesSpoken}`}</div>
                    )}
                  </div>
                  {isEdit === true && (
                    <div>
                      <img
                        src={
                          dropDownDescription === "language"
                            ? images.dropDownOpen
                            : images.dropDownClosed
                        }
                        alt=""
                        className="h-2 w-3 md:h-3 md:w-4"
                      />
                    </div>
                  )}
                </div>
                {dropDownDescription === "language" && isEdit === true && (
                  <div
                    className="fixed z-10 text-black  top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50"
                    onClick={closeLanguageModal}
                  >
                    <div
                      className="bg-purplegray w-[90%] sm:w-[70%] md:w-[40%] lg:w-[30%] max-w-[480px] pb-4 text-white rounded-lg"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="p-4 text-sm">
                        <div>languages:</div>
                        <div>
                          {selectedLanguage.length === 0 ? (
                            <div>....</div>
                          ) : (
                            <div className="text-xxs">
                              {`${selectedLanguage} `}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-center">
                        <div className=" w-[90%] flex items-center pl-2  bg-aboutcolor h-8 rounded-lg">
                          <img
                            src={images.search}
                            alt=""
                            className="w-3 h-3 cursor-pointer "
                          />
                          <input
                            type="text"
                            className="bg-transparent  focus:outline-none pl-2 text-sm "
                            value={search}
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className=" flex w-full flex-col overflow-auto max-h-[330px] pt-4 pl-5 pb-4 ">
                        {languageData
                          .filter((item) =>
                            item.toLowerCase().includes(search.toLowerCase())
                          )
                          .map((item, index) => (
                            <div
                              key={index}
                              className="flex w-[100%] my-2 "
                              onClick={() => handleLanguage(item)}
                            >
                              <div className=" items-center flex justify-center pr-5">
                                <div>
                                  {selectedLanguage.includes(item) ? (
                                    <img
                                      src={images.checked}
                                      alt=""
                                      className="h-5 w-5"
                                    />
                                  ) : (
                                    <img
                                      src={images.unchecked}
                                      alt=""
                                      className="h-5 w-5"
                                    />
                                  )}
                                </div>
                              </div>
                              {item}
                            </div>
                          ))}
                      </div>
                      <div className="flex items-center flex-col justify-center gap-2   border-t border-aboutcolor   ">
                        <div>
                          {languageLimit && (
                            <div
                              className="shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]
                                                    flex items-center justify-center gap-2  px-4 text-sm text-red-500  rounded-3xl"
                            >
                              <div>
                                You’ve sel ected the maximum of 6 additional
                                ethnicities.
                              </div>
                              <div
                                onClick={deselectMostRecentLanguage}
                                className="border rounded-full text-xxs w-4 h-4 cursor-pointer flex items-center border-red-500 justify-center"
                              >
                                X
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex gap-5 mt-2">
                          <div
                            className="px-10 py-2 border flex items-center justify-center border-aboutcolor active:opacity-50 cursor-pointer"
                            onClick={clearLanguage}
                          >
                            Clear
                          </div>
                          <div
                            className="px-10 py-2  flex items-center justify-center active:opacity-50 cursor-pointer bg-purple"
                            onClick={handleDoneLanguage}
                          >
                            Done
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-2 mt-2">
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Email
              </div>
              <div className="relative mt-2">
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  <input
                    className="w-full bg-transparent text-whitepink focus:outline-none"
                    value={email}
                    onChange={(e) => setEmailAdress(e.target.value)}
                    disabled={!isEdit}
                  />
                </div>
              </div>
            </div>

            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Allow Via Whatsapp
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("alloWhatsapp")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {allowViaWhatsapp ? "Yes" : "No"}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "alloWhatsapp"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "alloWhatsapp" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg">
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={() => setAllowViaWhatsapp(true)}
                    >
                      Yes
                    </div>
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={() => setAllowViaWhatsapp(false)}
                    >
                      No
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Allow Via Contact Form
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("allowContact")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {anonymousContactForm ? "Yes" : "No"}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "allowContact"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "allowContact" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg">
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={() => setAnonymousContactForm(true)}
                    >
                      Yes
                    </div>
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={() => setAnonymousContactForm(false)}
                    >
                      No
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Show my Lyla.ch Reviews
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("reviews")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {allowReview ? "Yes" : "No"}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "reviews"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "reviews" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg">
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={() => setAllowReview(true)}
                    >
                      Yes
                    </div>
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={() => setAllowReview(false)}
                    >
                      No
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-2 mt-2">
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Show Recent Ads
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("recentAds")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {showrecentads ? "Yes" : "No"}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "recentAds"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "recentAds" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg">
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={() => setShowRecentAds(true)}
                    >
                      Yes
                    </div>
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={() => setShowRecentAds(false)}
                    >
                      No
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Availability
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("Availability")}
              >
                <div
                  className={`bg-buttoncolor truncate text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {(inCallagree ? "in call," : "") +
                    (inCallagree && outCall ? " " : "") +
                    (outCall ? "out call," : "") +
                    ((inCallagree || outCall) && onlineagree ? " " : "") +
                    (onlineagree ? "online" : "") || null}

                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "Availability"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "Availability" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg">
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={handleIncallAgree}
                    >
                      <FontAwesomeIcon
                        icon={inCallagree ? faCheckSquare : faSquare}
                        size="1x"
                        style={{
                          height: "14px",
                          width: "14px",
                          cursor: "pointer",
                          color: inCallagree ? "white" : "transparent",
                          border: "1px solid #615770",
                          marginRight: "8px",
                        }}
                      />
                      In Call Agree
                    </div>
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={handleOutcall}
                    >
                      <FontAwesomeIcon
                        icon={outCall ? faCheckSquare : faSquare}
                        size="1x"
                        style={{
                          height: "14px",
                          width: "14px",
                          cursor: "pointer",
                          color: outCall ? "white" : "transparent",
                          border: "1px solid #615770",
                          marginRight: "8px",
                        }}
                      />
                      Out Call Agree
                    </div>
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={handleOncall}
                    >
                      <FontAwesomeIcon
                        icon={onlineagree ? faCheckSquare : faSquare}
                        size="1x"
                        style={{
                          height: "14px",
                          width: "14px",
                          cursor: "pointer",
                          color: onlineagree ? "white" : "transparent",
                          border: "1px solid #615770",
                          marginRight: "8px",
                        }}
                      />
                      Online Agree
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Online Services
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("Online Services")}
              >
                <div
                  className={`bg-buttoncolor truncate text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {(vidsForSale ? "Vid/Pics For Sale" : "") +
                    (vidsForSale && (videoShows || phoneSex) ? ", " : "") +
                    (videoShows ? "Video Shows" : "") +
                    (videoShows && phoneSex ? ", " : "") +
                    (phoneSex ? "Phone Sex" : "") || null}

                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "Online Services"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "Online Services" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg">
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={() => {
                        setVideoShows(!videoShows);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={videoShows ? faCheckSquare : faSquare}
                        size="1x"
                        style={{
                          height: "14px",
                          width: "14px",
                          cursor: "pointer",
                          color: videoShows ? "white" : "transparent",
                          border: "1px solid #615770",
                          marginRight: "8px",
                        }}
                      />
                      Video Shows
                    </div>
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={() => {
                        setPhoneSex(!phoneSex);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={phoneSex ? faCheckSquare : faSquare}
                        size="1x"
                        style={{
                          height: "14px",
                          width: "14px",
                          cursor: "pointer",
                          color: phoneSex ? "white" : "transparent",
                          border: "1px solid #615770",
                          marginRight: "8px",
                        }}
                      />
                      Phone Sex
                    </div>
                    <div
                      className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                      onClick={() => {
                        setVidsForSale(!vidsForSale);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={vidsForSale ? faCheckSquare : faSquare}
                        size="1x"
                        style={{
                          height: "14px",
                          width: "14px",
                          cursor: "pointer",
                          color: vidsForSale ? "white" : "transparent",
                          border: "1px solid #615770",
                          marginRight: "8px",
                        }}
                      />
                      Vid/Pics For Sale
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Height ft
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("heightft")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {`${heightFeet} `}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "heightft"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "heightft" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                    {heightFtData?.map((item, index) => {
                      return (
                        <div
                          onClick={() => setHeightFeet(item)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-2 mt-2">
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Height inch
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("heightinch")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {`${heightInches} `}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "heightinch"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "heightinch" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                    {heightInchData?.map((item, index) => {
                      return (
                        <div
                          onClick={() => setHeightInches(item)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
                Weight
              </div>
              <div
                className="relative mt-2"
                onClick={() => toggleDropdown("weight")}
              >
                <div
                  className={`bg-buttoncolor text-white lg:text-lg md:text-sm sm:text-sm text-xxs w-[100%]  border rounded-lg flex items-center cursor-pointer justify-between border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10 px-3 `}
                >
                  {`${weight} `}
                  {isEdit && (
                    <img
                      src={
                        selectDropDown === "weight"
                          ? images.dropDownOpen
                          : images.dropDownClosed
                      }
                      alt=""
                      className="md:h-3 md:w-4 h-2 w-3"
                    />
                  )}
                </div>
                {selectDropDown === "weight" && isEdit === true && (
                  <div className="absolute bg-weirdgray w-[100%] z-20 max-h-[200px] overflow-auto mt-1 rounded-lg ">
                    {weightData?.map((item, index) => {
                      return (
                        <div
                          onClick={() => setWeight(item)}
                          key={index}
                          className="hover:bg-purple cursor-pointer active:opacity-50 text-sm md:text-base border-whitepink border-b px-4 border-opacity-10 p-2"
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="pt-10">
            <div className="lg:text-sm md:text-sm sm:text-sm text-xs text-textopacity  ">
              Description
            </div>
            <div className="lg:pt-4 pt-2">
              {/* <input
                                type="text"
                                className="bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm  w-[94%] border rounded-lg border-whitepink border-opacity-20 lg:h-14 md:h-10 sm:h-10 h-10  pl-3 "
                                placeholder="..."
                                value={adTitle}
                                onChange={handleSetTitle}
                              /> */}
              <textarea
                name="message"
                id="message"
                cols="80"
                rows="10"
                className="resize-none bg-buttoncolor remove-arrow focus:outline-none text-white lg:text-lg md:text-sm sm:text-sm h-96 pt-3  w-[100%] border rounded-lg border-whitepink border-opacity-20  h-20  pl-3 "
                // className="w-full block bg-transparent text-white opacity-60 h-40 lg:text-lg md:text-base sm:text-sm sm:text-xs text-xxs focus:outline-none pl-4"
                placeholder="..."
                value={description}
                onChange={(e) => setdescription(e.target.value)}
                disabled={!isEdit}
              ></textarea>
            </div>
          </div>
          {isEdit && (
            <div className="flex flex-row items-center justify-between w-full mt-10">
              <div
                className="bg-white text-purple font-semibold text-lg px-5 py-1 cursor-pointer active:opacity-50"
                onClick={() => navigate("/MyAds")}
              >
                Cancel
              </div>
              <div
                onClick={handleUpdateAd}
                className={`bg-purple text-white font-semibold text-lg cursor-pointer active:opacity-50 ${
                  loader
                    ? "w-24 h-9 flex flex-row items-center justify-center"
                    : "px-5 py-1"
                }`}
              >
                {loader ? (
                  <ClipLoader
                    color="#FFFF"
                    cssOverride={{
                      height: "22px",
                      width: "22px",
                    }}
                  />
                ) : (
                  "Update"
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
