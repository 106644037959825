import React from "react";

const FormCarousel = ({ images , escortAd ,premiumTheme}) => {
  const doubledImages = [
    ...images,
    ...images,
    ...images,
    ...images,
    ...images,
    ...images,
    ...images,
    ...images,
    ...images,
    ...images,
    ...images,
    ...images,
    ...images,
    ...images,
    ...images,,
  ];

  return (
    <div className={ `carousel-container  bg-transparent h-12 shadow-md rounded flex items-center justify-center`}>
      <div className="carousel-slider relative overflow-hidden w-full  ">
        <div
          className="slide-track flex"
          style={{
            width: `calc(200px * ${doubledImages.length})`,
            animation: `scroll ${images.length * 10}s linear infinite`,
          }}
        >
          {escortAd ? (
            <>
            {doubledImages?.map((imageUrl, index) => (
            <div key={index} className="slide">
              <img src={imageUrl} className="block object-cover h-full w-full" alt={`Slide ${index}`} />
            </div>
          ))}
            </>
          ) : (
            <>
              {doubledImages?.map((image, index) => (
                <div key={index} className="slide">
                  <img
                    src={image}
                    className="block object-cover h-full w-full  "
                    alt={`Slide ${index}`}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(calc(-200px * ${images.length}));
          }
        }
        .carousel-container {
          width: 100%;
          max-width: 960px;
          overflow: hidden;
          position: relative;
        }
        .carousel-slider {
          margin: 0 auto;
        }
        .slide-track {
          display: flex;
          gap: 10px;
        }
        .slide {
          flex: none;
          width: 60px;
          height: 60px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      `}</style>
    </div>
  );
};

export default FormCarousel;
