import React, { useState, useEffect } from "react";
import images from "../../services/images";
import ImageSlider from "../ImageSlider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { createMeeting, getEscorts } from "../../services/API/index.js";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../../store/userData/index.js";
import { removeAuthToken } from "../../store/token/index.js";
import { selectLogedIn } from "../../store/logedIn/index.js";
import { streamingToken } from "../../services/API";

export default function Header({ useModal }) {
  const dispatch = useDispatch();

  const userData = useSelector(selectUserData);
  const navigate = useNavigate();
  const { setIsModalOpen, setmodalcomponent } = useModal();
  const location = useLocation();
  const activePath = location.pathname;
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [escortData, setEscortdata] = useState([]);

  const getEscortsData = async () => {
    const data = await getEscorts();
    // console.log(data);
    setEscortdata(data.escorts);
  };

  // console.log("asiopdasdnasjkod", userData);

  const userLogin = useSelector(selectLogedIn);

  const isLive = userData?.live;

  // console.log("wtf", isLive);

  useEffect(() => {
    getEscortsData();
  }, []);

  const handleLogout = async () => {
    dispatch(removeAuthToken());
    navigate("/");
    logout();
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // const togglearrowdowndrop = () => {
  //   setDropdownVisible(!setDropdownVisible);
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const headerElement = document.getElementById("header");

      if (headerElement && !headerElement.contains(event.target)) {
        // Clicked outside the header, close the dropdown
        setDropdownVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Emp

  const { isLoggedIn, logout, setisLoggedIn, escortloggedin } = useAuth();

  const handleSignInClick = () => {
    // setSignin(true);
    setmodalcomponent("signin");
    setIsModalOpen(true);
    // Use navigate to push the route
  };

  const handleJoinNowClick = () => {
    setmodalcomponent("signup");
    setIsModalOpen(true);
    // Use navigate to push the route
  };
  // if (userData.userType) {
  //   console.log(userData.userType, "=================????>>><<<");
  // }

  const getMeetingAndToken = async (id) => {
    const meetingId =
      id == null ? await createMeeting({ token: streamingToken }) : id;
    return meetingId;
  };

  const handleGoLive = async () => {
    let meetingId = await getMeetingAndToken(null);
    if (meetingId) {
      navigate("/golive", { state: { meetingId } });
    }
  };
  return (
    <div
      id="header"
      className=""
      style={{ position: "sticky", top: 0, zIndex: 50 }}
    >
      <div className="bg-purplegray flex flex-row  items-center  justify-between px-9 py-4  ">
        <Link to={"/"}>
          <img src={images.logo} className="w-28 cursor-pointer" />
        </Link>

        <div className="hidden lg:flex flex-row lg:w-40p xl:w-35p items-center justify-between">
          {!isLoggedIn && (
            <Link
              to={"/"}
              className={`cursor-pointer ${
                activePath === "/" ? "text-purple" : "text-white"
              }  font-semibold `}
            >
              Home
            </Link>
          )}
          {/* <Link
              to={"/"}
              className={`cursor-pointer ${activePath === "/" ? "text-purple" : "text-white"
                }  font-semibold `}
            >
              Home
            </Link> */}
          {!isLoggedIn && (
            <Link
              to={"/LiveModels/Newest"}
              className={`cursor-pointer ${
                activePath === "/LiveModels/Newest" ||
                activePath === "/LiveModels/Favorites" ||
                activePath === "/LiveModels/Popular" ||
                activePath.startsWith("/LiveStream")
                  ? "text-purple"
                  : "text-white"
              } font-semibold`}
            >
              Live Models
            </Link>
          )}

          {/* <Link
            to={"/LiveModels/Newest"}
            className={`cursor-pointer ${activePath === "/LiveModels/Newest" ||
              activePath === "/LiveModels/Favorites" ||
              activePath === "/LiveModels/Popular" ||
              activePath.startsWith("/LiveStream")
              ? "text-purple"
              : "text-white"
              } font-semibold`}
          >
            Live Models
          </Link> */}
          {!isLoggedIn && (
            <Link
              to={"/Escorts/All"}
              className={`cursor-pointer ${
                activePath === "/Escorts/All" ||
                activePath === "/Escorts/AvailableToday" ||
                activePath === "/Escorts/AvailableNow" ||
                activePath.startsWith("/Modeldetails")
                  ? "text-purple"
                  : "text-white"
              } font-semibold`}
            >
              Escorts
            </Link>
          )}

          {/* <Link
            to={"/Escorts/All"}
            className={`cursor-pointer ${activePath === "/Escorts/All" ||
              activePath === "/Escorts/AvailableToday" ||
              activePath === "/Escorts/AvailableNow" ||
              activePath.startsWith("/Modeldetails")
              ? "text-purple"
              : "text-white"
              } font-semibold`}
          >
            Escorts
          </Link> */}
          {!isLoggedIn && (
            <Link
              to={"/About"}
              className={`cursor-pointer ${
                activePath === "/About" ? "text-purple" : "text-white"
              } font-semibold`}
            >
              About
            </Link>
          )}
          {/* <Link
            to={"/About"}
            className={`cursor-pointer ${activePath === "/About" ? "text-purple" : "text-white"
              } font-semibold`}
          >
            About
          </Link> */}
          {!isLoggedIn && (
            <Link
              to={"/contactus"}
              className={`cursor-pointer ${
                activePath === "/contactus" ? "text-purple" : "text-white"
              } font-semibold`}
            >
              Contact Us
            </Link>
          )}
          {/* <Link
            to={"/contactus"}
            className={`cursor-pointer ${activePath === "/contactus" ? "text-purple" : "text-white"
              } font-semibold`}
          >
            Contact Us
          </Link> */}
        </div>
        <div className=" flex flex-row items-center justify-between mr-2 ">
          <div className="flex lg:hidden  text-white  justify-end">
            <img
              src={images.dropdown}
              alt=""
              className="w-20p "
              onClick={toggleDropdown}
            />
            <div className="">
              {dropdownVisible && (
                <div className="lg:hidden  fixed top-0 left-0 lg:w-28p md:w-28p sm:w-28p w-40p h-full bg-footercolor text-white">
                  <div className="flex items-center justify-center h-20 ">
                    <img
                      src={images.logo}
                      alt=""
                      className="lg:w-full sm:w-40 w-70p  "
                    />
                  </div>
                  <div className="flex flex-col items-center  justify-center   ">
                    {isLoggedIn && (
                      <div className="bg-purple active:opacity-50 mt-10 w-20 h-7 flex justify-center items-center">
                        <div
                          className="text-white font-bold cursor-pointer active:opacity-50 text-xs"
                          onClick={handleSignInClick}
                        >
                          LOG IN
                        </div>
                      </div>
                    )}
                    {isLoggedIn && (
                      <div
                        className="text-white  font-bold cursor-pointer active:opacity-50 text-xs mt-4"
                        onClick={handleJoinNowClick}
                      >
                        JOIN NOW
                      </div>
                    )}
                    {!isLoggedIn && (
                      <div>
                        {userData?.userType === "streamer" ||
                        userData?.userType === "both" ? (
                          <div>
                            <div
                              onClick={handleGoLive}
                              className="bg-purple text-white text-center mt-5 p-2    rounded-sm font-bold cursor-pointer active:opacity-50 text-xs"
                            >
                              GO LIVE
                            </div>
                          </div>
                        ) : null}
                        <Link
                          to={"/"}
                          className={`block my-3 ${
                            activePath === "/" ? "text-purple" : "text-white"
                          } font-semibold `}
                        >
                          Home
                        </Link>
                      </div>
                    )}
                    {userLogin ? (
                      <>
                        {!isLoggedIn && (
                          <Link
                            to={"/favorites"}
                            className="font-semibold my-3"
                          >
                            Favorite Ads
                          </Link>
                        )}
                        {!isLoggedIn && (
                          <Link to={"/giftcard"} className="font-semibold my-3">
                            Gift Card
                          </Link>
                        )}
                      </>
                    ) : (
                      <>
                        {!isLoggedIn && (
                          <Link to={"/giftcard"} className="font-semibold my-3">
                            Gift Card
                          </Link>
                        )}
                        {!isLoggedIn && (
                          <Link to={"/myads"} className="font-semibold my-3">
                            Ad Center
                          </Link>
                        )}
                        {!isLoggedIn && (
                          <Link to={"/forms"} className="font-semibold my-3">
                            Post Ad
                          </Link>
                        )}
                      </>
                    )}
                    {!isLoggedIn && (
                      <Link
                        to={"/LiveModels/Newest"}
                        className={`block my-3 ${
                          activePath === "/LiveModels/Newest" ||
                          activePath === "/LiveModels/Favorites" ||
                          activePath === "/LiveModels/Popular" ||
                          activePath.startsWith("/LiveStream")
                            ? "text-purple"
                            : "text-white"
                        } font-semibold`}
                      >
                        Live Models
                      </Link>
                    )}
                    {/* <Link
                      to={"/LiveModels/Newest"}
                      className={`block my-3 ${activePath === "/LiveModels/Newest" ||
                        activePath === "/LiveModels/Favorites" ||
                        activePath === "/LiveModels/Popular" ||
                        activePath.startsWith("/LiveStream")
                        ? "text-purple"
                        : "text-white"
                        } font-semibold`}
                    >
                      Live Models
                    </Link> */}
                    {!isLoggedIn && (
                      <Link
                        to={"/Escorts/All"}
                        className={`block my-3 ${
                          activePath === "/Escorts/All" ||
                          activePath === "/Escorts/AvailableToday" ||
                          activePath === "/Escorts/AvailableNow" ||
                          activePath.startsWith("/Modeldetails")
                            ? "text-purple"
                            : "text-white"
                        } font-semibold`}
                      >
                        Escorts
                      </Link>
                    )}
                    {/* <Link
                      to={"/Escorts/All"}
                      className={`block my-3 ${activePath === "/Escorts/All" ||
                        activePath === "/Escorts/AvailableToday" ||
                        activePath === "/Escorts/AvailableNow" ||
                        activePath.startsWith("/Modeldetails")
                        ? "text-purple"
                        : "text-white"
                        } font-semibold`}
                    >
                      Escorts
                    </Link> */}
                    {!isLoggedIn && (
                      <Link
                        to={"/About"}
                        className={`block my-3 ${
                          activePath === "/About" ? "text-purple" : "text-white"
                        } font-semibold`}
                      >
                        About
                      </Link>
                    )}
                    {/* <Link
                      to={"/About"}
                      className={`block my-3 ${activePath === "/About" ? "text-purple" : "text-white"
                        } font-semibold`}
                    >
                      About
                    </Link> */}
                    {!isLoggedIn && (
                      <Link
                        to={"/contact"}
                        className={`block my-3 ${
                          activePath === "/contact"
                            ? "text-purple"
                            : "text-white"
                        } font-semibold`}
                      >
                        Support
                      </Link>
                    )}

                    {/* <Link
                      to={"/contact"}
                      className={`block my-3 ${activePath === "/contact" ? "text-purple" : "text-white"
                        } font-semibold`}
                    >
                      Support
                    </Link> */}
                    {!isLoggedIn && (
                      <Link to={"/settings"} className="font-semibold my-3">
                        Settings
                      </Link>
                    )}
                    {!isLoggedIn && (
                      <Link
                        to={"/contactus"}
                        className={`cursor-pointer ${
                          activePath === "/contactus"
                            ? "text-purple"
                            : "text-white"
                        } font-semibold my-3`}
                      >
                        Contact Us
                      </Link>
                    )}
                    {!isLoggedIn && (
                      <Link
                        to={"/"}
                        onClick={handleLogout}
                        className="font-semibold my-3"
                      >
                        Sign out
                      </Link>
                    )}
                  </div>
                  {/* {userLogin ? null : (
                    <>
                      {!isLoggedIn && (
                        <div className="flex justify-center mt-8">
                          
                          <div
                            onClick={handleGoLive}
                            className="bg-purple text-white text-center ml-3  px-8 py-3 rounded-sm font-bold cursor-pointer active:opacity-50 text-xs"
                          >
                            GO LIVE
                          </div>

                        </div>
                      )}
                    </>
                  )} */}
                  {}
                </div>
              )}
            </div>
          </div>
          <div className="hidden lg:flex ">
            {isLoggedIn ? (
              <>
                <div
                  className="bg-white text-black text-center ml-3 px-10 py-3 rounded-sm font-bold cursor-pointer active:opacity-50 text-xs"
                  onClick={handleSignInClick}
                >
                  LOG IN
                </div>
                <div
                  className="bg-purple text-white text-center ml-3  px-8 py-3 rounded-sm font-bold cursor-pointer active:opacity-50 text-xs"
                  onClick={handleJoinNowClick}
                >
                  JOIN NOW
                </div>
              </>
            ) : (
              <>
                {/* {userLogin ? (
                  <div>
                    <Link to={"/Escorts/All"}>
                      <div className="bg-purple text-white text-center ml-3  px-8 py-3 rounded-sm font-bold cursor-pointer active:opacity-50 text-xs">
                        BOOK NOW
                      </div>
                    </Link>
                  </div>
                ) : (
                  <div>
                    <div
                      onClick={handleGoLive}
                      className="bg-purple text-white text-center ml-3  px-8 py-3 rounded-sm font-bold cursor-pointer active:opacity-50 text-xs"
                    >
                      GO LIVE
                    </div>
                  </div>
                )} */}
                {userData?.userType === "streamer" ||
                userData?.userType === "both" ? (
                  <div>
                    <div
                      onClick={handleGoLive}
                      className="bg-purple text-white text-center ml-3  px-8 py-3 rounded-sm font-bold cursor-pointer active:opacity-50 text-xs"
                    >
                      GO LIVE
                    </div>
                  </div>
                ) : null}
                <div className="bg-purple text-white text-center ml-3 py-1  rounded-full  flex justify-center  items-center w-20 font-bold  ">
                  <div className="flex w-full justify-evenly items-center">
                    <div>
                      <img
                        src={images.dropdownarrow}
                        alt=""
                        className="w-5 pt-1 h-5 cursor-pointer active:opacity-50"
                        onClick={toggleDropdown}
                      />
                      {dropdownVisible && (
                        <div className="absolute top-20 right-12 ">
                          <div
                            className=" text-textpurple bg-cover bg-center rounded-lg  "
                            style={{
                              backgroundImage: `url(${images.dropdownbox})`,
                              width: "130px",
                              // height: "175px",
                            }}
                          >
                            <div
                              className="pb-1 text-base pt-3"
                              style={
                                {
                                  // fontWeight: '640'
                                }
                              }
                            >
                              {!userLogin ? (
                                <>
                                  <div className="flex pl-3 items-center pb-1 pt-2  ">
                                    <img
                                      src={images.giftCardIcon}
                                      alt=""
                                      className="w-4 h-4  mr-3"
                                    />
                                    <div
                                      onClick={toggleDropdown}
                                      className="opacity-80 cursor-pointer active:opacity-50"
                                    >
                                      <Link to={"/giftcard"}>Gift Card</Link>
                                    </div>
                                  </div>
                                  <div className="mx-auto border-b border-bordercolor w-80p opacity-70 "></div>
                                  <div className="flex pl-3 items-center pb-1 pt-1  ">
                                    <img
                                      src={images.adsCenter}
                                      alt=""
                                      className="w-4 h-4  mr-3"
                                    />
                                    <div
                                      onClick={toggleDropdown}
                                      className="opacity-80 cursor-pointer active:opacity-50"
                                    >
                                      <Link to={"/myads"}>Ads Center</Link>
                                    </div>
                                  </div>
                                  <div className="mx-auto border-b border-bordercolor w-80p opacity-70 "></div>
                                  <div className="flex justify-evenly items-center pt-1 pb-1 ">
                                    <img
                                      src={images.postAd}
                                      alt=""
                                      className="w-3 h-3 "
                                    />
                                    <div
                                      onClick={toggleDropdown}
                                      className="opacity-80  cursor-pointer active:opacity-50 mr-4"
                                    >
                                      <Link to={"/forms"}>Post Ad</Link>
                                    </div>
                                  </div>
                                  <div className="mx-auto border-b border-bordercolor w-80p opacity-70 "></div>
                                </>
                              ) : (
                                <>
                                  <div className="flex justify-evenly items-center pt-1 pb-1 ">
                                    <img
                                      src={images.blacheart}
                                      alt=""
                                      className="w-3 h-3 "
                                    />
                                    <div
                                      onClick={toggleDropdown}
                                      className="opacity-80  cursor-pointer active:opacity-50 mr-4"
                                    >
                                      <Link to={"/favorites"}>Favorites</Link>
                                    </div>
                                  </div>
                                  <div className="mx-auto border-b border-bordercolor w-80p opacity-70 "></div>

                                  <div className="flex pl-3 items-center pb-1 pt-2  ">
                                    <img
                                      src={images.giftCardIcon}
                                      alt=""
                                      className="w-4 h-4  mr-3"
                                    />
                                    <div
                                      onClick={toggleDropdown}
                                      className="opacity-80 cursor-pointer active:opacity-50"
                                    >
                                      <Link to={"/giftcard"}>Gift Card</Link>
                                    </div>
                                  </div>
                                  <div className="mx-auto border-b border-bordercolor w-80p opacity-70 "></div>
                                </>
                              )}

                              <div className="flex justify-evenly items-center pt-1 pb-1 ">
                                <img
                                  src={images.support}
                                  alt=""
                                  className="w-4 h-4 "
                                />
                                <div
                                  onClick={toggleDropdown}
                                  className="opacity-80  cursor-pointer active:opacity-50 mr-4"
                                >
                                  <Link to={"/contact"}>Support</Link>
                                </div>
                              </div>
                              <div className="mx-auto border-b border-bordercolor w-80p opacity-70 "></div>

                              <div className="flex justify-evenly items-center pt-1 pb-1 ">
                                <img
                                  src={images.Setting}
                                  alt=""
                                  className="w-3 h-3 "
                                />
                                <div
                                  onClick={toggleDropdown}
                                  className="opacity-80  cursor-pointer active:opacity-50 mr-4"
                                >
                                  <Link to={"/settings"}>Settings</Link>
                                </div>
                              </div>

                              <div className="mx-auto border-b border-bordercolor w-80p opacity-70 "></div>
                              <div className=" flex items-center justify-evenly  pt-1 ml-1">
                                <img
                                  src={images.Send}
                                  alt=""
                                  className="w-3 h-3 "
                                />
                                <div
                                  className="opacity-80 cursor-pointer active:opacity-50 mr-4 "
                                  onClick={handleLogout}
                                >
                                  Signout
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <img
                        src={userData?.profilePhoto}
                        alt=""
                        className="border rounded-full w-8 h-8 cursor-pointer active:opacity-50"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="bg-purplegray">
        <div className="border-b border-whitepink opacity-10  bg-purplegray"></div>
      </div>
    </div>
  );
}
