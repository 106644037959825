import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import images from "../../services/images";
import AdCard from "../../components/adCard";
import AvatarEditor from "react-avatar-editor";
import {
  getBookedSlot,
  handleManageUpgrades,
  setPaymentDetailsForVerify,
  setPaymentDetailsForVerifyDec,
} from "../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../store/token";
import { ClipLoader } from "react-spinners";
import userData, { selectUserData, setUsereData } from "../../store/userData";

export default function ManageUpgrades() {
  const { _id } = useParams();
  const location = useLocation();
  const { item } = location?.state || {};

  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const editorRef = useRef(null);
  const token = useSelector(selectToken);

  const [boost, hideBoosts] = useState("boost");
  const [selectedPageOneSlot, setSelectedPageOneSlot] = useState(null);
  const [pageOne, setPageone] = useState(false);
  const [time, setTime] = useState("");
  const [selectHour, setSelecthour] = useState("five");
  const [immediatelyPageOneThreeHours, setImmediatelyPageOneThreeHours] =
    useState(null);
  const [immediatelyPageOneFiveHours, setImmediatelyPageOneFiveHours] =
    useState(null);
  const [immediatelyPageOneSevenHours, setImmediatelyPageOneSevenHours] =
    useState(null);
  const [pageOnedata, setPageoneData] = useState(0.25);
  const [totalCost, setTotalCost] = useState(0.0);
  const [pageTwodata, setPageTwodata] = useState(0.5);
  const [pageThreedata, setPageThreedata] = useState(0.75);
  const [morningData, setMorningdata] = useState([]);
  const [fiveHoursSlotMorning, setFiveHoursSlotMorning] = useState([]);
  const [fiveHoursSlotEvening, setFiveHoursSlotEvening] = useState([]);
  const [fiveHoursSlotAfternoon, setFiveHoursSlotAfternoon] = useState([]);
  const [fiveHoursSlotLateNight, setFiveHoursSlotLateNight] = useState([]);
  const [sevenHoursSlotMorning, setSevenHoursSlotMorning] = useState([]);
  const [sevenHoursSlotEvening, setSevenHoursSlotEvening] = useState([]);
  const [sevenHoursSlotAfternoon, setSevenHoursSlotAfternoon] = useState([]);
  const [sevenHoursSlotLateNight, setSevenHoursSlotLateNight] = useState([]);
  const [afterNoondata, setAfternoonData] = useState([]);
  const [eveningData, setEveningData] = useState([]);
  const [lateNightdata, setLatenightData] = useState([]);
  const [selectPageTwoSLot, setSelectPageTwoSLot] = useState(null);
  const [coditionalModal, setOpenCondtionalModal] = useState(false);
  const [conditionModalTitle, setConditionModalTitle] = useState("");
  const [conditionModalSubject, setConditionModalSubject] = useState("");
  const [conditionalModalBtnText, setConditionalModalBtnText] = useState("");
  const [adPremiume, showPremiumeAd] = useState(false);
  const [uploadimg, setuploadedimg] = useState([]);
  const [adTitle, setAdTitle] = useState("");
  const [age, setAge] = useState("");
  const [themeThreeHoursCost, setThemeThreeHoursCost] = useState(6.853);
  const [themeFiveHoursCost, setThemeFiveHoursCost] = useState(13.825);
  const [themeSevenHoursCost, setThemeSevenHoursCost] = useState(20.552);
  const [ethnicity, setEthnicity] = useState("");
  const [ethnicOrigin, setEthnicOrigin] = useState("");
  const [selectedcity, setSelectedcity] = useState(null);
  const [premiumAdHour, setPremiumAdHour] = useState("");
  const [addextra, setaddextra] = useState([
    {
      title2: "Bumps/ Auto Repost",
      description2: "Instantly push your ad to the top with a click!",
      cost2: 6.09,
    },
  ]);
  const [showBumpsAddTime, setShowBumpsAddTime] = useState(null);
  const [bumpsTab, setBumpsTab] = useState([
    { qty: 5, cost: 4.89, selected: false, enable: true },
    { qty: 10, cost: 4.89, selected: false, enable: true },
    { qty: 20, cost: 4.68, selected: false, enable: true },
    { qty: 50, cost: 4.26, selected: false, enable: true },
  ]);
  const [bumpsTimeDropdown, setBumpsTimeDropdown] = useState(null);
  const [hoursForBumps, setHoursForBumps] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);
  const [minutesForBumps, setMinutesForBumps] = useState([
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
  ]);
  const [adDescription, setAdDescription] = useState("");
  const [showBumps, setShowbumps] = useState(false);
  const [scheduleBumps, setScheduleBumps] = useState(false);
  const [bumpsDayOption, setBumpsDayOption] = useState([
    { day: 1, selected: true, enable: true },
    { day: 3, selected: true, enable: true },
    { day: 7, selected: true, enable: true },
    { day: 14, selected: true, enable: true },
    { day: 30, selected: true, enable: true },
  ]);
  const [selectedBumps, setSelectedBumps] = useState(null);
  const [thirdBumpsCost, setThirdBumpsCost] = useState(6.09);
  const [carouselAd, openCarouselAd] = useState(false);
  const [carouselAdhour, setCarouseladHour] = useState("six");
  const [carouselThreeHoursCost, setCarouselThreeHoursCost] = useState(1.449);
  const [carouselSixHoursCost, setCarouselSixHoursCost] = useState(2.898);
  const [carouselNineHoursCost, setCarouselNineHoursCost] = useState(4.347);
  const [selectedCarousel, setSelectedCarousel] = useState(null);
  const [availbeNowTheme, showAvaialblenowtheme] = useState(false);
  const [availbleHour, selectAvailableHour] = useState("two");
  const [availableNowOneHourCost, setAvailableNowOneHourCost] = useState(0.553);
  const [availableNowTwoHourCost, setAvailableNowTwoHourCost] = useState(1.106);
  const [availableNowThreeHourCost, setAvailableNowThreeHourCost] =
    useState(1.659);
  const [availableNowFourHourCost, setAvailableNowFourHourCost] =
    useState(2.212);
  const [selectedAvailableNow, setSelectedAvailableNow] = useState(null);
  const [featuredAd, showFeaturedAd] = useState(false);
  const [openModal, setOpenmodal] = useState(false);
  const [editedImage, setEditedImage] = useState(null);
  const [editPic, setEditpic] = useState(false);
  const [selectImg, setSelectedimg] = useState(null);
  const [featuredAdHour, selectedFeaturedAdhour] = useState("three");
  const [featuredAddOneHourCost, setFeaturedAddOneHourCost] = useState(2.09);
  const [featuredAddThreeHourCost, setFeaturedAddThreeHourCost] =
    useState(2.73);
  const [featuredAddSevenHourCost, setFeaturedAddSevenHourCost] =
    useState(7.12);
  const [emojiShow, setEmojiShow] = useState(false);
  const [textAreadata, setTextareadata] = useState("");
  const [selectedFeaturedAd, setSelectedFeaturedAd] = useState(null);
  const [emojiImages, setEmojiImages] = useState([
    { emoji: "🍓" },
    { emoji: "💓" },
    { emoji: "🌟" },
    { emoji: "🍑" },
    { emoji: "💋" },
    { emoji: "😘" },
    { emoji: "❣️" },
    { emoji: "💦" },
    { emoji: "❄️" },
    { emoji: "⚠️" },
    { emoji: "⭕" },
    { emoji: "❌" },
    { emoji: "✅" },
    { emoji: "🍆" },
    { emoji: "📱" },
    { emoji: "✈️" },
    { emoji: "👠" },
    { emoji: "🥂" },
    { emoji: "👅" },
  ]);
  const [emojiAdd, selectEmojiadd] = useState("7Days");
  const [emoji24HoursCost, setEmoji24HoursCost] = useState(1.11);
  const [emoji7DaysCost, setEmoji7DaysCost] = useState(6.64);
  const [emoji30DaysCost, setEmoji30DaysCost] = useState(27.71);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [changepanel, setchangepanel] = useState("Country");
  const [error, setError] = useState("validated");
  const [name, setName] = useState("");
  const [expirePageOne, setExpirePageOne] = useState(null);
  const [expireTheme, setExpireTheme] = useState(null);
  const [expireCarousel, setExpireCarousel] = useState(null);
  const [expireAvailableNow, setExpireAvailableNow] = useState(null);
  const [expireFearreAd, setExpireFearreAd] = useState(null);
  const [expireEmoji, setExpireEmoji] = useState(null);
  const [expireBumps, setExpireBumps] = useState(null);
  const [previousPayment, setPreviousPayment] = useState(0.0);
  const [disabledTabs, setDisabledTabs] = useState({
    three: false,
    five: false,
    seven: false,
  });
  const [disabledCarouselTabs, setDisabledCarouselTabs] = useState({
    three: false,
    six: false,
    nine: false,
  });
  const [disabledAvailabseNowTabs, setdisabledAvailabseNowTabs] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
  });
  const [disabledFeatureAdTbs, setdisabledFeatureAdTbs] = useState({
    one: false,
    three: false,
    seven: false,
  });
  const [disabledEmojiTabs, setDisabledEmojiTabs] = useState({
    twentyFour: false,
    seven: false,
    thirty: false,
  });
  const [disabledPageOneTabs, setDisabledPageOneTabs] = useState({
    three: false,
    five: false,
    seven: false,
  });
  const [showPageOneForEdit, setShowPageOneForEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const isExpired = (endDate, endTime) => {
    const endDateTime = new Date(`${endDate} ${endTime}`);
    const currentDateTime = new Date();
    return currentDateTime > endDateTime;
  };

  useEffect(() => {
    if (successModal || loader) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [successModal, loader]);

  useEffect(() => {
    handleGetBookedSlot();
    setuploadedimg(item?.pictures);
    setSelectedimg(item?.pictures[0]);
    handleScrollToTop();
    setAdTitle(item?.ad_title);
    setAge(item?.age);
    setEthnicity(item?.ethnicity);
    setEthnicOrigin(item?.ethnic_origin);
    setSelectedcity(item?.city);
    setName(item?.name);
    if (item?.upgrades?.pageOne) {
      if (
        isExpired(
          item?.upgrades?.pageOne?.endDate,
          item?.upgrades?.pageOne?.endTime
        )
      ) {
        setExpirePageOne(item?.upgrades?.pageOne);
      } else {
        setSelecthour(item?.upgrades?.pageOne?.hour);
        setSelectedPageOneSlot(item?.upgrades?.pageOne);
        setTotalCost((prevCost) => prevCost + item?.upgrades?.pageOne?.cost);
        setPreviousPayment(
          (prevCost) => prevCost + item?.upgrades?.pageOne?.cost
        );
      }
    }
    if (item?.upgrades?.premiumTheme) {
      if (
        isExpired(
          item?.upgrades?.premiumTheme?.endDate,
          item?.upgrades?.premiumTheme?.endTime
        )
      ) {
        setExpireTheme(item?.upgrades?.premiumTheme);
      } else {
        showPremiumeAd(true);
        setPremiumAdHour(item?.upgrades?.premiumTheme?.hour);
        setSelectPageTwoSLot(item?.upgrades?.premiumTheme);
        setTotalCost(
          (prevCost) => prevCost + item?.upgrades?.premiumTheme?.cost
        );
        setPreviousPayment(
          (prevCost) => prevCost + item?.upgrades?.premiumTheme?.cost
        );
      }
    }
    if (item?.upgrades?.carousel) {
      if (
        isExpired(
          item?.upgrades?.carousel?.endDate,
          item?.upgrades?.carousel?.endTime
        )
      ) {
        setExpireCarousel(item?.upgrades?.carousel);
      } else {
        openCarouselAd(true);
        setCarouseladHour(item?.upgrades?.carousel?.hour);
        setSelectedCarousel(item?.upgrades?.carousel);
        setTotalCost((prevCost) => prevCost + item?.upgrades?.carousel?.cost);
        setPreviousPayment(
          (prevCost) => prevCost + item?.upgrades?.carousel?.cost
        );
      }
    }
    if (item?.upgrades?.availableNow) {
      if (
        isExpired(
          item?.upgrades?.availableNow?.endDate,
          item?.upgrades?.availableNow?.endTime
        )
      ) {
        setExpireAvailableNow(item?.upgrades?.availableNow);
      } else {
        showAvaialblenowtheme(true);
        selectAvailableHour(item?.upgrades?.availableNow?.hour);
        setSelectedAvailableNow(item?.upgrades?.availableNow);
        setTotalCost(
          (prevCost) => prevCost + item?.upgrades?.availableNow?.cost
        );
        setPreviousPayment(
          (prevCost) => prevCost + item?.upgrades?.availableNow?.cost
        );
      }
    }
    if (item?.upgrades?.featuredAdd) {
      if (
        isExpired(
          item?.upgrades?.featuredAdd?.endDate,
          item?.upgrades?.featuredAdd?.endTime
        )
      ) {
        setExpireFearreAd(item?.upgrades?.featuredAdd);
      } else {
        showFeaturedAd(true);
        selectedFeaturedAdhour(item?.upgrades?.featuredAdd?.hour);
        setSelectedFeaturedAd(item?.upgrades?.featuredAdd);
        setTotalCost(
          (prevCost) => prevCost + item?.upgrades?.featuredAdd?.cost
        );
        setPreviousPayment(
          (prevCost) => prevCost + item?.upgrades?.featuredAdd?.cost
        );
      }
    }
    if (item?.upgrades?.emoji) {
      if (
        isExpired(
          item?.upgrades?.emoji?.endDate,
          item?.upgrades?.emoji?.endTime
        )
      ) {
        setExpireEmoji(item?.upgrades?.emoji);
      } else {
        setEmojiShow(true);
        selectEmojiadd(item?.upgrades?.emoji?.hour);
        setTextareadata(item?.upgrades?.emoji?.emoji);
        setSelectedEmoji(item?.upgrades?.emoji);
        setTotalCost((prevCost) => prevCost + item?.upgrades?.emoji?.cost);
        setPreviousPayment(
          (prevCost) => prevCost + item?.upgrades?.emoji?.cost
        );
      }
    }
    if (item.upgrades?.bumps) {
      const data = {
        cost: item.upgrades?.bumps?.cost,
        remainingBumps: item.upgrades?.bumps?.totalBumps,
        selectedScheduleBumps: null,
        times: null,
        totalBumps: item.upgrades?.bumps?.totalBumps,
      };
      setSelectedBumps(data);
      setShowbumps(true);
      setTotalCost((prevCost) => prevCost + item.upgrades?.bumps?.cost);
      setPreviousPayment((prevCost) => prevCost + item.upgrades?.bumps?.cost);
    }
    if (item.upgrades?.bumps && item.upgrades?.bumps?.selectedScheduleBumps) {
      const bumpsStatus = calculateExpireBumps(
        item.upgrades?.bumps?.selectedScheduleBumps,
        item.upgrades?.bumps?.times
      );
      const timesArray = item.upgrades?.bumps?.times;
      if (bumpsStatus?.expiredBumps?.length > 0) {
        setExpireBumps(bumpsStatus?.expiredBumps);
      }
      const totalBumps =
        calculateTotalScheduleBumps(bumpsStatus?.remainingBumps) +
        item.upgrades?.bumps?.remainingBumps;
      const data = {
        cost: item.upgrades?.bumps?.cost,
        remainingBumps: totalBumps,
        selectedScheduleBumps: null,
        times: null,
        totalBumps: totalBumps,
      };
      setSelectedBumps(data);
      setShowbumps(true);
      handleCreateInitialBumpsSchedule(totalBumps, timesArray);
      setTotalCost((prevCost) => prevCost + item.upgrades?.bumps?.cost);
      setPreviousPayment((prevCost) => prevCost + item.upgrades?.bumps?.cost);
    }
  }, [location.state]);

  useEffect(() => {
    if (
      item?.upgrades?.premiumTheme &&
      !isExpired(
        item?.upgrades?.premiumTheme?.endDate,
        item?.upgrades?.premiumTheme?.endTime
      )
    ) {
      let test = item?.upgrades?.premiumTheme?.hour;
      if (test === "five") {
        setDisabledTabs({ three: true, five: false, seven: false });
      } else if (test === "seven") {
        setDisabledTabs({ three: true, five: true, seven: false });
      } else {
        setDisabledTabs({ three: false, five: false, seven: false });
      }
    }
    if (
      item?.upgrades?.carousel &&
      !isExpired(
        item?.upgrades?.carousel?.endDate,
        item?.upgrades?.carousel?.endTime
      )
    ) {
      let test = item?.upgrades?.carousel?.hour;
      if (test === "six") {
        setDisabledCarouselTabs({ three: true, six: false, nine: false });
      } else if (test === "nine") {
        setDisabledCarouselTabs({ three: true, six: true, nine: false });
      } else {
        setDisabledCarouselTabs({ three: false, six: false, nine: false });
      }
    }
    if (
      item?.upgrades?.featuredAdd &&
      !isExpired(
        item?.upgrades?.featuredAdd?.endDate,
        item?.upgrades?.featuredAdd?.endTime
      )
    ) {
      let test = item?.upgrades?.featuredAdd?.hour;
      if (test === "three") {
        setdisabledFeatureAdTbs({ one: true, three: false, seven: false });
      } else if (test === "seven") {
        setdisabledFeatureAdTbs({ one: true, three: true, seven: false });
      } else {
        setdisabledFeatureAdTbs({ one: false, three: false, seven: false });
      }
    }
    if (
      item?.upgrades?.emoji &&
      !isExpired(item?.upgrades?.emoji?.endDate, item?.upgrades?.emoji?.endTime)
    ) {
      let test = item?.upgrades?.emoji?.hour;
      if (test === "7Days") {
        setDisabledEmojiTabs({ twentyFour: true, seven: false, thirty: false });
      } else if (test === "30days") {
        setDisabledEmojiTabs({ twentyFour: true, seven: true, thirty: false });
      } else {
        setDisabledEmojiTabs({
          twentyFour: false,
          seven: false,
          thirty: false,
        });
      }
    }
    if (
      item?.upgrades?.availableNow &&
      !isExpired(
        item?.upgrades?.availableNow?.endDate,
        item?.upgrades?.availableNow?.endTime
      )
    ) {
      let test = item?.upgrades?.availableNow?.hour;
      if (test === "two") {
        setdisabledAvailabseNowTabs({
          one: true,
          two: false,
          three: false,
          four: false,
        });
      } else if (test === "three") {
        setdisabledAvailabseNowTabs({
          one: true,
          two: true,
          three: false,
          four: false,
        });
      } else if (test === "four") {
        setdisabledAvailabseNowTabs({
          one: true,
          two: true,
          three: true,
          four: false,
        });
      } else {
        setdisabledAvailabseNowTabs({
          one: false,
          two: false,
          three: false,
          four: false,
        });
      }
    }
    if (
      item?.upgrades?.pageOne &&
      !isExpired(
        item?.upgrades?.pageOne?.endDate,
        item?.upgrades?.pageOne?.endTime
      )
    ) {
      let test = item?.upgrades?.pageOne?.hour;
      if (test === "five") {
        setDisabledPageOneTabs({ three: true, five: false, seven: false });
      } else if (test === "seven") {
        setDisabledPageOneTabs({ three: true, five: true, seven: false });
      } else {
        setDisabledPageOneTabs({ three: false, five: false, seven: false });
      }
    }
  }, [item]);

  useEffect(() => {
    handleCheckCanAddTime();
    handleDisableBumpsTabs();
  }, [selectedBumps]);

  function calculateExpireBumps(selectedScheduleBumps, timesArray) {
    const expiredBumps = [];
    const remainingBumps = [];
    const currentDateTime = new Date();
    selectedScheduleBumps.forEach((schedule) => {
      const { date, bumps } = schedule;
      const bumpDate = new Date(date);
      const dailyExpiredBumps = { date, bumps: 0, times: [] };
      const dailyRemainingBumps = {
        date,
        bumps: 0,
        // times: []
      };
      // Loop through the bumps and corresponding times
      for (let i = 0; i < bumps; i++) {
        const time = timesArray[i % timesArray.length];
        const [hour, minute] = time.slice(0, -3).split(":");
        const period = time.slice(-2);
        let bumpTime = new Date(bumpDate);
        bumpTime.setHours(
          period === "PM"
            ? (parseInt(hour, 10) % 12) + 12
            : parseInt(hour, 10) % 12,
          parseInt(minute, 10),
          0,
          0
        );
        if (bumpTime < currentDateTime) {
          dailyExpiredBumps.bumps += 1;
          dailyExpiredBumps.times.push(time);
        } else {
          dailyRemainingBumps.bumps += 1;
          // dailyRemainingBumps.times.push(time);
        }
      }
      if (dailyExpiredBumps.bumps > 0) expiredBumps.push(dailyExpiredBumps);
      if (dailyRemainingBumps.bumps > 0)
        remainingBumps.push(dailyRemainingBumps);
    });
    return { expiredBumps, remainingBumps };
  }
  function getTimeBefore(time, timesArray) {
    const [hours, minutes, period] = time.split(/:| /); // Splitting hours, minutes, and period (AM/PM)
    let hour = parseInt(hours);
    let minute = parseInt(minutes);
    // Subtract an hour and adjust minute if necessary
    if (hour === 0) {
      hour = 23;
    } else {
      hour -= 1;
    }
    const timeBefore = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")} ${period}`; // Reformatting time with period
    // Return the time before if it exists in timesArray, otherwise return the original time
    return timesArray.includes(timeBefore) ? timeBefore : time;
  }

  const checkForAddMoreBumps = (index) => {
    const totalScheduleBumbs = calculateTotalScheduleBumps(
      selectedBumps?.selectedScheduleBumps
    );
    const totalBumps = selectedBumps?.totalBumps;
    const selected = bumpsTab[index].selected;
    if (selected) {
      const bumpsAfterSubtract = totalBumps - bumpsTab[index].qty;
      if (bumpsAfterSubtract > totalScheduleBumbs) {
        return true;
      }
      return false;
    } else {
      const selectedBumpsTabQty = bumpsTab?.find((item) => item.selected)?.qty;
      if (selectedBumpsTabQty) {
        const bumpsAfterSubtract =
          totalBumps - selectedBumpsTabQty + bumpsTab[index].qty;
        if (bumpsAfterSubtract > totalScheduleBumbs) {
          return true;
        }
        return false;
      } else {
        const bumpsAfterSubtract = totalBumps + bumpsTab[index].qty;
        if (bumpsAfterSubtract > totalScheduleBumbs) {
          return true;
        }
        return false;
      }
    }
  };

  const handleDisableBumpsTabs = async () => {
    if (!item?.upgrades?.bumps) {
      const totalScheduleBumbs = calculateTotalScheduleBumps(
        selectedBumps?.selectedScheduleBumps
      );
      if (totalScheduleBumbs || totalScheduleBumbs?.length > 0) {
        setBumpsTab((prevState) =>
          prevState.map((item) => ({
            ...item,
            enable: item.qty >= totalScheduleBumbs,
          }))
        );
      } else {
        setBumpsTab((prevState) =>
          prevState.map((item) => ({
            ...item,
            enable: true,
          }))
        );
      }
    }
  };

  const handleCheckCanAddTime = () => {
    const totalBumps = selectedBumps?.totalBumps;
    const totalScheduleBumps = selectedBumps?.selectedScheduleBumps?.reduce(
      (total, option) => total + option.bumps,
      0
    );
    const days = selectedBumps?.selectedScheduleBumps?.length;
    const timePerDay = selectedBumps?.times?.length;

    const newTotalScheduledBumps = timePerDay * days;

    if (newTotalScheduledBumps + days <= totalBumps) {
      setShowBumpsAddTime(true);
    } else {
      setShowBumpsAddTime(false);
    }
  };

  const updateRemainingBumps = async () => {
    if (selectedBumps) {
      if (selectedBumps?.selectedScheduleBumps) {
        const bumps = { ...selectedBumps };
        bumps.remainingBumps =
          selectedBumps?.totalBumps -
          calculateTotalScheduleBumps(selectedBumps?.selectedScheduleBumps);
        bumps.cost = parseFloat(bumps.cost.toFixed(2));
        return bumps;
      } else {
        const bumps = { ...selectedBumps };
        bumps.remainingBumps = bumps.totalBumps;
        return bumps;
      }
    } else {
      return null;
    }
  };
  const [paymentModal, setPaymentModal] = useState(false);

  const handleWalletUpdate = async (cost, token) => {
    try {
      const body = {
        wallet: cost,
      };
      console.log("Sending wallet update request with data:", body);

      // Include token in the headers
      const paymentUpdateResponse = await setPaymentDetailsForVerifyDec(
        body,
        token
      );

      if (paymentUpdateResponse.success) {
        console.log(`Wallet updated successfully. Amount deducted: ${cost}`);
        console.log("Updated user data:", paymentUpdateResponse.updatedEscort);
        return paymentUpdateResponse;
      } else {
        console.error("Error updating wallet:", paymentUpdateResponse.message);
        return paymentUpdateResponse;
      }
    } catch (error) {
      console.error(
        "Error updating wallet:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  // const handlePostAdvertisement = async () => {
  //   if (loader) {
  //     return;
  //   }

  //   try {
  //     setLoader(true);
  //     const success = await handleAdvertisement();
  //     if (success) {
  //       setPaymentModal(true);
  //     }
  //   } catch (error) {
  //     console.error("Failed to post advertisement:", error);
  //   } finally {
  //     setLoader(false);
  //   }
  // };

  const [insufficentBalance, setInsufficientBalance] = useState(false);

  // const handleConfirm = async () => {
  //   const calculatedBumps = await updateRemainingBumps();
  //   const cost = totalCost - previousPayment;
  //   if (cost > 0) {
  //     setLoader(true);
  //     const body = {
  //       pageOne: selectedPageOneSlot ? selectedPageOneSlot : expirePageOne,
  //       premiumTheme: selectPageTwoSLot ? selectPageTwoSLot : expireTheme,
  //       bumps: calculatedBumps,
  //       carousel: selectedCarousel ? selectedCarousel : expireCarousel,
  //       availableNow: selectedAvailableNow
  //         ? selectedAvailableNow
  //         : expireAvailableNow,
  //       featuredAdd: selectedFeaturedAd ? selectedFeaturedAd : expireFearreAd,
  //       emoji: selectedEmoji ? selectedEmoji : expireEmoji,
  //     };
  //     const response = await handleManageUpgrades(
  //       token,
  //       body,
  //       item?.upgrades?._id
  //     );
  //     if (response.success) {
  //       setLoader(false);
  //       setSuccessModal(true);
  //     } else {
  //       setLoader(false);
  //       alert(response?.message);
  //     }
  //   } else {
  //     navigate("/myads");
  //   }
  // };

  const handleConfirm = async () => {
    const calculatedBumps = await updateRemainingBumps();
    const cost = totalCost - previousPayment;

    if (cost > 0) {
      if (userData?.wallet < cost) {
        // If wallet balance is insufficient, show an error or handle accordingly
        setInsufficientBalance(true);
        return;
      } else {
        setInsufficientBalance(false);
      }

      setLoader(true);

      try {
        // Deduct the wallet by the cost
        const paymentUpdateResponse = await handleWalletUpdate(cost, token);

        if (paymentUpdateResponse.success) {
          console.log("Wallet updated successfully.");
          // dispatch(setUsereData(paymentUpdateResponse.updatedEscort));
          console.log(
            "Updated user data:",
            paymentUpdateResponse.updatedEscort
          );

          const body = {
            pageOne: selectedPageOneSlot ? selectedPageOneSlot : expirePageOne,
            premiumTheme: selectPageTwoSLot ? selectPageTwoSLot : expireTheme,
            bumps: calculatedBumps,
            carousel: selectedCarousel ? selectedCarousel : expireCarousel,
            availableNow: selectedAvailableNow
              ? selectedAvailableNow
              : expireAvailableNow,
            featuredAdd: selectedFeaturedAd
              ? selectedFeaturedAd
              : expireFearreAd,
            emoji: selectedEmoji ? selectedEmoji : expireEmoji,
          };

          const response = await handleManageUpgrades(
            token,
            body,
            item?.upgrades?._id
          );

          if (response.success) {
            setLoader(false);
            setSuccessModal(true);
          } else {
            setLoader(false);
            alert(response?.message);
          }
        } else {
          setLoader(false);
          console.error(
            "Error updating wallet:",
            paymentUpdateResponse.message
          );
        }
      } catch (error) {
        setLoader(false);
        console.error("Failed to update wallet or manage upgrades:", error);
      }
    } else {
      navigate("/myads");
    }
  };

  const emptyError = () => {
    setError("validated");
  };
  const handleRemoveEmoji = () => {
    if (selectedEmoji) {
      setTotalCost((prevCost) => prevCost - selectedEmoji?.cost);
      setSelectedEmoji(null);
      setEmojiShow(false);
      setTextareadata(adTitle);
    }
  };
  const handleAddEmoji = async (hour, cost) => {
    if (
      item?.upgrades?.emoji &&
      !isExpired(item?.upgrades?.emoji?.endDate, item?.upgrades?.emoji?.endTime)
    ) {
      selectEmojiadd(hour);
      setEmojiShow(true);
      const dates =
        hour === "24"
          ? await getStartTimeAndReturnEndTime(
              selectedEmoji?.startDate,
              selectedEmoji?.startTime,
              24
            )
          : hour === "7Days"
          ? await getStartTimeAndReturnEndTime(
              selectedEmoji?.startDate,
              selectedEmoji?.startTime,
              168
            )
          : await getStartTimeAndReturnEndTime(
              selectedEmoji?.startDate,
              selectedEmoji?.startTime,
              720
            );
      setTotalCost((prevCost) => prevCost - selectedEmoji?.cost);
      setTotalCost((prevCost) => prevCost + cost);
      setSelectedEmoji((prevState) => {
        return {
          ...prevState,
          cost: cost,
          hour: hour,
          endDate: dates?.endDate,
          endTime: dates?.endTime,
        };
      });
    } else {
      setEmojiShow(true);
      if (selectedEmoji) {
        setTotalCost((prevCost) => prevCost - selectedEmoji?.cost);
        selectEmojiadd(hour);
        setEmojiShow(true);
        const dates =
          hour === "24"
            ? await getCurrentFormattedDate(24)
            : hour === "7Days"
            ? await getCurrentFormattedDate(168)
            : await getCurrentFormattedDate(720);
        const obj = {
          hour,
          cost,
          emoji: "",
        };
        const selected = { ...dates, ...obj };
        setTotalCost((prevCost) => prevCost + cost);
        setSelectedEmoji(selected);
      } else {
        selectEmojiadd(hour);
        setEmojiShow(true);
        const dates =
          hour === "24"
            ? await getCurrentFormattedDate(24)
            : hour === "7Days"
            ? await getCurrentFormattedDate(168)
            : await getCurrentFormattedDate(720);
        const obj = {
          hour,
          cost,
          emoji: "",
        };
        const selected = { ...dates, ...obj };
        setSelectedEmoji(selected);
        setTotalCost((prevCost) => prevCost + cost);
      }
    }
  };
  const handleEmojiClick = (emoji) => {
    const finalTitle = textAreadata + emoji;
    setTextareadata((prevData) => prevData + emoji);
    setSelectedEmoji((prevState) => ({ ...prevState, emoji: finalTitle }));
  };
  const handleChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 60) {
      setTextareadata(newText);
      setSelectedEmoji((prevState) => ({ ...prevState, emoji: newText }));
    }
  };
  const handleRemoveFeaturedAd = () => {
    if (selectedFeaturedAd) {
      setTotalCost((prevCost) => prevCost - selectedFeaturedAd?.cost);
      setSelectedFeaturedAd(null);
      showFeaturedAd(false);
      setOpenmodal(false);
    }
  };
  const handleAddFeaturedAdd = async (hour, cost) => {
    if (
      item?.upgrades?.featuredAdd &&
      !isExpired(
        item?.upgrades?.featuredAdd?.endDate,
        item?.upgrades?.featuredAdd?.endTime
      )
    ) {
      selectedFeaturedAdhour(hour);
      showFeaturedAd(true);
      const dates =
        hour === "three"
          ? await getStartTimeAndReturnEndTime(
              selectedFeaturedAd?.startDate,
              selectedFeaturedAd?.startTime,
              3
            )
          : hour === "seven"
          ? await getStartTimeAndReturnEndTime(
              selectedFeaturedAd?.startDate,
              selectedFeaturedAd?.startTime,
              7
            )
          : await getStartTimeAndReturnEndTime(
              selectedFeaturedAd?.startDate,
              selectedFeaturedAd?.startTime,
              1
            );
      setTotalCost((prevCost) => prevCost - selectedFeaturedAd?.cost);
      setTotalCost((prevCost) => prevCost + cost);
      setSelectedFeaturedAd((prevState) => {
        return {
          ...prevState,
          cost: cost,
          hour: hour,
          endDate: dates?.endDate,
          endTime: dates?.endTime,
        };
      });
    } else {
      showFeaturedAd(true);
      if (selectedFeaturedAd) {
        setTotalCost((prevCost) => prevCost - selectedFeaturedAd?.cost);
        selectedFeaturedAdhour(hour);
        showFeaturedAd(true);
        const dates =
          hour === "three"
            ? await getCurrentFormattedDate(3)
            : hour === "seven"
            ? await getCurrentFormattedDate(7)
            : await getCurrentFormattedDate(1);
        const obj = {
          hour,
          cost,
        };
        const selected = { ...dates, ...obj };
        setTotalCost((prevCost) => prevCost + cost);
        setSelectedFeaturedAd(selected);
      } else {
        selectedFeaturedAdhour(hour);
        showFeaturedAd(true);
        const dates =
          hour === "three"
            ? await getCurrentFormattedDate(3)
            : hour === "seven"
            ? await getCurrentFormattedDate(7)
            : await getCurrentFormattedDate(1);
        const obj = {
          hour,
          cost,
        };
        const selected = { ...dates, ...obj };
        setSelectedFeaturedAd(selected);
        setTotalCost((prevCost) => prevCost + cost);
      }
    }
  };

  const handleClickimg = (item) => {
    setSelectedimg(item);
    setEditpic(item);
  };

  const handleRemoveAvailableNow = () => {
    if (selectedAvailableNow) {
      setTotalCost((prevCost) => prevCost - selectedAvailableNow?.cost);
      setSelectedAvailableNow(null);
      showAvaialblenowtheme(false);
    }
  };
  const handleAddAvailableNow = async (hour, cost) => {
    if (
      item?.upgrades?.availableNow &&
      !isExpired(
        item?.upgrades?.availableNow?.endDate,
        item?.upgrades?.availableNow?.endTime
      )
    ) {
      selectAvailableHour(hour);
      showAvaialblenowtheme(true);
      const dates =
        hour === "two"
          ? await getStartTimeAndReturnEndTime(
              selectedAvailableNow?.startDate,
              selectedAvailableNow?.startTime,
              2
            )
          : hour === "one"
          ? await getStartTimeAndReturnEndTime(
              selectedAvailableNow?.startDate,
              selectedAvailableNow?.startTime,
              1
            )
          : hour === "three"
          ? await getStartTimeAndReturnEndTime(
              selectedAvailableNow?.startDate,
              selectedAvailableNow?.startTime,
              3
            )
          : await getStartTimeAndReturnEndTime(
              selectedAvailableNow?.startDate,
              selectedAvailableNow?.startTime,
              4
            );
      setTotalCost((prevCost) => prevCost - selectedAvailableNow?.cost);
      setTotalCost((prevCost) => prevCost + cost);
      setSelectedAvailableNow((prevState) => {
        return {
          ...prevState,
          cost: cost,
          hour: hour,
          endDate: dates.endDate,
          endTime: dates?.endTime,
        };
      });
    } else {
      showAvaialblenowtheme(true);
      if (selectedAvailableNow) {
        setTotalCost((prevCost) => prevCost - selectedAvailableNow?.cost);
        selectAvailableHour(hour);
        showAvaialblenowtheme(true);
        const dates =
          hour === "two"
            ? await getCurrentFormattedDate(2)
            : hour === "one"
            ? await getCurrentFormattedDate(1)
            : hour === "three"
            ? await getCurrentFormattedDate(3)
            : await getCurrentFormattedDate(4);
        const obj = {
          hour,
          cost,
        };
        const selected = { ...dates, ...obj };
        setTotalCost((prevCost) => prevCost + cost);
        setSelectedAvailableNow(selected);
      } else {
        selectAvailableHour(hour);
        showAvaialblenowtheme(true);
        const dates =
          hour === "two"
            ? await getCurrentFormattedDate(2)
            : hour === "one"
            ? await getCurrentFormattedDate(1)
            : hour === "three"
            ? await getCurrentFormattedDate(3)
            : await getCurrentFormattedDate(4);
        const obj = {
          hour,
          cost,
        };
        const selected = { ...dates, ...obj };
        setSelectedAvailableNow(selected);
        setTotalCost((prevCost) => prevCost + cost);
      }
    }
  };
  const handleRemoveCerousel = () => {
    if (selectedCarousel) {
      setTotalCost((prevCost) => prevCost - selectedCarousel?.cost);
      setSelectedCarousel(null);
      openCarouselAd(false);
    }
  };
  const handleAddCarouselAd = async (hour, cost) => {
    if (
      item?.upgrades?.carousel &&
      !isExpired(
        item?.upgrades?.carousel?.endDate,
        item?.upgrades?.carousel?.endTime
      )
    ) {
      setCarouseladHour(hour);
      openCarouselAd(true);
      const dates =
        hour === "six"
          ? await getStartTimeAndReturnEndTime(
              selectedCarousel?.startDate,
              selectedCarousel?.startTime,
              6
            )
          : hour === "three"
          ? await getStartTimeAndReturnEndTime(
              selectedCarousel?.startDate,
              selectedCarousel?.startTime,
              3
            )
          : await getStartTimeAndReturnEndTime(
              selectedCarousel?.startDate,
              selectedCarousel?.startTime,
              9
            );
      setTotalCost((prevCost) => prevCost - selectedCarousel?.cost);
      setTotalCost((prevCost) => prevCost + cost);
      setSelectedCarousel((prevState) => {
        return {
          ...prevState,
          cost: cost,
          hour: hour,
          endDate: dates?.endDate,
          endTime: dates?.endTime,
        };
      });
    } else {
      if (selectedCarousel) {
        setTotalCost((prevCost) => prevCost - selectedCarousel?.cost);
        setCarouseladHour(hour);
        openCarouselAd(true);
        const dates =
          hour === "six"
            ? await getCurrentFormattedDate(6)
            : hour === "three"
            ? await getCurrentFormattedDate(3)
            : await getCurrentFormattedDate(9);
        const obj = {
          hour,
          cost,
        };
        const selected = { ...dates, ...obj };
        setTotalCost((prevCost) => prevCost + cost);
        setSelectedCarousel(selected);
      } else {
        setCarouseladHour(hour);
        openCarouselAd(true);
        const dates =
          hour === "six"
            ? await getCurrentFormattedDate(6)
            : hour === "three"
            ? await getCurrentFormattedDate(3)
            : await getCurrentFormattedDate(9);
        const obj = {
          hour,
          cost,
        };
        const selected = { ...dates, ...obj };
        setSelectedCarousel(selected);
        setTotalCost((prevCost) => prevCost + cost);
      }
    }
  };
  const handleAgainSetBumpSchedule = async (selectedItem, timesLength) => {
    const date = await handleGetDateForBumps();
    const arrayOfBumps = await handleSetBumpsSchedule(
      selectedItem.day,
      date,
      timesLength
    );
    setSelectedBumps((prevState) => {
      return {
        ...prevState,
        selectedScheduleBumps: arrayOfBumps,
      };
    });
  };
  const selectBumpsDays = async (index) => {
    setBumpsDayOption((prevOptions) => {
      const updatedOptions = prevOptions?.map((option, i) => ({
        ...option,
        selected: i === index,
      }));
      const selectedItem = updatedOptions[index];
      const timesLength = selectedBumps.times?.length;
      handleAgainSetBumpSchedule(selectedItem, timesLength);
      return updatedOptions;
    });
  };
  const generateRandomTime = (previousArray) => {
    // Function to generate a random integer between min (inclusive) and max (inclusive)
    const getRandomInt = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    let randomTime;
    let isUnique = false;

    while (!isUnique) {
      // Generate random indices for hours and minutes
      const randomHourIndex = Math.floor(Math.random() * hoursForBumps.length);
      const randomMinuteIndex = Math.floor(
        Math.random() * minutesForBumps.length
      );

      // Select random hours and minutes using the generated indices
      let randomHour = hoursForBumps[randomHourIndex];
      let randomMinute = minutesForBumps[randomMinuteIndex];

      // Ensure that randomHour and randomMinute are always two-digit strings
      randomHour = randomHour < 10 ? `0${randomHour}` : randomHour.toString();
      randomMinute =
        randomMinute < 10 ? `0${randomMinute}` : randomMinute.toString();

      // Randomly select AM or PM
      const randomPeriod = Math.random() < 0.5 ? "AM" : "PM";

      // Construct the time string in "HH:MM AM/PM" format
      randomTime = `${randomHour}:${randomMinute} ${randomPeriod}`;

      // Check if the generated time is not in the previous array
      if (!previousArray.includes(randomTime)) {
        isUnique = true;
      }
    }

    return randomTime;
  };
  const handleAddTimeWithoutChangesDays = () => {
    const selectedTime = generateRandomTime(selectedBumps.times);
    setSelectedBumps((prevState) => {
      const updatedTimes = [...prevState.times, selectedTime];
      const updatedBumps = prevState?.selectedScheduleBumps?.map(
        (schedule) => ({
          ...schedule,
          bumps: schedule.bumps + 1,
        })
      );
      handleCalculateDays(updatedTimes);
      return {
        ...prevState,
        times: updatedTimes,
        selectedScheduleBumps: updatedBumps,
      };
    });
  };

  const handleCalculateDays = (timesArray) => {
    const totalBumps = selectedBumps?.totalBumps;
    let days = Math.floor(totalBumps / timesArray?.length);
    let matchedOption = bumpsDayOption
      .filter((option) => option.day <= days)
      .pop();
    const selectedIndex = bumpsDayOption.findIndex(
      (option) => option.day === matchedOption.day
    );
    setOnlyEnable(selectedIndex);
  };
  const handleRemoveTimeWithoutChangesDays = (index) => {
    setSelectedBumps((prevState) => {
      const updatedTimes = prevState?.times?.filter((_, i) => i !== index);
      const updatedBumps = prevState?.selectedScheduleBumps?.map(
        (schedule) => ({
          ...schedule,
          bumps: schedule.bumps - 1,
        })
      );
      handleCalculateDays(updatedTimes);
      return {
        ...prevState,
        times: updatedTimes,
        selectedScheduleBumps: updatedBumps,
      };
    });
    // handleCalculateDays()
  };
  const handleShowTimeDropdown = async (index) => {
    setBumpsTimeDropdown((prevState) => (prevState === index ? null : index));
  };
  const handleSetBumpsSchedule = async (number, dateString, bumps) => {
    const currentDate = new Date(dateString); // Get the date from the provided dateString
    const objectsArray = [];

    for (let i = 0; i < number; i++) {
      const newDate = new Date(currentDate); // Create a new date object to avoid modifying the original
      newDate.setDate(currentDate.getDate() + i); // Increment date by the provided number of days

      const formattedDate = `${
        newDate.getMonth() + 1
      }-${newDate.getDate()}-${newDate.getFullYear()}`;
      objectsArray.push({ date: formattedDate, bumps });
    }

    return objectsArray;
  };
  const handleGetDateForBumps = async () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // Add 1 to get tomorrow's date

    const month = tomorrow.getMonth() + 1; // Add 1 because getMonth() returns zero-based index
    const day = tomorrow.getDate();
    const year = tomorrow.getFullYear();

    // Pad single digit month and day with leading zero if necessary
    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedDay = day < 10 ? "0" + day : day;

    // Format the date as "MM-DAY-YEAR"
    const formattedDate = `${formattedMonth}-${formattedDay}-${year}`;

    return formattedDate;
  };
  const setSelectedAndEnable = (index) => {
    setBumpsDayOption((prevOptions) => {
      const updatedOptions = prevOptions?.map((option, i) => ({
        ...option,
        selected: i === index,
        enable: i <= index,
      }));
      return updatedOptions;
    });
  };
  const generateRandomTimesArray = () => {
    const count = 2;
    const randomTimesArray = [];
    for (let i = 0; i < count; i++) {
      // Generate random indices for hours and minutes
      const randomHourIndex = Math.floor(Math.random() * hoursForBumps.length);
      const randomMinuteIndex = Math.floor(
        Math.random() * minutesForBumps.length
      );

      // Select random hours and minutes using the generated indices
      let randomHour = hoursForBumps[randomHourIndex];
      let randomMinute = minutesForBumps[randomMinuteIndex];

      // Ensure that randomHour and randomMinute are always two-digit strings
      randomHour = randomHour < 10 ? `0${randomHour}` : randomHour.toString();
      randomMinute =
        randomMinute < 10 ? `0${randomMinute}` : randomMinute.toString();

      // Randomly select AM or PM
      const randomPeriod = Math.random() < 0.5 ? "AM" : "PM";

      // Construct the time string in "HH:MM AM/PM" format
      const randomTime = `${randomHour}:${randomMinute} ${randomPeriod}`;

      randomTimesArray.push(randomTime);
    }
    return randomTimesArray;
  };

  const calculateTotalScheduleBumps = (options) => {
    return options?.reduce((total, option) => total + option.bumps, 0);
  };

  const extractHour = (item) => {
    const hour = parseInt(item.split(":")[0], 10); // Extract the hour part and convert it to an integer
    return hour;
  };

  const extractMinutes = (item) => {
    const minutes = parseInt(item.split(":")[1].split(" ")[0], 10); // Extract the minutes part and convert it to an integer
    return minutes;
  };

  const extractFormat = (item) => {
    const period = item.split(" ")[1]; // Split the time string and extract the second part
    return period;
  };

  const formatHourAndMinutes = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const handleIncreaseBumpsHours = (item, index) => {
    const [time, periodInitial] = item.split(" ");
    let period = periodInitial; // Reassign to a let variable to allow changes
    let [hour, minute] = time.split(":").map(Number);
    hour += 1;
    if (hour === 12) {
      period = period === "AM" ? "PM" : "AM";
    } else if (hour > 12) {
      hour = 1;
    }
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const newTime = `${formattedHour}:${
      minute < 10 ? "0" : ""
    }${minute} ${period}`;
    setSelectedBumps((prevState) => {
      const newTimesArray = [...prevState.times];
      newTimesArray[index] = newTime;
      return {
        ...prevState,
        times: newTimesArray,
      };
    });
  };

  const handleIncreaseBumpsMinutes = (item, index) => {
    const [time, periodInitial] = item.split(" ");
    let period = periodInitial; // Reassign to a let variable to allow changes
    let [hour, minute] = time.split(":").map(Number);

    minute += 5; // Increment minutes by 15

    if (minute >= 60) {
      // If minutes exceed 60, increment hour and reset minutes
      hour += 1;
      minute -= 60;
    }

    if (hour === 12 && minute === 0) {
      // Toggle period if hour becomes 12 and minutes become 0
      period = period === "AM" ? "PM" : "AM";
    } else if (hour > 12) {
      // Reset hour to 1 if it exceeds 12
      hour = 1;
    }

    // Format hour and minutes
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;

    const newTime = `${formattedHour}:${formattedMinute} ${period}`;
    // Update selectedBumps state
    setSelectedBumps((prevState) => {
      const newTimesArray = [...prevState.times];
      newTimesArray[index] = newTime;
      return {
        ...prevState,
        times: newTimesArray,
      };
    });
  };

  const handleDecreaseBumpsMinutes = (item, index) => {
    const [time, periodInitial] = item.split(" ");
    let period = periodInitial; // Reassign to a let variable to allow changes
    let [hour, minute] = time.split(":").map(Number);

    minute -= 5; // Decrement minutes by 5

    if (minute < 0) {
      // If minutes go below 0, decrement hour and adjust minutes
      hour -= 1;
      minute += 60;
    }

    if (hour === 11 && minute === 55) {
      // Toggle period if hour becomes 11 and minutes become 55
      period = period === "AM" ? "PM" : "AM";
    } else if (hour < 1) {
      // Reset hour to 12 if it goes below 1
      hour = 12;
    }

    // Format hour and minutes
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;

    const newTime = `${formattedHour}:${formattedMinute} ${period}`;
    // Update selectedBumps state
    setSelectedBumps((prevState) => {
      const newTimesArray = [...prevState.times];
      newTimesArray[index] = newTime;
      return {
        ...prevState,
        times: newTimesArray,
      };
    });
  };

  const handleDecreaseBumpsHours = (item, index) => {
    const [time, periodInitial] = item.split(" ");
    let period = periodInitial;
    let [hour, minute] = time.split(":").map(Number);

    hour -= 1;

    if (hour === 0) {
      hour = 12;
      period = period === "AM" ? "PM" : "AM";
    }
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const newTime = `${formattedHour}:${
      minute < 10 ? "0" : ""
    }${minute} ${period}`;

    setSelectedBumps((prevState) => {
      const newTimesArray = [...prevState.times];
      newTimesArray[index] = newTime;
      return {
        ...prevState,
        times: newTimesArray,
      };
    });
  };

  const handleChangeFormat = (item, index) => {
    const [time, format] = item.split(" ");

    // Toggle the format between AM and PM
    const newFormat = format === "AM" ? "PM" : "AM";

    // Construct the new time string with the updated format
    const newTime = `${time} ${newFormat}`;

    setSelectedBumps((prevState) => {
      const newTimesArray = [...prevState.times];
      newTimesArray[index] = newTime;
      return {
        ...prevState,
        times: newTimesArray,
      };
    });
  };

  const handleCreateInitialBumpsSchedule = async (totalBumps, timesArray) => {
    if (totalBumps) {
      // const timesArray = generateRandomTimesArray()
      setSelectedBumps((prevState) => {
        return {
          ...prevState,
          times: timesArray,
        };
      });
      // const totalBumps = selectedBumps?.totalBumps
      let days = Math.floor(totalBumps / timesArray?.length);
      let matchedOption = bumpsDayOption
        .filter((option) => option.day <= days)
        .pop();
      const selectedIndex = bumpsDayOption.findIndex(
        (option) => option.day === matchedOption.day
      );
      setSelectedAndEnable(selectedIndex);
      const date = await handleGetDateForBumps();
      const arrayOfBumps = await handleSetBumpsSchedule(
        matchedOption.day,
        date,
        timesArray?.length
      );
      setSelectedBumps((prevState) => {
        return {
          ...prevState,
          selectedScheduleBumps: arrayOfBumps,
        };
      });
      setScheduleBumps(true);
    } else {
      const timesArray = generateRandomTimesArray();
      setSelectedBumps((prevState) => {
        return {
          ...prevState,
          times: timesArray,
        };
      });
      const totalBumps = selectedBumps?.totalBumps;
      let days = Math.floor(totalBumps / timesArray?.length);
      let matchedOption = bumpsDayOption
        .filter((option) => option.day <= days)
        .pop();
      const selectedIndex = bumpsDayOption.findIndex(
        (option) => option.day === matchedOption.day
      );
      setSelectedAndEnable(selectedIndex);
      const date = await handleGetDateForBumps();
      const arrayOfBumps = await handleSetBumpsSchedule(
        matchedOption.day,
        date,
        timesArray?.length
      );
      setSelectedBumps((prevState) => {
        return {
          ...prevState,
          selectedScheduleBumps: arrayOfBumps,
        };
      });
      setScheduleBumps(true);
    }
  };
  const setOnlyEnable = (index) => {
    setBumpsDayOption((prevOptions) => {
      const updatedOptions = prevOptions?.map((option, i) => ({
        ...option,
        // selected: i === index,
        enable: i <= index,
      }));
      return updatedOptions;
    });
  };

  const handleCalculateDaysSecond = (timesArray, totalBumps) => {
    let days = Math.floor(totalBumps / timesArray?.length);
    let matchedOption = bumpsDayOption
      .filter((option) => option.day <= days)
      .pop();
    const selectedIndex = bumpsDayOption.findIndex(
      (option) => option.day === matchedOption.day
    );
    setOnlyEnable(selectedIndex);
  };

  const managePageOneCost = () => {
    if (selectHour === "three") {
      return pageOnedata;
    } else if (selectHour === "five") {
      return pageTwodata;
    } else {
      return pageThreedata;
    }
  };

  const handleSetPageOne = (selectedSlot) => {
    if (
      item?.upgrades?.pageOne &&
      !isExpired(
        item?.upgrades?.pageOne?.endDate,
        item?.upgrades?.pageOne?.endTime
      )
    ) {
      // setSelecthour(selectedPageOneSlot?.hour)
      setTime("");
      setShowPageOneForEdit(false);
      const cost = managePageOneCost();
      setTotalCost((prevCost) => prevCost - selectedPageOneSlot?.cost);
      setTotalCost((prevCost) => prevCost + cost);
      setSelectedPageOneSlot((prevState) => {
        return {
          ...prevState,
          cost: cost,
          hour: selectHour,
          startDate: selectedSlot?.startDate,
          endDate: selectedSlot?.endDate,
          startTime: selectedSlot?.startTime,
          endTime: selectedSlot?.endTime,
        };
      });
    } else {
      const currentDate = new Date();
      const formattedDate = currentDate.toDateString();
      selectedSlot.date = formattedDate;
      if (selectHour === "three") {
        selectedSlot.hour = selectHour;
        selectedSlot.cost = pageOnedata;
        setSelectedPageOneSlot(selectedSlot);
        setTotalCost(totalCost + pageOnedata);
      } else if (selectHour === "five") {
        selectedSlot.cost = pageTwodata;
        selectedSlot.hour = selectHour;
        setSelectedPageOneSlot(selectedSlot);
        setTotalCost(totalCost + pageTwodata);
      } else {
        selectedSlot.hour = selectHour;
        selectedSlot.cost = pageThreedata;
        setSelectedPageOneSlot(selectedSlot);
        setTotalCost(totalCost + pageThreedata);
      }
    }
  };
  const handleEditPageOne = () => {
    if (
      item?.upgrades?.pageOne &&
      !isExpired(
        item?.upgrades?.pageOne?.endDate,
        item?.upgrades?.pageOne?.endTime
      )
    ) {
      setSelecthour(selectedPageOneSlot?.hour);
      setShowPageOneForEdit(true);
    } else {
      setSelectedPageOneSlot(null);
      setTotalCost(
        (prevTotalCost) => prevTotalCost - selectedPageOneSlot?.cost
      );
      setTime("");
    }
  };

  const handleRemovePageOne = () => {
    setPageone(false);
    setSelectedPageOneSlot(null);
    setOpenCondtionalModal(false);
    setTotalCost((prevTotalCost) => prevTotalCost - selectedPageOneSlot?.cost);
    setTime("");
  };

  const closeModal = () => {
    if (selectedPageOneSlot) {
      setTotalCost(
        (prevTotalCost) => prevTotalCost - selectedPageOneSlot?.cost
      );
      setSelectedPageOneSlot(null);
      handleAddThemeWithoutCondition(themeFiveHoursCost, "five");
      setOpenCondtionalModal(false);
      setPageone(false);
    } else {
      setTotalCost((prevCost) => prevCost - selectPageTwoSLot?.cost);
      setSelectPageTwoSLot(null);
      showPremiumeAd(false);
      setOpenCondtionalModal(false);
      setPageone(true);
      setTime("");
    }
  };

  const handleAddThemeWithoutCondition = (cost, time) => {
    setPremiumAdHour(time);
    showPremiumeAd(true);
    const currentDate = new Date();
    const formattedDate = currentDate.toDateString();
    const selectedTheme = { cost, hour: time, date: formattedDate };
    if (selectPageTwoSLot) {
      setTotalCost((prevTotalCost) => prevTotalCost - selectPageTwoSLot?.cost);
      setTotalCost((prevTotalCost) => prevTotalCost + cost);
      setSelectPageTwoSLot(selectedTheme);
    } else {
      setTotalCost((prevTotalCost) => prevTotalCost + cost);
      setSelectPageTwoSLot(selectedTheme);
    }
  };

  const handleAddTheme = async (cost, time) => {
    if (
      item?.upgrades?.premiumTheme &&
      !isExpired(
        item?.upgrades?.premiumTheme?.endDate,
        item?.upgrades?.premiumTheme?.endTime
      )
    ) {
      setPremiumAdHour(time);
      showPremiumeAd(true);
      const dates =
        time === "five"
          ? await getStartTimeAndReturnEndTime(
              selectPageTwoSLot?.startDate,
              selectPageTwoSLot?.startTime,
              5
            )
          : time === "three"
          ? await getStartTimeAndReturnEndTime(
              selectPageTwoSLot?.startDate,
              selectPageTwoSLot?.startTime,
              3
            )
          : await getStartTimeAndReturnEndTime(
              selectPageTwoSLot?.startDate,
              selectPageTwoSLot?.startTime,
              7
            );
      setTotalCost((prevTotalCost) => prevTotalCost - selectPageTwoSLot?.cost);
      setTotalCost((prevTotalCost) => prevTotalCost + cost);
      setSelectPageTwoSLot((prevState) => {
        return {
          ...prevState,
          cost: cost,
          hour: time,
          endDate: dates?.endDate,
          endTime: dates?.endTime,
        };
      });
    } else {
      setPageone(false);
      if (selectedPageOneSlot) {
        setConditionModalTitle(
          "You can’t have Love Ad and Page1 at the same time"
        );
        setConditionModalSubject(
          "If you add Love Ad, it will remove your Page1 upgrade.Are you sure you want to add the Love Ad?"
        );
        setConditionalModalBtnText("Yes, Add Love Ad anyway");
        setOpenCondtionalModal(true);
      } else {
        setPremiumAdHour(time);
        showPremiumeAd(true);
        const dates =
          time === "five"
            ? await getCurrentFormattedDate(5)
            : time === "three"
            ? await getCurrentFormattedDate(3)
            : await getCurrentFormattedDate(7);
        const currentDate = new Date();
        const formattedDate = currentDate.toDateString();
        const obj = { cost, hour: time, date: formattedDate };
        const selectedTheme = { ...obj, ...dates };
        if (selectPageTwoSLot) {
          setTotalCost(
            (prevTotalCost) => prevTotalCost - selectPageTwoSLot?.cost
          );
          setTotalCost((prevTotalCost) => prevTotalCost + cost);
          setSelectPageTwoSLot(selectedTheme);
        } else {
          setTotalCost((prevTotalCost) => prevTotalCost + cost);
          setSelectPageTwoSLot(selectedTheme);
        }
      }
    }
  };
  const handleAddMoreBumps = async (isTrue, index) => {
    if (isTrue) {
      setBumpsTab((prevState) => {
        return prevState.map((item, i) => ({
          ...item,
          selected: false,
        }));
      });
      const subtractCost =
        bumpsTab[index].cost * bumpsTab[index].qty.toFixed(2);
      const updatedCost = selectedBumps?.cost - subtractCost;
      setTotalCost((prevTotalCost) => prevTotalCost - selectedBumps?.cost);
      setTotalCost((prevTotalCost) => prevTotalCost + updatedCost);
      const totalBumps = selectedBumps?.totalBumps - bumpsTab[index]?.qty;
      handleCalculateDaysSecond(selectedBumps?.times, totalBumps);
      setSelectedBumps((prevState) => {
        return {
          ...prevState,
          totalBumps: totalBumps,
          cost: updatedCost,
        };
      });
    } else {
      const qty = bumpsTab?.find((item) => item.selected)?.qty;
      const cost = bumpsTab?.find((item) => item.selected)?.cost;
      if (qty) {
        const subtractCost = cost * qty.toFixed(2);
        const addCost = bumpsTab[index].cost * bumpsTab[index].qty.toFixed(2);
        const updatedCost = selectedBumps?.cost - subtractCost + addCost;
        setTotalCost((prevTotalCost) => prevTotalCost - selectedBumps?.cost);
        setTotalCost((prevTotalCost) => prevTotalCost + updatedCost);
        const totalBumps =
          selectedBumps?.totalBumps + bumpsTab[index]?.qty - qty;
        handleCalculateDaysSecond(selectedBumps?.times, totalBumps);
        setSelectedBumps((prevState) => {
          return {
            ...prevState,
            totalBumps: totalBumps,
            cost: updatedCost,
          };
        });
        setBumpsTab((prevState) => {
          return prevState.map((item, i) => ({
            ...item,
            selected: i === index ? true : false,
          }));
        });
      } else {
        const subtractCost =
          bumpsTab[index].cost * bumpsTab[index].qty.toFixed(2);
        const updatedCost = selectedBumps?.cost + subtractCost;
        const totalBumps = bumpsTab[index]?.qty + selectedBumps?.totalBumps;
        setTotalCost((prevTotalCost) => prevTotalCost - selectedBumps?.cost);
        setTotalCost((prevTotalCost) => prevTotalCost + updatedCost);
        handleCalculateDaysSecond(selectedBumps?.times, totalBumps);
        setSelectedBumps((prevState) => {
          return {
            ...prevState,
            totalBumps: totalBumps,
            cost: updatedCost,
          };
        });
        setBumpsTab((prevState) => {
          return prevState.map((item, i) => ({
            ...item,
            selected: i === index ? true : false,
          }));
        });
      }
    }
  };
  const handleAddBumps = async (index) => {
    if (item?.upgrades?.bumps) {
      const selected = bumpsTab[index].selected;
      const permission = checkForAddMoreBumps(index);
      if (permission) {
        handleAddMoreBumps(selected, index);
      }
    } else {
      if (selectedBumps) {
        handleCalculateDaysSecond(selectedBumps?.times, bumpsTab[index]?.qty);
        setShowbumps(true);
        setBumpsTab((prevState) => {
          return prevState.map((item, i) => ({
            ...item,
            selected: i === index ? true : false,
          }));
        });
        const cost = bumpsTab[index].cost * bumpsTab[index].qty.toFixed(2);
        const totalBumps = bumpsTab[index].qty;
        setTotalCost((prevTotalCost) => prevTotalCost - selectedBumps?.cost);
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        setSelectedBumps((prevState) => {
          return {
            ...prevState,
            cost,
            totalBumps,
            remainingBumps: totalBumps,
          };
        });
      } else {
        setShowbumps(true);
        setBumpsTab((prevState) => {
          return prevState.map((item, i) => ({
            ...item,
            selected: i === index ? true : false,
          }));
        });
        const cost = bumpsTab[index].cost * bumpsTab[index].qty.toFixed(2);
        const totalBumps = bumpsTab[index].qty;
        setTotalCost((prevTotalCost) => prevTotalCost + cost);
        const dataaa = {
          cost,
          totalBumps,
          selectedScheduleBumps: null,
          remainingBumps: totalBumps,
          times: [],
        };
        setSelectedBumps(dataaa);
      }
    }
  };

  const getStartTimeAndReturnEndTime = (startDate, startTime, hoursToAdd) => {
    const [startMonth, startDay, startYear] = startDate.split("/").map(Number);
    let [startHour, startMinute] = startTime.split(/[: ]/).map(Number);
    const ampm = startTime.split(" ")[1];

    // Convert to 24-hour format
    if (ampm === "PM" && startHour < 12) {
      startHour += 12;
    } else if (ampm === "AM" && startHour === 12) {
      startHour = 0;
    }

    // Create the start date object
    const startDateTime = new Date(
      startYear,
      startMonth - 1,
      startDay,
      startHour,
      startMinute
    );

    // Add the specified number of hours
    const futureDateTime = new Date(
      startDateTime.getTime() + hoursToAdd * 60 * 60 * 1000
    );

    // Format the end date
    const futureMonth = String(futureDateTime.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const futureDay = String(futureDateTime.getDate()).padStart(2, "0");
    const futureYear = futureDateTime.getFullYear();
    const futureFormattedDate = `${futureMonth}/${futureDay}/${futureYear}`;

    // Format the end time
    const futureHours = futureDateTime.getHours();
    const futureMinutes = String(futureDateTime.getMinutes()).padStart(2, "0");
    const ampmEnd = futureHours >= 12 ? "PM" : "AM";
    const endHours = String(
      futureHours % 12 === 0 ? 12 : futureHours % 12
    ).padStart(2, "0"); // Convert to 12-hour format
    const endTime = `${endHours}:${futureMinutes} ${ampmEnd}`;

    return {
      endDate: futureFormattedDate,
      endTime: endTime,
    };
  };

  const getCurrentFormattedDate = async (hoursToAdd) => {
    const currentDate = new Date();

    // Get the start date and time
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const currentDay = String(currentDate.getDate()).padStart(2, "0");
    const currentYear = currentDate.getFullYear();
    const currentHours = currentDate.getHours();
    const currentMinutes = String(currentDate.getMinutes()).padStart(2, "0");
    const ampmStart = currentHours >= 12 ? "PM" : "AM";
    const startHours = String(
      currentHours % 12 === 0 ? 12 : currentHours % 12
    ).padStart(2, "0"); // Convert to 12-hour format
    const startTime = `${startHours}:${currentMinutes} ${ampmStart}`;
    const currentFormattedDate = `${currentMonth}/${currentDay}/${currentYear}`;

    // Get the end date and time
    const futureDate = new Date(
      currentDate.getTime() + hoursToAdd * 60 * 60 * 1000
    );
    const futureMonth = String(futureDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const futureDay = String(futureDate.getDate()).padStart(2, "0");
    const futureYear = futureDate.getFullYear();
    const futureHours = futureDate.getHours();
    const futureMinutes = String(futureDate.getMinutes()).padStart(2, "0");
    const ampmEnd = futureHours >= 12 ? "PM" : "AM";
    const endHours = String(
      futureHours % 12 === 0 ? 12 : futureHours % 12
    ).padStart(2, "0"); // Convert to 12-hour format
    const endTime = `${endHours}:${futureMinutes} ${ampmEnd}`;
    const futureFormattedDate = `${futureMonth}/${futureDay}/${futureYear}`;

    return {
      startDate: currentFormattedDate,
      startTime: startTime,
      endDate: futureFormattedDate,
      endTime: endTime,
    };
  };

  const closeThemeAd = () => {
    if (selectPageTwoSLot) {
      setTotalCost((prevCost) => prevCost - selectPageTwoSLot?.cost);
      setSelectPageTwoSLot(null);
      showPremiumeAd(false);
    }
  };

  const handleRemoveBumps = async () => {
    handleRemoveScheduleBumps();
    setTotalCost((prevTotalCost) => prevTotalCost - selectedBumps?.cost);
    setSelectedBumps(null);
    setShowbumps(false);
    setScheduleBumps(false);
  };

  const handleRemoveScheduleBumps = () => {
    setSelectedBumps((prevState) => {
      const updatedSelectedScheduleBumps = null;
      const updatedTime = [];
      return {
        ...prevState,
        selectedScheduleBumps: updatedSelectedScheduleBumps,
        times: updatedTime,
      };
    });
    setScheduleBumps(false);
  };

  const formatedCost = (total, previous) => {
    const formattedCost = (total - previous).toFixed(2);
    return formattedCost;
  };

  const createTodayAndTomowworDate = () => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const todayMonth = now.getMonth() + 1;
    const todayDay = now.getDate();
    const todayYear = now.getFullYear();
    const todayDate = `${todayMonth}/${todayDay}/${todayYear}`;

    const tomorrowMonth = tomorrow.getMonth() + 1;
    const tomorrowDay = tomorrow.getDate();
    const tomorrowYear = tomorrow.getFullYear();
    const tomorrowDate = `${tomorrowMonth}/${tomorrowDay}/${tomorrowYear}`;
    return {
      todayDate,
      tomorrowDate,
    };
  };

  const handleGetBookedSlot = async () => {
    try {
      const date = createTodayAndTomowworDate();
      const response = await getBookedSlot(date, token);
      if (response.success) {
        getCurrentTime(response.bookedSlot);
      } else {
        getCurrentTime(response.bookedSlot);
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentTime = (bookedSlot) => {
    const now = new Date();
    const minutes = now.getMinutes();
    const date = now.getDate();
    let roundedMinutes;
    if (minutes < 30) {
      roundedMinutes = 30;
    } else {
      roundedMinutes = 0;
      now.setHours(now.getHours() + 1);
    }
    now.setMinutes(roundedMinutes);
    const options = {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const formattedTime = now.toLocaleTimeString("en-US", options);
    createThreeHoursTimeSlot(formattedTime, bookedSlot);
    createFiveHoursTimeSlot(formattedTime, bookedSlot);
    createSevenHoursTimeSlot(formattedTime, bookedSlot);
  };

  const createThreeHoursTimeSlot = async (timeString, bookedSlot) => {
    // Parse the time string into a Date object
    const now = new Date();
    const [hours, minutes] = timeString.split(":")?.map((str) => parseInt(str));
    const isPM = timeString.includes("PM");
    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours + (isPM && hours !== 12 ? 12 : 0), // Adjusting hours for PM
      minutes
    );
    // Initialize an array to store the time slots
    let timeSlots = [];
    // Define the duration of each time slot in milliseconds (3 hours)
    const slotDuration = 3 * 60 * 60 * 1000;
    // Define the gap between each time slot in milliseconds (30 minutes)
    const gap = 30 * 60 * 1000;
    // Calculate the start time of the first time slot
    let slotStartTime = today;
    // Calculate the end time of the last time slot (24 hours from now)
    const endTime = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    // Iterate until we reach the end time
    while (slotStartTime < endTime) {
      // Calculate the end time of the current time slot
      const slotEndTime = new Date(slotStartTime.getTime() + slotDuration);
      // Format start and end times without seconds
      const startTimeString = slotStartTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const endTimeString = slotEndTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      // Set start date based on current time
      let startDate = slotStartTime;
      let endDate = new Date(startDate);
      // Check if end time crosses midnight
      if (slotEndTime.getDate() !== startDate.getDate()) {
        // End time crosses midnight, update end date
        endDate.setDate(endDate.getDate() + 1);
      }
      // Push the formatted time slot object into the array
      timeSlots.push({
        startDate: startDate.toLocaleDateString(),
        endDate: endDate.toLocaleDateString(),
        startTime: startTimeString,
        endTime: endTimeString,
      });
      // Move to the start time of the next slot, 30 minutes after the current slot starts
      slotStartTime = new Date(slotStartTime.getTime() + gap);
    }
    // Filter out time slots that overlap with booked slots
    timeSlots = timeSlots.filter((slot) => {
      const slotStartTime = new Date(slot.startDate + " " + slot.startTime);
      const slotEndTime = new Date(slot.endDate + " " + slot.endTime);
      return !bookedSlot.some((booked) => {
        const bookedStartTime = new Date(
          booked.startDate + " " + booked.startTime
        );
        const bookedEndTime = new Date(booked.endDate + " " + booked.endTime);
        return (
          (slotStartTime >= bookedStartTime && slotStartTime < bookedEndTime) ||
          (slotEndTime > bookedStartTime && slotEndTime <= bookedEndTime) ||
          (slotStartTime <= bookedStartTime && slotEndTime >= bookedEndTime)
        );
      });
    });
    setImmediatelyPageOneThreeHours(timeSlots[0]);
    const data = divideTimeSlots(timeSlots);
    setMorningdata(data?.morning);
    setAfternoonData(data?.afternoon);
    setEveningData(data?.evening);
    setLatenightData(data?.lateNight);
  };

  const createFiveHoursTimeSlot = async (timeString, bookedSlot) => {
    // Parse the time string into a Date object
    const now = new Date();
    const [hours, minutes] = timeString.split(":")?.map((str) => parseInt(str));
    const isPM = timeString.includes("PM");
    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours + (isPM && hours !== 12 ? 12 : 0), // Adjusting hours for PM
      minutes
    );
    // Initialize an array to store the time slots
    let timeSlots = [];
    // Define the duration of each time slot in milliseconds (3 hours)
    const slotDuration = 5 * 60 * 60 * 1000;
    // Define the gap between each time slot in milliseconds (30 minutes)
    const gap = 30 * 60 * 1000;
    // Calculate the start time of the first time slot
    let slotStartTime = today;
    // Calculate the end time of the last time slot (24 hours from now)
    const endTime = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    // Iterate until we reach the end time
    while (slotStartTime < endTime) {
      // Calculate the end time of the current time slot
      const slotEndTime = new Date(slotStartTime.getTime() + slotDuration);
      // Format start and end times without seconds
      const startTimeString = slotStartTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const endTimeString = slotEndTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      // Set start date based on current time
      let startDate = slotStartTime;
      let endDate = new Date(startDate);
      // Check if end time crosses midnight
      if (slotEndTime.getDate() !== startDate.getDate()) {
        // End time crosses midnight, update end date
        endDate.setDate(endDate.getDate() + 1);
      }
      // Push the formatted time slot object into the array
      timeSlots.push({
        startDate: startDate.toLocaleDateString(),
        endDate: endDate.toLocaleDateString(),
        startTime: startTimeString,
        endTime: endTimeString,
      });
      // Move to the start time of the next slot, 30 minutes after the current slot starts
      slotStartTime = new Date(slotStartTime.getTime() + gap);
    }
    // Filter out time slots that overlap with booked slots
    timeSlots = timeSlots.filter((slot) => {
      const slotStartTime = new Date(slot.startDate + " " + slot.startTime);
      const slotEndTime = new Date(slot.endDate + " " + slot.endTime);
      return !bookedSlot.some((booked) => {
        const bookedStartTime = new Date(
          booked.startDate + " " + booked.startTime
        );
        const bookedEndTime = new Date(booked.endDate + " " + booked.endTime);
        return (
          (slotStartTime >= bookedStartTime && slotStartTime < bookedEndTime) ||
          (slotEndTime > bookedStartTime && slotEndTime <= bookedEndTime) ||
          (slotStartTime <= bookedStartTime && slotEndTime >= bookedEndTime)
        );
      });
    });
    setImmediatelyPageOneFiveHours(timeSlots[0]);
    const data = divideTimeSlots(timeSlots);
    setFiveHoursSlotMorning(data?.morning);
    setFiveHoursSlotAfternoon(data?.afternoon);
    setFiveHoursSlotEvening(data?.evening);
    setFiveHoursSlotLateNight(data?.lateNight);
  };
  const createSevenHoursTimeSlot = async (timeString, bookedSlot) => {
    // Parse the time string into a Date object
    const now = new Date();
    const [hours, minutes] = timeString.split(":")?.map((str) => parseInt(str));
    const isPM = timeString.includes("PM");
    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours + (isPM && hours !== 12 ? 12 : 0), // Adjusting hours for PM
      minutes
    );
    // Initialize an array to store the time slots
    let timeSlots = [];
    // Define the duration of each time slot in milliseconds (3 hours)
    const slotDuration = 7 * 60 * 60 * 1000;
    // Define the gap between each time slot in milliseconds (30 minutes)
    const gap = 30 * 60 * 1000;
    // Calculate the start time of the first time slot
    let slotStartTime = today;
    // Calculate the end time of the last time slot (24 hours from now)
    const endTime = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    // Iterate until we reach the end time
    while (slotStartTime < endTime) {
      // Calculate the end time of the current time slot
      const slotEndTime = new Date(slotStartTime.getTime() + slotDuration);
      // Format start and end times without seconds
      const startTimeString = slotStartTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const endTimeString = slotEndTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      // Set start date based on current time
      let startDate = slotStartTime;
      let endDate = new Date(startDate);
      // Check if end time crosses midnight
      if (slotEndTime.getDate() !== startDate.getDate()) {
        // End time crosses midnight, update end date
        endDate.setDate(endDate.getDate() + 1);
      }
      // Push the formatted time slot object into the array
      timeSlots.push({
        startDate: startDate.toLocaleDateString(),
        endDate: endDate.toLocaleDateString(),
        startTime: startTimeString,
        endTime: endTimeString,
      });
      // Move to the start time of the next slot, 30 minutes after the current slot starts
      slotStartTime = new Date(slotStartTime.getTime() + gap);
    }
    // Filter out time slots that overlap with booked slots
    timeSlots = timeSlots.filter((slot) => {
      const slotStartTime = new Date(slot.startDate + " " + slot.startTime);
      const slotEndTime = new Date(slot.endDate + " " + slot.endTime);
      return !bookedSlot.some((booked) => {
        const bookedStartTime = new Date(
          booked.startDate + " " + booked.startTime
        );
        const bookedEndTime = new Date(booked.endDate + " " + booked.endTime);
        return (
          (slotStartTime >= bookedStartTime && slotStartTime < bookedEndTime) ||
          (slotEndTime > bookedStartTime && slotEndTime <= bookedEndTime) ||
          (slotStartTime <= bookedStartTime && slotEndTime >= bookedEndTime)
        );
      });
    });
    setImmediatelyPageOneSevenHours(timeSlots[0]);
    const data = divideTimeSlots(timeSlots);
    setSevenHoursSlotMorning(data?.morning);
    setSevenHoursSlotAfternoon(data?.afternoon);
    setSevenHoursSlotEvening(data?.evening);
    setSevenHoursSlotLateNight(data?.lateNight);
  };
  const divideTimeSlots = (timeSlots) => {
    const morning = [];
    const afternoon = [];
    const evening = [];
    const lateNight = [];
    timeSlots.forEach((slot) => {
      const startTime = new Date(slot.startDate + " " + slot.startTime);
      const endTime = new Date(slot.endDate + " " + slot.endTime);
      if (
        (startTime.getHours() >= 6 && startTime.getHours() < 12) ||
        (startTime.getHours() === 12 && startTime.getMinutes() === 0)
      ) {
        morning.push(slot);
      } else if (
        (startTime.getHours() >= 12 && startTime.getHours() < 18) ||
        (startTime.getHours() === 18 && startTime.getMinutes() === 0)
      ) {
        afternoon.push(slot);
      } else if (
        (startTime.getHours() >= 18 && startTime.getHours() < 24) ||
        (startTime.getHours() === 0 && startTime.getMinutes() === 0)
      ) {
        evening.push(slot);
      } else {
        lateNight.push(slot);
      }
    });
    return { morning, afternoon, evening, lateNight };
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="text-white flex flex-col items-center mx-auto mt-10 md:max-w-[80%] lg:max-w-[80%] xl:max-w-[60%] max-w-[95%]">
      {successModal && (
        <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="text-white rounded-xl py-4 bg-purplegray flex items-center justify-center px-5 flex flex-col gap-3">
            <img src={images.tickmarkdone} className="w-[50%]" />
            <div className="font-bold text-xl">congratulations!</div>
            <div className="font-semibold text-center text-md">
              Chnages updates successfully
            </div>
            <div
              className="bg-purple rounded-md px-4 py-2 cursor-pointer active:opacity-50"
              onClick={() => {
                setSuccessModal(false);
                navigate("/myads");
              }}
            >
              Done
            </div>
          </div>
        </div>
      )}
      <div className="w-full">
        {[
          "availableNow",
          "bumps",
          "carousel",
          "emoji",
          "featuredAdd",
          "pageOne",
          "premiumTheme",
        ].every((key) => item?.upgrades[key] === null) ? (
          <div className="text-center md:text-left">No Upgrades chosen</div>
        ) : (
          <div className="flex flex-col w-full  gap-2">
            {expirePageOne && (
              <div className=" bg-footercolor border border-disabledBorder flex flex-col justify-between p-5 text-sm md:text-lg font-semibold">
                <div className="flex w-full justify-between">
                  <div className="text-disabledBorder">Expire Page 1 :</div>

                  <div className="text-disabledBorder">
                    {`$ ${expirePageOne?.cost}`}
                  </div>
                </div>
                <div className="text-disabledText">
                  {`  ${
                    expirePageOne?.hour === "five"
                      ? "5"
                      : expirePageOne?.hour === "three"
                      ? "3"
                      : "7"
                  }`}{" "}
                  {`Hours from (${expirePageOne?.startDate} , ${expirePageOne?.startTime}) - (${expirePageOne?.endDate} , ${expirePageOne?.endTime})`}
                </div>
              </div>
            )}
            {expireBumps && (
              <div className=" bg-footercolor border border-disabledBorder flex flex-col justify-between p-5 text-sm md:text-lg font-semibold">
                <div className="flex w-full justify-between">
                  <div className="text-disabledBorder">Expire Bumps :</div>

                  {/* <div className="text-disabledBorder">
                    {`$ ${expireBumps?.cost}`}
                  </div> */}
                </div>
                <div className="text-disabledText">
                  {`${calculateTotalScheduleBumps(expireBumps)} Expire bumps`}
                  {/* {`${
                    selectedBumps?.totalBumps
                  } Bumps Package (${calculateTotalScheduleBumps(
                    selectedBumps?.selectedScheduleBumps
                  )} Scheduled Bumps)`} */}
                </div>
              </div>
            )}
            {expireAvailableNow && (
              <div className=" bg-footercolor border border-disabledBorder flex flex-col justify-between p-5 text-sm md:text-lg font-semibold">
                <div className="flex w-full justify-between">
                  <div className="text-disabledBorder">
                    Expire Available Now :
                  </div>

                  <div className="text-disabledBorder">
                    {`$ ${expireAvailableNow?.cost}`}
                  </div>
                </div>
                <div className="text-disabledText">
                  {`  ${
                    expireAvailableNow?.hour === "one"
                      ? "1"
                      : expireAvailableNow?.hour === "two"
                      ? "2"
                      : expireAvailableNow?.hour === "three"
                      ? "3"
                      : "4"
                  }`}{" "}
                  {`Hours from (${expireAvailableNow?.startDate} , ${expireAvailableNow?.startTime}) - (${expireAvailableNow?.endDate} , ${expireAvailableNow?.endTime})`}
                </div>
              </div>
            )}
            {expireTheme && (
              <div className=" bg-footercolor border border-disabledBorder flex flex-col justify-between p-5  text-sm md:text-lg font-semibold">
                <div className="flex w-full justify-between">
                  <div className="text-disabledBorder">Expire Theme :</div>

                  <div className="text-disabledBorder">
                    {`$ ${expireTheme?.cost}`}
                  </div>
                </div>
                <div className="text-disabledText">
                  {`  ${
                    expireTheme?.hour === "five"
                      ? "5"
                      : expireTheme?.hour === "three"
                      ? "3"
                      : "7"
                  }`}{" "}
                  {`Hours from (${expireTheme?.startDate} , ${expireTheme?.startTime}) - (${expireTheme?.endDate} , ${expireTheme?.endTime})`}
                </div>
              </div>
            )}
            {expireTheme && (
              <div className=" bg-footercolor border border-disabledBorder flex flex-col justify-between p-5 text-sm md:text-lg font-semibold">
                <div className="flex w-full justify-between">
                  <div className="text-disabledBorder">Expire Theme :</div>

                  <div className="text-disabledBorder">
                    {`$ ${expireTheme?.cost}`}
                  </div>
                </div>
                <div className="text-disabledText">
                  {`  ${
                    expireTheme?.hour === "five"
                      ? "5"
                      : expireTheme?.hour === "three"
                      ? "3"
                      : "7"
                  }`}{" "}
                  {`Hours from (${expireTheme?.startDate} , ${expireTheme?.startTime}) - (${expireTheme?.endDate} , ${expireTheme?.endTime})`}
                </div>
              </div>
            )}
            {expireCarousel && (
              <div className=" bg-footercolor border border-disabledBorder flex flex-col justify-between p-5  text-sm md:text-lg font-semibold">
                <div className="flex w-full justify-between">
                  <div className="text-disabledBorder">Carousel Theme :</div>
                  <div className="text-disabledBorder">
                    {`$ ${expireCarousel?.cost}`}
                  </div>
                </div>
                <div className="text-disabledText">
                  {`  ${
                    expireCarousel?.hour === "three"
                      ? "3"
                      : expireCarousel?.hour === "six"
                      ? "6"
                      : "9"
                  }`}{" "}
                  {`Hours from (${expireCarousel?.startDate} , ${expireCarousel?.startTime}) - (${expireCarousel?.endDate} , ${expireCarousel?.endTime})`}
                </div>
              </div>
            )}
            {expireFearreAd && (
              <div className=" bg-footercolor border border-disabledBorder flex flex-col justify-between p-5 text-sm md:text-lg font-semibold">
                <div className="flex w-full justify-between">
                  <div className="text-disabledBorder">Featured Ad :</div>
                  <div className="text-disabledBorder">
                    {`$ ${expireFearreAd?.cost}`}
                  </div>
                </div>
                <div className="text-disabledText">
                  {`${
                    expireFearreAd?.hour === "three"
                      ? "3"
                      : expireFearreAd?.hour === "six"
                      ? "6"
                      : "9"
                  }`}{" "}
                  {`Hours from (${expireFearreAd?.startDate} , ${expireFearreAd?.startTime}) - (${expireFearreAd?.endDate} , ${expireFearreAd?.endTime})`}
                </div>
              </div>
            )}
            {expireEmoji && (
              <div className=" bg-footercolor border border-disabledBorder flex flex-col justify-between p-5 text-sm md:text-lg font-semibold">
                <div className="flex w-full justify-between">
                  <div className="text-disabledBorder">Emoji</div>
                  <div className="text-disabledBorder">
                    {`$ ${expireEmoji?.cost}`}
                  </div>
                </div>
                <div className="text-disabledText">
                  {`${
                    expireEmoji?.hour === "three"
                      ? "3"
                      : expireEmoji?.hour === "six"
                      ? "6"
                      : "9"
                  }`}{" "}
                  {`Hours from (${expireEmoji?.startDate} , ${expireEmoji?.startTime}) - (${expireEmoji?.endDate} , ${expireEmoji?.endTime})`}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="lg:w-full w-full mt-8">
        <div className="bg-weirdgray rounded-lg">
          <div className="flex p-3 items-center gap-4">
            <div className="text-white lg:text-2xl md:text-xl sm:text-sm text-sm">
              Choose Your Upgrades
            </div>
          </div>
          <div className="bg-buttoncolor flex text-white flex flex-col lg:flex-row gap-2 py-4">
            {/* BOOSTS CONTENT */}
            <div className="w-full flex flex-col gap-4 px-2">
              <div className="w-full shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] p-4">
                <div
                  className="cursor-pointer active:opacity-50"
                  onClick={() => hideBoosts(boost === "boost" ? "" : "boost")}
                >
                  <div className="lg:text-2xl md:text-xl sm:text-sm text-sm font-semibold cursor-pointer ">
                    Boosts
                  </div>
                  <div className="text-whitepink  opacity-80 lg:text-base md:text-base sm:text-sm text-xs">
                    Upgrades that move your ad to the top of the list
                  </div>
                </div>
                {boost === "boost" && (
                  <div>
                    {(!item?.upgrades?.premiumTheme ||
                      isExpired(
                        item?.upgrades?.premiumTheme?.endDate,
                        item?.upgrades?.premiumTheme?.endTime
                      )) && (
                      <div className="pt-5 ">
                        <div className="gap-20 flex w-full">
                          <div className="lg:w-90p w-full ">
                            <div className="flex flex-col w-full ">
                              <div className="bg-purple w-full pl-3 md:pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                                page 1
                              </div>
                              <div className="flex flex-col ">
                                <div className="border p-3 md:p-10">
                                  {selectedPageOneSlot === null ? (
                                    <div className="pb-4 lg:text-base md:text-base sm:text-sm text-xs flex flex-row items-center justify-between">
                                      Automatically keeps your ad on Page 1!
                                      {pageOne && (
                                        <div
                                          className="px-2 py-1 text-xs cursor-pointer border flex md:hidden items-center justify-center"
                                          onClick={() => {
                                            setPageone(false);
                                            setTime("");
                                          }}
                                        >
                                          X
                                        </div>
                                      )}
                                    </div>
                                  ) : null}

                                  <div>
                                    {selectedPageOneSlot === null ? (
                                      <div>
                                        {pageOne ? (
                                          <div className="flex flex-col">
                                            <div className="flex gap-2 w-full">
                                              <div className="flex gap-2 w-full">
                                                <div
                                                  className={
                                                    selectHour === "three"
                                                      ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                      : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                  }
                                                  onClick={() =>
                                                    setSelecthour("three")
                                                  }
                                                >
                                                  3 hours
                                                </div>
                                                <div
                                                  className={
                                                    selectHour === "five"
                                                      ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                      : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                  }
                                                  onClick={() =>
                                                    setSelecthour("five")
                                                  }
                                                >
                                                  5 hours
                                                </div>
                                                <div
                                                  className={
                                                    selectHour === "seven"
                                                      ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                      : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                  }
                                                  onClick={() =>
                                                    setSelecthour("seven")
                                                  }
                                                >
                                                  7 hours
                                                </div>
                                              </div>
                                              <div
                                                className=" h-13 w-16 cursor-pointer border hidden md:flex items-center justify-center"
                                                onClick={() => {
                                                  setPageone(false);
                                                  setTime("");
                                                }}
                                              >
                                                X
                                              </div>
                                            </div>
                                            <div className="mt-10 flex flex-col gap-4 ">
                                              <div
                                                onClick={() => {
                                                  if (selectHour === "three") {
                                                    handleSetPageOne(
                                                      immediatelyPageOneThreeHours
                                                    );
                                                  } else if (
                                                    selectHour === "five"
                                                  ) {
                                                    handleSetPageOne(
                                                      immediatelyPageOneFiveHours
                                                    );
                                                  } else {
                                                    handleSetPageOne(
                                                      immediatelyPageOneSevenHours
                                                    );
                                                  }
                                                }}
                                                className=" border h-24 cursor-pointer justify-between px-4 active:opacity-50 items-center  flex  "
                                              >
                                                <div className="text-sm flex flex-col ">
                                                  <div className="font-bold">
                                                    Now
                                                  </div>
                                                  <div className="flex ">
                                                    Activates immediately after
                                                    payment
                                                    <div className="text-purple pl-1">
                                                      {selectHour === "three"
                                                        ? pageOnedata
                                                        : selectHour === "five"
                                                        ? pageTwodata
                                                        : selectHour === "seven"
                                                        ? pageThreedata
                                                        : null}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="text-2xl ml-1">
                                                  +
                                                </div>
                                              </div>
                                              <div
                                                className="border h-auto py-6 cursor-pointer justify-between flex-col   px-4
                                           flex  "
                                              >
                                                <div
                                                  className="flex  w-full justify-between"
                                                  onClick={() =>
                                                    setTime(
                                                      time === "morning"
                                                        ? ""
                                                        : "morning"
                                                    )
                                                  }
                                                >
                                                  <div className="text-sm">
                                                    <div className="font-bold">
                                                      Morning
                                                    </div>
                                                    <div className="flex gap-1">
                                                      {`6am - 12pm -   ${
                                                        selectHour === "three"
                                                          ? morningData?.length
                                                          : selectHour ===
                                                            "five"
                                                          ? fiveHoursSlotMorning?.length
                                                          : selectHour ===
                                                            "seven"
                                                          ? sevenHoursSlotMorning.length
                                                          : null
                                                      } slots Available from `}
                                                      <div className="text-purple">
                                                        {selectHour === "three"
                                                          ? pageOnedata
                                                          : selectHour ===
                                                            "five"
                                                          ? pageTwodata
                                                          : selectHour ===
                                                            "seven"
                                                          ? pageThreedata
                                                          : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="flex items-center  ">
                                                    <img
                                                      src={images.dropdownarrow}
                                                      alt=""
                                                      className="h-4 w-6"
                                                    />
                                                  </div>
                                                </div>
                                                {selectHour === "three" ? (
                                                  <div>
                                                    {time === "morning" && (
                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                        {morningData?.map(
                                                          (item, index) => (
                                                            <div
                                                              key={index}
                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2 "
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : selectHour === "five" ? (
                                                  <div>
                                                    {time === "morning" && (
                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                        {fiveHoursSlotMorning?.map(
                                                          (item, index) => (
                                                            <div
                                                              key={index}
                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2 "
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : selectHour === "seven" ? (
                                                  <div>
                                                    {time === "morning" && (
                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                        {sevenHoursSlotMorning?.map(
                                                          (item, index) => (
                                                            <div
                                                              key={index}
                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2                                                                                "
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : null}
                                              </div>
                                              <div className="border py-4 cursor-pointer  justify-center px-4 flex flex-col  ">
                                                <div
                                                  className="flex  w-full justify-between"
                                                  onClick={() =>
                                                    setTime(
                                                      time === "afternoon"
                                                        ? ""
                                                        : "afternoon"
                                                    )
                                                  }
                                                >
                                                  <div className="text-sm">
                                                    <div className="font-bold">
                                                      Afternoon
                                                    </div>
                                                    <div className="flex gap-1">
                                                      12pm - 6pm -
                                                      {selectHour === "three"
                                                        ? afterNoondata?.length
                                                        : selectHour === "five"
                                                        ? fiveHoursSlotAfternoon?.length
                                                        : selectHour === "seven"
                                                        ? sevenHoursSlotAfternoon.length
                                                        : null}{" "}
                                                      slots Available from
                                                      <div className="text-purple">
                                                        {selectHour === "three"
                                                          ? pageOnedata
                                                          : selectHour ===
                                                            "five"
                                                          ? pageTwodata
                                                          : selectHour ===
                                                            "seven"
                                                          ? pageThreedata
                                                          : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="flex items-center w-6 ">
                                                    <img
                                                      src={images.dropdownarrow}
                                                      alt=""
                                                      className="h-4"
                                                    />
                                                  </div>
                                                </div>
                                                {selectHour === "three" ? (
                                                  <div>
                                                    {time === "afternoon" && (
                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                        {afterNoondata?.map(
                                                          (item, index) => (
                                                            <div
                                                              className="bg-slightydarkerBackgorund  justify-center cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                              key={index}
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : selectHour === "five" ? (
                                                  <div>
                                                    {time === "afternoon" && (
                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                        {fiveHoursSlotAfternoon?.map(
                                                          (item, index) => (
                                                            <div
                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                              key={index}
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : selectHour === "seven" ? (
                                                  <div>
                                                    {time === "afternoon" && (
                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                        {sevenHoursSlotAfternoon?.map(
                                                          (item, index) => (
                                                            <div
                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                              key={index}
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : null}
                                              </div>
                                              <div className=" border py-4 cursor-pointer justify-center px-4 flex flex-col  ">
                                                <div
                                                  className="flex  w-full justify-between"
                                                  onClick={() =>
                                                    setTime(
                                                      time === "evening"
                                                        ? ""
                                                        : "evening"
                                                    )
                                                  }
                                                >
                                                  <div className="text-sm">
                                                    <div className="font-bold">
                                                      Evenings
                                                    </div>
                                                    <div className="flex gap-1">
                                                      6pm - 12am -{" "}
                                                      {selectHour === "three"
                                                        ? eveningData?.length
                                                        : selectHour === "five"
                                                        ? fiveHoursSlotEvening?.length
                                                        : selectHour === "seven"
                                                        ? sevenHoursSlotEvening.length
                                                        : null}{" "}
                                                      slots Available from
                                                      <div className="text-purple">
                                                        {selectHour === "three"
                                                          ? pageOnedata
                                                          : selectHour ===
                                                            "five"
                                                          ? pageTwodata
                                                          : selectHour ===
                                                            "seven"
                                                          ? pageThreedata
                                                          : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="flex items-center w-6 ">
                                                    <img
                                                      src={images.dropdownarrow}
                                                      alt=""
                                                      className="h-4"
                                                    />
                                                  </div>
                                                </div>
                                                {selectHour === "three" ? (
                                                  <div>
                                                    {time === "evening" && (
                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                        {eveningData?.map(
                                                          (item, index) => (
                                                            <div
                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                              key={index}
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : selectHour === "five" ? (
                                                  <div>
                                                    {time === "evening" && (
                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                        {fiveHoursSlotEvening?.map(
                                                          (item, index) => (
                                                            <div
                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2 "
                                                              key={index}
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : selectHour === "seven" ? (
                                                  <div>
                                                    {time === "evening" && (
                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                        {sevenHoursSlotEvening?.map(
                                                          (item, index) => (
                                                            <div
                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2                                                                                "
                                                              key={index}
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : null}
                                              </div>
                                              <div className=" border py-4 cursor-pointer justify-center px-4 flex flex-col  ">
                                                <div
                                                  className="flex  w-full justify-between"
                                                  onClick={() =>
                                                    setTime(
                                                      time === "night"
                                                        ? ""
                                                        : "night"
                                                    )
                                                  }
                                                >
                                                  <div className="text-sm">
                                                    <div className="font-bold">
                                                      Late Night
                                                    </div>
                                                    <div className="flex gap-1">
                                                      12am - 6am -{" "}
                                                      {selectHour === "three"
                                                        ? lateNightdata?.length
                                                        : selectHour === "five"
                                                        ? fiveHoursSlotLateNight?.length
                                                        : selectHour === "seven"
                                                        ? sevenHoursSlotLateNight.length
                                                        : null}{" "}
                                                      slots Available from
                                                      <div className="text-purple">
                                                        {selectHour === "three"
                                                          ? pageOnedata
                                                          : selectHour ===
                                                            "five"
                                                          ? pageTwodata
                                                          : selectHour ===
                                                            "seven"
                                                          ? pageThreedata
                                                          : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="flex items-center w-6 ">
                                                    <img
                                                      src={images.dropdownarrow}
                                                      alt=""
                                                      className="h-4"
                                                    />
                                                  </div>
                                                </div>

                                                {selectHour === "three" ? (
                                                  <div>
                                                    {time === "night" && (
                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                        {lateNightdata?.map(
                                                          (item, index) => (
                                                            <div
                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                              key={index}
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : selectHour === "five" ? (
                                                  <div>
                                                    {time === "night" && (
                                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                        {fiveHoursSlotLateNight?.map(
                                                          (item, index) => (
                                                            <div
                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                              key={index}
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : selectHour === "seven" ? (
                                                  <div>
                                                    {time === "night" && (
                                                      <div
                                                        className=" grid grid-cols-2 md:grid-cols-3 gap-4 mt-4
                                                        "
                                                      >
                                                        {sevenHoursSlotLateNight?.map(
                                                          (item, index) => (
                                                            <div
                                                              className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2
                                                                "
                                                              key={index}
                                                              onClick={() => {
                                                                handleSetPageOne(
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                {`${item?.startTime} - ${item?.endTime}`}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="bg-purple mx-auto md:mx-0 text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full  h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50
                          lg:text-sm md:text-sm sm:text-sm text-xxs"
                                            onClick={() => {
                                              if (selectPageTwoSLot) {
                                                setConditionModalTitle(
                                                  "You can’t have Page1 and Love Ad at the same time"
                                                );
                                                setConditionModalSubject(
                                                  "If you add Page1, it will remove yourLove Ad. Are you sure you want to add Page1?"
                                                );
                                                setConditionalModalBtnText(
                                                  "Yes, Add Page1 anyway"
                                                );
                                                setOpenCondtionalModal(true);
                                                setSelecthour("five");
                                              } else {
                                                setPageone(true);
                                                setSelecthour("five");
                                              }
                                            }}
                                          >
                                            Add Now from CAD $1.631/hour
                                            <img
                                              src={images.Vector4}
                                              alt=""
                                              className="lg:w-2 lg:h-2 w-auto h-2 "
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="w-full">
                                        {selectedPageOneSlot && (
                                          <div className="shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-full h-auto p-2 md:p-5 text-xxs sm:text-sm">
                                            <div className="flex flex-col border-b pb-2">
                                              <div>{`${selectedPageOneSlot?.startTime} - ${selectedPageOneSlot?.endTime}`}</div>
                                              <div className="flex gap-2 items-end">
                                                <div>{`Guaranteed to stay on Page 1 for ${
                                                  selectedPageOneSlot?.hour ===
                                                  "five"
                                                    ? `5`
                                                    : selectedPageOneSlot?.hour ===
                                                      "three"
                                                    ? `3`
                                                    : `7`
                                                }  hours.`}</div>
                                                <div className="whitespace-nowrap">{`$ ${selectedPageOneSlot?.cost}`}</div>
                                              </div>
                                              ``{" "}
                                            </div>
                                            <div className="flex w-full gap-10 pt-2">
                                              {!showPageOneForEdit && (
                                                <div
                                                  className="cursor-pointer active:opacity-50 items-center flex gap-2"
                                                  onClick={handleEditPageOne}
                                                >
                                                  <img
                                                    src={images.whiteSettings}
                                                    alt=""
                                                    className="w-4 h-4"
                                                  />
                                                  <div>Edit</div>
                                                </div>
                                              )}
                                              {(!item?.upgrades?.pageOne ||
                                                isExpired(
                                                  item?.upgrades?.pageOne
                                                    ?.endDate,
                                                  item?.upgrades?.pageOne
                                                    ?.endTime
                                                )) && (
                                                <div
                                                  className="cursor-pointer active:opacity-50 items-center flex gap-2"
                                                  onClick={handleRemovePageOne}
                                                >
                                                  <img
                                                    src={images.whiteCross}
                                                    alt=""
                                                    className="w-4 h-4"
                                                  />
                                                  <div>Cancel</div>
                                                </div>
                                              )}
                                              {item?.upgrades?.pageOne &&
                                                !isExpired(
                                                  item?.upgrades?.pageOne
                                                    ?.endDate,
                                                  item?.upgrades?.pageOne
                                                    ?.endTime
                                                ) &&
                                                showPageOneForEdit && (
                                                  <div className="w-full">
                                                    <div className="flex flex-col">
                                                      <div className="flex gap-2 w-full">
                                                        <div className="flex gap-2 w-full">
                                                          <div
                                                            // className={
                                                            //   selectHour ===
                                                            //   "three"
                                                            //     ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                            //     : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                            // }
                                                            // onClick={() =>
                                                            //   setSelecthour(
                                                            //     "three"
                                                            //   )
                                                            // }
                                                            className={
                                                              selectHour ===
                                                              "three"
                                                                ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                                                : disabledPageOneTabs.three
                                                                ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                                                : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                                            }
                                                            onClick={() => {
                                                              if (
                                                                !disabledPageOneTabs.three
                                                              ) {
                                                                setSelecthour(
                                                                  "three"
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            3 hours
                                                          </div>
                                                          <div
                                                            // className={
                                                            //   selectHour ===
                                                            //   "five"
                                                            //     ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                                            //     : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                                            // }
                                                            // onClick={() =>
                                                            //   setSelecthour(
                                                            //     "five"
                                                            //   )
                                                            // }
                                                            className={
                                                              selectHour ===
                                                              "five"
                                                                ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                                                : disabledPageOneTabs.five
                                                                ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                                                : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                                            }
                                                            onClick={() => {
                                                              if (
                                                                !disabledPageOneTabs.five
                                                              ) {
                                                                setSelecthour(
                                                                  "five"
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            5 hours
                                                          </div>
                                                          <div
                                                            className={
                                                              selectHour ===
                                                              "seven"
                                                                ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                                                : disabledPageOneTabs.seven
                                                                ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                                                : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                                            }
                                                            onClick={() => {
                                                              if (
                                                                !disabledPageOneTabs.seven
                                                              ) {
                                                                setSelecthour(
                                                                  "seven"
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            7 hours
                                                          </div>
                                                        </div>
                                                        {(!item?.upgrades
                                                          ?.pageOne ||
                                                          isExpired(
                                                            item?.upgrades
                                                              ?.pageOne
                                                              ?.endDate,
                                                            item?.upgrades
                                                              ?.pageOne?.endTime
                                                          )) && (
                                                          <div
                                                            className=" h-13 w-16 cursor-pointer border hidden md:flex items-center justify-center"
                                                            onClick={() => {
                                                              setPageone(false);
                                                              setTime("");
                                                            }}
                                                          >
                                                            X
                                                          </div>
                                                        )}
                                                      </div>
                                                      <div className="mt-10 flex flex-col gap-4 ">
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              selectHour ===
                                                              "three"
                                                            ) {
                                                              handleSetPageOne(
                                                                immediatelyPageOneThreeHours
                                                              );
                                                            } else if (
                                                              selectHour ===
                                                              "five"
                                                            ) {
                                                              handleSetPageOne(
                                                                immediatelyPageOneFiveHours
                                                              );
                                                            } else {
                                                              handleSetPageOne(
                                                                immediatelyPageOneSevenHours
                                                              );
                                                            }
                                                          }}
                                                          className=" border h-24 cursor-pointer justify-between px-4 active:opacity-50 items-center  flex  "
                                                        >
                                                          <div className="text-sm flex flex-col ">
                                                            <div className="font-bold">
                                                              Now
                                                            </div>
                                                            <div className="flex ">
                                                              Activates
                                                              immediately after
                                                              payment
                                                              <div className="text-purple pl-1">
                                                                {selectHour ===
                                                                "three"
                                                                  ? pageOnedata
                                                                  : selectHour ===
                                                                    "five"
                                                                  ? pageTwodata
                                                                  : selectHour ===
                                                                    "seven"
                                                                  ? pageThreedata
                                                                  : null}
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="text-2xl ml-1">
                                                            +
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="border h-auto py-6 cursor-pointer justify-between flex-col   px-4
                                           flex  "
                                                        >
                                                          <div
                                                            className="flex  w-full justify-between"
                                                            onClick={() =>
                                                              setTime(
                                                                time ===
                                                                  "morning"
                                                                  ? ""
                                                                  : "morning"
                                                              )
                                                            }
                                                          >
                                                            <div className="text-sm">
                                                              <div className="font-bold">
                                                                Morning
                                                              </div>
                                                              <div className="flex gap-1">
                                                                {`6am - 12pm -   ${
                                                                  selectHour ===
                                                                  "three"
                                                                    ? morningData?.length
                                                                    : selectHour ===
                                                                      "five"
                                                                    ? fiveHoursSlotMorning?.length
                                                                    : selectHour ===
                                                                      "seven"
                                                                    ? sevenHoursSlotMorning.length
                                                                    : null
                                                                } slots Available from `}
                                                                <div className="text-purple">
                                                                  {selectHour ===
                                                                  "three"
                                                                    ? pageOnedata
                                                                    : selectHour ===
                                                                      "five"
                                                                    ? pageTwodata
                                                                    : selectHour ===
                                                                      "seven"
                                                                    ? pageThreedata
                                                                    : null}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="flex items-center  ">
                                                              <img
                                                                src={
                                                                  images.dropdownarrow
                                                                }
                                                                alt=""
                                                                className="h-4 w-6"
                                                              />
                                                            </div>
                                                          </div>
                                                          {selectHour ===
                                                          "three" ? (
                                                            <div>
                                                              {time ===
                                                                "morning" && (
                                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                  {morningData?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        key={
                                                                          index
                                                                        }
                                                                        className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2 "
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : selectHour ===
                                                            "five" ? (
                                                            <div>
                                                              {time ===
                                                                "morning" && (
                                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                  {fiveHoursSlotMorning?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        key={
                                                                          index
                                                                        }
                                                                        className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2 "
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : selectHour ===
                                                            "seven" ? (
                                                            <div>
                                                              {time ===
                                                                "morning" && (
                                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                  {sevenHoursSlotMorning?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        key={
                                                                          index
                                                                        }
                                                                        className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2                                                                                "
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                        <div className="border py-4 cursor-pointer  justify-center px-4 flex flex-col  ">
                                                          <div
                                                            className="flex  w-full justify-between"
                                                            onClick={() =>
                                                              setTime(
                                                                time ===
                                                                  "afternoon"
                                                                  ? ""
                                                                  : "afternoon"
                                                              )
                                                            }
                                                          >
                                                            <div className="text-sm">
                                                              <div className="font-bold">
                                                                Afternoon
                                                              </div>
                                                              <div className="flex gap-1">
                                                                12pm - 6pm -
                                                                {selectHour ===
                                                                "three"
                                                                  ? afterNoondata?.length
                                                                  : selectHour ===
                                                                    "five"
                                                                  ? fiveHoursSlotAfternoon?.length
                                                                  : selectHour ===
                                                                    "seven"
                                                                  ? sevenHoursSlotAfternoon.length
                                                                  : null}{" "}
                                                                slots Available
                                                                from
                                                                <div className="text-purple">
                                                                  {selectHour ===
                                                                  "three"
                                                                    ? pageOnedata
                                                                    : selectHour ===
                                                                      "five"
                                                                    ? pageTwodata
                                                                    : selectHour ===
                                                                      "seven"
                                                                    ? pageThreedata
                                                                    : null}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="flex items-center w-6 ">
                                                              <img
                                                                src={
                                                                  images.dropdownarrow
                                                                }
                                                                alt=""
                                                                className="h-4"
                                                              />
                                                            </div>
                                                          </div>
                                                          {selectHour ===
                                                          "three" ? (
                                                            <div>
                                                              {time ===
                                                                "afternoon" && (
                                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                  {afterNoondata?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        className="bg-slightydarkerBackgorund  justify-center cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                        key={
                                                                          index
                                                                        }
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : selectHour ===
                                                            "five" ? (
                                                            <div>
                                                              {time ===
                                                                "afternoon" && (
                                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                  {fiveHoursSlotAfternoon?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                        key={
                                                                          index
                                                                        }
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : selectHour ===
                                                            "seven" ? (
                                                            <div>
                                                              {time ===
                                                                "afternoon" && (
                                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                  {sevenHoursSlotAfternoon?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                        key={
                                                                          index
                                                                        }
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                        <div className=" border py-4 cursor-pointer justify-center px-4 flex flex-col  ">
                                                          <div
                                                            className="flex  w-full justify-between"
                                                            onClick={() =>
                                                              setTime(
                                                                time ===
                                                                  "evening"
                                                                  ? ""
                                                                  : "evening"
                                                              )
                                                            }
                                                          >
                                                            <div className="text-sm">
                                                              <div className="font-bold">
                                                                Evenings
                                                              </div>
                                                              <div className="flex gap-1">
                                                                6pm - 12am -{" "}
                                                                {selectHour ===
                                                                "three"
                                                                  ? eveningData?.length
                                                                  : selectHour ===
                                                                    "five"
                                                                  ? fiveHoursSlotEvening?.length
                                                                  : selectHour ===
                                                                    "seven"
                                                                  ? sevenHoursSlotEvening.length
                                                                  : null}{" "}
                                                                slots Available
                                                                from
                                                                <div className="text-purple">
                                                                  {selectHour ===
                                                                  "three"
                                                                    ? pageOnedata
                                                                    : selectHour ===
                                                                      "five"
                                                                    ? pageTwodata
                                                                    : selectHour ===
                                                                      "seven"
                                                                    ? pageThreedata
                                                                    : null}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="flex items-center w-6 ">
                                                              <img
                                                                src={
                                                                  images.dropdownarrow
                                                                }
                                                                alt=""
                                                                className="h-4"
                                                              />
                                                            </div>
                                                          </div>
                                                          {selectHour ===
                                                          "three" ? (
                                                            <div>
                                                              {time ===
                                                                "evening" && (
                                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                  {eveningData?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                        key={
                                                                          index
                                                                        }
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : selectHour ===
                                                            "five" ? (
                                                            <div>
                                                              {time ===
                                                                "evening" && (
                                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                  {fiveHoursSlotEvening?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2 "
                                                                        key={
                                                                          index
                                                                        }
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : selectHour ===
                                                            "seven" ? (
                                                            <div>
                                                              {time ===
                                                                "evening" && (
                                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                  {sevenHoursSlotEvening?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2                                                                                "
                                                                        key={
                                                                          index
                                                                        }
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                        <div className=" border py-4 cursor-pointer justify-center px-4 flex flex-col  ">
                                                          <div
                                                            className="flex  w-full justify-between"
                                                            onClick={() =>
                                                              setTime(
                                                                time === "night"
                                                                  ? ""
                                                                  : "night"
                                                              )
                                                            }
                                                          >
                                                            <div className="text-sm">
                                                              <div className="font-bold">
                                                                Late Night
                                                              </div>
                                                              <div className="flex gap-1">
                                                                12am - 6am -{" "}
                                                                {selectHour ===
                                                                "three"
                                                                  ? lateNightdata?.length
                                                                  : selectHour ===
                                                                    "five"
                                                                  ? fiveHoursSlotLateNight?.length
                                                                  : selectHour ===
                                                                    "seven"
                                                                  ? sevenHoursSlotLateNight.length
                                                                  : null}{" "}
                                                                slots Available
                                                                from
                                                                <div className="text-purple">
                                                                  {selectHour ===
                                                                  "three"
                                                                    ? pageOnedata
                                                                    : selectHour ===
                                                                      "five"
                                                                    ? pageTwodata
                                                                    : selectHour ===
                                                                      "seven"
                                                                    ? pageThreedata
                                                                    : null}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="flex items-center w-6 ">
                                                              <img
                                                                src={
                                                                  images.dropdownarrow
                                                                }
                                                                alt=""
                                                                className="h-4"
                                                              />
                                                            </div>
                                                          </div>

                                                          {selectHour ===
                                                          "three" ? (
                                                            <div>
                                                              {time ===
                                                                "night" && (
                                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                  {lateNightdata?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                        key={
                                                                          index
                                                                        }
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : selectHour ===
                                                            "five" ? (
                                                            <div>
                                                              {time ===
                                                                "night" && (
                                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                                                                  {fiveHoursSlotLateNight?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                        key={
                                                                          index
                                                                        }
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : selectHour ===
                                                            "seven" ? (
                                                            <div>
                                                              {time ===
                                                                "night" && (
                                                                <div className=" grid grid-cols-2 md:grid-cols-3 gap-4 mt-4                                                        ">
                                                                  {sevenHoursSlotLateNight?.map(
                                                                    (
                                                                      item,
                                                                      index
                                                                    ) => (
                                                                      <div
                                                                        className="bg-slightydarkerBackgorund  justify-center  cursor-pointer active:opacity-50 shadow-xl text-xs flex items-center py-2 px-2"
                                                                        key={
                                                                          index
                                                                        }
                                                                        onClick={() => {
                                                                          handleSetPageOne(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="text-xxs md:text-xs whitespace-nowrap">
                                                                          {`${item?.startTime} - ${item?.endTime}`}
                                                                        </div>
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {coditionalModal && (
                      <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div className="bg-white w-[600px] h-[400px] rounded-lg">
                          <div className="w-full flex-col gap-6 mx-auto mt-16 flex items-center justify-center border-b-4 border-purple pb-20">
                            <div className=" text-3xl font-bold w-[500px] text-center ">
                              {conditionModalTitle}
                            </div>
                            <div className=" text-xl text-opacit-20 w-[500px] text-center ">
                              {conditionModalSubject}
                            </div>
                          </div>
                          <div className="bg-darkerpurple h-[25%] text-white  items-center px-4 flex justify-between w-full">
                            <div
                              className="border border-purple p-4 rounded-xl active:bg-purple  cursor-pointer "
                              onClick={() => {
                                setOpenCondtionalModal(false);
                              }}
                            >
                              Cancel
                            </div>
                            <div
                              onClick={() => closeModal()}
                              className="text-xl  p-3 bg-purple rounded-xl cursor-pointer active:opacity-50"
                            >
                              {conditionalModalBtnText}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="pt-10">
                      <div className="flex flex-col w-full">
                        <div className="flex flex-col">
                          {(!item?.upgrades?.pageOne ||
                            isExpired(
                              item?.upgrades?.pageOne?.endDate,
                              item?.upgrades?.pageOne?.endTime
                            )) && (
                            <div>
                              <div className="bg-purple lg:w-90p w-full pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                                Page 1 + Floral Add
                              </div>
                              <div className="border p-10 lg:w-90p w-full text-sm  ">
                                <div className="pb-5 text-whitepink lg:text-base md:text-base sm:text-sm text-xs">
                                  Eye catching Floral Ad theme! includes Page 1
                                  upgrade
                                </div>
                                {adPremiume ? (
                                  <div className="flex flex-col gap-2">
                                    <AdCard />

                                    <div className="bg-premiumeColor text-black border-4 border-purple px-4 gap-2 items-center justify-center cursor-pointer active:opacity-50  h-32   flex flex-col">
                                      <div className="flex  w-full justify-between ">
                                        <div className=" flex  gap-2  max-w-[240px] overflow-hidden ">
                                          {uploadimg?.map((image, index) => (
                                            <img
                                              key={index}
                                              src={image}
                                              alt=""
                                              className="w-10 h-10 md:w-8 md:h-8"
                                            />
                                          ))}
                                        </div>
                                        {/* <div>
                                          <img
                                            src={images.premiumStar}
                                            alt=""
                                            className="h-4 w-4"
                                          />
                                        </div> */}
                                      </div>
                                      <div className="w-full">
                                        <div className="w-full gap-2  flex flex-col">
                                          <div className="flex w-full  font-bold  text-sm sm:text-sm ">
                                            {adTitle}
                                          </div>
                                          <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row w-full justify-between  font-semibold  text-xxs sm:text-sm">
                                            <div>
                                              {age} - {name} -{ethnicity}-{" "}
                                              {ethnicOrigin}- {selectedcity}
                                            </div>
                                            <div className="font-semibold text-xs sm:text-sm rounded-2xl gap-1 sm:gap-1 h-5 sm:h-9  bg-whitepink flex items-center justify-center w-20 sm:w-20   text-textdarkerpurpel ">
                                              <img
                                                src={images.verified1}
                                                className="h-4 sm:h-4"
                                              />
                                              Verified
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <AdCard />

                                    <div className="flex  gap-2 w-full mt-6">
                                      <div className="flex w-full gap-2">
                                        <div
                                          className={
                                            premiumAdHour === "three"
                                              ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                              : disabledTabs.three
                                              ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                              : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                          }
                                          onClick={() => {
                                            if (!disabledTabs.three) {
                                              handleAddTheme(
                                                themeThreeHoursCost,
                                                "three"
                                              );
                                            }
                                          }}
                                        >
                                          <div>3 hours</div>
                                          <div>
                                            {`$ ${themeThreeHoursCost}`}
                                          </div>
                                        </div>
                                        <div
                                          className={
                                            premiumAdHour === "five"
                                              ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                              : disabledTabs.five
                                              ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                              : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                          }
                                          // className={
                                          //     premiumAdHour ===
                                          //         "five"
                                          //         ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          //         : `w-full h-13 cursor-pointer border flex  flex-col items-center justify-center`
                                          // }
                                          onClick={() => {
                                            if (!disabledTabs.five) {
                                              handleAddTheme(
                                                themeFiveHoursCost,
                                                "five"
                                              );
                                            }
                                          }}
                                          // onClick={() => {
                                          //     handleAddTheme(
                                          //         themeFiveHoursCost,
                                          //         "five"
                                          //     );
                                          // }}
                                        >
                                          <div>5 hours</div>
                                          <div>{`$ ${themeFiveHoursCost}`}</div>
                                        </div>
                                        <div
                                          className={
                                            premiumAdHour === "seven"
                                              ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                              : disabledTabs.seven
                                              ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                              : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                          }
                                          // className={
                                          //     premiumAdHour ===
                                          //         "seven"
                                          //         ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          //         : `w-full h-13 cursor-pointer border flex flex-col items-center justify-center`
                                          // }
                                          onClick={() => {
                                            if (!disabledTabs.seven) {
                                              handleAddTheme(
                                                themeSevenHoursCost,
                                                "seven"
                                              );
                                            }
                                          }}
                                          // onClick={() => {
                                          //     handleAddTheme(
                                          //         themeSevenHoursCost,
                                          //         "seven"
                                          //     );
                                          // }}
                                        >
                                          <div>7 hours</div>
                                          <div>
                                            {` $ ${themeSevenHoursCost}`}
                                          </div>
                                        </div>
                                      </div>
                                      {(!item?.upgrades?.premiumTheme ||
                                        isExpired(
                                          item?.upgrades?.premiumTheme?.endDate,
                                          item?.upgrades?.premiumTheme?.endTime
                                        )) && (
                                        <div
                                          className=" h-13 w-16 cursor-pointer border flex items-center justify-center"
                                          onClick={closeThemeAd}
                                        >
                                          X
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="gap-4 flex flex-col">
                                    <div>
                                      <div className="bg-premiumeColor text-black border-4 border-purple px-4 gap-2 items-center justify-center h-32   flex flex-col">
                                        <div className="flex  w-full justify-between ">
                                          <div className=" flex  gap-2  max-w-[240px] overflow-hidden  ">
                                            {uploadimg?.map((image, index) => (
                                              <img
                                                key={index}
                                                src={image}
                                                alt=""
                                                className="w-10 h-10  md:w-8 md:h-8"
                                              />
                                            ))}
                                          </div>
                                          {/* <div>
                                            <img
                                              src={images.premiumStar}
                                              alt=""
                                              className="h-4 w-4"
                                            />
                                          </div> */}
                                        </div>
                                        <div className="w-full">
                                          <div className="w-full gap-2  flex flex-col">
                                            <div className="flex w-full  font-bold  text-sm sm:text-sm ">
                                              {adTitle}
                                            </div>
                                            <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row w-full justify-between  font-semibold  text-xxs sm:text-sm">
                                              <div>
                                                {age} - {name} -{ethnicity}-{" "}
                                                {ethnicOrigin}- {selectedcity}
                                              </div>
                                              <div className="font-semibold text-xs sm:text-sm rounded-2xl gap-1 sm:gap-1 h-5 sm:h-9  bg-whitepink flex items-center justify-center w-20 sm:w-20   text-textdarkerpurpel ">
                                                <img
                                                  src={images.verified1}
                                                  className="h-4 sm:h-4"
                                                />
                                                Verified
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="bg-purple text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full  h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50
                          lg:text-sm md:text-sm sm:text-sm text-xxs"
                                      // onClick={() => {
                                      //   openFloralAd()
                                      // }}
                                      onClick={() =>
                                        handleAddTheme(
                                          themeFiveHoursCost,
                                          "five"
                                        )
                                      }
                                    >
                                      Add Now from CAD $2.28/hour
                                      <img
                                        src={images.Vector4}
                                        alt=""
                                        className="lg:w-2 lg:h-2 w-auto h-2 "
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="bg-purple flex lg:w-90p w-full pl-10 justify-between mt-8 py-2">
                            <div className="lg:text-2xl md:text-xl sm:text-sm text-sm font-semibold">
                              {addextra[0].title2}
                            </div>
                            <div className="bg-white text-purple font-bold flex justify-center items-center w-24p mr-4 lg:gap-2 md:gap-2 sm:gap-2 gap-1">
                              <img
                                src={images.purplecircle}
                                alt=""
                                className="w-3 h-3"
                              />
                              <div className="lg:text-lg md:text-lg  sm:text-sm text-sm">
                                New
                              </div>
                            </div>
                          </div>
                          {/* bumpsStart */}
                          <div className="border p-3 md:p-10 lg:w-90p w-full">
                            <div className="pb-4 lg:text-base md:text-base sm:text-sm text-xxs">
                              {addextra[0].description2}
                            </div>
                            {showBumps ? (
                              <div className="flex  flex-col">
                                <div className="flex  gap-2 w-full mt-6">
                                  <div className="grid grid-cols-2 md:grid-cols-4 w-full gap-2">
                                    {bumpsTab?.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          onClick={() =>
                                            item.enable && handleAddBumps(index)
                                          }
                                          className={
                                            item.selected
                                              ? "w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center"
                                              : item.enable
                                              ? "w-full h-13 cursor-pointer border flex items-center justify-center"
                                              : "w-full h-13 border flex items-center justify-center opacity-50"
                                          }
                                        >
                                          <div className="flex flex-col text-sm">
                                            <div className="font-bold">
                                              {`${item.qty} Bumps`}
                                            </div>
                                            <div>{`$ ${item.cost}/each`}</div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  {!item?.upgrades?.bumps && (
                                    <div
                                      className=" h-13 w-16 cursor-pointer active:opacity-50 border flex items-center justify-center"
                                      onClick={handleRemoveBumps}
                                    >
                                      X
                                    </div>
                                  )}
                                </div>
                                <div className="mt-10 flex">
                                  <div className=" w-full">
                                    Schedule your bumps
                                  </div>
                                  <div className="flex w-full gap-1 ">
                                    <div
                                      className={
                                        scheduleBumps
                                          ? `w-full border cursor-pointer active:opacity-50 flex items-center justify-center h-12`
                                          : `w-full border border-purple flex items-center cursor-pointer active:opacity-50 justify-center h-12`
                                      }
                                      onClick={handleRemoveScheduleBumps}
                                    >
                                      No
                                    </div>
                                    <div
                                      className={
                                        scheduleBumps
                                          ? `w-full border border-purple  flex items-center cursor-pointer active:opacity-50 justify-center h-12`
                                          : `w-full border flex items-center cursor-pointer active:opacity-50 justify-center h-12`
                                      }
                                      onClick={
                                        () => handleCreateInitialBumpsSchedule()
                                        // setBumpyes(true)
                                      }
                                    >
                                      Yes
                                    </div>
                                  </div>
                                </div>
                                {scheduleBumps && (
                                  <div>
                                    <div className="flex w-full mt-2 jsutify-between gap-2">
                                      <div className="w-full bg-lighterpurple flex flex-col items-center justify-center h-20">
                                        <div className="font-semibold">
                                          Remaining Bumps
                                        </div>
                                        <div className="font-bold text-4xl">
                                          {selectedBumps?.totalBumps -
                                            calculateTotalScheduleBumps(
                                              selectedBumps?.selectedScheduleBumps
                                            )}
                                        </div>
                                      </div>
                                      <div className="w-full bg-lighterpurple flex flex-col flex items-center justify-center h-20">
                                        <div className="font-semibold">
                                          Scheduled Bumps
                                        </div>
                                        <div className="font-bold text-4xl">
                                          {calculateTotalScheduleBumps(
                                            selectedBumps?.selectedScheduleBumps
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="w-full mt-2 flex items-center md:justify-between flex-row flex-wrap gap-1">
                                      {/* timesPrint */}
                                      {selectedBumps?.times?.map(
                                        (item, index) => {
                                          const itemHour = extractHour(item);
                                          const itemMinutes =
                                            extractMinutes(item);
                                          const itemFormat =
                                            extractFormat(item);
                                          return (
                                            <div
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleShowTimeDropdown(index);
                                              }}
                                              key={index}
                                              className="border relative border-purple cursor-pointer w-[49%] md:w-[32%] h-10 flex items-center justify-between px-3"
                                            >
                                              <img
                                                src={images.time}
                                                className="w-5"
                                              />
                                              <div className="w-20 text-center">
                                                {item}
                                              </div>
                                              <img
                                                src={images.crossBumps}
                                                className="w-4 "
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleRemoveTimeWithoutChangesDays(
                                                    index
                                                  );
                                                }}
                                              />
                                              {bumpsTimeDropdown === index && (
                                                <div className="absolute bg-graymore top-10 w-full p-1 right-0 z-50 mt-1 shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]">
                                                  <div className="flex flex-row items-center justify-between border-b border-white-500">
                                                    <div
                                                      className=" w-full flex items-center justify-center  border-r border-white-500"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleIncreaseBumpsHours(
                                                          item,
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={
                                                          images.dropDownClosed
                                                        }
                                                        className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                      />
                                                    </div>
                                                    <div
                                                      className=" w-full flex items-center justify-center border-r border-white-500"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleIncreaseBumpsMinutes(
                                                          item,
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={
                                                          images.dropDownClosed
                                                        }
                                                        className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                      />
                                                    </div>
                                                    <div
                                                      className=" w-full flex items-center justify-center"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleChangeFormat(
                                                          item,
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={
                                                          images.dropDownClosed
                                                        }
                                                        className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="flex flex-row items-center justify-between border-b border-white-500">
                                                    <div className=" w-full flex items-center justify-center border-r border-white-500">
                                                      <div className="my-2">
                                                        {hoursForBumps.includes(
                                                          itemHour
                                                        ) &&
                                                          formatHourAndMinutes(
                                                            itemHour
                                                          )}
                                                      </div>
                                                    </div>
                                                    <div className=" w-full flex items-center justify-center border-r border-white-500">
                                                      <div className="my-2">
                                                        {minutesForBumps.includes(
                                                          itemMinutes
                                                        ) &&
                                                          formatHourAndMinutes(
                                                            itemMinutes
                                                          )}
                                                      </div>
                                                    </div>
                                                    <div className=" w-full flex items-center justify-center">
                                                      <div className="my-2">
                                                        {itemFormat}
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="flex flex-row items-center justify-between">
                                                    <div
                                                      className=" w-full flex items-center justify-center border-r border-white-500"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDecreaseBumpsHours(
                                                          item,
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={
                                                          images.dropDownOpen
                                                        }
                                                        className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                      />
                                                    </div>
                                                    <div
                                                      className=" w-full flex items-center justify-center border-r border-white-500"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDecreaseBumpsMinutes(
                                                          item,
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={
                                                          images.dropDownOpen
                                                        }
                                                        className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                      />
                                                    </div>
                                                    <div
                                                      className=" w-full flex items-center justify-center"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleChangeFormat(
                                                          item,
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={
                                                          images.dropDownOpen
                                                        }
                                                        className="w-5 my-2 hover:opacity-50 active:opacity-20"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                      <div
                                        className={
                                          showBumpsAddTime
                                            ? "flex-grow bg-purple  flex flex-col flex items-center justify-center h-10 cursor-pointer active:opacity-50"
                                            : "flex-grow bg-graymore opacity-50 flex flex-col flex items-center justify-center h-10"
                                        }
                                        onClick={() =>
                                          showBumpsAddTime &&
                                          handleAddTimeWithoutChangesDays()
                                        }
                                      >
                                        + Add
                                      </div>
                                    </div>

                                    {/* daysOptions */}
                                    <div className="sm:flex sm:flex-row gap-2 grid  grid-cols-3 mt-2">
                                      {bumpsDayOption?.map((item, index) => {
                                        return (
                                          <div
                                            key={index}
                                            onClick={() =>
                                              item.enable &&
                                              selectBumpsDays(index)
                                            }
                                            className={
                                              item.selected
                                                ? "bg-purple px-3 py-2 cursor-pointer active:opacity-50 font-semibold"
                                                : item.enable
                                                ? "bg-transparent border border-purple px-3 py-2 cursor-pointer active:opacity-50"
                                                : "bg-graymore opacity-50 px-3 py-2 cursor-pointer"
                                            }
                                          >
                                            {item.day == 1
                                              ? `${item.day} day`
                                              : `${item.day} days`}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                className="bg-purple text-white lg:w-70p md:w-70p sm:w-70p w-full    lg:text-sm md:text-sm sm:text-sm text-xxs w-75p h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50"
                                onClick={() => {
                                  handleAddBumps(2);
                                }}
                              >
                                Add Now from CAD ${thirdBumpsCost}
                                /bump
                                <img
                                  src={images.Vector4}
                                  alt=""
                                  className="lg:w-2 lg:h-2 w-auto h-2  "
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* UPGRADES */}
              <div className="w-full  shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] p-4 ">
                <div
                  className="cursor-pointer active:opacity-50"
                  // onClick={() =>
                  //     hideBoosts(
                  //         boost === "upgrades" ? "" : "upgrades"
                  //     )
                  // }
                  onClick={() => {
                    hideBoosts(boost === "upgrades" ? "" : "upgrades");
                    handleScrollToTop();
                  }}
                >
                  <div className="lg:text-2xl md:text-xl sm:text-sm text-sm font-semibold cursor-pointer ">
                    Visual Upgrades
                  </div>
                  <div className="text-whitepink  opacity-80 lg:text-base md:text-base sm:text-sm text-xs">
                    Spice up your ad by customising the look of your ad
                  </div>
                </div>
                {boost === "upgrades" && (
                  <div>
                    <div className="pt-10">
                      <div className="gap-20 flex w-full">
                        <div className="lg:w-90p w-full ">
                          <div className="flex flex-col w-full">
                            <div className="bg-purple w-full pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                              Carousel
                            </div>
                            <div className="flex flex-col">
                              <div className="border p-10">
                                <div className="pb-4 lg:text-base md:text-base sm:text-sm text-xs">
                                  Double your Ad size with a delightful
                                  scrollable carousel!
                                </div>
                                {carouselAd ? (
                                  <div>
                                    <div className="h-full flex-col flex items-center justify-center ">
                                      <div className=" h-full gap-2 mb-4 w-full">
                                        <AdCard />
                                        <div className="overflow-hidden">
                                          <AdCard
                                            age={age}
                                            name={name}
                                            ethnicity={ethnicity}
                                            ethnicOrigin={ethnicOrigin}
                                            selectedcity={selectedcity}
                                            uploadimg={uploadimg}
                                            adTitle={adTitle}
                                            carousel={true}
                                          />
                                        </div>
                                        <AdCard />
                                      </div>
                                    </div>
                                    <div className="mt-4">
                                      <div className="flex  gap-2 w-full mt-6">
                                        <div className="flex gap-2 w-full">
                                          <div
                                            // className={
                                            //     carouselAdhour ===
                                            //         "three"
                                            //         ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                            //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                            // }
                                            className={
                                              carouselAdhour === "three"
                                                ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                                : disabledCarouselTabs.three
                                                ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                                : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                            }
                                            onClick={() => {
                                              if (!disabledCarouselTabs.three) {
                                                handleAddCarouselAd(
                                                  "three",
                                                  carouselThreeHoursCost
                                                );
                                              }
                                            }}
                                          >
                                            <div className="flex flex-col text-sm">
                                              <div className="font-bold">
                                                3 hours
                                              </div>
                                              <div>
                                                {`$ ${carouselThreeHoursCost}`}
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            // className={
                                            //     carouselAdhour ===
                                            //         "six"
                                            //         ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                            //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                            // }
                                            // onClick={() =>
                                            //     // setCarouseladHour(
                                            //     //   "six"
                                            //     // )
                                            //     handleAddCarouselAd(
                                            //         "six",
                                            //         carouselSixHoursCost
                                            //     )
                                            // }
                                            className={
                                              carouselAdhour === "six"
                                                ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                                : disabledCarouselTabs.six
                                                ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                                : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                            }
                                            onClick={() => {
                                              if (!disabledCarouselTabs.six) {
                                                handleAddCarouselAd(
                                                  "six",
                                                  carouselSixHoursCost
                                                );
                                              }
                                            }}
                                          >
                                            <div className="flex flex-col text-sm">
                                              <div className="font-bold">
                                                6 hours
                                              </div>
                                              <div>
                                                {`$ ${carouselSixHoursCost}`}
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            // className={
                                            //     carouselAdhour ===
                                            //         "nine"
                                            //         ? `w-full h-13 border-2 cursor-pointer flex text-purple border-purple items-center justify-center`
                                            //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                            // }
                                            // onClick={() =>
                                            //     handleAddCarouselAd(
                                            //         "nine",
                                            //         carouselNineHoursCost
                                            //     )
                                            // }

                                            className={
                                              carouselAdhour === "nine"
                                                ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                                : disabledCarouselTabs.nine
                                                ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                                : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                            }
                                            onClick={() => {
                                              if (!disabledCarouselTabs.nine) {
                                                handleAddCarouselAd(
                                                  "nine",
                                                  carouselNineHoursCost
                                                );
                                              }
                                            }}
                                          >
                                            <div className="flex flex-col text-sm">
                                              <div className="font-bold">
                                                9 hours
                                              </div>
                                              <div>
                                                {`$ ${carouselNineHoursCost}`}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {(!item?.upgrades?.carousel ||
                                          isExpired(
                                            item?.upgrades?.carousel?.endDate,
                                            item?.upgrades?.carousel?.endTime
                                          )) && (
                                          <div
                                            className=" h-13 w-16 cursor-pointer border flex items-center justify-center"
                                            onClick={() =>
                                              handleRemoveCerousel()
                                            }
                                          >
                                            X
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="h-full  items-center justify-center ">
                                    <div className=" flex flex-col  my-4 w-[100%] md:w-96 ">
                                      <AdCard
                                        age={age}
                                        name={name}
                                        ethnicity={ethnicity}
                                        ethnicOrigin={ethnicOrigin}
                                        selectedcity={selectedcity}
                                        uploadimg={uploadimg}
                                        adTitle={adTitle}
                                        carousel={true}
                                      />
                                    </div>
                                    <div>
                                      <div
                                        className="bg-purple text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full  h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50
                          lg:text-sm md:text-sm sm:text-sm text-xxs"
                                        onClick={() =>
                                          handleAddCarouselAd(
                                            "six",
                                            carouselSixHoursCost
                                          )
                                        }
                                      >
                                        Add Now from CAD $0.48/hour
                                        <img
                                          src={images.Vector4}
                                          alt=""
                                          className="lg:w-2 lg:h-2 w-auto h-2 "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-10">
                      <div className="flex flex-col w-full">
                        <div className="bg-purple lg:w-90p w-full pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                          Available Now
                        </div>
                        <div className="flex flex-col">
                          <div className="border p-10 lg:w-90p w-full text-sm  ">
                            <div className="pb-5 text-whitepink lg:text-base md:text-base sm:text-sm text-xs">
                              Let clients know you’re available + appear in a
                              special search filter
                            </div>
                            {availbeNowTheme ? (
                              <div className="flex flex-col gap-2">
                                <AdCard />
                                <AdCard
                                  available={true}
                                  age={age}
                                  name={name}
                                  ethnicity={ethnicity}
                                  ethnicOrigin={ethnicOrigin}
                                  selectedcity={selectedcity}
                                  uploadimg={uploadimg}
                                  adTitle={adTitle}
                                />
                                <AdCard />
                                <div className="flex  gap-2 w-full mt-6">
                                  <div className="sm:flex grid-cols-2 grid w-full gap-2 ">
                                    <div
                                      className={
                                        availbleHour === "one"
                                          ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          : disabledAvailabseNowTabs.one
                                          ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                          : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                      }
                                      onClick={() => {
                                        if (!disabledAvailabseNowTabs.one) {
                                          handleAddAvailableNow(
                                            "one",
                                            availableNowOneHourCost
                                          );
                                        }
                                      }}
                                      // className={
                                      //     availbleHour === "one"
                                      //         ? `w-full h-13 border-2 cursor-pointer flex  border-purple items-center justify-center`
                                      //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                      // }
                                      // onClick={() =>
                                      //     handleAddAvailableNow(
                                      //         "one",
                                      //         availableNowOneHourCost
                                      //     )
                                      // }
                                    >
                                      <div className="flex flex-col items-center">
                                        <div
                                          className={
                                            availbleHour == "one"
                                              ? `font-bold text-purple  text-md`
                                              : `font-bold  text-md`
                                          }
                                        >
                                          1 hour
                                        </div>
                                        <div>
                                          {`$ ${availableNowOneHourCost}`}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        availbleHour === "two"
                                          ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          : disabledAvailabseNowTabs.two
                                          ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                          : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                      }
                                      onClick={() => {
                                        if (!disabledAvailabseNowTabs.two) {
                                          handleAddAvailableNow(
                                            "two",
                                            availableNowTwoHourCost
                                          );
                                        }
                                      }}
                                      // className={
                                      //     availbleHour === "two"
                                      //         ? `w-full h-13 border-2 cursor-pointer flex  border-purple items-center justify-center`
                                      //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                      // }
                                      // onClick={() =>
                                      //     handleAddAvailableNow(
                                      //         "two",
                                      //         availableNowTwoHourCost
                                      //     )
                                      // }
                                    >
                                      <div className="flex flex-col items-center">
                                        <div
                                          className={
                                            availbleHour == "two"
                                              ? `font-bold text-purple  text-md`
                                              : `font-bold  text-md`
                                          }
                                        >
                                          2 hour
                                        </div>
                                        <div>
                                          {`$ ${availableNowTwoHourCost}`}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        availbleHour === "three"
                                          ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          : disabledAvailabseNowTabs.three
                                          ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                          : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                      }
                                      onClick={() => {
                                        if (!disabledAvailabseNowTabs.three) {
                                          handleAddAvailableNow(
                                            "three",
                                            availableNowThreeHourCost
                                          );
                                        }
                                      }}
                                      // className={
                                      //     availbleHour ===
                                      //         "three"
                                      //         ? `w-full h-13 border-2 cursor-pointer flex  border-purple items-center justify-center`
                                      //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                      // }
                                      // onClick={() =>
                                      //     handleAddAvailableNow(
                                      //         "three",
                                      //         availableNowThreeHourCost
                                      //     )
                                      // }
                                    >
                                      <div className="flex flex-col items-center">
                                        <div
                                          className={
                                            availbleHour == "three"
                                              ? `font-bold text-purple  text-md`
                                              : `font-bold  text-md`
                                          }
                                        >
                                          3 hour
                                        </div>
                                        <div>
                                          {`$ ${availableNowThreeHourCost}`}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        availbleHour === "four"
                                          ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          : disabledAvailabseNowTabs.four
                                          ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                          : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                      }
                                      onClick={() => {
                                        if (!disabledAvailabseNowTabs.four) {
                                          handleAddAvailableNow(
                                            "four",
                                            availableNowFourHourCost
                                          );
                                        }
                                      }}
                                      // className={
                                      //     availbleHour ===
                                      //         "four"
                                      //         ? `w-full h-13 border-2 cursor-pointer flex  border-purple items-center justify-center`
                                      //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                      // }
                                      // onClick={() =>
                                      //     handleAddAvailableNow(
                                      //         "four",
                                      //         availableNowFourHourCost
                                      //     )
                                      // }
                                    >
                                      <div className="flex flex-col items-center">
                                        <div
                                          className={
                                            availbleHour == "four"
                                              ? `font-bold text-purple  text-md`
                                              : `font-bold  text-md`
                                          }
                                        >
                                          4 hour
                                        </div>
                                        <div>
                                          {`$ ${availableNowFourHourCost}`}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {(!item?.upgrades?.availableNow ||
                                    isExpired(
                                      item?.upgrades?.availableNow?.endDate,
                                      item?.upgrades?.availableNow?.endTime
                                    )) && (
                                    <div
                                      className=" h-13 w-16 cursor-pointer active:opacity-50 border flex items-center justify-center"
                                      onClick={handleRemoveAvailableNow}
                                    >
                                      X
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="gap-4 flex flex-col">
                                <div>
                                  <AdCard
                                    available={true}
                                    age={age}
                                    name={name}
                                    ethnicity={ethnicity}
                                    ethnicOrigin={ethnicOrigin}
                                    selectedcity={selectedcity}
                                    uploadimg={uploadimg}
                                    adTitle={adTitle}
                                  />
                                </div>
                                <div
                                  className="bg-purple text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full  h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50
                          lg:text-sm md:text-sm sm:text-sm text-xxs"
                                  onClick={() =>
                                    handleAddAvailableNow(
                                      "two",
                                      availableNowTwoHourCost
                                    )
                                  }
                                >
                                  Add Now from CAD $0.55/hour
                                  <img
                                    src={images.Vector4}
                                    alt=""
                                    className="lg:w-2 lg:h-2 w-auto h-2 "
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* FEATURED AD */}
                    <div className="pt-10">
                      <div className="flex flex-col w-full">
                        <div className="bg-purple lg:w-90p w-full pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                          Featured Ad
                        </div>
                        <div className="flex flex-col">
                          <div className="border p-10 lg:w-90p w-full text-sm  ">
                            <div className="pb-5 text-whitepink lg:text-base md:text-base sm:text-sm text-xs flex justify-between items-center ">
                              <div>Featured Ad on Page 1+Regular Ad!</div>
                              {featuredAd && (
                                <div>
                                  {/* <div
                                    className="border p-2 cursor-pointer active:opacity-50 relative"
                                    onClick={() => setOpenmodal(!openModal)}
                                  >
                                    Preview
                                  </div> */}
                                  {openModal && (
                                    <div className="bg-white absolute right-[47%] mt-2">
                                      <div className="w-96 h-[460px] mt-2 mx-2  ">
                                        <AdCard />
                                        <div className="mt-2 flex gap-2">
                                          <div
                                            className="bg-white bg-cover bg-center  h-44 w-40 flex justify-end flex-col  font-bold"
                                            style={{
                                              backgroundImage: `url(${editedImage})`,
                                            }}
                                          >
                                            <div className="p-2 text-white">
                                              {adTitle}
                                            </div>
                                          </div>
                                          <div
                                            className="bg-white bg-cover bg-center text-white  h-44 w-40 flex justify-end flex-col  font-bold"
                                            style={{
                                              backgroundImage: `url(${images.yellowbandi})`,
                                            }}
                                          >
                                            <div className="p-2">{adTitle}</div>
                                          </div>
                                        </div>
                                        <AdCard />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            {featuredAd ? (
                              <div className="flex flex-col gap-2">
                                {editPic ? (
                                  <div className="bg-lighterpurple h-60 flex items-center justify-center">
                                    <AvatarEditor
                                      ref={editorRef}
                                      image={selectImg}
                                      width={160}
                                      height={160}
                                      color={[255, 255, 255, 0.6]}
                                      scale={1.4}
                                    />
                                  </div>
                                ) : (
                                  <div className="bg-lighterpurple h-60 flex items-center justify-center">
                                    {editedImage === null ? (
                                      <img
                                        src={selectImg}
                                        alt=""
                                        className="h-40 w-40"
                                      />
                                    ) : (
                                      <img
                                        src={editedImage}
                                        alt=""
                                        className="h-40 w-40"
                                      />
                                    )}
                                  </div>
                                )}
                                <div className="flex justify-center  border-b pb-4">
                                  {editPic ? (
                                    <div
                                      className="flex justify-center h-10 items-center border w-24 text-md cursor-pointer active:opacity-50"
                                      onClick={() => {
                                        setEditpic(!editPic);
                                      }}
                                    >
                                      Done
                                    </div>
                                  ) : (
                                    <div
                                      className="flex justify-center h-10 items-center border w-24 text-md cursor-pointer active:opacity-50"
                                      onClick={() => setEditpic(!editPic)}
                                    >
                                      Edit Pic
                                    </div>
                                  )}
                                </div>
                                <div>PICTURE</div>
                                <div className="flex item-start gap-2 justify-start overflow-x-scroll">
                                  {uploadimg?.map((item, index) => (
                                    // <div
                                    //   key={index}
                                    //   className="bg-green-500"
                                    // >
                                    <img
                                      src={item}
                                      className="h-14 w-14 cursor-pointer active:opacity-50"
                                      onClick={() => handleClickimg(item)}
                                    />
                                    // </div>
                                  ))}
                                </div>

                                <div className="flex  gap-2 w-full mt-6">
                                  <div className="flex w-full gap-2">
                                    <div
                                      className={
                                        featuredAdHour === "one"
                                          ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          : disabledFeatureAdTbs.one
                                          ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                          : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                      }
                                      onClick={() => {
                                        if (!disabledFeatureAdTbs.one) {
                                          handleAddFeaturedAdd(
                                            "one",
                                            featuredAddOneHourCost
                                          );
                                        }
                                      }}
                                      // className={
                                      //     featuredAdHour ===
                                      //         "one"
                                      //         ? `w-full h-13 border-2 cursor-pointer flex  border-purple items-center justify-center`
                                      //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                      // }
                                      // onClick={() =>
                                      //     handleAddFeaturedAdd(
                                      //         "one",
                                      //         featuredAddOneHourCost
                                      //     )
                                      // }
                                    >
                                      <div className="flex flex-col items-center">
                                        <div
                                          className={
                                            featuredAdHour == "one"
                                              ? `font-bold text-purple  text-md`
                                              : `font-bold  text-md`
                                          }
                                        >
                                          1 hour
                                        </div>
                                        <div>
                                          {`$ ${featuredAddOneHourCost}`}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        featuredAdHour === "three"
                                          ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          : disabledFeatureAdTbs.three
                                          ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                          : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                      }
                                      onClick={() => {
                                        if (!disabledFeatureAdTbs.three) {
                                          handleAddFeaturedAdd(
                                            "three",
                                            featuredAddThreeHourCost
                                          );
                                        }
                                      }}
                                      // className={
                                      //     featuredAdHour ===
                                      //         "three"
                                      //         ? `w-full h-13 border-2 cursor-pointer flex  border-purple items-center justify-center`
                                      //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                      // }
                                      // onClick={() =>
                                      //     handleAddFeaturedAdd(
                                      //         "three",
                                      //         featuredAddThreeHourCost
                                      //     )
                                      // }
                                    >
                                      <div className="flex flex-col items-center">
                                        <div
                                          className={
                                            featuredAdHour == "three"
                                              ? `font-bold text-purple  text-md`
                                              : `font-bold  text-md`
                                          }
                                        >
                                          3 hour
                                        </div>
                                        <div>
                                          {`$ ${featuredAddThreeHourCost}`}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        featuredAdHour === "seven"
                                          ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          : disabledFeatureAdTbs.seven
                                          ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                          : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                      }
                                      onClick={() => {
                                        if (!disabledFeatureAdTbs.seven) {
                                          handleAddFeaturedAdd(
                                            "seven",
                                            featuredAddSevenHourCost
                                          );
                                        }
                                      }}
                                      // className={
                                      //     featuredAdHour ===
                                      //         "seven"
                                      //         ? `w-full h-13 border-2 cursor-pointer flex  border-purple items-center justify-center`
                                      //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                      // }
                                      // onClick={() =>
                                      //     handleAddFeaturedAdd(
                                      //         "seven",
                                      //         featuredAddSevenHourCost
                                      //     )
                                      // }
                                    >
                                      <div className="flex flex-col items-center">
                                        <div
                                          className={
                                            featuredAdHour == "seven"
                                              ? `font-bold text-purple  text-md`
                                              : `font-bold  text-md`
                                          }
                                        >
                                          7 hour
                                        </div>
                                        <div>
                                          {`$ ${featuredAddSevenHourCost}`}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {(!item?.upgrades?.featuredAdd ||
                                    isExpired(
                                      item?.upgrades?.featuredAdd?.endDate,
                                      item?.upgrades?.featuredAdd?.endTime
                                    )) && (
                                    <div
                                      className=" h-13 w-16 cursor-pointer active:opacity-50 border flex items-center justify-center"
                                      onClick={handleRemoveFeaturedAd}
                                    >
                                      X
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="gap-4 flex flex-col">
                                <div className="bg-lighterpurple h-60 flex items-center justify-center">
                                  {editedImage !== null ? (
                                    <div
                                      className="bg-white bg-cover bg-center h-44 w-40 flex justify-end flex-col text-black font-bold"
                                      style={{
                                        backgroundImage: `url(${editedImage})`,
                                      }}
                                    >
                                      <div className="p-2 text-white">{}</div>
                                    </div>
                                  ) : (
                                    <div
                                      className="bg-white bg-cover bg-center h-44 w-44 flex justify-end flex-col text-black font-bold"
                                      style={{
                                        backgroundImage: `url(${selectImg})`,
                                      }}
                                    >
                                      <div className="p-2 text-white "></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="bg-purple text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full  h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50
                          lg:text-sm md:text-sm sm:text-sm text-xxs"
                                  onClick={() => {
                                    handleAddFeaturedAdd(
                                      "three",
                                      featuredAddThreeHourCost
                                    );
                                  }}
                                >
                                  Add Now from CAD $0.91/hour
                                  <img
                                    src={images.Vector4}
                                    alt=""
                                    className="lg:w-2 lg:h-2 w-auto h-2 "
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* EMOJI */}
                    <div className="pt-10">
                      <div className="flex flex-col w-full">
                        <div className="bg-purple lg:w-90p w-full pl-10 text-2xl  font-semibold py-2 lg:text-2xl md:text-xl sm:text-sm text-sm ">
                          Emoji
                        </div>
                        <div className="flex flex-col">
                          <div className="border p-10 lg:w-90p w-full text-sm  ">
                            <div className="pb-5 text-whitepink lg:text-base md:text-base sm:text-sm text-xs">
                              Show off your playful (or naughty) side with
                              emojis!
                            </div>
                            {emojiShow ? (
                              <div className="flex flex-col gap-2">
                                <AdCard />
                                <AdCard
                                  age={age}
                                  name={name}
                                  ethnicity={ethnicity}
                                  ethnicOrigin={ethnicOrigin}
                                  selectedcity={selectedcity}
                                  uploadimg={uploadimg}
                                  adTitle={adTitle}
                                  textAreadata={textAreadata}
                                />
                                <AdCard />
                                <div className=" ">
                                  <div className="flex justify-between w-full ">
                                    <div>Title</div>
                                    {/* <div className="">
                                          {textAreadata.length}/60
                                          Characters left
                                        </div> */}
                                  </div>
                                  <div className="bg-red-500">
                                    <input
                                      value={textAreadata}
                                      onChange={handleChange}
                                      className="resize-none  focus:outline-none h-10 w-full p-2 text-lg text-black font-semibold"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-row w-full text-xl flex-wrap items-center justify-start sm:justify-between flex-wrap">
                                  {emojiImages?.map((item, index) => {
                                    return (
                                      <div
                                        className="cursor-pointer"
                                        key={index}
                                        onClick={() =>
                                          handleEmojiClick(item.emoji)
                                        }
                                      >
                                        {item.emoji}
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="flex  gap-2 w-full mt-6">
                                  <div className="flex w-full gap-2">
                                    <div
                                      className={
                                        emojiAdd === "24"
                                          ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          : disabledEmojiTabs.twentyFour
                                          ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                          : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                      }
                                      onClick={() => {
                                        if (!disabledEmojiTabs.twentyFour) {
                                          handleAddEmoji(
                                            "24",
                                            emoji24HoursCost
                                          );
                                        }
                                      }}
                                      // className={
                                      //     emojiAdd === "24"
                                      //         ? `w-full h-13 border-2 cursor-pointer flex  border-purple items-center justify-center`
                                      //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                      // }
                                      // onClick={() =>
                                      //     handleAddEmoji(
                                      //         "24",
                                      //         emoji24HoursCost
                                      //     )
                                      // }
                                    >
                                      <div className="flex flex-col items-center">
                                        <div
                                          className={
                                            emojiAdd == "one"
                                              ? `font-bold text-purple  text-md`
                                              : `font-bold  text-md`
                                          }
                                        >
                                          24 hours
                                        </div>
                                        <div>{`$ ${emoji24HoursCost}`}</div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        emojiAdd === "7Days"
                                          ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          : disabledEmojiTabs.seven
                                          ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                          : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                      }
                                      onClick={() => {
                                        if (!disabledEmojiTabs.seven) {
                                          handleAddEmoji(
                                            "7Days",
                                            emoji7DaysCost
                                          );
                                        }
                                      }}
                                      // className={
                                      //     emojiAdd === "7Days"
                                      //         ? `w-full h-13 border-2 cursor-pointer flex  border-purple items-center justify-center`
                                      //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                      // }
                                      // onClick={() =>
                                      //     handleAddEmoji(
                                      //         "7Days",
                                      //         emoji7DaysCost
                                      //     )
                                      // }
                                    >
                                      <div className="flex flex-col items-center">
                                        <div
                                          className={
                                            emojiAdd == "two"
                                              ? `font-bold text-purple  text-md`
                                              : `font-bold  text-md`
                                          }
                                        >
                                          7 days
                                        </div>
                                        <div>{`$ ${emoji7DaysCost}`}</div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        emojiAdd === "30days"
                                          ? `w-full h-13 border-2 cursor-pointer flex flex-col text-purple border-purple items-center justify-center`
                                          : disabledEmojiTabs.thirty
                                          ? "w-full h-13 bg-graymore opacity-50 cursor-pointer border flex flex-col items-center justify-center"
                                          : "w-full h-13 cursor-pointer border flex flex-col items-center justify-center"
                                      }
                                      onClick={() => {
                                        if (!disabledEmojiTabs.thirty) {
                                          handleAddEmoji(
                                            "30days",
                                            emoji30DaysCost
                                          );
                                        }
                                      }}
                                      // className={
                                      //     emojiAdd === "30days"
                                      //         ? `w-full h-13 border-2 cursor-pointer flex  border-purple items-center justify-center`
                                      //         : `w-full h-13 cursor-pointer border flex items-center justify-center`
                                      // }
                                      // onClick={() =>
                                      //     handleAddEmoji(
                                      //         "30days",
                                      //         emoji30DaysCost
                                      //     )
                                      // }
                                    >
                                      <div className="flex flex-col items-center">
                                        <div
                                          className={
                                            emojiAdd == "three"
                                              ? `font-bold text-purple  text-md`
                                              : `font-bold  text-md`
                                          }
                                        >
                                          30 days
                                        </div>
                                        <div>{`$ ${emoji30DaysCost}`}</div>
                                      </div>
                                    </div>
                                  </div>
                                  {(!item?.upgrades?.emoji ||
                                    isExpired(
                                      item?.upgrades?.emoji?.endDate,
                                      item?.upgrades?.emoji?.endTime
                                    )) && (
                                    <div
                                      className=" h-13 w-16 cursor-pointer active:opacity-50 border flex items-center justify-center"
                                      onClick={handleRemoveEmoji}
                                    >
                                      X
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="gap-4 flex flex-col">
                                <AdCard
                                  age={age}
                                  name={name}
                                  ethnicity={ethnicity}
                                  ethnicOrigin={ethnicOrigin}
                                  selectedcity={selectedcity}
                                  uploadimg={uploadimg}
                                  adTitle={adTitle}
                                  textAreadata={textAreadata}
                                />
                                <div
                                  className="bg-purple text-white text-md font-semibold lg:w-70p md:w-70p sm:w-70p w-full  h-10 flex items-center justify-center gap-2 cursor-pointer active:opacity-50
                          lg:text-sm md:text-sm sm:text-sm text-xxs"
                                  onClick={() =>
                                    handleAddEmoji("7Days", emoji7DaysCost)
                                  }
                                >
                                  Add Now from CAD $0.92/hour
                                  <img
                                    src={images.Vector4}
                                    alt=""
                                    className="lg:w-2 lg:h-2 w-auto h-2 "
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Cost */}
            <div className="lg:flex flex-col lg:w-46p w-[95%] mr-2 mx-auto">
              <div className="sticky top-[90px] ">
                <div className="text-black bg-transparent shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-full flex-col h-auto flex items-center p-5 pt-5 pb-5 gap-2 ">
                  <div
                    className="flex w-full justify-between border-b
                      text-white"
                  >
                    <div className="w-full flex flex-col  ">
                      <div className="w-full text-xl font-semibold ">
                        <div className="flex gap-2 flex-col ">
                          <div className="">Advertisement</div>
                        </div>
                      </div>
                      <div className="flex gap-2 flex-col pt-2">
                        <div>{selectedcity}</div>
                      </div>
                    </div>
                    <div className=" flex font-bold text-xl flex-col items-center text-white ">
                      {/* <div className="w-80p text-end">
                                                {"FREE"}
                                            </div> */}
                    </div>
                  </div>
                  {selectedPageOneSlot && (
                    <div className="w-full flex flex-col border-b gap-2 relative text-white group-hover/item ">
                      <div className="flex justify-between w-full">
                        <div className="w-full  text-xl font-semibold">
                          Page 1
                        </div>
                        <div className="w-full text-end  font-bold text-xl ">{`$ ${selectedPageOneSlot?.cost}`}</div>
                      </div>
                      {(!item?.upgrades?.pageOne ||
                        isExpired(
                          item?.upgrades?.pageOne?.endDate,
                          item?.upgrades?.pageOne?.endTime
                        )) && (
                        <div
                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%]
                                                flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100  transition-opacity duration-300
                                                active:opacity-50"
                          onClick={handleRemovePageOne}
                        >
                          X
                        </div>
                      )}
                      <div>
                        {`${
                          selectedPageOneSlot?.hour === "five"
                            ? "5"
                            : selectedPageOneSlot?.hour === "three"
                            ? "3"
                            : "7"
                        } Hours from ${selectedPageOneSlot?.startTime}`}
                      </div>
                    </div>
                  )}
                  {selectPageTwoSLot && (
                    <div className="w-full flex flex-col border-b relative py-3 text-white">
                      <div className="flex justify-between w-full">
                        <div className="w-full font-semibold">
                          {`
                        Floral Ad theme :
                        ${
                          selectPageTwoSLot?.hour === "five"
                            ? "5"
                            : selectPageTwoSLot?.hour === "three"
                            ? "3"
                            : "7"
                        }
                          hours
                          `}
                        </div>
                        <div className="w-[85px]  text-end  font-bold text-lg text-white">{`$ ${selectPageTwoSLot?.cost}`}</div>
                      </div>
                      {(!item?.upgrades?.premiumTheme ||
                        isExpired(
                          item?.upgrades?.premiumTheme?.endDate,
                          item?.upgrades?.premiumTheme?.endTime
                        )) && (
                        // !item?.upgrades?.premiumTheme &&
                        <div
                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%]
                                            flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300
                                            active:opacity-50"
                          onClick={closeThemeAd}
                        >
                          X
                        </div>
                      )}
                    </div>
                  )}
                  {selectedCarousel && (
                    <div className="flex w-full justify-between border-b relative text-white">
                      <div className="w-full flex flex-col">
                        <div className="w-full text-xl font-semibold ">
                          <div className="flex gap-2 flex-col ">
                            <div>Carosoul</div>
                          </div>
                        </div>
                        <div className="flex gap-2 flex-col pt-2">
                          <div>
                            {`
                        ${
                          selectedCarousel?.hour === "three"
                            ? "3"
                            : selectedCarousel?.hour === "six"
                            ? "6"
                            : "9"
                        }
                          hours
                          `}
                          </div>
                        </div>
                      </div>
                      <div className=" flex font-bold text-lg flex-col items-center text-white ">
                        <div className="w-[60px] text-end">
                          {`$ ${selectedCarousel?.cost}`}
                        </div>
                      </div>
                      {(!item?.upgrades?.carousel ||
                        isExpired(
                          item?.upgrades?.carousel?.endDate,
                          item?.upgrades?.carousel?.endTime
                        )) && (
                        <div
                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%]
                       flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300
                       active:opacity-50"
                          onClick={() => {
                            handleRemoveCerousel();
                          }}
                        >
                          X
                        </div>
                      )}
                    </div>
                  )}
                  {selectedAvailableNow && (
                    <div className="flex w-full justify-between border-b relative text-white">
                      <div className="w-full flex flex-col">
                        <div className="w-full text-xl font-semibold ">
                          <div className="flex gap-2 flex-col ">
                            <div>Available Now</div>
                          </div>
                        </div>
                        <div className="flex gap-2 flex-col pt-2">
                          <div>
                            {`${
                              selectedAvailableNow?.hour === "one"
                                ? "1 hour"
                                : selectedAvailableNow?.hour === "two"
                                ? "2 hours"
                                : selectedAvailableNow?.hour === "three"
                                ? "3 hours"
                                : "4 hours"
                            }`}
                          </div>
                        </div>
                      </div>
                      <div className=" flex font-bold text-lg flex-col items-center text-white">
                        <div className="w-[60px] text-end">
                          {`$ ${selectedAvailableNow?.cost}`}
                        </div>
                      </div>
                      {(!item?.upgrades?.availableNow ||
                        isExpired(
                          item?.upgrades?.availableNow?.endDate,
                          item?.upgrades?.availableNow?.endTime
                        )) && (
                        <div
                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%] flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300 active:opacity-50"
                          onClick={handleRemoveAvailableNow}
                        >
                          X
                        </div>
                      )}
                    </div>
                  )}
                  {selectedFeaturedAd && (
                    <div className="flex w-full justify-between border-b relative text-white">
                      <div className="w-full flex flex-col">
                        <div className="w-full text-xl font-semibold ">
                          <div className="flex gap-2 flex-col ">
                            <div>Featured Ad</div>
                          </div>
                        </div>
                        <div className="flex gap-2 flex-col pt-2">
                          <div>
                            {`${
                              selectedFeaturedAd?.hour === "one"
                                ? "1 hour"
                                : selectedFeaturedAd?.hour === "three"
                                ? "3 hours"
                                : "7 hours"
                            }`}
                          </div>
                        </div>
                      </div>
                      <div className=" flex font-bold text-lg flex-col items-center text-white ">
                        <div className="w-[60px] text-end">
                          {`$ ${selectedFeaturedAd?.cost}`}
                        </div>
                      </div>
                      {(!item?.upgrades?.featuredAdd ||
                        isExpired(
                          item?.upgrades?.featuredAdd?.endDate,
                          item?.upgrades?.featuredAdd?.endTime
                        )) && (
                        <div
                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%] flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300 active:opacity-50"
                          onClick={handleRemoveFeaturedAd}
                        >
                          X
                        </div>
                      )}
                    </div>
                  )}
                  {selectedEmoji && (
                    <div className="flex w-full justify-between border-b relative text-white">
                      <div className="w-full flex flex-col">
                        <div className="w-full text-xl font-semibold ">
                          <div className="flex gap-2 flex-col ">
                            <div>Emoji for Ad Title</div>
                          </div>
                        </div>
                        <div className="flex gap-2 flex-col pt-2 ">
                          <div>
                            {`${
                              selectedEmoji?.hour === "24"
                                ? "24 hours"
                                : selectedEmoji?.hour === "7Days"
                                ? "7 days"
                                : "30 days"
                            }`}
                          </div>
                        </div>
                      </div>
                      <div className="text-black flex font-bold text-lg flex-col items-center  text-white">
                        <div className="w-[65px] text-end">
                          {`$ ${selectedEmoji?.cost}`}
                        </div>
                      </div>
                      {(!item?.upgrades?.emoji ||
                        isExpired(
                          item?.upgrades?.emoji?.endDate,
                          item?.upgrades?.emoji?.endTime
                        )) && (
                        <div
                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%] flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300 active:opacity-50"
                          onClick={handleRemoveEmoji}
                        >
                          X
                        </div>
                      )}
                    </div>
                  )}
                  {selectedBumps && (
                    <div className="w-full flex flex-col border-b relative py-3 text-white">
                      <div className="flex justify-between w-full">
                        <div className="w-full font-semibold">
                          {`${selectedBumps?.totalBumps} Bumps Package`}
                        </div>
                        {/* {formatedCost(totalCost, previousPayment)} */}
                        <div className="w-[105px]  text-end  font-bold text-md text-white">{`$ ${selectedBumps?.cost?.toFixed(
                          2
                        )}`}</div>
                      </div>
                      {selectedBumps?.selectedScheduleBumps?.length > 0 && (
                        <div className="text-sm">
                          {`${calculateTotalScheduleBumps(
                            selectedBumps?.selectedScheduleBumps
                          )} Scheduled Bumps`}
                        </div>
                      )}
                      {!item?.upgrades?.bumps && (
                        <div
                          class="rounded-full absolute  w-7 h-7 bg-purple text-white border-2 font-semibold left-[-13%] top-[20%] flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-300 active:opacity-50"
                          onClick={handleRemoveBumps}
                        >
                          X
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div></div>
                <div className="bg-purple p-6 flex justify-between text-2xl">
                  <div className="text-white font-semibold">Total</div>
                  <div className="text-white font-semibold">
                    {formatedCost(totalCost, previousPayment)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* BUTTONS  */}
        <div className=" w-full flex items-center justify-end pt-10 pb-20 ">
          <div
            className="text-white bg-purple w-full h-10 md:w-[200px] flex lg:text-base md:text-sm sm:text-sm text-xs items-center justify-center cursor-pointer active:opacity-50"
            onClick={() => !loader && handleConfirm()}
          >
            {insufficentBalance && (
              <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                <div className="text-white rounded-xl py-4 bg-purplegray flex items-center justify-center px-5 flex flex-col gap-5">
                  {/* <div>Ad posted successfully 🎉 </div> */}
                  {/* <img
                                src={images.tickmarkdone}
                                className="w-[50%]"
                              /> */}
                  <div className="font-semibold font-lg">
                    Insufficient balance. Cannot proceed with the advertisement
                    posting.
                  </div>
                  <div
                    className="bg-purple rounded-md px-4 py-2 cursor-pointer active:opacity-50"
                    onClick={() => {
                      setPaymentModal(false);
                      navigate("/giftcard");
                    }}
                  >
                    Add Pleasury Credits
                  </div>
                </div>
              </div>
            )}
            {loader ? (
              <ClipLoader
                color="#FFFF"
                cssOverride={{
                  height: "22px",
                  width: "22px",
                }}
              />
            ) : totalCost - previousPayment > 0 ? (
              "Pay by P C"
            ) : (
              "Cancel"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
